import { useState, useEffect } from "react";

const useHostName = () => {
	const [hostName, setHostName] = useState("");

	useEffect(() => {
		const storedHostName = localStorage.getItem("hostName");
		if (storedHostName) {
			setHostName(storedHostName);
		} else {
			console.log("No host name found in localStorage");
		}
	}, []);

	return { hostName };
};

export default useHostName;
