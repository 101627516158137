function roundNumToDecimalPlaces(
    input: string | number,
    decimalPlaces = 2
): string {
    let inputVal: number;

    if (typeof input === "string") {
        inputVal = Number(removeCommasFromNumber(input));
    } else {
        inputVal = input;
    }

    const inputStr = input.toString();

    if (
        inputStr.includes(".") &&
        inputStr.split(".")[1].length > decimalPlaces
    ) {
        return inputVal.toFixed(decimalPlaces);
    } else {
        return inputVal.toFixed(decimalPlaces);
    }
}

function removeCommasFromNumber(input: string): string {
    return input.replace(/,/g, "");
}

export default roundNumToDecimalPlaces;
