import { getInvoicePdf } from "./../pages/Orders/listing/api/OrdersApi";

/**
 * Downloads an invoice PDF by its UUID.
 *
 * @param invoiceUuid The UUID of the invoice to download.
 */

function base64ToArrayBuffer(base64: string) {
	const binaryString = atob(base64);
	const len = binaryString.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes.buffer;
}
async function downloadInvoicePdf(invoiceUuid: string): Promise<void> {
	if (!invoiceUuid) {
		return;
	}

	try {
		const pdfData = await getInvoicePdf(invoiceUuid);
		const base64Data = pdfData.invoicePdfFile;
		const arrayBuffer = base64ToArrayBuffer(base64Data);

		const blob = new Blob([arrayBuffer], {
			type: "application/pdf",
		});

		const pdfUrl = URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.href = pdfUrl;
		link.download = `Invoice_${pdfData.number}.pdf`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		// Clean up the URL after initiating download
		URL.revokeObjectURL(pdfUrl);
	} catch (error) {
		console.error("Failed to download invoice PDF:", error);
	}
}
export default downloadInvoicePdf;
