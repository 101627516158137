import React, { useState } from 'react';
import getFontAwesomeIcon from '../../utils/getFontAwesomeIcon';
import BaseButton from './BaseButton';
import Input from './Input';

interface PasswordInputProps {
	id: string;
	value: string;
	placeholder?: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	required?: boolean;
	name?: string;
	isValid?: boolean;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	additionalClasses?: string;
	iconVisible?: string;
	iconHidden?: string;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
	id,
	value,
	placeholder = "Password *",
	onChange,
	required = false,
	name = "userPassword",
	isValid = true,
	onKeyDown,
	additionalClasses = "",
	iconVisible = "EyeSlash",
	iconHidden = "Eye",
}) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const togglePasswordVisibility = () => {
		setIsPasswordVisible(!isPasswordVisible);
	};

	const buttonClassNames = "absolute right-0 top-0 h-full flex items-center pr-3 bg-transparent border-none text-gray-500";

	return (
		<div className="relative w-full">
			<Input
				id={id}
				firstIcon={getFontAwesomeIcon("Fingerprint", "regular") || undefined}
				value={value}
				placeholder={placeholder}
				type={isPasswordVisible ? "text" : "password"}
				onChange={onChange}
				label=""
				required={required}
				name={name}
				isValid={isValid}
				onKeyDown={onKeyDown}
				additionalClasses={`pl-10 pr-10 ${additionalClasses}`}
			/>
			<BaseButton
				icon={getFontAwesomeIcon(isPasswordVisible ? iconVisible : iconHidden, "regular")}
				type="button"
				additionalClasses={buttonClassNames}
				onClick={togglePasswordVisibility}
				text=""
				fontColor="gray-500"
			/>
		</div>
	);
};

export default PasswordInput;
