import React, { useState } from "react";

import Container from "../../components/layout/Container";
import Heading from "../../components/layout/MainHeading";
import Section from "../../components/layout/Section";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBinary,
	faBinaryCircleCheck,
	faFileBinary,
} from "@fortawesome/pro-regular-svg-icons";
import { faBinaryLock } from "@fortawesome/pro-light-svg-icons";
import { faBinarySlash } from "@fortawesome/pro-solid-svg-icons";

const PageNotFound: React.FC = () => {
	return (
		<Container
			testId={"contacts-page"}
			containerClasses="container mx-auto px-4"
		>
			<Section>
				<Heading
					text="Page not found"
					additionalClasses="text-center mb-2"
				/>
				<div className="text-[100px] mt-12">
					<FontAwesomeIcon icon={faBinarySlash} />
				</div>
			</Section>
		</Container>
	);
};

export default PageNotFound;
