import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "../ui/FormButton";

interface ErrorMessageProps {
	message: string;
	additionalClasses?: string;
	clearMessage?: React.Dispatch<React.SetStateAction<boolean>>;
	color: "green-500" | "red-500" | "yellow-500" | "blue-500" | "orange-500";
	icon?: JSX.Element;
	fullColor?: boolean;
	hasBumper?: boolean;
	title?: string;
	hasClearText?: boolean;
	clearText?: string;
}

const Toaster: React.FC<ErrorMessageProps> = ({
	message,
	additionalClasses,
	clearMessage,
	color = "red-500",
	icon,
	fullColor = false,
	hasBumper = true,
	title = "",
	hasClearText,
	clearText,
}) => {
	const handleClearMessage = () => {
		if (clearMessage) {
			clearMessage(true);
		}
	};

	const borderClass = hasBumper ? "border-l-4" : "border-l-1";
	const backgroundColorClass = fullColor ? `bg-${color}` : "bg-white";
	const messageColorClass = fullColor ? "text-white" : "text-black";
	const xIconColorClass = fullColor ? "text-white" : "text-black";
	//This comp has a weird Switch statement to control the color of the icon, this is the only way I could get it to work with rendering styles so if you can make it cooler go for it.
	switch (color) {
		case "green-500":
			var iconClass = "text-green-500";
			break;
		case "red-500":
			var iconClass = "text-red-500";
			break;
		case "orange-500":
			var iconClass = "text-orange-500";
			break;
		case "blue-500":
			var iconClass = "text-blue-500";
			break;
		default:
			var iconClass = "";
	}

	return (
		<div
			className={`border ${borderClass} border-${color} p-4 shadow-lg   shadow-boxShadow text-sm ${backgroundColorClass} rounded ${additionalClasses}`}
		>
			<h3 className="ml-8 font-bold text-xl text-left">{title}</h3>
			<div className="flex items-center justify-between">
				<span className={`mr-2 ${iconClass}`}>{icon}</span>
				<span className={messageColorClass}>{message}</span>
				{clearMessage && !hasClearText && (
					<span className="cursor-pointer hover:bg-gray-200 py-1 px-2 rounded-md">
						<FontAwesomeIcon
							onClick={handleClearMessage}
							className={`cursor-pointer ${xIconColorClass}`}
							icon={faTimes}
						/>
					</span>
				)}
				{hasClearText && clearText && (
					<Button
						onClick={handleClearMessage}
						text={clearText}
						color="primary"
						shape="flat"
						type={"button"}
						isDisabled={false}
					/>
				)}
			</div>
		</div>
	);
};

export default Toaster;
