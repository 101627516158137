import React, { ReactNode } from "react";

export type TagName = "span" | "p" | "h1" | "h2" | "h3" | "h4" | "h5" | string;

export interface BaseTextTypes {
	size: string;
	color: string;
	tag?: TagName;
	text: ReactNode;
	fontFamily?: string;
	additionalClasses?: string | undefined;
}

const BaseText: React.FC<BaseTextTypes> = ({
	size,
	color,
	tag,
	text,
	fontFamily,
	additionalClasses,
}) => {
	let textClasses = `${size} ${color} ${fontFamily} ${additionalClasses}`;

	const ElementTag: React.ElementType = tag as
		| keyof JSX.IntrinsicElements
		| React.ComponentType;

	return <ElementTag className={`${textClasses}`}>{text}</ElementTag>;
};

export default BaseText;
