import { useCallback, useEffect, useContext } from "react";
import {
	Route,
	Routes,
	Navigate,
	useNavigate,
	useLocation,
} from "react-router-dom";
import UserContext from "./hooks/useUserContext";
import { OrderView, OrdersPage } from "./pages/Orders";
import { ItemsPage } from "./pages/Items";
import { LoginPage } from "./pages/Login";
import { ShipmentItemsPage } from "./pages/ShipmentItems";
import { FulfilledShipmentsPage } from "./pages/FulfilledShipments";
import { ShipmentsPage } from "./pages/Shipments";
import { VendorItemsPage } from "./pages/VendorItems";
import { EditShipmentPage } from "./pages/UpdateShipment";
import { PageNotFound } from "./components/misc";
import { Footer, Header } from "./components/layout";
import { ForgotPasswordPage } from "./pages/ForgotPassword";
import { CreatePasswordPage } from "./pages/CreatePassword";
import ToasterList from "./components/misc/ToasterList";
import { useToaster } from "./hooks/useToasterContext";
import { handleClientAuthentication } from "./utils/handleClientAuthentication";
import { getQueryParams } from "./utils";
import { getClientUrl } from "./pages/Login/api/LoginApi";

const InnerApp = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user, setUser } = useContext(UserContext);
	const { toasters } = useToaster();
	const currentUrl = location.pathname + location.search;

	/* ---------------------------------------------------------------------------------------------- */
	/*                                  CLIENT AUTHENTICATION HANDLER START                               */
	/* ---------------------------------------------------------------------------------------------- */

	useEffect(() => {
		const fetchData = async () => {
			const queryParams = getQueryParams();
			if (queryParams["customerIdentity"] !== undefined) {
				const fetchData = async () => {
					const url = await getClientUrl(
						queryParams["customerIdentity"]
					);
					if (isAuthenticated()) {
						//Add the query Params to the url
						const urlWithParams =
							url +
							"/shipment-items?internalId=" +
							queryParams["internalId"];
						window.location.href = urlWithParams;
					} else {
						//Add the query Params to the url
						const urlWithParams =
							url +
							"/login?internalId=" +
							queryParams["internalId"];
						window.location.href = urlWithParams;
					}
				};
				fetchData();
			} else if (
				location.pathname !== "/login" &&
				location.pathname !== "/forgot-password" &&
				location.pathname !== "/create-password" &&
				user.isUserLoggedIn === false
			) {
				await handleClientAuthentication(
					user,
					setUser,
					navigate,
					currentUrl
				);
			} else if (queryParams["saml"] !== undefined) {
				await handleClientAuthentication(
					user,
					setUser,
					navigate,
					currentUrl
				);
			}
		};
		fetchData();
	}, []);
	/* ---------------------------------------------------------------------------------------------- */
	/*                                  CLIENT AUTHENTICATION HANDLER END                              */
	/* ---------------------------------------------------------------------------------------------- */

	const queryStrings = new URLSearchParams(window.location.search);
	const uuid = queryStrings.get("uuid");
	const orders = queryStrings.get("orders");
	if (uuid && orders) {
		localStorage.setItem("uuid", uuid || "");
		localStorage.setItem("orders", orders || "");
		localStorage.setItem("approvalFilter", "true");
	}

	const isAuthenticated = () => {
		const token = localStorage.getItem("accessToken");
		const isUserLoggedIn = user.isUserLoggedIn;

		return !!token && isUserLoggedIn;
	};

	return (
		<div className="App app-body m-0 relative overflow-hidden ">
			<ToasterList messages={toasters} />
			<Header />

			<Routes>
				{/* Publicly accessible routes */}
				<Route path="/login" element={<LoginPage />} />
				<Route
					path="/forgot-password"
					element={<ForgotPasswordPage />}
				/>
				<Route
					path="/create-password"
					element={<CreatePasswordPage />}
				/>

				{/* Private routes, accessible only to logged-in users */}
				{user.isUserLoggedIn ? (
					<>
						<Route
							path="/order-view/:uuid"
							element={<OrderView />}
						/>
						<Route path="/items" element={<ItemsPage />} />
						<Route
							path="/vendor-items"
							element={<VendorItemsPage />}
						/>
						<Route
							path="/fulfilled-shipments"
							element={<FulfilledShipmentsPage />}
						/>
						<Route path="/shipments" element={<ShipmentsPage />} />
						<Route
							path="/shipment-items"
							element={<ShipmentItemsPage />}
						/>
						<Route
							path="/edit-shipment"
							element={<EditShipmentPage />}
						/>
						<Route path="/" element={<OrdersPage />} />
						<Route path="/*" element={<PageNotFound />} />
					</>
				) : (
					""
				)}
			</Routes>
			{user.isUserLoggedIn && isAuthenticated() && (
				<Footer companyName={"Agilant Solutions Inc."} />
			)}
		</div>
	);
};

export default InnerApp;
