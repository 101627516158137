import React from "react";
import { useContext } from "react";
import { togaApiRequest } from "../../../api/toga";
import { getQueryParams } from "../../../utils/getQueryParams";
import UserContext from "../../../hooks/useUserContext";
import { User } from "../../../hooks/useUserContext";
import { useLocation } from "react-router-dom";
// import { createBrowserHistory } from "history";

interface UserProps {
	firstName: string;
	lastName: string;
	isUserLoggedIn: boolean;
}

interface OptionsType {
	transactionId?: string;
}

interface ExtendedOptionsType extends OptionsType {
	fields?: {
		[key: string]: string[];
	};
}

interface SamlData {
	auth: string;
	payload: any;
}

type SetUserFunc = (user: UserProps) => void;
const storedHostName = localStorage.getItem("hostName");

export async function submitLoginPassword(
	email: string,
	password: string,
	setUser: SetUserFunc,
	navigate: (to: string) => void
): Promise<boolean> {
	// const history = createBrowserHistory();
	const payload = {
		email: email,
		password: password,
	};

	// make an auth request, also asking for top-level user information
	const response = await togaApiRequest("POST", "/auth/login", payload, {
		fields: [{ user: ["firstName", "lastName"] }],
	} as OptionsType);
	if (response.isSuccess) {
		const accessToken = response.data.tokens.access;
		const refreshToken = response.data.tokens.refresh;
		const firstName = response.data.user.firstName;
		const lastName = response.data.user.lastName;

		localStorage.setItem("accessToken", accessToken);
		localStorage.setItem("refreshToken", refreshToken);

		//Make an api call for Users to get the user details and save it in the state
		const userOptions = {
			fields: storedHostName === "COMPASS" ? ["_isSupervisor", "_isAdmin", "uuid"] : ["uuid"],
			where: {
				and: [{ "Users.email": { "=": email } }],
			},
		}
		const userData = await togaApiRequest("GET", "/users", null, userOptions);

		const newUser: User = {
			firstName: firstName,
			lastName: lastName,
			isUserLoggedIn: true,
			isAdmin: userData.data.users[0]._isAdmin,
			isSupervisor: userData.data.users[0]._isSupervisor,
			uuid: userData.data.users[0].uuid,
		};
		setUser(newUser); // Save the user information in the state and local storage

		return true; // Login is successful
	} else {
		console.log(response.messages[1].code, response.messages[0].code);
		return false; // Login is unsuccessful
	}
}

export async function validateEmailDomain(email: string) {
	const domain = email.split("@")[1];
	const payload = null;

	//Getting the current url and splitting it into protocol and hostname
	const protocol = window.location.protocol;
	const hostname = window.location.hostname;
	const domainName = protocol + "//" + hostname;

	let environment = import.meta.env.MODE;
	if (environment === "development") {
		environment = "DEV";
	} else if (environment === "production") {
		environment = "PRODUCTION";
	} else if (environment === "alpha") {
		environment = "ALPHA";
	}

	const response = await togaApiRequest("GET", "/domains", payload, {
		fields: ["domain"],
		join: [
			{
				ClientEmailDomains: {
					"ClientEmailDomains.clientId": "Domains.clientId",
				},
			},
			{
				["Domains@SelfDomains"]: {
					"SelfDomains.appId": "Domains.appId",
				},
			},
			{
				Environments: {
					"Domains.environmentId": "Environments.id",
				},
			},
		],
		where: {
			and: [
				{
					"ClientEmailDomains.domain": {
						like: domain,
					},
				},
				{
					"Environments.slug": {
						like: environment,
					},
				},
				{
					"SelfDomains.domain": {
						like: domainName,
					},
				},
			],
		},
	} as OptionsType);

	if (response.isSuccess) {
		const domain = response.data.domains[0].domain;
		if (environment === "DEV") {
			return domain + ":5173/login";
		}
		return domain;
	} else {
		console.log(response.messages[1].code, response.messages[0].code);
		return false;
	}
}

export async function getClientUrl(uuid:string){
	//Getting the current url and splitting it into protocol and hostname
	const protocol = window.location.protocol;
	const hostname = window.location.hostname;
	const payload = null;
	let environment = import.meta.env.MODE;
	if (environment === "development") {
		environment = "DEV";
	} else if (environment === "production") {
		environment = "PRODUCTION";
	} else if (environment === "alpha") {
		environment = "ALPHA";
	}

	const response = await togaApiRequest("GET", "/domains", payload, {
		fields: ["domain"],
		join: [
			{
				Clients: {
					"Clients.id": "Domains.clientId",
				},
			},
			{
				Environments: {
					"Domains.environmentId": "Environments.id",
				},
			},
		],
		where: {
			and: [
				{
					"Clients.uuid": {
						like: uuid,
					},
				},
				{
					"Environments.slug": {
						like: environment,
					},
				}
			],
		},
	} as OptionsType);

	console.log(response);
	if (response.isSuccess) {
		const domain = response.data.domains[0].domain;
		if (environment === "DEV") {
			return domain + ":5173";
		}
		return domain;
	} else {
		console.log(response.messages[1].code, response.messages[0].code);
		return false;
	}
}

/*
export async function handleClientAuthentication(
	navigate: (to: string) => void
): Promise<boolean | undefined> {
	const isAuthenticated = () => {
		const token = localStorage.getItem("accessToken");
		const isUserLoggedIn = user.isUserLoggedIn;
		return !!token && isUserLoggedIn;
	};

	if (isAuthenticated()) {
		// user is authenticated
		navigate("/");
	} else {
		// no access token, not logged in

		// get query parameters
		const queryParams = getQueryParams();
		if (queryParams["saml"] === undefined) {
			// This is NOT an incoming SAML request from our Assertion Consumer Service (ACS)

			const payload = null;

			//Getting the current url and splitting it into protocol and hostname
			const protocol = window.location.protocol;
			const hostname = window.location.hostname;
			const domainName = protocol + "//" + hostname;

			let environment = import.meta.env.MODE;
			if (environment === "development") {
				environment = "DEV";
			} else if (environment === "production") {
				environment = "PRODUCTION";
			} else if (environment === "alpha") {
				environment = "ALPHA";
			}

			const response = await togaApiRequest("GET", "/domains", payload, {
				fields: [
					"ClientAuthentications.uuid",
					"ClientAuthentications.type",
					"Apps.uuid",
				],
				join: [
					{
						Environments: {
							"Environments.id": "Domains.environmentId",
						},
					},
					{
						ClientAuthentications: {
							"ClientAuthentications.clientId":
								"Domains.clientId",
							"ClientAuthentications.environmentId":
								"Domains.environmentId",
						},
					},
					{
						Apps: {
							"Apps.id": "Domains.appId",
						},
					},
				],
				where: {
					and: [
						{
							"Domains.domain": {
								like: domainName,
							},
						},
						{
							"Environments.slug": {
								like: environment,
							},
						},
					],
				},
			} as OptionsType);

			if (response.isSuccess) {
				const clientConfig = response.data.domains[0];
				if (clientConfig.ClientAuthentications.type === "SSO") {
					// this is SSO authentication, call the singleSignOnServiceUrl scripted API to determine where to direct them to

					// get the single sign on url for this client's environment
					const responseSso = await togaApiRequest(
						"GET",
						"/client-authentications/singleSignOnServiceUrl",
						payload,
						{
							uuid: clientConfig.ClientAuthentications.uuid,
							returnToAppUuid: clientConfig.Apps.uuid,
						} as OptionsType
					);
					if (responseSso.isSuccess) {
						// successful response, redirect to the single sign on service
						const singleSignOnServiceUrl =
							responseSso.data.clientAuthentications
								.singleSignOnServiceUrl;
						window.location.href = singleSignOnServiceUrl;
					}
				}
			} else {
				console.log(
					response.messages[1].code,
					response.messages[0].code
				);
				return false;
			}
		} else {
			// This IS an incoming SAML request from our Assertion Consumer Service (ACS)

			// decode the saml paramater containing the encrypted user
			const samlEncoded = queryParams["saml"];
			const decodedSaml = Buffer.from(samlEncoded, "base64").toString();
			const saml: SamlData = JSON.parse(decodedSaml) as SamlData;

			// make an auth request to get an access token
			const response = await togaApiRequest(
				"POST",
				"/auth/" + saml["auth"],
				saml["payload"],
				{
					fields: [{ user: ["firstName", "lastName"] }],
				} as OptionsType
			);

			if (response.isSuccess) {
				const accessToken = response.data.tokens.access;
				const refreshToken = response.data.tokens.refresh;
				const firstName = response.data.user.firstName;
				const lastName = response.data.user.lastName;

				const newUser: User = {
					firstName: firstName,
					lastName: lastName,
					isUserLoggedIn: true,
				};
				console.log(newUser);
				setUser(newUser); // Save the user information in the state and local storage

				localStorage.setItem("accessToken", accessToken);
				localStorage.setItem("refreshToken", refreshToken);
				navigate("/");

				return true; // Login is successful
			} else {
				console.log(
					response.messages[1].code,
					response.messages[0].code
				);
				return false; // Login is unsuccessful
			}
		}
	}
	return undefined;
}
*/
