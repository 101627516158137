import { convertConstructorColumnTitles } from "../../utils";

export type SearchCriterion = {
	searchColumn: any;
	submittedSearchText: string;
};

type AdditionalData = {
	whereClause?: string;
};

type UseConstructUrlOptions = {
	slug: string;
	searchCriteria: SearchCriterion[];
	pageSize: number;
	sortColumn: Record<string, string>;
	columnMappings: any;
	page: number;
	pageMeta?: any;
	additionalData?: AdditionalData;
};

const useConstructUrl = ({
	slug,
	searchCriteria,
	pageSize,
	sortColumn,
	page,
	columnMappings,
	pageMeta,
	additionalData,
}: UseConstructUrlOptions): string => {
	let urlParts: string[] = [];
	let slugForUrl = slug;
	let columnKey = "";

	if (sortColumn && Object.keys(sortColumn).length > 0) {
		[columnKey] = Object.keys(sortColumn);
	}

	if (searchCriteria && searchCriteria.length > 0) {
		const convertedColumnMappings = convertConstructorColumnTitles(
			pageMeta,
			columnMappings
		);

		searchCriteria.forEach(({ searchColumn, submittedSearchText }) => {
			const columnNameToUpdate = convertedColumnMappings.find(
				(item: {
					settings: { field: string };
					labelSingular: string;
				}) => {
					const simplifiedItemSlug = item.labelSingular
						.replace(/\W+/g, "")
						.toLowerCase();
					const simplifiedHeader = searchColumn.Header.replace(
						/\W+/g,
						""
					).toLowerCase();

					return simplifiedItemSlug.includes(simplifiedHeader);
				}
			);

			if (!columnNameToUpdate) {
				console.error(
					"No column mapping found for the header:",
					searchColumn.Header
				);
				return;
			}

			const searchPart = `${slugForUrl}_${customEncodeURIComponent(
				columnNameToUpdate.slug
			)}=${customEncodeURIComponent(submittedSearchText)}`;
			urlParts.push(searchPart);
		});
	}

	urlParts.push(`${slug}_recordsPerPage=${pageSize}`);
	urlParts.push(`${slug}_page=${page}`);

	// Include additionalData, specifically the whereClause, if provided
	if (additionalData?.whereClause) {
		urlParts.push(
			`where=${customEncodeURIComponent(additionalData.whereClause)}`
		);
	}

	function customEncodeURIComponent(component: string): string {
		return encodeURIComponent(component).replace(/%20/g, "+");
	}

	let baseUrl = `?${urlParts.join("&")}`;
	const modalOrders = localStorage.getItem("orders");
	if (modalOrders) {
		baseUrl += `&Orders=${modalOrders}`;
	}
	const modalUuid = localStorage.getItem("uuid");
	if (modalUuid) {
		baseUrl += `&uuid=${modalUuid}`;
	}
	if (columnKey) {
		const sortDirection = sortColumn[columnKey];
		const prefix = sortDirection === "+" ? "+" : "-";
		let sortPart = `&${slugForUrl}_sort[0]=${prefix}${customEncodeURIComponent(
			columnKey
		)}`;
		return baseUrl + sortPart;
	}
	return baseUrl;
};

export default useConstructUrl;
