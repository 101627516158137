import React, { useRef, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import BaseModal from "../layout/Modal";

import SupplyPortal from "../misc/SupplyPortal";
import { getFontAwesomeIcon } from "../../utils";
import { simpleFadeVariants } from "../../motionVariants";

type ZoomCompProps = {
	isOpen: boolean;
	onClose: () => void;
	handlePrev: () => void;
	handleNext: () => void;
	activeIndex: number;
	imageUrls: string[];
	backgroundPosition: string;
	backgroundSize: string;
	getFontAwesomeIcon: (
		iconName: string,
		style: "solid" | "regular" | "light" | undefined
	) => JSX.Element | null;
	getLargeViewThumbnailClassNames: (isActive: boolean) => string;
	thumbnailsContainerClassNames: string;
	largeViewActiveImageClassNames: string;
	largeViewImageThumbnails: string;
	iconHoverClassNames: string;
	iconClassNames: string;
	handleThumbnailClick: (index: number) => void;
	enabled: boolean;
};

const ZoomComp: React.FC<ZoomCompProps> = ({
	isOpen,
	onClose,
	handlePrev,
	handleNext,
	activeIndex,
	imageUrls,
	backgroundPosition,
	backgroundSize,
	getFontAwesomeIcon,
	getLargeViewThumbnailClassNames,
	thumbnailsContainerClassNames,
	largeViewActiveImageClassNames,
	largeViewImageThumbnails,
	iconHoverClassNames,
	iconClassNames,
	handleThumbnailClick,
	enabled,
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const zoomContainerClassNames =
		"bg-transparent shadow-none min-h-[100%] max-h-[100%] overflow-hidden";
	const zoomIconClassNames = "flex justify-center";
	const iconSize = "text-lg";
	const zoomModalContainerClassNames =
		"flex justify-center max-h-svh min-h-svh overflow-auto relative z-10";

	const [isZoomed, setIsZoomed] = useState(false);
	const [zoomPosition, setZoomPosition] = useState(backgroundPosition);

	const handleImageClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		const { left, top, width, height } =
			e.currentTarget.getBoundingClientRect();
		const x = ((e.clientX - left) / width) * 100;
		const y = ((e.clientY - top) / height) * 100;
		setZoomPosition(`${x}% ${y}%`);
		setIsZoomed(!isZoomed);
	};

	const handleRefClick = (event: MouseEvent) => {
		// Prevent close if the clicked element has a data-close attribute
		const target = event.target as HTMLElement;
		if (target.closest('[data-close="false"]')) {
			return;
		}
		onClose();
	};

	useEffect(() => {
		const currentRef = ref.current;
		if (currentRef) {
			currentRef.addEventListener("click", handleRefClick);
		}
		return () => {
			if (currentRef) {
				currentRef.removeEventListener("click", handleRefClick);
			}
		};
	}, [isOpen]);

	return (
		<SupplyPortal containerId="portal-root">
			<div className="hidden lg:block">
				{isOpen && (
					<BaseModal
						isOpen={isOpen}
						containerClassNames={zoomContainerClassNames}
						onClose={onClose}
						altCloseButton={(handleClose) => (
							<div
								className={`${iconHoverClassNames}`}
								data-close="false"
							>
								<div
									className={`${iconClassNames} ${zoomIconClassNames}`}
									onClick={handleClose}
									data-close="false"
								>
									<span
										className={iconSize}
										data-close="false"
									>
										{getFontAwesomeIcon("x", "regular")}
									</span>
								</div>
							</div>
						)}
						children={
							<div
								ref={ref}
								className={zoomModalContainerClassNames}
								data-close="true"
							>
								<AnimatePresence>
									{isOpen && (
										<>
											<motion.div
												key="prev-icon"
												initial={
													simpleFadeVariants.initial
												}
												animate={
													simpleFadeVariants.animate
												}
												exit={simpleFadeVariants.exit}
												className="items-center flex mx-4"
												data-close="false"
											>
												<div
													onClick={handlePrev}
													className={
														iconHoverClassNames
													}
													data-close="false"
												>
													<div
														className={`${iconClassNames} ${zoomIconClassNames}`}
														data-close="false"
													>
														<span
															className={iconSize}
															data-close="false"
														>
															{getFontAwesomeIcon(
																"chevronLeft",
																"regular"
															)}
														</span>
													</div>
												</div>
											</motion.div>

											<div
												data-close="false"
												className=""
											>
												<motion.div // The main motion.div for image
													key={`image-${activeIndex}`} // Key based on activeIndex to uniquely identify images
													initial={
														simpleFadeVariants.initial
													}
													animate={
														simpleFadeVariants.animate
													}
													exit={
														simpleFadeVariants.exit
													}
													transition={{
														duration: 0.3,
													}}
													style={{
														backgroundImage: `url(${imageUrls[activeIndex]})`,
														backgroundPosition:
															zoomPosition,
														backgroundSize: isZoomed
															? "250%"
															: "cover",
													}}
													className={
														largeViewActiveImageClassNames
													}
													onClick={handleImageClick}
												/>
											</div>

											<motion.div
												key="next-icon"
												initial={
													simpleFadeVariants.initial
												}
												animate={
													simpleFadeVariants.animate
												}
												exit={simpleFadeVariants.exit}
												className="items-center flex mx-4"
												data-close="false"
											>
												<div
													onClick={handleNext}
													className={
														iconHoverClassNames
													}
													data-close="false"
												>
													<div
														className={`${iconClassNames} ${zoomIconClassNames}`}
														data-close="false"
													>
														<span
															className={iconSize}
															data-close="false"
														>
															{getFontAwesomeIcon(
																"chevronRight",
																"regular"
															)}
														</span>
													</div>
												</div>
											</motion.div>
										</>
									)}
								</AnimatePresence>
								<div
									className={`${thumbnailsContainerClassNames} ${largeViewImageThumbnails}`}
									data-close="false"
								>
									{imageUrls.map((url, index) => (
										<img
											key={index}
											src={url}
											alt={`Thumbnail ${index + 1}`}
											className={getLargeViewThumbnailClassNames(
												index === activeIndex
											)}
											onClick={() =>
												handleThumbnailClick(index)
											}
											tabIndex={!enabled ? -1 : 0}
											data-close="false"
										/>
									))}
								</div>
							</div>
						}
					/>
				)}
			</div>
		</SupplyPortal>
	);
};

type ImageCarouselProps = {
	imageUrls: string[];
	altText?: string;
	containerMinHeight?: string;
	containerMaxHeight?: string;
	enabled: boolean;
};

const imageVariants = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0 },
};

const VerticalImageCarousel: React.FC<ImageCarouselProps> = ({
	imageUrls,
	altText,
	containerMinHeight,
	containerMaxHeight,
	enabled,
}) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [largeView, setLargeView] = useState(false);
	const [backgroundSize, setBackgroundSize] = useState("cover");
	const [backgroundPosition, setBackgroundPosition] =
		useState("center center");

	const handleThumbnailClick = (index: number) => {
		if (enabled) {
			setActiveIndex(index);
		}
	};

	const handleActiveItemClick = () => {
		setLargeView(true);
	};

	const handleLargeViewClose = () => {
		setLargeView(false);
	};

	const handleNext = () => {
		if (enabled && activeIndex < imageUrls.length - 1) {
			setActiveIndex((prevIndex) => prevIndex + 1);
		}
	};

	const handlePrev = () => {
		if (enabled && activeIndex > 0) {
			setActiveIndex((prevIndex) => prevIndex - 1);
		}
	};

	const containerClassNames = `flex gap-3 rounded-md w-full ${containerMinHeight} ${containerMaxHeight} ${
		enabled ? "" : "opacity-25"
	}`;
	const productContainerClassNames =
		"w-full h-full max-h-content items-center justify-center rounded-2xl hover:cursor-zoom-in";
	const thumbnailsContainerClassNames = `space-y-3  ${
		enabled ? "overflow-auto" : "overscroll-none overflow-hidden"
	}`;
	const productImageClassNames = `rounded-md ${containerMinHeight} ${containerMaxHeight}`;
	const getThumbnailClassNames = (isActive: boolean) => {
		return `size-20 object-cover border rounded-lg ${
			isActive
				? " border-navy-500 border-2 "
				: enabled
				? "opacity-50 hover:opacity-100 border-transparent cursor-pointer"
				: "opacity-50 border-transparent"
		}`;
	};
	const getLargeViewThumbnailClassNames = (isActive: boolean) => {
		return `size-28 object-cover border rounded-lg ${
			isActive
				? " border-navy-500 border-2 "
				: enabled
				? "hover:opacity-100 border-transparent cursor-pointer"
				: " border-transparent"
		}`;
	};

	const iconHoverClassNames =
		"bg-white relative z-50 p-6 rounded-full hover:cursor-pointer hover:bg-navy-200";
	const iconClassNames = "absolute top-3 text-sm left-0 right-0 z-50";
	const largeViewActiveImageClassNames =
		"size-[900px]  overflow-auto rounded-md hover:cursor-zoom-in";
	const largeViewImageThumbnails =
		"overflow-scroll max-h-[1000px] min-h-[700px]";

	return (
		<div className={containerClassNames}>
			<div className={thumbnailsContainerClassNames}>
				{imageUrls.map((url, index) => (
					<img
						key={index}
						src={url}
						alt={`Thumbnail ${index + 1}`}
						className={getThumbnailClassNames(
							index === activeIndex
						)}
						onClick={() => handleThumbnailClick(index)}
						tabIndex={!enabled ? -1 : 0}
						data-close="false"
					/>
				))}
			</div>
			<div className={productContainerClassNames}>
				<AnimatePresence mode="wait" initial={false}>
					<motion.img
						key={imageUrls[activeIndex]}
						src={imageUrls[activeIndex]}
						alt={altText}
						tabIndex={!enabled ? -1 : 0}
						className={`${productImageClassNames} ${
							imageUrls[activeIndex] && "zoom-container"
						}`}
						initial="initial"
						animate="animate"
						exit="exit"
						variants={simpleFadeVariants}
						transition={{ duration: 0.5 }}
						onClick={() => handleActiveItemClick()}
						data-close="false"
					/>
				</AnimatePresence>
			</div>

			<ZoomComp
				isOpen={largeView}
				onClose={handleLargeViewClose}
				handlePrev={handlePrev}
				handleNext={handleNext}
				activeIndex={activeIndex}
				imageUrls={imageUrls}
				backgroundPosition={backgroundPosition}
				backgroundSize={backgroundSize}
				getFontAwesomeIcon={getFontAwesomeIcon}
				getLargeViewThumbnailClassNames={
					getLargeViewThumbnailClassNames
				}
				thumbnailsContainerClassNames={thumbnailsContainerClassNames}
				largeViewActiveImageClassNames={largeViewActiveImageClassNames}
				largeViewImageThumbnails={largeViewImageThumbnails}
				iconHoverClassNames={iconHoverClassNames}
				iconClassNames={iconClassNames}
				handleThumbnailClick={handleThumbnailClick}
				enabled={enabled}
			/>
		</div>
	);
};

export default VerticalImageCarousel;
