import { components } from "react-select";
import { getFontAwesomeIcon } from "../../../utils";
const Option = (props: any) => {
	if (props.isMulti) {
		return (
			<components.Option {...props}>
				<div className="py-1 border-b border-slate-200 hover:bg-supply-blue-50">
					<input
						type="checkbox"
						checked={props.isSelected}
						className="sr-only"
						id={`checkbox-${props.data.name}`}
						onChange={() => null}
					/>
					<label
						className="pl-2 flex items-center"
						htmlFor={`checkbox-${props.data.name}`}
					>
						<span
							className={`rounded border text-xs ${
								props.isSelected
									? "bg-supply-blue-400"
									: "bg-white"
							} w-5 h-5 inline-block mr-2 relative p-0`}
						>
							{props.isSelected && (
								<div className="absolute w-3 h-3 fill-current text-white top-[3px] left-[3px]">
									{getFontAwesomeIcon("check", "solid")}
								</div>
							)}
						</span>
						{props.data.name}
					</label>
				</div>
			</components.Option>
		);
	} else if (Array.isArray(props.data.name)) {
		let firstValue = props.data.name[0];
		let secondValue = props.data.name[1];

		return (
			<components.Option {...props}>
				<div className="border-b border-slate-200 hover:bg-supply-blue-50">
					<input
						type=""
						checked={props.isSelected}
						className="sr-only"
						id={`${props.value}`}
						onChange={() => null}
					/>
					{props.isSelected ? (
						<label
							className="flex items-center py-1 px-2"
							htmlFor={`${props.value}`}
						>
							<div className="flex items-start pr-2.5">
								<div className="w-3 h-3 fill-current text-supply-blue-400">
									{getFontAwesomeIcon("check", "solid")}
								</div>
							</div>
							<div className="flex flex-col py-1">
								<div className="">{firstValue}</div>
								<div className="text-navy-350">
									{secondValue}
								</div>
							</div>
						</label>
					) : (
						<label
							className="pl-2 flex items-center py-1 px-4"
							htmlFor={`${props.value}`}
						>
							<div className="flex flex-col py-1">
								<div className="">{firstValue}</div>
								<div className="text-navy-350">
									{secondValue}
								</div>
							</div>
						</label>
					)}
				</div>
			</components.Option>
		);
	} else {
		return (
			<components.Option {...props}>
				<div className="border-b border-slate-200 hover:bg-supply-blue-50">
					<input
						type=""
						checked={props.isSelected}
						className="sr-only"
						id={`${props.value}`}
						onChange={() => null}
					/>
					{props.isSelected ? (
						<label
							className="relative pl-8 flex items-center py-1 px-4"
							htmlFor={`${props.value}`}
						>
							<div className="absolute w-3 h-3 fill-current text-supply-blue-400 top-[5px] left-[5px]">
								{getFontAwesomeIcon("check", "solid")}
							</div>

							{props.label}
						</label>
					) : (
						<label
							className="pl-2 flex items-center py-1 px-4"
							htmlFor={`${props.value}`}
						>
							{props.label}
						</label>
					)}
				</div>
			</components.Option>
		);
	}
};

export default Option;
