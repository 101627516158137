import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface UseUrlSyncProps {
	previousUrl: string | null | undefined;
	setPreviousUrl?: React.Dispatch<
		React.SetStateAction<string | null | undefined>
	>;
	constructedUrl: string;
	currentPage: number;
	setCurrentPage: (page: number) => void;
	setSearchText: (text: string) => void;
	submittedSearchText: string;
	sortColumn: { [key: string]: string };
	pageSize: number;
	updateUrlWithNewPage: (newPage: number) => void;
	slug: string;
	setRecordsPerPageAfterChange: (recordsPerPage: number) => void;
}

const useUrlSync = ({
	previousUrl,
	setPreviousUrl,
	constructedUrl,
	currentPage,
	setCurrentPage,
	setSearchText,
	submittedSearchText,
	sortColumn,
	pageSize,
	updateUrlWithNewPage,
	slug,
	setRecordsPerPageAfterChange,
}: UseUrlSyncProps) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!previousUrl) {
			navigate(constructedUrl);
			setCurrentPage(currentPage);
			setSearchText("");
		} else {
			navigate(previousUrl);

			const url = new URL(previousUrl, window.location.origin);
			const params = new URLSearchParams(url.search);
			let pageFromUrl: string | null = null;
			let recordFromUrl = "10";

			for (const [key, value] of params.entries()) {
				if (key.endsWith("_page")) {
					pageFromUrl = value;
					break;
				}
			}

			for (const [key, value] of params.entries()) {
				if (key.endsWith("_recordsPerPage")) {
					recordFromUrl = value;
					break;
				}
			}

			if (pageFromUrl) {
				const pageNumber = parseInt(pageFromUrl, 10);
				if (!isNaN(pageNumber)) {
					setCurrentPage(pageNumber);
				}
			}

			if (recordFromUrl) {
				const recordsPerPage = parseInt(recordFromUrl, 10);
				if (!isNaN(recordsPerPage)) {
					setRecordsPerPageAfterChange(recordsPerPage);
				}
			}

			setSearchText("");
		}
		setPreviousUrl && setPreviousUrl(previousUrl);
	}, [
		submittedSearchText,
		sortColumn,
		pageSize,
		updateUrlWithNewPage,
		slug,
		previousUrl,
		constructedUrl,
		currentPage,
		setCurrentPage,
		setSearchText,
		setPreviousUrl,
		setRecordsPerPageAfterChange,
		navigate,
	]);
};

export default useUrlSync;
