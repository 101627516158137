interface ShipmentDataTypes {
	number?: string;
	shippingCarrier?: {
		urlLogo?: string;
		name?: string;
		urlLinkPrefix?: string;
	};
	status?: string;
}

interface OrderShipmentTypes {
	shipmentData: ShipmentDataTypes;
	containerClasses?: string;
	shape?: string;
	borderColor?: string;
	backgroundColor?: string;
	additionalClasses?: string;
	fontFamily?: string;
	textSize?: string;
}

const OrderShipment: React.FC<OrderShipmentTypes> = ({
	shipmentData,
	containerClasses,
	shape,
	borderColor,
	backgroundColor,
	additionalClasses,
	fontFamily,
	textSize,
}) => {
	let shipmentNumber;
	let shippingCarrier;

	if (
		shipmentData.status === null ||
		shipmentData.status === undefined ||
		shipmentData.status === "N/A"
	) {
		shipmentData.status = "—";
	}

	if (
		shipmentData.shippingCarrier?.name === null ||
		shipmentData.shippingCarrier?.name === undefined ||
		shipmentData.shippingCarrier?.name === "—"
	) {
		shippingCarrier = <></>;
	} else {
		shippingCarrier = (
			<>
				<img
					className="w-6"
					src={shipmentData.shippingCarrier?.urlLogo}
					alt={shipmentData.shippingCarrier?.name}
				/>
				<p className={`pl-2 ${fontFamily} ${textSize} py-0`}>
					{shipmentData.shippingCarrier?.name}
				</p>
			</>
		);
	}

	if (
		shipmentData.shippingCarrier === null ||
		shipmentData.shippingCarrier === undefined
	) {
		shipmentNumber = (
			<p className={`${fontFamily} ${textSize} `}>
				{shipmentData.number}
			</p>
		);
	} else {
		shipmentNumber = (
			<a
				href={`${shipmentData.shippingCarrier?.urlLinkPrefix}${shipmentData.number} `}
				target="_blank"
				className={` text-supply-blue-500 underline ${fontFamily} ${textSize} cursor-pointer`}
			>
				{shipmentData.number}
			</a>
		);
	}

	return shipmentData ? (
		<div
			className={`flex flex-col items-start px-1.5 py-1 ${containerClasses} ${shape}  border-${borderColor} ${backgroundColor} ${additionalClasses}`}
		>
			{shipmentNumber}
			<div className="flex pt-1">{shippingCarrier}</div>
		</div>
	) : null;
};

export default OrderShipment;
