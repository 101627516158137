import React from "react";

type LogoProps = {
	trackingNumber?: string;
	carrierPath?: string;
	path: string;
	additionalClasses?: string;
	shouldOpenInNewTab?: boolean;
};

const Logo: React.FC<LogoProps> = ({
	path,
	additionalClasses,
	carrierPath,
	trackingNumber,
	shouldOpenInNewTab,
}) => {
	return (
		<div className={`w-40 py-2 ${additionalClasses}`}>
			{shouldOpenInNewTab ? (
				<a href={`${carrierPath}${trackingNumber}`} target="_blank">
					<img src={path} alt="Logo" />
				</a>
			) : (
				<a href={"/"}>
					<img src={path} alt="Logo" />
				</a>
			)}
		</div>
	);
};

export default Logo;
