import React, { useState, useCallback, useEffect } from "react";
import { motion } from "framer-motion";
import Container from "../../components/layout/Container";
import MainHeading from "../../components/layout/MainHeading";
import Section from "../../components/layout/Section";
import { ProgressBar } from "../../components/layout";
import Input from "../../components/ui/Input";
import { getFontAwesomeIcon, validateEmail } from "../../utils";
import { FormButton } from "../../components/ui";
import { Link } from "react-router-dom";
import { Toaster } from "../../components/misc";
import { triggerForgotPasswordEmail } from "./api/ForgotPasswordApi";

const MemoizedInput = React.memo(Input);
const MemoizedProgressBar = React.memo(ProgressBar);

const ForgotPasswordPage: React.FC = () => {
	const [_, setIsLoading] = useState(false);
	const [step, setStep] = useState<number>(1);
	const [email, setEmail] = useState<string>("");
	const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
	const [changeStep, setChangeStep] = useState<boolean>(false);

	//Check if a token exist in the url then set step to 2
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get("token");
		if (token) {
			setStep(3);
		}
	}, []);
	


	const handleFormSubmit = async (
		event:
			| React.FormEvent<HTMLFormElement>
			| React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
		const isEmailValid = validateEmail(email);
		setIsValidEmail(isEmailValid);

		
		
		if (!isEmailValid) {
			return;
		}

		try {
			setIsLoading(true);
			const emailSent = await submitEmail(email);
			if(emailSent){
				setChangeStep(true);
				setStep(step + 1);
			}
			else{
				//Throw an error toaster
			}

		} catch (error) {
			console.error("Error submitting email:", error);
		} finally {
			setIsLoading(false);
		}
	};
	const submitEmail = async (email: string) => {
		// Replace this with actual API call logic
		const sendEmail = await triggerForgotPasswordEmail(email);
		return sendEmail;
	};

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setEmail(event.target.value);
		},
		[]
	);

	const getCurrentStep = useCallback(() => step, [step]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleFormSubmit(event as any);
		}
	};

	const handleResendClick = () => {
		// console.log("resend clicked");
	};

	const additionalSectionClasses =
		"mb-5 text-center mt-18 text-4xl font-omnes-medium";
	const linkClassNames =
		"text-primary hover:cursor-pointer hover:underline hover:text-primaryLightHover font-omnes-regular text-lg";
	const textClassNames = "font-omnes-regular text-navy-400 mb-8";
	const sectionClassNames = "text-center max-w-sm mx-auto relative";
	const largeIconClassNames = "text-[100px] text-primary mb-4";
	const formButtonClassNames =
		"mt-4 bg-primary text-white text-lg font-omnes-regular mb-7";
	const resendTextClassNames =
		"text-primary hover:cursor-pointer font-omnes-medium";

	const fadeVariants = {
		initial: { opacity: 0 },
		animate: { opacity: 1 },
		exit: { opacity: 0 },
	};

	return (
		<Container size="sm" containerClasses="container mx-auto px-4">
			<div className="mt-28">
				<MemoizedProgressBar
					title={"Step"}
					lastStep={4}
					divider="/"
					getCurrentStep={getCurrentStep}
				/>
			</div>

			<Section additionalClasses={sectionClassNames}>
				{changeStep ? (
					<motion.div
						key="check-mail"
						variants={fadeVariants}
						initial="initial"
						animate="animate"
						exit="exit"
					>
						<MainHeading
							text="Check Your Mail!"
							additionalClasses={additionalSectionClasses}
						/>
						<p className={`${textClassNames} mb-0`}>
							If you have an existing account you will have
							received an email with instructions to create a new
							password.
						</p>
						<div className={largeIconClassNames}>
							{getFontAwesomeIcon("mailbox", "solid")}
						</div>
						<p className={textClassNames}>
							Please check your spam folder. The email may take up
							to 5 minutes to arrive. Thank you for your patience.
						</p>
						<p className={textClassNames}>
							Didn’t receive an email?{" "}
							<span
								onClick={handleResendClick}
								className={resendTextClassNames}
							>
								Resend
							</span>
						</p>
						<div className={linkClassNames}>
							<Link to={"/login"}>Return to Login</Link>
						</div>
						<div>
							<Link to={"/create-password"}>
								LINK TO CREATE PASSWORD FOR TESTING ONLY, REMOVE
								BEFORE ADDED TO PROD
							</Link>
						</div>
					</motion.div>
				) : (
					<motion.div
						key="forgot-password"
						variants={fadeVariants}
						initial="initial"
						animate="animate"
						exit="exit"
					>
						{!isValidEmail && (
							<div>
								<Toaster
									clearMessage={() => setIsValidEmail(true)}
									message="Please provide a valid email address"
									additionalClasses="absolute top-0"
									color="red-500"
								/>
							</div>
						)}
						<MainHeading
							text="Forgot Password"
							additionalClasses={additionalSectionClasses}
						/>
						<p className={textClassNames}>
							Please enter your email address and we'll send you a
							link to reset your password.
						</p>

						<form onSubmit={handleFormSubmit}>
							<MemoizedInput
								firstIcon={getFontAwesomeIcon(
									"envelope",
									"solid"
								)}
								value={email}
								placeholder="Email *"
								type="email"
								id="email"
								onChange={handleChange}
								label={""}
								required={false}
								name={"userEmail"}
								isValid={isValidEmail}
								additionalClasses={"bg-transparent mb-4"}
								onKeyDown={handleKeyDown}
							/>
							<FormButton
								icon={getFontAwesomeIcon("right", "solid")}
								additionalClasses={formButtonClassNames}
								text={"Continue"}
								shape="flat"
								onClick={handleFormSubmit}
							/>
						</form>
						<div className={linkClassNames}>
							<Link to={"/login"}>Return to Login</Link>
						</div>
					</motion.div>
				)}
			</Section>
		</Container>
	);
};

export default ForgotPasswordPage;
