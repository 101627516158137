import React from "react";
import EllipsisIcon from "./EllipsisIcon";

interface item {
	uuid?: string;
	number: string;
}

interface DataPillProps {
	text?: item[] | string;
	iconLeft?: React.ReactNode;
	iconRight?: React.ReactNode;
	textColor?: string;
	hoverColor?: string;
	additionalClasses?: string;
	noRightIcon?: boolean;
	naMessage?: string;
	shape?: "cornered" | "semi-rounded" | "rounded";
	onClickIconRight?: () => void;
	backgroundColor?: string;
	borderColor?: string;
	fontFamily?: string;
}

const DataPill: React.FC<DataPillProps> = ({
	text,
	iconLeft,
	iconRight = <EllipsisIcon />,
	hoverColor,
	additionalClasses,
	noRightIcon,
	onClickIconRight,
	shape,
	backgroundColor,
	borderColor,
	textColor,
	fontFamily
}) => {
	let displayText = "N/A";
	let pillShapeClasses;

	if (Array.isArray(text) && text.length === 1) {
		displayText = text[0].number;
	} else if (Array.isArray(text) && text.length > 1) {
		displayText = text.length.toString();
	} else if (typeof text === "string") {
		displayText = text;
	}

	if (shape === "cornered") {
		pillShapeClasses = "rounded-sm";
	} else if (shape === "semi-rounded") {
		pillShapeClasses = "rounded-md";
	} else {
		pillShapeClasses = "rounded-full";
	}

	return (
		<div
			className={`grid ${
				noRightIcon ? "flex justify-center " : "grid-cols-3"
			} items-center ${backgroundColor} border border-${borderColor} mx-1 overflow-hidden ${pillShapeClasses} hover:${hoverColor} ${additionalClasses}`}
		>
			<div
				className={`${
					noRightIcon ? "col-span-2 " : "col-span-3"
				} group flex justify-around items-center h-full`}
			>
				<span className={`text-${textColor}`}>{iconLeft}</span>
				<p className={` text-${textColor} font-${fontFamily}`}>
					{displayText}
				</p>
				{iconRight && !noRightIcon && (
					<span
						onClick={onClickIconRight}
						className={`border-1 border-transparent hover:border-primary rounded-full h-6 w-6 justify-center flex items-center hover:cursor-pointer`}
					>
						{iconRight}
					</span>
				)}
			</div>
		</div>
	);
};

export default DataPill;
