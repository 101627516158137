import { ModalType } from "../../../../../pages/Orders/view/modals/ApprovalView/types";

export type ListItem = {
	id: string;
	content: string;
	icon: string;
	weight: "regular" | "solid" | "light";
	color: string;
	setActionModal: () => void;
	activeRowUuid: string;
};

export const quickActionListData = (
	setActiveModal: (modal: ModalType | null) => void,
	activeRowUuid: string,
	isAdmin: boolean
): ListItem[] => [
	{
		id: "1",
		content: "Approve",
		icon: "check",
		weight: "regular",
		color: "mint-700",
		setActionModal: () => setActiveModal("approve"),
		activeRowUuid: activeRowUuid || "",
	},
	{
		id: "2",
		content: "Deny",
		icon: "xmark",
		weight: "regular",
		color: "crimson-700",
		setActionModal: () => setActiveModal("deny"),
		activeRowUuid: activeRowUuid || "",
	},
	{
		id: "3",
		content: "Assign & Manage",
		icon: "userCheck",
		weight: "solid",
		color: "primary",
		setActionModal: () => setActiveModal("assign"),
		activeRowUuid: activeRowUuid || "",
	},

	...(isAdmin
		? [
				{
					id: "4",
					content: "Manager Override",
					icon: "badgeCheck",
					weight: "solid" as const,
					color: "mint-800",
					setActionModal: () => setActiveModal("adminOnly"),
					activeRowUuid: activeRowUuid || "",
				},
		  ]
		: []),
];
