const formatDateForDetailsBox = (
	dateString: string | undefined,
	includeTime?: boolean
) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	// Check if dateString is undefined or null
	if (!dateString) {
		return "Invalid Date"; // or return a default message, or an empty string
	}

	// Validate the date format (expects YYYY-MM-DD)
	const dateParts = dateString.split("-");
	if (
		dateParts.length !== 3 ||
		isNaN(Number(dateParts[0])) ||
		isNaN(Number(dateParts[1])) ||
		isNaN(Number(dateParts[2]))
	) {
		return "Invalid Date Format"; // return a meaningful error message or handle gracefully
	}

	const date = new Date(
		Date.UTC(
			parseInt(dateParts[0], 10), // Year
			parseInt(dateParts[1], 10) - 1, // Month (0-based index)
			parseInt(dateParts[2], 10) // Day
		)
	);

	const formattedMonth = months[date.getUTCMonth()];
	const formattedDay = date.getUTCDate().toString().padStart(2, "0");
	const formattedYear = date.getUTCFullYear();
	let formattedTime = "";

	if (includeTime) {
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const ampm = hours >= 12 ? "pm" : "am";
		const formattedHours = hours % 12 || 12;
		const formattedMinutes = minutes.toString().padStart(2, "0");
		formattedTime = `${formattedHours}:${formattedMinutes}${ampm}`;
	}

	return `${
		formattedTime ? formattedTime + ", " : ""
	}${formattedMonth} ${formattedDay}, ${formattedYear}`;
};

export default formatDateForDetailsBox;
