import React from "react";
import ImageComponent from "../../../../../components/layout/ImageComponent";
import placeholderImage from "../../../../../assets/placeholder-no-image-available.png";
import formatCurrency from "../../../../../utils/formatCurrency";
import formatCustomerName from "../../../../../utils/formatCustomerName";
import BaseToolTip from "../../../../../components/ui/BaseToolTip";
import { getFontAwesomeIcon } from "../../../../../utils";

interface Item {
	id?: string;
	labelIcon?: string;
	label?: string;
	valueKey: string;
	inputType?: string;
	labelTextClasses?: string;
	valueTextClasses?: string;
	hasValueLeftIconOne?: string;
	hasValueLeftIconTwo?: string;
	hasValueRightIcon?: string;
	valueIconClassesOne?: string;
	valueIconClassesTwo?: string;
	hasValueImage?: string;
	detailGridClasses?: string;
	inputGridClasses?: string;
	additionalInputContainerClasses?: string;
	isBoolean?: boolean;
	truthyValue?: string;
	falsyValue?: string;
	isConcatenated?: string;
	isEditOnly?: boolean;
	inputHasLeftIcon?: string;
	inputIconClasses?: string;
	disabledClasses?: string;
	secondValueTextClasses?: string;
	imageClasses?: string;
	flexDirection?: string;
	hasBottomBorder?: boolean;
	labelToolTip?: boolean;
	isCurrency?: boolean;
	isCustomerName?: boolean;
	bottomBorderClasses?: string;
	valueIconOneWeight?: string;
	valueIconTwoWeight?: string;
	hasTopBorder?: boolean;
	topBorderClasses?: string;
}

interface OrderDetailRowProps {
	field?: Item;
	allFields?: Item[];
	dataItem?: string | boolean | number;
	allData?: object;
	keyTextColor?: string;
	keyTextSize?: string;
	valueTextColor?: string;
	valueTextSize?: string;
	containerWidth?: string;
	hasValuePrice?: boolean;
	hasValueLongDescription?: boolean;
	iconStyle?: "solid" | "regular" | "light";
	mobileBreakpoint?: string;
	isEnabled?: boolean;
	textSize?: string;
	hostName?: string;
}

const OrderDetailRow: React.FC<OrderDetailRowProps> = ({
	dataItem,
	field,
	allFields,
	allData,
	containerWidth = "w-full",
	iconStyle = "solid",
	mobileBreakpoint,
	isEnabled,
	textSize = "text-sm",
	hostName,
}) => {
	const baseDetailClasses = "w-full flex text-left items-center";
	const baseLabelClasses = "pr-2 text-left text-nowrap";

	const renderValue = (item: Item, value: string | boolean | number) => {
		if (Array.isArray(value)) {
			return (
				<div
					className={`flex flex-col ${
						!isEnabled && item.disabledClasses
					}`}
				>
					<>
						<p
							className={`${textSize} ${
								!isEnabled && item.disabledClasses
							}`}
						>
							{value[0]}
						</p>
						<p
							className={`${textSize} ${
								item.secondValueTextClasses
							} ${!isEnabled && item.disabledClasses}`}
						>
							{value[1]}
						</p>
					</>
				</div>
			);
		} else if (item.isBoolean) {
			return (
				<p
					className={`${textSize} ${
						!isEnabled && item.disabledClasses
					}`}
				>
					{value ? item.truthyValue : item.falsyValue}
				</p>
			);
		} else if (item.isConcatenated && dataItem && allData) {
			const secondValue = allFields?.find((singleField) => {
				return singleField.valueKey === item.isConcatenated;
			});

			let secondValueKey = secondValue?.valueKey as keyof typeof dataItem;
			return (
				<p
					className={`${textSize} ${
						!isEnabled && item.disabledClasses
					}`}
				>
					{`${value} ${allData[secondValueKey] ?? ""}`}
				</p>
			);
		} else if (
			item.isCurrency &&
			(typeof value === "string" || typeof value === "number")
		) {
			return (
				<p
					className={`${textSize} ${
						!isEnabled && item.disabledClasses
					}`}
				>
					{formatCurrency(value)}
				</p>
			);
		} else if (
			item.isCustomerName &&
			typeof value === "string" &&
			hostName
		) {
			return (
				<p
					className={`${textSize} ${
						!isEnabled && item.disabledClasses
					}`}
				>
					{formatCustomerName(value, hostName)}
				</p>
			);
		} else if (value === "—" || value === null || value === undefined) {
			return <p className={`${textSize}`}>—</p>;
		} else {
			return (
				<p
					className={`${textSize} ${
						!isEnabled && item.disabledClasses
					}`}
				>
					{value}
				</p>
			);
		}
	};

	return field && dataItem && !field.isEditOnly ? (
		<div
			key={field.id}
			className={`${containerWidth} ${field.detailGridClasses} `}
		>
			<div
				className={`flex w-full ${mobileBreakpoint} ${
					field.flexDirection
				} ${field.hasBottomBorder ? field.bottomBorderClasses : ""} ${
					field.hasTopBorder ? field.topBorderClasses : ""
				}`}
			>
				{/* LABEL LEFT ICON */}
				{field.labelIcon === "true" && field.label && iconStyle && (
					<div className={`flex pr-2 size-12 items-center`}>
						{getFontAwesomeIcon(
							field.label,
							iconStyle as "solid" | "regular" | "light"
						)}
					</div>
				)}

				{/* LABEL */}
				{field.label && (
					<div
						className={`${baseLabelClasses} ${
							!isEnabled
								? field.disabledClasses
								: field.labelTextClasses
						}`}
					>
						<p className={`${textSize}`}>{field.label}</p>
					</div>
				)}
				{/* LABEL TOOL TIP*/}
				{field.labelToolTip === true && field.label && (
					<BaseToolTip
						message="A projected cost based on current data."
						icon="circleInfo"
						toolTipPlacement="right"
					/>
				)}

				<div
					className={`${baseDetailClasses} ${field.valueTextClasses}`}
				>
					{/* VALUE ICON ONE */}

					{field.hasValueLeftIconOne && iconStyle && (
						<div className={`px-1 ${field.valueIconClassesOne}`}>
							{getFontAwesomeIcon(
								field.hasValueLeftIconOne,
								field.valueIconOneWeight as
									| "solid"
									| "regular"
									| "light"
							)}
						</div>
					)}

					{/* VALUE ICON TWO */}

					{field.hasValueLeftIconTwo && iconStyle && (
						<div className={`px-1 ${field.valueIconClassesTwo}`}>
							{getFontAwesomeIcon(
								field.hasValueLeftIconTwo,
								(field.valueIconTwoWeight as
									| "solid"
									| "regular"
									| "light") || "regular" // Default fallback
							)}
						</div>
					)}

					{/* VALUE IMAGE */}
					{field.hasValueImage && (
						<div className="pr-2">
							<ImageComponent
								imageUrl={placeholderImage}
								altText={""}
								className={field.imageClasses}
							/>
						</div>
					)}

					{/* VALUE */}
					{renderValue(field, dataItem)}

					{/* VALUE RIGHT ICON */}
					{field.hasValueRightIcon && iconStyle && (
						<div className="pl-6">
							{/* {getFontAwesomeIcon(
								field.hasValueRightIcon,
								iconStyle as "solid" | "regular" | "light"
							)} */}
						</div>
					)}
				</div>
			</div>
		</div>
	) : null;
};

export default OrderDetailRow;
