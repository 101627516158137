import {
	FrontEndValidationResults,
	NetSuiteValidationResult,
	ValidationResults,
	CheckSerialNumbers,
} from "../../../../types";

export const checkFrontEndValidationErrors = (
	frontEndValidationResults: FrontEndValidationResults,
	selectedPartNumber: string
): {
	hasDuplicates: boolean;
	hasInvalidCharacters: boolean;
	isFrontEndValid: boolean;
} => {
	let hasDuplicates = false;
	let hasInvalidCharacters = false;
	let isFrontEndValid = false;

	if (
		frontEndValidationResults &&
		frontEndValidationResults &&
		frontEndValidationResults.length > 0
	) {
		const checkForErrors =
			frontEndValidationResults.filter(
				(item: CheckSerialNumbers) =>
					item.partNumber === selectedPartNumber
			);

		checkForErrors.forEach((item: CheckSerialNumbers) => {
			hasDuplicates = item.hasDuplicates;
			hasInvalidCharacters =
				hasInvalidCharacters || item.hasInvalidCharacters;
		});

		if (
			!hasDuplicates &&
			!hasInvalidCharacters &&
			checkForErrors.length > 0
		) {
			isFrontEndValid = true;
		}
	}

	return { hasDuplicates, hasInvalidCharacters, isFrontEndValid };
};

// this returns the result of the validation from netsuite.
export const checkNetSuiteValidationResults = (
	validationResults: NetSuiteValidationResult[],
	partNumber: string
): boolean => {
	let netSuiteValidationResult = false;

	if (validationResults.length > 0) {
		const applyNetsuiteValidation = validationResults.filter(
			(result) => result.partNumber === partNumber
		);

		let checkForInvalidSerialNumbers = applyNetsuiteValidation.some(
			(result) => result.isValid === false
		);

		if (!checkForInvalidSerialNumbers) {
			netSuiteValidationResult = true;
		} else {
			netSuiteValidationResult = false;
		}
	}

	return netSuiteValidationResult;
};

const determineValidationResults = (
	frontEndValidationResults: FrontEndValidationResults,
	validationResults: NetSuiteValidationResult[],
	selectedPartNumber: string
): ValidationResults => {
	const { hasDuplicates, hasInvalidCharacters, isFrontEndValid } =
		checkFrontEndValidationErrors(
			frontEndValidationResults,
			selectedPartNumber
		);

	let netSuiteValidationResult = checkNetSuiteValidationResults(
		validationResults,
		selectedPartNumber
	);

	return {
		selectedPartNumber,
		hasDuplicates,
		hasInvalidCharacters,
		isFrontEndValid,
		netSuiteValidationResult,
	};
};

export default determineValidationResults;