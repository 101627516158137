import React, { useState, useCallback, useEffect } from "react";
import Container from "../../components/layout/Container";
import MainHeading from "../../components/layout/MainHeading";
import Section from "../../components/layout/Section";
import { ProgressBar } from "../../components/layout";
import Input from "../../components/ui/Input";
import { getFontAwesomeIcon } from "../../utils";
import { FormButton } from "../../components/ui";
import { Link, useNavigate } from "react-router-dom";
import { Toaster } from "../../components/misc";
import { motion } from "framer-motion";
import UnavailableFeature from "../../components/layout/UnavailableFeature";
import PasswordInput from "../../components/ui/PasswordInput";
import iconSvg from "../../assets/unavailable-icon.svg";
import { updatePassword } from "./api/CreatePasswordApi";

const MemoizedInput = React.memo(PasswordInput);
const MemoizedProgressBar = React.memo(ProgressBar);

const CreatePasswordPage: React.FC = () => {
	const [_, setIsLoading] = useState(false);
	const [step, setStep] = useState<number>(3);
	const [tokenExpired, setTokenExpired] = useState<boolean>(false);
	const [newPassword, setNewPassword] = useState<string>("");
	const [confirmPassword, setConfirmPassword] = useState<string>("");
	const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
	const [changeStep, setChangeStep] = useState<boolean>(false);
	const [passwordsMatch, setPasswordsMatch] = useState<boolean | null>(null);
	const [isLongEnough, setIsLongEnough] = useState<boolean | null>(null);
	const [hasUppercase, setHasUppercase] = useState<boolean | null>(null);
	const [hasNumber, setHasNumber] = useState<boolean | null>(null);
	const [hasSpecialChar, setHasSpecialChar] = useState<boolean | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		// Check if a token exist in the url then set step to 2
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get("token");
		if (token) {
			setTokenExpired(true);
		}
	}, []);

	const handleFormSubmit = async (
		event:
			| React.FormEvent<HTMLFormElement>
			| React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
		if (newPassword !== confirmPassword) {
			setPasswordsMatch(false);
			return;
		}
		try {
			setIsLoading(true);

			await submitPassword(newPassword);
			setStep(step + 1);
			setChangeStep(!changeStep);
			setIsLoading(true);
		} catch (error) {
			console.error("Error submitting email:", error);
		} finally {
			setIsLoading(false);
		}
	};
	const submitPassword = async (password: string) => {
		// Replace this with actual API call logic
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get("token");
		const response = await updatePassword(password, token ? token : "");
		return response;
	};

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { id, value } = event.target;
			if (id === "newPassword") {
				setNewPassword(value);
				// setIsLongEnough(value.length >= 12);
				setIsLongEnough(value.length >= 3);
				setHasUppercase(/[A-Z]/.test(value));
				setHasNumber(/\d/.test(value));
				setHasSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(value));
				setPasswordsMatch(value === confirmPassword);
			} else if (id === "confirmPassword") {
				setConfirmPassword(value);
				setPasswordsMatch(value === newPassword);
			}
		},
		[newPassword, confirmPassword]
	);

	const getCurrentStep = useCallback(() => step, [step]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleFormSubmit(event as any);
		}
	};

	const handleSignInClick = () => {
		navigate("/login");
	};

	const getValidationIcon = (isValid: boolean | null) => {
		if (isValid === null) return null;
		return getFontAwesomeIcon(isValid ? "check" : "times", "solid");
	};

	const getValidationTextClass = (isValid: boolean | null) => {
		if (isValid === null) return "text-navy-300";
		return isValid ? "text-mint-800" : "text-crimson-700";
	};

	const getInputBorderClass = (isValid: boolean | null) => {
		if (isValid === null) return "border-gray-300";
		return isValid ? "border-mint-600" : "border-crimson-700";
	};
	const isFormValid =
		isLongEnough &&
		hasUppercase &&
		hasNumber &&
		hasSpecialChar &&
		passwordsMatch;
	const sectionClassNames =
		"text-center max-w-sm mx-auto relative font-omnes-medium";
	const additionalSectionClasses =
		"mb-5 text-center mt-18 text-4xl font-omnes-medium";
	const linkClassNames =
		"text-primary hover:cursor-pointer hover:underline hover:text-primaryLightHover font-omnes-regular text-md mt-6";

	const passwordReqTextClassNames =
		"text-left text-sm mb-4 border-1 border-navy-250 rounded-md p-4 font-omnes-medium mt-14";
	const formButtonClassNames =
		"mt-4 bg-primary text-white text-lg font-omnes-medium mb-7";
	const textClassNames = "font-omnes-medium text-navy-400 mb-8";
	const passwordFailClassNames =
		"text-crimson-700 text-left text-sm mb-6 font-omnes-medium absolute top-14";
	const largeIconClassNames = "text-[100px] text-primary mb-4";
	const additionalInputClassesNames =
		"bg-transparent mb-4 border-1 font-omnes-medium";

	const fadeVariants = {
		initial: { opacity: 0 },
		animate: { opacity: 1 },
		exit: { opacity: 0 },
	};
	if (!tokenExpired) {
		return (
			<motion.div
				key="feature-unavailable"
				variants={fadeVariants}
				initial="initial"
				animate="animate"
				exit="exit"
				className="flex flex-col items-center justify-center bg-white"
			>
				<UnavailableFeature
					icon={
						<img
							src={iconSvg}
							alt="Unavailable Icon"
							className="w-64 h-64 mb-4"
						/>
					}
					title="Feature Unavailable"
					message="You do not currently have access to this feature. For additional assistance, please contact your"
					message2="administrator or reach out to our support team at"
					emailLink="mailto:helpdesk@goagilant.com"
					emailText="helpdesk@goagilant.com."
					containerClass="flex flex-col items-center justify-center"
					iconClass={largeIconClassNames}
					titleClass={additionalSectionClasses}
					messageClass="text-navy-500 font-omnes-regular"
					linkClassNames={linkClassNames}
				/>
			</motion.div>
		);
	}

	return (
		<Container size="sm" containerClasses="container mx-auto px-4">
			<div className="mt-28">
				<MemoizedProgressBar
					title={"Step"}
					lastStep={4}
					divider="/"
					getCurrentStep={getCurrentStep}
				/>
			</div>

			<Section additionalClasses={sectionClassNames}>
				{changeStep ? (
					<>
						<motion.div
							key="all-done"
							variants={fadeVariants}
							initial="initial"
							animate="animate"
							exit="exit"
						>
							<MainHeading
								text="All Done!"
								additionalClasses={additionalSectionClasses}
							/>
							<p className={textClassNames}>
								Your password has been successfully updated.
							</p>
							<div className={largeIconClassNames}>
								{getFontAwesomeIcon("squareCheck", "solid")}
							</div>

							<FormButton
								icon={getFontAwesomeIcon("right", "solid")}
								additionalClasses={formButtonClassNames}
								text={"Sign In"}
								onClick={handleSignInClick}
								shape="flat"
								isDisabled={!isFormValid}
							/>
						</motion.div>
					</>
				) : (
					<>
						{!isValidEmail && (
							<div>
								<Toaster
									clearMessage={() => setIsValidEmail(true)}
									message="Please provide a valid email address"
									additionalClasses="absolute top-0"
									color="red-500"
								/>
							</div>
						)}

						<form
							role="create-password-form"
							className="relative"
							onSubmit={handleFormSubmit}
						>
							<motion.div
								key="check-mail"
								variants={fadeVariants}
								initial="initial"
								animate="animate"
								exit="exit"
							>
								<MainHeading
									text="Create New Password"
									additionalClasses={additionalSectionClasses}
								/>

								<MemoizedInput
									value={newPassword}
									placeholder="New Password *"
									id="newPassword"
									onChange={handleChange}
									required={false}
									name={"newPassword"}
									isValid={isValidEmail}
									additionalClasses={`${additionalInputClassesNames} ${getInputBorderClass(
										isLongEnough &&
										hasUppercase &&
										hasNumber &&
										hasSpecialChar &&
										passwordsMatch
									)}`}
									onKeyDown={(event) => handleKeyDown(event)}
								/>
								<div className="relative">
									<MemoizedInput
										value={confirmPassword}
										placeholder="Confirm Password *"
										id="confirmPassword"
										onChange={handleChange}
										required={false}
										name={"confirmPassword"}
										isValid={isValidEmail}
										additionalClasses={`mb-14 ${additionalInputClassesNames} ${getInputBorderClass(
											isLongEnough &&
											hasUppercase &&
											hasNumber &&
											hasSpecialChar &&
											passwordsMatch
										)}`}
										onKeyDown={(event) =>
											handleKeyDown(event)
										}
									/>
									{passwordsMatch === false && (
										<div className={passwordFailClassNames}>
											<>
												{getFontAwesomeIcon(
													"circleExclamation",
													"solid"
												)}
											</>
											<span className="ml-2.5 ">
												Passwords don't match
											</span>
										</div>
									)}
								</div>

								<div className={passwordReqTextClassNames}>
									<p>Password Must:</p>
									<p
										className={`flex items-center ${getValidationTextClass(
											isLongEnough
										)}`}
									>
										{getValidationIcon(isLongEnough) && (
											<span
												className={getValidationTextClass(
													isLongEnough
												)}
											>
												{getValidationIcon(
													isLongEnough
												)}
											</span>
										)}
										<span className="ml-2">
											Be at least 12 characters
										</span>
									</p>
									<p
										className={`flex items-center ${getValidationTextClass(
											hasUppercase
										)}`}
									>
										{getValidationIcon(hasUppercase) && (
											<span
												className={getValidationTextClass(
													hasUppercase
												)}
											>
												{getValidationIcon(
													hasUppercase
												)}
											</span>
										)}
										<span className="ml-2">
											Include at least one uppercase
											letter
										</span>
									</p>
									<p
										className={`flex items-center ${getValidationTextClass(
											hasNumber
										)}`}
									>
										{getValidationIcon(hasNumber) && (
											<span
												className={getValidationTextClass(
													hasNumber
												)}
											>
												{getValidationIcon(hasNumber)}
											</span>
										)}
										<span className="ml-2">
											Include at least one number
										</span>
									</p>
									<p
										className={`flex items-center ${getValidationTextClass(
											hasSpecialChar
										)}`}
									>
										{getValidationIcon(hasSpecialChar) && (
											<span
												className={getValidationTextClass(
													hasSpecialChar
												)}
											>
												{getValidationIcon(
													hasSpecialChar
												)}
											</span>
										)}
										<span className="ml-2">
											Include at least one special
											character
										</span>
									</p>
								</div>
								<FormButton
									icon={getFontAwesomeIcon("right", "solid")}
									additionalClasses={formButtonClassNames}
									text={"Create New Password"}
									onClick={handleFormSubmit}
									shape="flat"
									isDisabled={!isFormValid}
								/>
								<div className={linkClassNames}>
									<Link to={"/login"}>Return to Login</Link>
								</div>
							</motion.div>
						</form>
					</>
				)}
			</Section>
		</Container>
	);
};

export default CreatePasswordPage;
