import { ItemsSliderModal } from "../../../../../pages/Items";
import { getItemDetails } from "../../../../../pages/Items/api/itemsApi";
import { OrderViewSliderModal } from "../../../../../pages/Orders";
import ApprovalsViewSliderModal from "../../../../../pages/Orders/view/modals/ApprovalView/ApprovalsViewSliderModal";
import { VendorSliderModal } from "../../../../../pages/VendorItems";

type RenderModalContentProps = {
	pageTitle: string;
	isModalOpen: boolean;
	toggleModal: () => void;
	data: any;
	hostName: string;
	activeRowStatus: any;
	updateRowData: (uuid: string, key: string, newValue: any) => void;
};

export const renderModalContent = ({
	pageTitle,
	isModalOpen,
	toggleModal,
	data,
	hostName,
	activeRowStatus,
	updateRowData,
}: RenderModalContentProps) => {
	switch (pageTitle) {
		case "Items":
			return (
				<ItemsSliderModal
					makeApiCall={getItemDetails}
					isOpen={isModalOpen}
					onClose={toggleModal}
					parentData={data}
				/>
			);

		case "Vendor Items":
			return (
				<VendorSliderModal isOpen={isModalOpen} onClose={toggleModal} />
			);

		case "Orders":
			if (
				hostName === "COMPASS" &&
				[
					"pendingInitialApproval",
					"pendingApproval",
					"Pending Approval",
					"Pending Initial Approval",
					undefined,
				].includes(activeRowStatus?.data?.status)
			) {
				return (
					<ApprovalsViewSliderModal
						isOpen={isModalOpen}
						onClose={toggleModal}
						updateRowData={updateRowData}
					/>
				);
			} else {
				return (
					<OrderViewSliderModal
						isOpen={isModalOpen}
						onClose={toggleModal}
					/>
				);
			}

		default:
			return null;
	}
};
