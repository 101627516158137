import React from "react";
import BasePill from "../../../../../components/ui/BasePill";

export const convertStatus = (status: string, hostName: string) => {
	let color = "#000";
	let convertedStatus;

	switch (status.toLowerCase()) {
		case "billed":
			convertedStatus = "Billed";
			color = "#4CAF50";
			break;
		case "fulfilled":
			convertedStatus = "Fulfilled";
			color = "#388E3C";
			break;
		case "pendingapproval":
			convertedStatus = "Pending Approval";
			color = "#FF8F00";
			break;
		case "pendinginitialapproval":
			convertedStatus = "Pending Initial Approval";
			color = "#FF8F00";
			break;
		case "pending billing/partially fulfilled || pendingBillingPartiallyFulfilled":
			convertedStatus = "Pending Billing/Partially Fulfilled";
			color = "#9C27B0";
			break;
		case "pendingBillingPartiallyFulfilled":
			convertedStatus = "Pending Billing/Partially Fulfilled";
			color = "#00FFFF";
			break;
		case "pendingbillingpartiallyfulfilled":
			convertedStatus = "Pending Billing/Partially Fulfilled";
			color = "#00FFFF";
			break;
		case "pending fulfillment":
			convertedStatus = "Pending Fulfillment";
			color = "#1E88E5";
			break;
		case "pendingfulfillment":
			convertedStatus = "Pending Fulfillment";
			color = "#1E88E5";
			break;
		case "partially fulfilled":
			convertedStatus = "Partially Fulfilled";
			color = "#FDD835";
			break;
		case "partiallyfulfilled":
			convertedStatus = "Partially Fulfilled";
			color = "#FDD835";
			break;
		case "pendingbilling":
			convertedStatus = "Pending Billing";
			color = "#9C27B0";
			break;
		case "pending billing":
			convertedStatus = "Pending Billing";
			color = "#9C27B0";
			break;
		case "closed":
			convertedStatus = "Closed";
			color = "#9E9E9E";
			break;
		case "canceled":
			convertedStatus = "Canceled";
			color = "#EF5350";
			break;
		case "vendor":
			convertedStatus = "Vendor";
			color = "#4CAF50";
			break;
		case "location":
			convertedStatus = "Location";
			color = "#4CAF50";
			break;
		case "paid":
			convertedStatus = "Paid";
			color = "#4CAF50";
			break;
		case "open":
			convertedStatus = "Open";
			color = "#4CAF50";
			break;
		case "n/a":
			convertedStatus = "N/A";
			color = "#ff0000";
			break;
		case "true":
			convertedStatus = "True";
			color = "#ff0000";
			break;
		case "false":
			convertedStatus = "False";
			color = "#ff0000";
			break;
		default:
			convertedStatus = "Unknown";
			color = "#A7A9AF";
	}
	return { convertedStatus, color };
};

interface StatusBadgeProps {
	statusName: string;
	colorHex?: string;
	additionalClasses?: string;
	fontFamily?: string;
	customBackgroundColor?: string;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({
	statusName,
	colorHex,
	additionalClasses = "",
	fontFamily = "font-inter-medium",
}) => {
	let backgroundColor
	let textColor

	if (statusName === "Pending Initial Approval") {
		backgroundColor = 'bg-yellow-500'
		textColor = 'text-yellow-900'
	} else if (statusName === "Pending Approval") {
		backgroundColor = "bg-yellow-100";
		textColor = "text-yellow-600";
	} else if (statusName === "Pending Fulfillment") {
		backgroundColor = "bg-blue-100";
		textColor = "text-blue-600";
	} else if (statusName === "Canceled") {
		backgroundColor = "bg-red-100";
		textColor = "text-red-600";
	} else if (statusName === "Fulfilled") {
		backgroundColor = "bg-green-100";
		textColor = "text-green-600";
	} else {
		backgroundColor = "bg-gray-100";
		textColor = "text-gray-600";
	}

	return (
		<BasePill
			text={
				<span className={`text-xs ${fontFamily} flex items-center`}>
					{statusName}
				</span>
			}
			additionalClasses={`px-2 py-0.5 h-6 mr-1 ${additionalClasses} ${textColor}`}
			color={backgroundColor}
			shape="rounded-full"
			borderColor="transparent"
			type="span"
			hoverBackgroundColor="none"
		/>
	);
};

export default StatusBadge;
