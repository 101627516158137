import { getDataTableData, getDataTableMeta } from "../../api/toga";
import useHostName from "../useHostName";
import { TableFieldValues } from "./useDataTableState";

type StatusValue = {
	slug: string;
	name: string;
	colorHex: string;
};

type UseFetchDataOptions = {
	slug: string;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setColumnMappings?: React.Dispatch<React.SetStateAction<any>>; //TODO: Define a proper type
	setData: React.Dispatch<React.SetStateAction<any>>; //TODO: Define a proper type
	setColumnTitles: React.Dispatch<React.SetStateAction<string[]>>;
	setTotalPages: React.Dispatch<React.SetStateAction<number>>;
	setTotalRecords: React.Dispatch<React.SetStateAction<number>>;
	setIsError: React.Dispatch<React.SetStateAction<boolean>>;
	setNoDataFound: React.Dispatch<React.SetStateAction<boolean>>;
	nestedTableUuid?: string | null;
	setChildColumnTitles?: React.Dispatch<React.SetStateAction<string[]>>;
	setChildColumnMappings?: React.Dispatch<React.SetStateAction<any>>; //TODO: Define a proper type
	setTotalPagesInChildTable?: React.Dispatch<React.SetStateAction<number>>;
	setDataTableValueFields?: React.Dispatch<
		React.SetStateAction<TableFieldValues[]>
	>;
	setTypeValues: React.Dispatch<React.SetStateAction<StatusValue[]>>;
	additionalData?: any;
	pageTitle: string;
};

const useFetchData = ({
	slug,
	setIsLoading,
	setColumnMappings,
	setData,
	setColumnTitles,
	setTotalPages,
	setIsError,
	setNoDataFound,
	setChildColumnTitles,
	nestedTableUuid = null,
	setTotalPagesInChildTable,
	setDataTableValueFields,
	setTypeValues,
	additionalData,
	setTotalRecords,
	pageTitle,
}: UseFetchDataOptions) => {
	const fetchData = async () => {
		try {
			setIsLoading(true);

			const tableMeta = await getDataTableMeta(slug);

			if (!tableMeta) {
				console.error("No table metadata found");
				setIsError(true);
				return;
			}
			const fields = tableMeta?.table?.fields || [];

			const statusField = fields.find(
				(field: Record<string, any>) =>
					field.settings?.field === "_status"
			);

			//@ts-ignore
			if (statusField && statusField?.values) {
				//@ts-ignore
				setTypeValues(statusField.values);
			}

			let tableData = null;

			tableData = await getDataTableData(
				tableMeta as any,
				nestedTableUuid,
				additionalData
			);

			if (!tableData) {
				console.error("No table data found");
				setIsError(true);
				return;
			}

			let isNestedTable = false;
			if (tableMeta?.nestedTable !== null) {
				isNestedTable = true;
			}

			if (isNestedTable) {
				setData(tableData.rows);
				if (tableMeta?.table?.fields) {
					const columnMappings = tableMeta.table.fields.map(
						(field) => ({
							name: field.field,
							slug: field.slug,
						})
					);

					setColumnMappings && setColumnMappings(columnMappings);
				}

				if (tableData?.rows) {
					const newColumnTitles = [
						// "",
						...(tableMeta.table?.fields.map(
							(field) => field.name
						) || []),
					];
					const newChildColumnTitles = [
						// "",
						...(tableMeta.nestedTable?.fields.map(
							(field: any) => field.name
						) || []),
					];

					setColumnTitles(newColumnTitles);
					if (setChildColumnTitles) {
						setChildColumnTitles(newChildColumnTitles);
					}
				} else {
					setData([]);
				}

				setTotalPages(tableData?.meta.totalPageCount || 0);

				if (tableData?.rows === null) {
					setNoDataFound(true);
				} else {
					setNoDataFound(false);
				}

				if (tableData.rows[0].nested.meta.totalPageCount) {
					setTotalPagesInChildTable &&
						setTotalPagesInChildTable(
							tableData.rows[0].nested.meta.totalPageCount
						);
				}
			} else {
				setData(tableData);

				if (tableMeta?.table?.fields) {
					// const columnMappings = tableMeta.table.fields.map(
					// 	(field) => ({
					// 		name: field.settings.field,
					// 		slug: field.settings.table,
					// 	})
					// );
					const columnMappings = tableMeta.table.fields.map(
						(field) => ({
							name: field.settings.table,
							slug: field.slug,
							settings: field.settings,
							isCopyable: field.isCopyable,
						})
					);
					setColumnMappings && setColumnMappings(columnMappings);
				}
				// the fields are set here so we can map them
				setDataTableValueFields &&
					setDataTableValueFields(tableMeta.table?.fields || []);

				if (tableData?.rows) {
					setData(tableData?.rows);
					const newColumnTitles = tableMeta.table?.fields.map(
						(field) => field.settings.field
					);
					setColumnTitles(newColumnTitles || []);
				} else {
					setData([]);
				}

				setTotalPages(tableData?.meta.totalPageCount || 0);
				setTotalRecords(tableData?.meta.totalRecordCount || 0);

				setIsError(false);

				if (tableData?.rows === null) {
					setNoDataFound(true);
				} else {
					setNoDataFound(false);
				}
			}
		} catch (error) {
			console.error(error);
			setIsError(true);
		} finally {
			setIsLoading(false);
		}
	};

	return fetchData;
};

export default useFetchData;
