import { Fragment } from "react";
import { Cell } from "react-table";
import { useItemsContext } from "../../../hooks/dataTableHooks/useItemsContext";
import { DataWithUUID, TableRowProps } from "./types";
import TableCell from "./TableCell";

const TableRow = <T extends DataWithUUID>({
	row,
	prepareRow,
	activeIndex,
	globalTrimActive,
	rowUuid,
	columnInputs,
	rowHoverClasses = "hover:bg-navy-100 hover:cursor-pointer",
}: TableRowProps<T>) => {
	prepareRow(row);
	const { handleRowClick: handleRowClickFromContext } = useItemsContext();
	const isActive = activeIndex === row.index;

	let rowClasses = `border-primary`;

	if (isActive) {
		rowClasses += " activeRow"; // class when only active
	}

	return (
		<tr
			data-testid="table-row"
			className={`border-b border-b-navy-200 ${rowHoverClasses} ${rowClasses}`}
			{...(row.getRowProps ? row.getRowProps() : {})}
			onClick={(event) => {
				handleRowClickFromContext(row.index, rowUuid || "", event);
			}}
		>
			{row.cells.map((cell: Cell<T>, index: number) => {
				const isLastCell = index === row.cells.length - 1;

				const cellProps = cell.getCellProps();
				return (
					<Fragment key={cellProps.key}>
						<TableCell
							cell={cell}
							rowUuid={rowUuid}
							globalTrimActive={globalTrimActive}
							columnInputs={columnInputs}
							isLastCell={isLastCell}
						/>
					</Fragment>
				);
			})}
		</tr>
	);
};

export default TableRow;
