export const fadeInOutVariants = {
	initial: { opacity: 0 },
	animate: { opacity: 1, transition: { duration: 0.3, ease: "easeOut" } },
	exit: { opacity: 0, transition: { duration: 0.3, ease: "easeOut" } },
};

export const opacityScaleVariants = {
	initial: { opacity: 1, scale: 1 },
	animate: (isClicked: boolean) =>
		isClicked ? { opacity: 1, scale: 1 } : { opacity: 1, scale: 1 },
	transition: {
		duration: 0.8,
		ease: [0, 0.71, 0.2, 1.01],
	},
};

export const heightOpacityVariants = {
	initial: { opacity: 1, height: 0 },
	animate: { height: "auto" },
	exit: { opacity: 0, height: 0 },
	transition: {
		opacity: { duration: 0.2, exit: 0.5 },
		height: { duration: 0.3 },
	},
};

export const simpleFadeVariants = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0 },
};

export const tooltipVariants = {
	initial: {
		opacity: 0,
		scale: 0.95,
		transition: { duration: 0.2, ease: "easeInOut" },
	},
	animate: {
		opacity: 1,
		scale: 1,
		transition: { duration: 0.2, ease: "easeInOut" },
	},
	exit: {
		opacity: 0,
		scale: 0.95,
		transition: { duration: 0.2, ease: "easeInOut" },
	},
};

export const slideInOutVariants = {
	initial: {
		opacity: 0,
		x: "10px",
	},
	animate: {
		opacity: 1,
		x: 0,
		transition: { duration: 0.1 },
	},
	exit: {
		opacity: 0,
		x: "-10px",
		transition: { duration: 0.1 },
	},
};

export const arrowContainerFadeInOutVariants = {
	initial: {
		opacity: 0,
		transition: { duration: 0.1 },
	},
	animate: {
		opacity: 1,
		transition: { duration: 0.1 },
	},
	exit: {
		opacity: 0,
		transition: { duration: 0.1 },
	},
};

export const tableVariants = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	transition: { ease: "easeOut", duration: 0.2 },
};

export const slideOutMenuVariants = {
	initial: { x: "-100%" },
	animate: { x: "0%" },
	exit: { x: "-100%" },
	transition: { duration: 0.2 },
};

export const slideOutMenuOverlayVariant = {
	initial: { opacity: 0 },
	animate: { opacity: 0.1 },
	exit: { opacity: 0 },
	transition: { duration: 0.2 },
};

export const slideUpModalVariants = {
	initial: (switchContent: boolean = false) =>
		switchContent ? {} : { y: "100vh" },
	animate: (switchContent: boolean = false) =>
		switchContent ? {} : { y: "-3vh" },
	exit: (switchContent: boolean = false) =>
		switchContent ? {} : { y: "100vh" },
	transition: {
		duration: 0.1,
		type: "spring",
		stiffness: 100,
		damping: 15,
	},
};

export const slideUpModalOverlayVariants = {
	initial: { opacity: 0 },
	animate: { opacity: 0.3 },
	exit: { opacity: 0 },
	transition: { duration: 0.3 },
};

export const extendedEaseOutVariant = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	transition: { ease: "easeOut", duration: 0.4 },
};
