import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { useUrl } from "../../hooks";

const GoBackButton: React.FC = () => {
	const navigate = useNavigate();
	const { setPreviousUrl, previousUrl } = useUrl();

	const goBack = () => {
		if (previousUrl) {
			setPreviousUrl && setPreviousUrl(previousUrl);
			navigate(previousUrl);
		} else {
			navigate("/");
		}
	};

	return (
		<button
			onClick={goBack}
			className="rounded-full hover:bg-primaryVeryLight  h-8 w-8 border-1 border-primary"
		>
			<FontAwesomeIcon
				icon={faArrowLeft}
				className="text-primary text-1xl"
			/>
		</button>
	);
};

export default GoBackButton;
