import React, { createContext, useContext, useState, ReactNode } from "react";

interface Toaster {
	isOpen: boolean;
	message: string;
	status: "success" | "error" | "info" | "warning";
	id: string;
}

interface ToasterContextProps {
	toasters: Toaster[];
	addToaster: (toaster: Omit<Toaster, "id">) => void;
	removeToaster: (id: string) => void;
	clearAllToasters: () => void;
}

const ToasterContext = createContext<ToasterContextProps | undefined>(undefined);

export const ToasterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [toasters, setToasters] = useState<Toaster[]>([]);

	const addToaster = (toaster: Omit<Toaster, "id">) => {
		const id = `${Date.now()}-${Math.random()}`;
		const newToaster = { ...toaster, id };
		setToasters((prevToasters) => [...prevToasters, newToaster]);

		setTimeout(() => {
			removeToaster(id);
		}, 3000);
	};

	const removeToaster = (id: string) => {
		setToasters((prevToasters) => prevToasters.filter((toaster) => toaster.id !== id));
	};

	const clearAllToasters = () => {
		setToasters([]);
	};

	return (
		<ToasterContext.Provider value={{ toasters, addToaster, removeToaster, clearAllToasters }}>
			{children}
		</ToasterContext.Provider>
	);
};

export const useToaster = () => {
	const context = useContext(ToasterContext);
	if (!context) {
		throw new Error("useToaster must be used within a ToasterProvider");
	}
	return context;
};