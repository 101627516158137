import React, {
	createContext,
	useState,
	useContext,
	Dispatch,
	useEffect,
} from "react";
import { SearchCriterion } from "./useConstructUrl";

type DataType = Record<string, any>;

interface UseItemsContextProps {
	isModalOpen: boolean;
	setIsModalOpen: Dispatch<React.SetStateAction<boolean>>;
	toggleModal: () => void;
	activeIndex: number | null;
	setActiveIndex: (index: number | null) => void;
	handleRowClick: (
		index: number,
		rowUuid: string,
		event: React.MouseEvent<HTMLTableRowElement>
	) => void;
	activeSortColumn: string | null;
	setActiveSortColumn: (columnId: string | null) => void;
	activeUuid: string | null;
	setActiveUuid: (uuid: string | null) => void;
	searchDropdownValue: string | null;
	setSearchDropdownValue: Dispatch<React.SetStateAction<string | null>>;
	searchColumn: string;
	setSearchColumn: Dispatch<React.SetStateAction<string>>;
	switchContent: boolean;
	setSwitchContent: Dispatch<React.SetStateAction<boolean>>;
	data: DataType[];
	activeRowUuid: string | null;
	setActiveRowUuid: Dispatch<React.SetStateAction<string | null>>;
	deactivatedQuickMenuIds: string[];
	setDeactivatedQuickMenuIds: Dispatch<React.SetStateAction<string[]>>;
	searchCriteria: SearchCriterion[];
	setSearchCriteria: Dispatch<React.SetStateAction<SearchCriterion[]>>;
}

const initialContextData: UseItemsContextProps = {
	isModalOpen: false,
	setIsModalOpen: () => {},
	toggleModal: () => {},
	activeIndex: null,
	setActiveIndex: () => {},
	handleRowClick: () => {},
	activeSortColumn: null,
	setActiveSortColumn: () => {},
	activeUuid: null,
	setActiveUuid: () => {},
	searchDropdownValue: null,
	setSearchDropdownValue: () => {},
	searchColumn: "",
	setSearchColumn: () => {},
	switchContent: false,
	setSwitchContent: () => {},
	data: [],
	activeRowUuid: "",
	setActiveRowUuid: () => {},
	deactivatedQuickMenuIds: [],
	setDeactivatedQuickMenuIds: () => {},
	searchCriteria: [],
	setSearchCriteria: () => {},
};

const ItemsContext = createContext<UseItemsContextProps>(initialContextData);

export const useItemsContext = () => useContext(ItemsContext);

export const ItemsContextProvider: React.FC<{
	children: React.ReactNode;
	hasModalTable: boolean;
	data: DataType[];
}> = ({ children, hasModalTable, data }) => {
	const [searchCriteria, setSearchCriteria] = useState<SearchCriterion[]>([]);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [activeSortColumn, setActiveSortColumn] = useState<string | null>(
		null
	);
	const [activeUuid, setActiveUuid] = useState<string | null>(null);
	const [searchDropdownValue, setSearchDropdownValue] = useState<
		string | null
	>(null);
	const [searchColumn, setSearchColumn] = useState<string>("");
	const [switchContent, setSwitchContent] = useState<boolean>(false);
	const [activeRowUuid, setActiveRowUuid] = useState<string | null>(null);
	const [deactivatedQuickMenuIds, setDeactivatedQuickMenuIds] = useState<
		string[]
	>([]);
	useEffect(() => {
		const storedCriteria = localStorage.getItem("searchCriteria");
		if (storedCriteria) {
			try {
				const parsedCriteria = JSON.parse(storedCriteria);
				setSearchCriteria(parsedCriteria);
			} catch (error) {
				console.error(
					"Failed to parse searchCriteria from local storage:",
					error
				);
			}
		}
	}, []);

	// Save searchCriteria to local storage whenever it changes
	useEffect(() => {
		try {
			localStorage.setItem(
				"searchCriteria",
				JSON.stringify(searchCriteria)
			);
		} catch (error) {
			console.error(
				"Failed to save searchCriteria to local storage:",
				error
			);
		}
	}, [searchCriteria]);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleRowClick = (
		index: number,
		rowUuid: string,
		event: React.MouseEvent<HTMLTableRowElement>
	) => {
		setActiveIndex(index);
		if (!hasModalTable) {
			toggleModal();
			setActiveUuid(rowUuid);
			setSwitchContent(false);
		} else {
			toggleModal();
			setActiveUuid(rowUuid);
			setSwitchContent(true);
		}
		if (data && data[index] && data[index].data && data[index].data.uuid) {
			setActiveUuid(data[index].data.uuid);
		} else {
			setActiveUuid(rowUuid);
		}
	};

	const contextValue: UseItemsContextProps = {
		isModalOpen,
		setIsModalOpen,
		toggleModal,
		activeIndex,
		setActiveIndex,
		handleRowClick,
		activeSortColumn,
		setActiveSortColumn,
		activeUuid,
		setActiveUuid,
		searchDropdownValue,
		setSearchDropdownValue,
		searchColumn,
		setSearchColumn,
		switchContent,
		setSwitchContent,
		data,
		activeRowUuid,
		setActiveRowUuid,
		deactivatedQuickMenuIds,
		setDeactivatedQuickMenuIds,
		searchCriteria,
		setSearchCriteria,
	};

	return (
		<ItemsContext.Provider value={contextValue}>
			{children}
		</ItemsContext.Provider>
	);
};

export default useItemsContext;
