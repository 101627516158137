// validateEmail.ts

/**
 * validateEmail that takes a string as an argument and returns a boolean indicating whether the string is a valid email address. The function uses a regular expression to check if the string matches the format of a valid email address. The regular expression used in this function is as follows:
 *
 * /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
 *
 * This regular expression checks for the following conditions in the email string:
 *
 * The string must contain a combination of alphanumeric characters, symbols, and/or dot (.) and @.
 * The string must have at least one character before the @ symbol.
 * The string must have at least one character between the @ symbol and the dot (.) symbol.
 * The string must have at least two characters after the dot (.) symbol.
 * If the input string satisfies all of these conditions, the function returns true, indicating that the input string is a valid email address. Otherwise, the function returns false.
 */
function validateEmail(email: string): boolean {
	const emailRegex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})$/;
	return (
		email.length >= 3 &&
		email.lastIndexOf(".") !== -1 &&
		emailRegex.test(email)
	);
}

export default validateEmail;
