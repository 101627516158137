import React from "react";
import BaseText from "../../../../../components/ui/BaseText";
import Item from "./Item";
import Bundle from "./Bundle";
import { ApprovalContentItemsProps } from "./types";

// FIGMA DOCUMENTATION (lease pricing fields + displaying bundles+bundle items): https://www.figma.com/design/wtVOcbRCEL5r3Kj0UgrB4C/Supply?node-id=122-12


const ApprovalContentItems: React.FC<ApprovalContentItemsProps> = ({
	items,
	fields,
}) => {
 	const formatItems = () => {
		const standaloneItems = items.filter(
			(item) => item.Bundles.name === null
		);

		const bundles = items.filter((item) => item.Bundles.name !== null);

		return {
			standaloneItems,
			bundles,
		};
	};

	const { standaloneItems, bundles } = formatItems();

	const formatBundles = () => {
		const primaryItems = bundles.filter(
			(bundle) =>
				bundle.BundleItemGroups &&
				bundle.BundleItemGroups.name === "Primary"
		);

		const totalBundleItems = primaryItems.map((item) => {
			let bundleMatches = bundles.filter((bundle) => {
				return (
					bundle.Bundles.name === item.Bundles.name &&
					bundle.BundleItemGroups.name !== "Primary"
				);
			});

			return {
				...item,
				bundleItems: bundleMatches,
			};
		});

		return totalBundleItems;
	};

	const updatedBundles = formatBundles();

	return (
		standaloneItems &&
		bundles && (
			<div className="w-full">
				<div className="text-left mb-2">
					{standaloneItems.length > 0 ||
						(bundles.length > 0 && (
							<BaseText
								text={fields[0].label}
								size="text-base"
								color="text-gray-700"
								fontFamily="font-inter-medium"
								tag="h3"
							/>
						))}
				</div>
				{standaloneItems.map((item) => (
					<Item key={item.uuid} item={item} />
				))}
				{updatedBundles.map((bundle) => (
					<Bundle key={bundle.uuid} bundle={bundle} />
				))}
			</div>
		)
	);
};

export default ApprovalContentItems;
