import { useCallback } from "react";

const useHandleClear = (
	setDetailsAreUndefined: React.Dispatch<React.SetStateAction<boolean>>,
	setNoDataFound: React.Dispatch<React.SetStateAction<boolean>>,
	setSearchText: React.Dispatch<React.SetStateAction<string>>,
	setSubmittedSearchText: React.Dispatch<React.SetStateAction<string>>,
	setSortColumn: React.Dispatch<React.SetStateAction<any>>,
	setResetSearch: React.Dispatch<React.SetStateAction<boolean>>,
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
	resetSearch: boolean
) => {
	const handleClear = useCallback(() => {
		setDetailsAreUndefined(false);
		setNoDataFound(false);
		setSearchText("");
		setSubmittedSearchText("");
		setSortColumn({});
		setResetSearch(!resetSearch);
		setCurrentPage(1);
	}, [resetSearch]);

	return {
		handleClear,
	};
};

export default useHandleClear;
