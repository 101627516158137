import { ColumnInstance } from "react-table";
interface HeaderContentProps<T extends object> {
	column: ColumnInstance<T>;
}

const HeaderContent = <T extends object>({
	column,
}: HeaderContentProps<T>): JSX.Element => {
	const headerContainerClassNames = `flex items-center justify-center text-sm font-omnes-medium `;
	return (
		<div className={headerContainerClassNames}>
			{column.render("Header")}
		</div>
	);
};

export default HeaderContent;
