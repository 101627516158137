import React, { useState } from "react";

type ExpandableContentProps = {
	columnData: string;
	columnDataLength: number;
	onHoverColumnDataLength: number;
	minWidth: string;
	notShortenedClasses: string;
};

const ExpandableCellContent: React.FC<ExpandableContentProps> = ({
	columnData,
	columnDataLength,
	onHoverColumnDataLength,
	minWidth,
	notShortenedClasses,
}) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [isHovered, setIsHovered] = useState<boolean>(false);

	let content;
	if (columnData !== null  && columnData.length > columnDataLength) {
		content = (
			<div
				style={{
					position: "relative",
				}}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				className={`${
					!isExpanded
						? "flex items-center justify-between font-omnes-regular "
						: "flex flex-col items-start h-auto relative"
				} ${minWidth}`}
			>
				<div
					className={`font-omnes-regular text-sm text-left text-wrap break-words ${
						!isExpanded ? "pt-0" : "pt-3 pb-7"
					} w-full`}
				>
					{!isExpanded && !isHovered
						? `${columnData.substring(0, columnDataLength)}...`
						: !isExpanded && isHovered
						? `${columnData.substring(
								0,
								onHoverColumnDataLength
						  )}...`
						: columnData}
				</div>
				<div
					className={`${
						isExpanded ? "absolute bottom-[-5px] right-2.5" : ""
					}`}
				>
					<button
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							setIsExpanded(!isExpanded);
						}}
						style={{
							display:
								isHovered && !isExpanded
									? "block"
									: isExpanded && isHovered
									? "block"
									: "none",
						}}
						className={`text-nowrap font-omnes-regular text-sm text-supply-blue-500 hover:text-supply-blue-300 z-10 hover:cursor-pointer underline ${
							!isExpanded ? "p-0" : "py-2"
						}`}
					>
						{isExpanded ? "Show Less" : "Show More"}
					</button>
				</div>
			</div>
		);
	} else {
		content = <div className={notShortenedClasses}>{columnData}</div>;
	}

	return content;
};

export default ExpandableCellContent;


