import { Dispatch, SetStateAction } from "react";
import { User } from "../hooks/useUserContext";
import getQueryParams from "./getQueryParams";
import { OptionsType, togaApiRequest } from "../api/toga";
import { Buffer } from "buffer";

interface SamlData {
	auth: string;
	payload: any;
}

export const handleClientAuthentication = async (
	user: User,
	setUser: Dispatch<SetStateAction<User>>,
	navigate: (path: string) => void,
	currentUrl: string
): Promise<boolean> => {
	const isUserLoggedIn = user.isUserLoggedIn;
	if (isUserLoggedIn) {
		// User is already logged in, redirect them to the home page
		navigate(currentUrl);
		return true;
	} else {
		// User is not logged in, proceed with authentication process
		const queryParams = getQueryParams();
		if (queryParams["saml"] === undefined) {
			// This is NOT an incoming SAML request from our Assertion Consumer Service (ACS)
			const payload = null;
			// Getting the current URL and splitting it into protocol and hostname
			const protocol = window.location.protocol;
			const hostname = window.location.hostname;
			const domainName = protocol + "//" + hostname;

			let environment = import.meta.env.MODE;
			if (environment === "development") {
				environment = "DEV";
			} else if (environment === "production") {
				environment = "PRODUCTION";
			} else if (environment === "alpha") {
				environment = "ALPHA";
			}
			const response = await togaApiRequest("GET", "/domains", payload, {
				fields: [
					"ClientAuthentications.uuid",
					"ClientAuthentications.type",
					"Apps.uuid",
				],
				join: [
					{
						Environments: {
							"Environments.id": "Domains.environmentId",
						},
					},
					{
						ClientAuthentications: {
							"ClientAuthentications.clientId":
								"Domains.clientId",
							"ClientAuthentications.environmentId":
								"Domains.environmentId",
						},
					},
					{
						Apps: {
							"Apps.id": "Domains.appId",
						},
					},
				],
				where: {
					and: [
						{
							"Domains.domain": {
								like: domainName,
							},
						},
						{
							"Environments.slug": {
								like: environment,
							},
						},
					],
				},
			} as OptionsType);

			if (response.isSuccess) {
				// Successful response, check the client authentication type
				if (response.data.domains.length === 0) {
					// This is not SSO authentication, redirect to the login page
					navigate("/login");
				} else {
					const clientConfig = response.data.domains[0];
					if (clientConfig.ClientAuthentications.type === "SSO") {
						// This is SSO authentication, call the singleSignOnServiceUrl scripted API to determine where to direct them to

						// Get the single sign on URL for this client's environment
						const responseSso = await togaApiRequest(
							"GET",
							"/client-authentications/singleSignOnServiceUrl",
							payload,
							{
								uuid: clientConfig.ClientAuthentications.uuid,
								returnToAppUuid: clientConfig.Apps.uuid,
							} as OptionsType
						);
						if (responseSso.isSuccess) {
							// Successful response, redirect to the single sign on service
							const singleSignOnServiceUrl =
								responseSso.data.clientAuthentications
									.singleSignOnServiceUrl;
							window.location.href = singleSignOnServiceUrl;
						}
					} else {
						// This is not SSO authentication, redirect to the login page
						navigate("/login");
					}
				}
			} else {
				// Error response
				console.log(
					response.messages[1].code,
					response.messages[0].code
				);
				return false;
			}
		} else {
			// This IS an incoming SAML request from our Assertion Consumer Service (ACS) after they have been authenticated by the IdP
			console.log("SAML request received");
			// Decode the SAML parameter containing the encrypted user
			const samlEncoded = queryParams["saml"];
			console.log(samlEncoded);
			const decodedSaml = Buffer.from(samlEncoded, "base64").toString();
			const saml: SamlData = JSON.parse(decodedSaml) as SamlData;

			// Make an auth request to get an access token using the encrypted SAML payload passed to us
			const response = await togaApiRequest(
				"POST",
				"/auth/" + saml["auth"],
				saml["payload"],
				{
					fields: [{ user: ["firstName", "lastName", "uuid"] }],
				} as OptionsType
			);
			console.log(response);
			if (response.isSuccess) {
				// Successful API response
				const accessToken = response.data.tokens.access;
				const refreshToken = response.data.tokens.refresh;
				const firstName = response.data.user.firstName;
				const lastName = response.data.user.lastName;
				const uuid = response.data.user.uuid;

				localStorage.setItem("accessToken", accessToken);
				localStorage.setItem("refreshToken", refreshToken);

				// Make an API call for Users to get the user details and save it in the state
				const userOptions = {
					fields: ["_isSupervisor", "_isAdmin"],
					where: {
						and: [{ "Users.uuid": { "=": uuid } }],
					},
				};
				const userData = await togaApiRequest(
					"GET",
					"/users",
					null,
					userOptions
				);

				const newUser: User = {
					firstName: firstName,
					lastName: lastName,
					isUserLoggedIn: true,
					isAdmin: userData.data.users[0]._isAdmin,
					isSupervisor: userData.data.users[0]._isSupervisor,
					uuid: uuid,
				};

				setUser(newUser); // Save the user information in the state and local storage

				localStorage.setItem("accessToken", accessToken);
				localStorage.setItem("refreshToken", refreshToken);
				navigate("/");

				return true; // Login is successful
			} else {
				// Error
				console.log(
					response.messages[1].code,
					response.messages[0].code
				);
				return false;
			}
		}
	}
	return false;
};
