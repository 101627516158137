interface InitialAvatarTypes {
	firstName: string;
	lastName: string;
	containerClasses: string;
	textClasses: string;
}

const InitialsAvatar: React.FC<InitialAvatarTypes> = ({
	firstName,
	lastName,
	containerClasses,
	textClasses,
}) => {
	const initials = `${firstName?.charAt(0).toUpperCase()}${lastName
		?.charAt(0)
		.toUpperCase()}`;

	return (
		<div className={`${containerClasses} ${textClasses}`}>
			<p>{initials}</p>
		</div>
	);
};

export default InitialsAvatar;
