import React, { useState, useEffect, useRef } from "react";

import useViewportHeight from "../../hooks/useViewPortHeight";
import { useDarkMode } from "../../hooks";
import { getFontAwesomeIcon } from "../../utils";

interface DropdownProps {
	options: string[] | number[];
	onSelect?: (value: string) => void;
	isReadOnly?: boolean;
	additionalClasses?: string;
	readOnlyInfo?: string;
	defaultValue?: string | number;
	children?: React.ReactNode;
	onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	setSelectedOptionInParent?: React.Dispatch<React.SetStateAction<string>>;
	placeHolder?: string;
	value?: string | number;
	itemsTableDropdown?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
	options,
	isReadOnly = false,
	additionalClasses = "",
	readOnlyInfo = "",
	defaultValue = "Select",
	onChange,
	itemsTableDropdown,
	setSelectedOptionInParent,
}) => {
	const { darkMode } = useDarkMode();
	const isOver = useViewportHeight(900);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = useState<string>("");
	const dropdownRef = useRef<HTMLDivElement>(null);

	const handleSelect = (option: string | number) => {
		setSelectedOption(option.toString());
		setIsOpen(false);
		if (onChange) {
			onChange({
				target: {
					value: option,
				},
			} as React.ChangeEvent<HTMLSelectElement>);
		}
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};
		window.addEventListener("click", handleClickOutside);
		return () => {
			window.removeEventListener("click", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (setSelectedOptionInParent) {
			setSelectedOptionInParent(selectedOption);
		}
	}, [selectedOption, setSelectedOptionInParent]);

	return (
		<div className="relative" ref={dropdownRef}>
			{!isReadOnly ? (
				<div className="relative ">
					<button
						data-testid="dropdown-button"
						type="button"
						className={`${
							darkMode ? "bg-transparent  text-secondary" : ""
						} text-lightGray border text-left border-navy-200 rounded w-full hover:border-primary hover:bg-primaryVeryLight hover:text-navy-500 ${
							itemsTableDropdown
								? "py-0.5 px-2 rounded"
								: "pl-4 pr-8 py-2"
						}  flex items-center text-md focus:outline-none focus:ring-2 focus:ring-primary ${additionalClasses}`}
						onClick={() => setIsOpen(!isOpen)}
					>
						<span className="flex-1">
							{selectedOption || defaultValue}
						</span>
						{itemsTableDropdown ? (
							<div className="text-primary  pl-2">
								{getFontAwesomeIcon("chevronDown", "regular")}
							</div>
						) : (
							<span className=" absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
								<svg
									className="h-5 w-5 text-primary"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="none"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path
										stroke="currentColor"
										d="M5 7.5 L10 12.5 L15 7.5 L5 7.5 Z"
									/>
								</svg>
							</span>
						)}
					</button>
					{isOpen && (
						<div
							className={`${
								darkMode ? "bg-accent" : ""
							} origin-top-right absolute right-0 ${
								isOver ? "" : "bottom-8 "
							} mt-2 w-full rounded-md shadow-lg bg-white z-50`}
						>
							<div
								className="py-1"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="options-menu"
							>
								{options.map((option, index) => (
									<button
										data-testid={`dropdown-option-${index}`}
										key={option}
										className={`${
											darkMode
												? "text-primary hover:bg-primary hover:text-accent"
												: "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
										} block w-full text-left px-4 py-2 text-sm `}
										onClick={() => handleSelect(option)}
									>
										{option}
									</button>
								))}
							</div>
						</div>
					)}
				</div>
			) : (
				<div
					className={`border text-left focus:outline-none focus:ring-2 block my-2 w-full py-2 pl-10 rounded-lg shadow-input transition-all outline outline-0 outline-barelyPrimary focus:outline-4 sm:w-2/5 md:w-full lg:w-full xl:w-full ${additionalClasses}`}
				>
					{readOnlyInfo}
				</div>
			)}
		</div>
	);
};

export default Dropdown;
