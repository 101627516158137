import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	SetStateAction,
	Dispatch
} from "react";
import { PageMeta } from "../../pages/PageHooks/usePageDetails";

// Define the shape of the context value
interface DataTableContextProps {
	pageMeta: PageMeta | null;
	pageTitle: string;
}

// Create the context with a default value
const DataTableContext = createContext<DataTableContextProps | undefined>(
	undefined
);

// Create a provider component
export const DataTableProvider: React.FC<{
	children: ReactNode;
	pageMeta: PageMeta | null;
	pageTitle: string;
	setRowsClicked?: Dispatch<SetStateAction<string[]>> | undefined;
	rowsClicked?: string[];
	selectAllChecked?: boolean;
	setSelectAllChecked?: Dispatch<SetStateAction<boolean>>;
}> = ({ children, pageMeta, pageTitle }) => {

	return (
		<DataTableContext.Provider
			value={{
				pageMeta,
				pageTitle,
			}}
		>
			{children}
		</DataTableContext.Provider>
	);
};

// Custom hook to use the DataTableContext
const useDataTableContext = () => {
	const context = useContext(DataTableContext);
	if (!context) {
		throw new Error(
			"useDataTableContext must be used within a DataTableProvider"
		);
	}
	return context;
};
export default useDataTableContext;
