// returns a key-value paired object of the data passed in the query string/parameters
export function getQueryParams(): { [key: string]: string } {
	const urlParams = new URLSearchParams(window.location.search);

	let params: { [key: string]: string } = {};

	for (let param of urlParams) {
		params[param[0]] = param[1];
	}

	return params;
}
export default getQueryParams;
