function formatTimestamp(timestamp: string): string {
	const date = new Date(timestamp);
	const now = new Date();
	const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
	const diffInMinutes = Math.floor(diffInSeconds / 60);
	const diffInHours = Math.floor(diffInMinutes / 60);
	const diffInDays = Math.floor(diffInHours / 24);

	if (diffInDays >= 1) {
		// More than a day ago
	   const options: Intl.DateTimeFormatOptions = {
			month: "short",
			day: "2-digit",
			year: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			hour12: true,
		};
		let formattedDate = new Intl.DateTimeFormat("en-US", options).format(
			date
		);
		// Split the formatted date into parts
		const [monthDay, year, time] = formattedDate.split(", ");
		// Return the formatted date with a comma after the day but not after the year
		return `${monthDay}, ${year} ${time}`;
	} else if (diffInHours >= 1) {
		// Less than a day ago, but more than an hour ago
		return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
	} else if (diffInMinutes >= 1) {
		// Less than an hour ago, but more than a minute ago
		return `${diffInMinutes} ${
			diffInMinutes === 1 ? "minute" : "minutes"
		} ago`;
	} else {
		// Less than a minute ago
		return `${diffInSeconds} ${
			diffInSeconds === 1 ? "second" : "seconds"
		} ago`;
	}
}
export default formatTimestamp;
