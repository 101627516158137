import React, { ReactElement, FC, ReactNode } from "react";

type ContextProvider = FC<{ children: ReactElement | ReactNode }>;

const ContextComposer: FC<{
	contexts: ContextProvider[];
	children: ReactNode;
}> = ({ contexts, children }) => {
	return contexts.reduceRight(
		(kids, Parent) => <Parent>{kids}</Parent>,
		children as ReactElement | ReactNode // Ensure the final return is ReactElement or undefined
	) as ReactElement | null; // Cast the return value to ReactElement | null
};

export default ContextComposer;
