import React from "react";
import { motion } from "framer-motion";

type ProgressBarProps = {
	title?: string;
	lastStep?: number;
	divider?: string;
	getCurrentStep: () => number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
	title,
	lastStep = 99,
	divider = " - ",
	getCurrentStep,
}) => {
	const currentStep = getCurrentStep();
	const progressPercentage = (currentStep / lastStep) * 100;

	return (
		<div className="flex flex-col items-center">
			<div>
				{title && (
					<p className="font-omnes-regular text-xl">
						{title} {currentStep}
						{divider}
						{lastStep}
					</p>
				)}
			</div>
			<div className="w-full border border-navy-250 h-5 rounded-full mt-2 max-w-96 sm:max-w-full">
				<motion.div
					className="h-full bg-primary rounded-full"
					initial={{ width: 0 }}
					animate={{ width: `${progressPercentage}%` }}
					transition={{ duration: 1, ease: "easeInOut" }}
				></motion.div>
			</div>
		</div>
	);
};

export default ProgressBar;
