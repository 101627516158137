// GENERAL
import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
// COMPONENTS
import VendorItemsModalContentTemplate from "../../../../VendorItems/view/modals/edit/VendorItemsModalContentTemplate";
import LoadingMessage from "../../../../../components/misc/LoadingMessage";
import { ItemsModalContentTemplate } from "..";
// UTILS, HOOKS, API, ASSETS, TYPES
import { useItemsContext } from "../../../../../hooks/dataTableHooks/useItemsContext";
import { getData, getSingleItemDetails } from "../../../api/itemsApi";
import { useDataTableContext } from "../../../../../hooks";
import {
	slideUpModalOverlayVariants,
	slideUpModalVariants,
} from "../../../../../motionVariants";
import { Persona, ItemsSliderBaseModalProps } from "../../../types";

const ItemsSliderBaseModal = <T,>({
	isOpen,
	onClose,
	hasBorder = false,
	borderColor = "primary",
	loadingMessage = "",
}: ItemsSliderBaseModalProps<T>) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<T | null>(null);
	const [_, setError] = useState<string | null>(null);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const { activeUuid, switchContent, setSwitchContent } = useItemsContext();
	const [selectsData, setSelectsData] = useState<any | null>(null);
	const { pageTitle } = useDataTableContext();
	const [refresh, setIsRefresh] = useState<boolean>(false);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const uuid = activeUuid || "";

			try {
				const result = await getSingleItemDetails(uuid);

				const manufacturerData = await getData("manufacturers");
				const itemTypeData = await getData("item-types");
				const departmentsData = await getData("asset-types");
				const itemCategory = await getData("item-categories");
				const unitOfMeasures = await getData("measures");
				const priceTypes = await getData("price-types", [
					"uuid",
					"name",
				]);

				// For the default purchase method drop down, the options include 'each', 'lease-48-months', and
				// 'lease-36-months' but we want to exclude the 36 month option - filtering that out via the uuid
				const formatPriceTypes = priceTypes
					.filter(
						({ uuid }) =>
							uuid !== "1b5a09bf-13a1-11ef-8e3b-0aae2980db55"
					)
					.map(({ uuid, name }) => ({
						uuid: uuid,
						name: name,
					}));

				const personas = await getData("personas", [
					"uuid",
					"name",
					"number",
				]);

				const formatPersonas = personas.map(
					({ uuid, number, name }: Persona) => ({
						uuid,
						name: `${number} - ${name}`,
					})
				);

				if (result.defaultPriceType) {
					result.defaultPriceType = {
						uuid: result.defaultPriceType.uuid,
						name: result.defaultPriceType.name,
					};
				}

				setSelectsData({
					manufacturerData: manufacturerData,
					itemTypeData: itemTypeData,
					assetTypeData: departmentsData,
					itemCategory: itemCategory,
					unitOfMeasures: unitOfMeasures,
					priceTypes: formatPriceTypes,
					formatPersonas: formatPersonas,
				});

				setData(result);
				setError(null);
			} catch (error) {
				setError("An error occurred while fetching data");
				setData(null);
			} finally {
				setIsLoading(false);
			}
		};

		if (isOpen) {
			fetchData();
			setIsMenuOpen(true);
			setSwitchContent(switchContent);
		}
	}, [isOpen, activeUuid, refresh]);

	const handleModalOpen = () => {
		document.body.classList.add("overflow-hidden");
	};

	const modalContainerClassNames =
		"fixed inset-0 flex items-end justify-center bg-transparent z-50 max-w-[1200px] mx-auto modal-bottom z-[9920]";
	const modalBorderClassNames = `modal-box min-w-90% rounded-3xl max-h-[95dvh] p-0 ${
		isMenuOpen ? " overflow-hidden pt-0" : ` pt-0 `
	}  ${hasBorder ? `border-${borderColor}` : "border-transparent"}`;
	const modalOverLayClassNames = "fixed inset-0 bg-black z-[9910]";

	const rightToLeftAnimation = {
		initial: { x: 300, opacity: 1 },
		animate: { x: 0, opacity: 1, transition: { duration: 0.2 } },
		exit: { x: -300, opacity: 0, transition: { duration: 0.2 } },
		transition: { type: "spring", stiffness: 100 },
	};

	return (
		<>
			<AnimatePresence>
				{isOpen && (
					<>
						{handleModalOpen()}
						<motion.div
							className={modalContainerClassNames}
							initial={slideUpModalVariants.initial(
								switchContent
							)}
							animate={slideUpModalVariants.animate(
								switchContent
							)}
							exit={slideUpModalVariants.exit(switchContent)}
							transition={slideUpModalVariants.transition}
						>
							<div className="min-w-full">
								{isLoading && !switchContent ? (
									<div
										className={`${modalBorderClassNames} min-full ${
											switchContent
												? "min-h-dvh"
												: "min-h-[95vh]"
										} flex justify-center items-center`}
									>
										<div className="h-full">
											<LoadingMessage
												message={loadingMessage}
											/>
										</div>
									</div>
								) : (
									<div>
										{switchContent ? (
											<div>
												<motion.div
													initial={
														rightToLeftAnimation.initial
													}
													animate={
														rightToLeftAnimation.animate
													}
													exit={
														rightToLeftAnimation.exit
													}
													transition={
														rightToLeftAnimation.transition
													}
												>
													<VendorItemsModalContentTemplate
														modalRef={modalRef}
														pageTitle={pageTitle}
														onClose={onClose}
														isFromVendorItemsPage={
															false
														}
													/>
												</motion.div>
											</div>
										) : (
											<div
												className={
													modalBorderClassNames
												}
											>
												<div>
													<ItemsModalContentTemplate
														modalRef={modalRef}
														parentData={data}
														parentSelectsData={
															selectsData
														}
														onClose={onClose}
														pageTitle={pageTitle}
														setIsRefresh={
															setIsRefresh
														}
														setIsLoading={
															setIsLoading
														}
													/>
												</div>
											</div>
										)}
									</div>
								)}
							</div>
						</motion.div>
						<motion.div
							initial={slideUpModalOverlayVariants.initial}
							animate={slideUpModalOverlayVariants.animate}
							exit={slideUpModalOverlayVariants.exit}
							transition={slideUpModalOverlayVariants.transition}
							className={modalOverLayClassNames}
						></motion.div>
					</>
				)}
			</AnimatePresence>
		</>
	);
};

export default ItemsSliderBaseModal;
