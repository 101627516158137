import React, { useEffect, useMemo, useState } from "react";
import LoadingMessage from "../../misc/LoadingMessage";

interface BaseModalProps<T> {
	isOpen: boolean;
	makeApiCall?: () => Promise<T>;
	onClose: () => void;
	children: React.ReactNode;
	closeButton?: (handleClose: () => void) => React.ReactNode;
	hasBorder?: boolean;
	borderColor?: string;
	loadingMessage?: string;
	altCloseButton?: (handleClose: () => void) => React.ReactNode;
	header?: React.ReactNode;
	containerClassNames?: string;
}

const BaseModal = <T,>({
	header,
	children,
	makeApiCall,
	onClose,
	isOpen,
	closeButton,
	hasBorder = false,
	borderColor = "primary",
	loadingMessage = "",
	altCloseButton,
	containerClassNames = "",
}: BaseModalProps<T>) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<T | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (isOpen && makeApiCall && data === null) {
			// Only fetch data if it hasn't been fetched yet
			const fetchData = async () => {
				setIsLoading(true);
				try {
					const result = await makeApiCall();
					setData(result);
					setError(null);
				} catch (error) {
					setError("An error occurred while fetching data");
					setData(null);
				} finally {
					setIsLoading(false);
				}
			};

			fetchData();
		}
	}, [isOpen, makeApiCall, data]);

	const handleClose = () => {
		onClose();
	};

	const memoizedContentCLassNames = "min-h-loadingModal flex items-center";
	// this class is setting the BG when the item zoom modal is open .modal:not(dialog:not(.modal-open))  it can be found in index.scss
	const modalContainerClassNames =
		"modal modal-open modal-bottom sm:modal-middle z-[9910]";
	const modalBorderClassNames = ` ${containerClassNames} border-2 ${
		hasBorder ? `border-${borderColor}` : "border-transparent"
	}`;
	const modalActionClassNames = "modal-action";
	const headerContainerClassNames = "flex items-center justify-between pb-5";
	const contentContainerClassNames = "min-w-max max-w-full overflow-auto";
	const rowFlexClassNames = "flex-1";
	const memoizedContent = useMemo(() => {
		return (
			<>
				{isLoading && (
					<div className={memoizedContentCLassNames}>
						<LoadingMessage message={loadingMessage} />
					</div>
				)}
				{!isLoading && children}
			</>
		);
	}, [isLoading, children, loadingMessage]);

	const handleBackgroundClick = () => {
		onClose();
	};

	const handleModalClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		e.stopPropagation();
	};

	if (!isOpen) return null;

	return (
		<div>
			<dialog
				className={modalContainerClassNames}
				onClick={handleBackgroundClick}
			>
				<div
					className={modalBorderClassNames}
					onClick={handleModalClick}
				>
					<div className={contentContainerClassNames}>
						<div className={headerContainerClassNames}>
							<div className={rowFlexClassNames}>{header}</div>
							{altCloseButton && altCloseButton(handleClose)}
						</div>
						{memoizedContent}
					</div>
					<div className={modalActionClassNames}>
						{closeButton && closeButton(handleClose)}
					</div>
				</div>
			</dialog>
		</div>
	);
};

export default BaseModal;

// BEFORE MERGE

// import React, {useState } from "react";
// import LoadingMessage from "../../misc/LoadingMessage";

// interface BaseModalProps<T> {
// 	isOpen: boolean;
// 	onClose: () => void;
// 	children: React.ReactNode;
// 	loadingMessage?: string;
// 	header?: React.ReactNode;
// 	contentContainerClasses?: string;
// }

// const BaseModal = <T,>({
// 	header,
// 	children,
// 	onClose,
// 	isOpen,
// 	contentContainerClasses,
// }: BaseModalProps<T>) => {
// 	const [isLoading, setIsLoading] = useState<boolean>(false);
// 	const memoizedContentCLassNames = "min-h-loadingModal flex items-center";
// 	// this class is setting the BG when the item zoom modal is open .modal:not(dialog:not(.modal-open))  it can be found in index.scss
// 	const modalContainerClassNames =
// 		"modal modal-open modal-bottom sm:modal-middle flex items-center justify-center min-h-[100%] w-full max-h-[80%] p-5";

// 	const modalActionClassNames = "modal-action";
// 	const headerContainerClassNames = "flex items-center justify-between pb-5";
// 	const contentContainerClassNames = "min-w-max max-w-full overflow-auto";
// 	const rowFlexClassNames = "flex-1";

// 	const handleBackgroundClick = () => {
// 		onClose();
// 	};

// 	const handleModalClick = (
// 		e: React.MouseEvent<HTMLDivElement, MouseEvent>
// 	) => {
// 		e.stopPropagation();
// 	};

// 	if (!isOpen) return null;

// 	return (
// 		<div>
// 			<dialog
// 				className={modalContainerClassNames}
// 				onClick={handleBackgroundClick}
// 			>
// 				<div
// 					onClick={handleModalClick}
// 					className={`${contentContainerClassNames} ${contentContainerClasses}`}
// 				>
// 					<div className={rowFlexClassNames}>{header}</div>
// 					{children}
// 				</div>
// 			</dialog>
// 		</div>
// 	);
// };

// export default BaseModal;
