import { useEffect } from "react";

const usePopState = (handlePopStateFunction: () => void): void => {
	useEffect(() => {
		window.addEventListener("popstate", handlePopStateFunction);

		return () => {
			window.removeEventListener("popstate", handlePopStateFunction);
		};
	}, [handlePopStateFunction]);
};

export default usePopState;
