import React, { useState } from "react";
import Section from "../../../components/layout/Section";
import { PrimaryTable } from "../../../components/ui/Tables/PrimaryTable";
import { ItemsContextProvider } from "../../../hooks/dataTableHooks/useItemsContext";

import BaseText from "../../../components/ui/BaseText";
import { DataTableProvider } from "../../../hooks/dataTableHooks/useDataTableContext";
import { getFontAwesomeIcon } from "../../../utils";
import usePageListDetails from "../../PageHooks/usePageListDetails";
import { BaseButton } from "../../../components/ui";
import { ItemsListingModal } from "../view/modals/create";

const ItemsPage: React.FC = () => {
	const [pageTitle, setPageTitle] = useState<string>("Items");
	const [hasModalTable, setHasModalTable] = useState<boolean>(true);
	const [data, setData] = useState<any[]>([]);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const slug = "items";
	const { pageMeta } = usePageListDetails("items-listing");

	//Building Additional Data object
	const additionalData = {
		slug: "catalogId",
		uuid: "1",
	};

	const handleListingModalClick = () => {
		setIsOpen((prevState) => !prevState);
	};
	const modal = (
		<ItemsListingModal
			isOpen={isOpen}
			handleListingModalClick={handleListingModalClick}
		/>
	);

	const baseButtonClassNames =
		"text-sm bg-primary text-white px-4 flex items-center gap-2 hover:bg-primaryDefaultHover border-none font-omnes-medium";
	const containerClassNames =
		"pt-10 mx-[60px] min-h-full max-h-full font-omnes-regular";
	const sectionClassNames = "flex justify-between pb-3";
	return (
		<div className={containerClassNames}>
			<Section additionalClasses={sectionClassNames}>
				<div className="flex items-center">
					{getFontAwesomeIcon("box", "solid")}
					<BaseText
						text={pageTitle}
						additionalClasses="pl-2"
						size="text-[22px]"
						color="text-navy-500"
						tag="h1"
						fontFamily="font-omnes-medium"
					/>
				</div>
				<BaseButton
					icon={getFontAwesomeIcon("plus", "solid")}
					iconSize="text-sm"
					text="New Item"
					type="button"
					onClick={handleListingModalClick}
					additionalClasses={baseButtonClassNames}
					shape="rounded"
					customHeight="h-26px"
				/>
			</Section>
			<DataTableProvider pageMeta={pageMeta} pageTitle={pageTitle}>
				<ItemsContextProvider hasModalTable={false} data={data}>
					<PrimaryTable
						pageTitle={pageTitle}
						hasModalTable={hasModalTable}
						setPageTitle={setPageTitle}
						setHasModalTable={setHasModalTable}
						slug={slug}
						setData={setData}
						additionalData={additionalData}
					/>
				</ItemsContextProvider>
			</DataTableProvider>
			{modal}
		</div>
	);
};

export default ItemsPage;
