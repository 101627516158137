const formatCustomerName = (name: string, hostName: string) => {
	if (
		name === null ||
		name === undefined ||
		name === "—" ||
		(name === "N/A" && hostName === "COMPASS")
	) {
		return "Compass Group";
	} else if (
		name === null ||
		name === undefined ||
		name === "—" ||
		(name === "N/A" && hostName === "MIAMIDADE")
	) {
		return "Miami-Dade County";
	} else if (
		name === null ||
		name === undefined ||
		name === "—" ||
		(name === "N/A" && hostName === "NYCHH")
	) {
		return "NYC Health + Hospitals";
	} else if (
		name === null ||
		name === undefined ||
		name === "—" ||
		(name === "N/A" && hostName === "NYCHH")
	) {
		return "NYC Health + Hospitals";
	} else if (
		name === null ||
		name === undefined ||
		name === "—" ||
		(name === "N/A" && hostName === "BANKOFAMERICA")
	) {
		return "Bank of America";
	} else if (
		name === null ||
		name === undefined ||
		name === "—" ||
		(name === "N/A" && hostName === "PRUDENTIAL")
	) {
		return "Prudential";
	} else {
		return name;
	}
};

export default formatCustomerName;
