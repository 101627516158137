interface BundleItemProps {
	uuid: string;
	_extPriceBundlePurchase?: string;
	_extPriceBundleLease?: string;
}

// Utility function to add lease prices into bundle items

const checkLeaseTotals = (
	itemDetails: BundleItemProps[],
	orderDetails: any
): BundleItemProps | undefined => {
	// bundle information is coming from two different endpoints (okay per Jeff)
	// we need to add the lease price and bundle total price to the bundle's primary item
	// this is what we are doing here
	if (Array.isArray(orderDetails.salesOrderItems)) {
		itemDetails.forEach((item: BundleItemProps) => {
			// For each item (contains bundle info but not pricing), finds the corresponding sales order i
			// tem in orderDetails.salesOrderItems (contains pricing info, but not bundle info) where the uuid matches.
			const correspondingSalesOrderItem =
				orderDetails.salesOrderItems.find(
					(salesOrderItem: BundleItemProps) =>
						salesOrderItem.uuid === item.uuid
				);

			// If a corresponding sales order item is found, updates the _extPriceBundlePurchase and _extPriceBundleLease
			// properties of the item with the values from the corresponding sales order item.
			if (correspondingSalesOrderItem) {
				item._extPriceBundlePurchase =
					correspondingSalesOrderItem._extPriceBundlePurchase;
				item._extPriceBundleLease =
					correspondingSalesOrderItem._extPriceBundleLease;
			}
		});
	}

	return undefined;
};

export default checkLeaseTotals;
