// useHandlePopState.ts
import { useEffect, useCallback } from "react";

const useHandlePopState = (
	prevPageFromURL: number,
	setPrevPageFromURL: (page: number) => void,
	fetchData: () => void
) => {
	const handlePopState = useCallback(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const pageParam = searchParams.get("page");
		const currentPageFromURL = pageParam ? Number(pageParam) : 1;

		if (prevPageFromURL !== currentPageFromURL) {
			setPrevPageFromURL(currentPageFromURL);
			fetchData();
		}
	}, [prevPageFromURL, setPrevPageFromURL, fetchData]);

	useEffect(() => {
		window.addEventListener("popstate", handlePopState);

		// Cleanup
		return () => {
			window.removeEventListener("popstate", handlePopState);
		};
	}, [handlePopState]);

	return handlePopState;
};

export default useHandlePopState;
