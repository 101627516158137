type UpdateRowDataProps = {
	uuid: string;
	key: string;
	newValue: any;
	setRowData: React.Dispatch<React.SetStateAction<any[]>>;
	keysArray: string[];
};

export const updateRowData = ({
	uuid,
	key,
	newValue,
	setRowData,
	keysArray,
}: UpdateRowDataProps) => {
	setRowData((prevData) => {
		const updatedData = prevData.map((row) => {
			if (row.data.uuid === uuid) {
				if (keysArray.includes(key)) {
					return {
						...row,
						data: {
							...row.data,
							[key]: newValue,
						},
					};
				}
			}
			return row;
		});
		return updatedData;
	});
};
