const signatureTypeOptions = [
	{
		uuid: "None Specified",
		name: "None Specified",
	},
	{ uuid: "Required", name: "Required" },
	{ uuid: "Not Required", name: "Not Required" },
];

import {
	Field,
	ShippingMethodOptions,
	CarrierAccount,
	OptionsArray,
} from "../../../../types";

const getUpdateShipmentFormOptions = (
	field: Field,
	optionsArray: OptionsArray[],
	watchedFields: any,
	shippingMethodOptions: ShippingMethodOptions[],
	carrierAccountNumber: any
) => {
	let options;
	if (field.valueKey === "signatureType") {
		options = signatureTypeOptions;
	} else if (
		field.valueKey === "shipmentMethod" &&
		shippingMethodOptions &&
		watchedFields[0] !== null && watchedFields[0] !== undefined
	) {
		let selectedMethods = shippingMethodOptions.filter((shippingMethod) => {
			return shippingMethod.carrierName === watchedFields[0].name;
		});

		if (selectedMethods.length > 0) {
			options = selectedMethods[0].shippingMethods;
		}
	} else if (field.valueKey === "carrier" && optionsArray) {
		options = optionsArray[0].shippingCarriers;
	} else if (
		field.valueKey === "carrierAccountNumber" &&
		optionsArray &&
		watchedFields[0] !== null &&
		watchedFields[0] !== undefined
	) {
		let carrierAccountNumberOptions = carrierAccountNumber
			?.filter((carrierAccount: CarrierAccount) => {
				return carrierAccount.carrierName === watchedFields[0].name;
			})
			.map(
				(carrierAccount: CarrierAccount) =>
					carrierAccount.accountNumbers
			);
		options = carrierAccountNumberOptions?.[0] || [];
	} else if (field.valueKey === "state" && optionsArray) {
		options = optionsArray[1].states;
	} else if (field.valueKey === "country" && optionsArray) {
		options = optionsArray[2].countries;
	}
	return options;
};

export default getUpdateShipmentFormOptions;
