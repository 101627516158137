import React, { useState } from "react";
import { getFontAwesomeIcon } from "../../utils";

type AccordionProps = {
	headerChildren?: React.ReactNode;
	children?: React.ReactNode;
	accordionParentClasses: string;
	accordionExpandedClasses: string;
};

const BaseAccordion: React.FC<AccordionProps> = ({
	headerChildren,
	children,
	accordionParentClasses,
	accordionExpandedClasses,
}) => {
	const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);

	const toggleAccordion = () => {
		setAccordionExpanded(!accordionExpanded);
	};

	const baseChevronClasses =
		"text-xs text-black transition-transform duration-500 transform";
	const baseChildrenContainerClasses =
		"overflow-hidden transition-[max-height] duration-300 ease-in-out";
	const accordionParentBaseClasses =
		"items-center flex justify-between w-full";

	return (
		<div>
			<button
				className={`cursor-pointer w-full ${accordionParentClasses}`}
				onClick={() => toggleAccordion()}
				aria-expanded={accordionExpanded}
				tabIndex={0}
			>
				<div className={accordionParentBaseClasses}>
					<div className="flex w-full">{headerChildren}</div>
					<div className="flex justify-end">
						<div
							className={`${baseChevronClasses} ${
								accordionExpanded ? "rotate-180" : ""
							}`}
						>
							{getFontAwesomeIcon("chevronDown", "light")}
						</div>
					</div>
				</div>
			</button>
			<div
				className={`${baseChildrenContainerClasses} ${
					accordionExpanded ? "max-h-96" : "max-h-0"
				} ${accordionExpandedClasses}`}
				aria-hidden={!accordionExpanded}
				tabIndex={accordionExpanded ? 0 : -1}
			>
				<div>{children}</div>
			</div>
		</div>
	);
};

export default BaseAccordion;
