import { togaApiRequest } from "../../../api/toga";
import { ExtendedOptionsType } from "../../Items/api/itemsApi";

export async function triggerForgotPasswordEmail(email: string) {
	let response = await togaApiRequest(
		"GET",
		"/users/sendForgotPasswordEmail?userEmail=" + email,
	);

	if (!response.isSuccess) {
		console.log(":(");
		return;
	}
	return response.data.users.sendForgotPasswordEmail;
}
