// The generateDynamicConfig function takes an array of DataItem objects and generates a dynamic configuration for columns based on the slug property of each item. It creates an array of ColumnConfig objects, where each object has a unique key (e.g., col1, col2, etc.) and a path set to the slug value. The resulting configuration is returned as an object with a single key default mapping to the array of column configurations.
// The convertDataUtil function takes an array of DataItem objects and a dynamicConfigMap object containing column configurations. It first retrieves the column configurations from the default key of the dynamicConfigMap.
// Inside convertDataUtil, there are two helper functions:
// getColumnValue: This function takes a DataItem object and a ColumnConfig object. It accesses the value of the data item using the path specified in the column configuration. If the value is falsy, it returns "—".
// getTransformedData: This function takes a DataItem object and creates a new object rowData to store the transformed data for that row. It iterates over the column configurations and calls getColumnValue for each configuration, setting the value in rowData using the column's key as the property name.
// The convertDataUtil function maps over the array of DataItem objects and calls getTransformedData for each item. This creates an array of row data objects, where each object has properties corresponding to the column configurations, with values taken from the respective data item.
// The resulting array of row data objects can be used to render a table or grid, where each object represents a row, and the properties represent the columns with their respective values.

// TypeScript types for the data structures used in this utility
import React from "react";

// An object that maps keys to path strings
export type DataMappingConfig = {
	[key: string]: { path: string };
};

// A data item with a slug property and other properties of any type
export type DataItem = {
	slug: string;
	[key: string]: any;
};

// Configuration for a column in a table or grid
export type ColumnConfig = {
	key: string; // Unique key for the column
	path: string; // Path to the property in the data item
	type: string;
};

// Function to generate dynamic column configurations from an array of data items
export const generateDynamicConfig = (
	data: DataItem[]
): Record<string, ColumnConfig[]> => {
	return {
		default: data.map((item, index) => ({
			key: `col${index + 1}`, // Generate a unique key for the column
			path: item.slug, // Use the slug property as the path
			type: item.type,
		})),
	};
};

// Function to convert data items to a format suitable for rendering in a table or grid
const convertDataUtil = (
	data: DataItem[],
	dynamicConfigMap: Record<string, ColumnConfig[]>
): Record<string, string | React.ReactElement>[] => {
	const columnsConfig = dynamicConfigMap["default"] || []; // Get the column configurations

	// Function to get the value from a data item based on the column configuration
	const getColumnValue = (item: DataItem, config: ColumnConfig) => {
		const value = item.data[config.path]; // Access the value using the path
		if (typeof value === "boolean") {
			return value ? "True" : "False";
		} else if (value === 0 && config.type === "CURRENCY") {
			// If value is 0 return 0.00
			return "0.00";
		} else {
			return value || "—"; // Return the value or "—" if it's falsy
		}
	};

	// Function to transform a data item into a row data object
	const getTransformedData = (dataItem: DataItem) => {
		let rowData: Record<string, string | React.ReactElement> = {};
		columnsConfig.forEach((config) => {
			rowData[config.key] = getColumnValue(dataItem, config); // Set the value for each column
		});

		return rowData;
	};

	// Map over the data items and transform each one into a row data object
	return data.map((item: DataItem) => getTransformedData(item));
};
export default convertDataUtil;
