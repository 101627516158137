import { togaApiRequest } from "../../../api/toga";
import { ExtendedOptionsType } from "../../Items/api/itemsApi";

export async function updatePassword(password: string,token: string) {
	let response = await togaApiRequest(
		"GET",
		"/users/updatePassword?password=" + password + "&token=" + token,
	);
	if (!response.isSuccess) {
		console.log(":(");
		return;
	}
	return response.data.users.updatePassword;
}
