import {
	Field,
	ShipmentData,
	ShippingMethodOptions,
	ShipmentOrderSelectedItemTypes,
} from "../../../../types";
import BaseInput from "../../../../../../../components/ui/BaseInput/BaseInput";
import BaseDetailRow from "../../../../../../../components/ui/BaseDetailRow";
import BaseDisabledInput from "../../../../../../../components/ui/BaseDisabledInput";
import formatCurrency from "../../../../../../../utils/formatCurrency";
export const renderUpdateShipmentFormInput = (
	field: Field,
	value: string | any,
	options: ShippingMethodOptions[],
	tableFormData: ShipmentOrderSelectedItemTypes[],
	shipmentAddress: string,
	watchedFields: any,
	control: any,
	setError: any,
	clearErrors: any
): JSX.Element => {


	if (field.valueType === "detail" && field.valueKey === "address") {
		return (
			<BaseDetailRow
				key={field.id}
				field={field}
				dataItem={shipmentAddress}
				iconStyle="regular"
			/>
		);
	} else if (
		field.valueType === "detail" &&
		field.valueKey === "itemQuantity"
	) {
		return (
			<BaseDetailRow
				key={field.id}
				field={field}
				dataItem={tableFormData?.length}
				iconStyle="regular"
			/>
		);
	} else if (field.valueType === "detail") {
		return (
			<BaseDetailRow
				key={field.id}
				field={field}
				dataItem={value}
				iconStyle="regular"
			/>
		);
	} else if (field.valueKey === "shipmentMethod" && !watchedFields[0]) {
		return (
			<BaseDisabledInput
				key={field.id}
				field={field}
				inputContainerClasses={field.specificInputContainerClasses}
				inputTextSize="text-sm text-navy-400"
				text="Select Carrier"
				disabledInputClasses="rounded w-56 text-sm bg-navy-200 px-2"
			/>
		);
	} else if (field.valueKey === "carrierAccountNumber" && !watchedFields[0]) {
		return (
			<div className="w-32">
				<BaseDisabledInput
					key={field.id}
					field={field}
					inputContainerClasses={""}
					inputTextSize="text-sm text-navy-400"
					text="Select Carrier"
					disabledInputClasses="rounded w-56 text-sm bg-navy-200 px-2"
				/>
			</div>
		);
	} else if (
		field.valueKey === "carrierAccountNumber" &&
		watchedFields[1] === null
	) {
		return (
			<div className="w-32">
				<BaseDisabledInput
					key={field.id}
					field={field}
					inputContainerClasses={""}
					inputTextSize="text-sm text-navy-400"
					text="Select Carrier"
					disabledInputClasses="rounded w-56 text-sm bg-navy-200 px-2"
				/>
			</div>
		);
	} else {
		return (
			<BaseInput
				key={field.id}
				field={field}
				dataItem={value}
				inputContainerClasses={field.specificInputContainerClasses}
				options={options}
				inputTextSize="text-sm"
				hasParentControl={control}
			/>
		);
	}
};

export default renderUpdateShipmentFormInput;
