import { DataWithUUID, TableCellProps } from "./types";

const TableCell = <T extends DataWithUUID>({
	globalTrimActive,
	rowUuid,
	columnInputs,
	cell,
	isLastCell,
}: TableCellProps<T>) => {
	const trimText = (text: string) =>
		text.length > 30 ? `${text.substring(0, 30)}...` : text;

	const shouldTrim = globalTrimActive && typeof cell.value === "string";
	const cellContent = shouldTrim ? trimText(cell.value) : cell.render("Cell");
	const cellProps = cell.getCellProps();

	const compassTableDataClassNames =
		"relative overflow-hidden font-light text-sm text-left z-0 px-5";

	const tableDataClassNames = compassTableDataClassNames;
	("relative overflow-hidden font-light text-sm lg:text-sm py-2 text-left z-0 pl-2 pr-2 hidden");
	const cellContainerClassNames = "min-h-[40px] flex items-center";

	return (
		<td
			{...cell.getCellProps()}
			className={isLastCell ? "" : tableDataClassNames}
		>
			<div className={cellContainerClassNames}>{cellContent}</div>
		</td>
	);
};

export default TableCell;
