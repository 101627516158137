import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Container from "../layout/Container";

import { motion, AnimatePresence } from "framer-motion";
import { useDarkMode } from "../../hooks";
interface LoadingProps {
	message: string;
	additionalClasses?: string;
	containerClasses?: string;
	messageAdditionalClasses?: string;
}

const LoadingMessage: React.FC<LoadingProps> = ({
	containerClasses,
	additionalClasses,
	message,
	messageAdditionalClasses,
}) => {
	const { darkMode } = useDarkMode();
	const defaultContainerClasses =
		containerClasses || "container mx-auto px-4";

	return (
		<AnimatePresence>
			<motion.div>
				<Container additionalClasses={defaultContainerClasses}>
					<div className="fa-spin">
						{/* Wrapper with fa-spin */}
						<FontAwesomeIcon
							className={`${additionalClasses} fa-beat-fade ${
								darkMode ? "text-accent" : "text-primary"
							} text-loading`}
							icon={faLoader}
						/>
					</div>
					<div
						role="loadingMessage"
						className={`${additionalClasses} ${messageAdditionalClasses}`}
					>
						{message}
					</div>
				</Container>
			</motion.div>
		</AnimatePresence>
	);
};

export default LoadingMessage;
