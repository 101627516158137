import { togaApiRequest } from "../../../../api/toga";
import { ExtendedOptionsType } from "../../../Items/api/itemsApi";

// This is the API call to get the order details
export async function getOrderDetails(internalId: string){
    let options: ExtendedOptionsType = {
		fields:[
			"uuid",
			"salesOrderItems.uuid",
			"salesOrderItems.lineNumber",
			"salesOrderItems.item.partNumber",
			"salesOrderItems.item.skuNumber",
			"salesOrderItems.item.upcCode",
			"salesOrderItems.item.manufacturer.name",
			"salesOrderItems.item.description",
			"salesOrderItems.quantity",
			"salesOrderItems.item.inventoryType",
			"salesOrderItems.qtyCommitted",
			"salesOrderItems.price",
			"salesOrderItems._extPrice",
			"salesOrderItems._qtyBilled",
			"salesOrderItems._qtyFulfilled",
			"salesOrderItems.item._qtyTotal"
		],
		where: {
			and: [{ "c_netsuiteInternalSalesOrderId": { "=": internalId } }],
		},
	};
	let response = await togaApiRequest(
		"GET",
		"/sales-orders",
		null,
		options
	);
	return response.data;
}
