import React from "react";
import GenericList from "../../layout/GenericList";
import InitialsAvatar from "../InitialsAvatar";
import formatTimestamp from "../../../utils/formatDateForEventLogs";
import BaseText from "../BaseText";
import BaseBadge from "../BaseBadge";
import { NotesListProps, Note } from "./types";

const NotesList: React.FC<NotesListProps> = ({
	notesData,
	badgeText,
	badgeClasses,
	maxHeightClasses,
}) => {
	const contactIconClasses =
		"flex items-center justify-center size-8 rounded-full bg-navy-200";
	const individualNoteContainer = "flex flex-col w-full items-start mb-2.5";
	const noteNameDateContainer = "flex items-center flex-row gap-1.5 w-full";
	const genericListContainer = "flex flex-col items-start w-full text-sm ";

	const renderItem = (note: Note) => (
		<div className={individualNoteContainer}>
			<div className={noteNameDateContainer}>
				<InitialsAvatar
					firstName={note.contact.firstName}
					lastName={note.contact.lastName}
					containerClasses={contactIconClasses}
					textClasses="font-omnes-medium text-navy-500"
				/>
				<BaseText
					text={note.contact._name}
					fontFamily="font-omnes-medium"
					tag="p"
					size="text-sm"
					color="text-navy-500"
					additionalClasses="m="
				/>
				<BaseBadge
					text={badgeText}
					badgeContainerClasses={badgeClasses}
				/>
				<BaseText
					text={formatTimestamp(note.dtStamp)}
					fontFamily="font-omnes-regular"
					tag="p"
					size="text-sm"
					color="text-gray-500"
					additionalClasses="m="
				/>
			</div>
			<BaseText
				text={note.note}
				fontFamily="font-omnes-regular"
				tag="p"
				size="text-sm"
				color="text-navy-500"
				additionalClasses="pt-1 text-left"
			/>
		</div>
	);

	return (
		<div className="w-full pt-4">
			<GenericList<Note>
				containerClasses={`${genericListContainer} ${maxHeightClasses}`}
				data={notesData}
				renderItem={renderItem}
				listType="ordered"
			/>
		</div>
	);
};

export default NotesList;
