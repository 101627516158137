type Address = {
	addressee?: string;
	street?: string;
	line1?: string;
	line2?: string;
	city?: string;
	zip?: string;
};

interface GoogleMapsLinkProps {
	address: Address;
	hoverClasses?: string;
}

const GoogleMapsLink: React.FC<GoogleMapsLinkProps> = ({
	address,
	hoverClasses,
}) => {
	const { addressee, line1, line2, city, zip } = address;

	const addressParts = [addressee, line1, line2, city, zip].filter(
		(part) => part != null || part != undefined
	);
	const fullAddress = addressParts.join(", ");

	const formattedAddress = encodeURIComponent(
		fullAddress.trim().replace(/\s+/g, "+")
	);
	const link = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;

	return (
		<a
			href={link}
			target="_blank"
			rel="noopener noreferrer"
			className={hoverClasses}
		>
			Google Maps
		</a>
	);
};

export default GoogleMapsLink;
