export const formatShipmentData = (value: any, valueKey: string) => {
	if (valueKey === "weight") {
		if (
			value === "0" ||
			value === 0 ||
			value === "" ||
			value === undefined ||
			value === null
		) {
			return "-";
		} else {
			return value;
		}
	} else if (
		valueKey === "quantity" ||
		valueKey === "description" ||
		valueKey === "carrier" ||
		valueKey === "shipmentMethod" ||
		valueKey === "carrierAccountNumber" ||
		valueKey === "declaredCost"
	) {
		if (value === undefined || value === "" || value === null) {
			return "-";
		} else {
			return value;
		}
	} else if (valueKey === "dimensions") {
		if (value.length === "" && value.height === "" && value.width === "") {
			value.length = "0";
			value.height = "0";
			value.width = "0";
		} else {
			return value;
		}
	} else {
		return value;
	}
};
