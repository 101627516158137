import React, { forwardRef, useEffect, useState } from "react";
import { ColumnInstance } from "react-table";
import { getFontAwesomeIcon } from "../../../utils";

interface MagnifyingIconProps<T extends object> {
	editingHeader: number | null;
	columnIndex: number;
	setEditingHeader: (headerIndex: number | null) => void;
	setHeaderValue: (headerValue: string) => void;
	column: ColumnInstance<T>;
	setSearchText?: (text: string) => void;
	searchText?: string;
	setSearchColumn: any;
	setActiveColumnFromSearch: React.Dispatch<React.SetStateAction<string>>;
	isActive: boolean;
	setActiveColumn: (columnIndex: number) => void;
	isSearchOpen?: boolean;
}

const MagnifyingIcon = forwardRef<HTMLDivElement, MagnifyingIconProps<any>>(
	(
		{
			columnIndex,
			setEditingHeader,
			setHeaderValue,
			column,
			setSearchColumn,
			setActiveColumnFromSearch,
			setActiveColumn,
			editingHeader,
		},
		ref
	) => {
		const [showActiveStyles, setShowActiveStyles] =
			useState<boolean>(false);

		const handleClick = (event: React.MouseEvent) => {
			event.stopPropagation();
			setEditingHeader(columnIndex);
			setHeaderValue(column.Header as string);
			setSearchColumn(column);
			setActiveColumnFromSearch(column.id);
			setActiveColumn(columnIndex);

			// No need to update localStorage here, as it's handled elsewhere
		};

		useEffect(() => {
			const checkSearchCriteria = () => {
				const searchCriteriaRaw =
					localStorage.getItem("searchCriteria");
				let searchCriteria: any[] = [];
				try {
					if (searchCriteriaRaw) {
						searchCriteria = JSON.parse(searchCriteriaRaw);
					}
				} catch (e) {
					console.error(
						"Failed to parse searchCriteria from localStorage:",
						e
					);
					searchCriteria = [];
				}

				// searchCriteria is an array
				const hasSearchValue = searchCriteria.some(
					(item) =>
						item.searchColumn.id === column.id &&
						item.submittedSearchText &&
						item.submittedSearchText.trim() !== ""
				);

				setShowActiveStyles(
					hasSearchValue || editingHeader === columnIndex
				);
			};

			checkSearchCriteria();

			// Set up an event listener for custom events when localStorage changes
			const handleStorageEvent = () => {
				checkSearchCriteria();
			};

			window.addEventListener("localStorageUpdate", handleStorageEvent);

			return () => {
				window.removeEventListener(
					"localStorageUpdate",
					handleStorageEvent
				);
			};
		}, [editingHeader, column.id, columnIndex]);

		return (
			<div
				ref={ref}
				className={`flex items-center cursor-pointer size-[18px] rounded relative ml-1 ${
					showActiveStyles
						? "bg-supply-blue-400 hover:bg-supply-blue-300 still-active"
						: "hover:bg-white"
				}`}
				onClick={handleClick}
			>
				<div
					className={`text-xs w-full ${
						showActiveStyles ? "text-white" : "text-navy-500"
					}`}
				>
					{getFontAwesomeIcon("magnifyingGlass", "solid")}
				</div>
			</div>
		);
	}
);

export default MagnifyingIcon;
