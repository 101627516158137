import React from "react";

import StatusColorDot from "../../../../../components/ui/Tables/StatusColorDot";
import formatModalDate from "../../../../../utils/formateModalDate";
import { Invoice } from "./../../types";
export interface Item {
	itemHeader: string;
	trackingNumber: string;
	deliveryType: string;
	carrier: string;
	shippingMethod: string;
	shippingCarrier: {
		name: string;
		urlLinkPrefix: string;
		urlLogo: string;
	};
	invoice: {
		salesOrder: {
			shippingMethod: string;
		};
	};
	number?: string;
	shippingStatus?: string;
	lastUpdate?: string;
	shippingCosts?: string;
	shippingPrice?: string;
	shippingWeight?: string;
	location?: string;
	address?: string;
	status?: string;
	dateInvoice?: string;
	dateDue?: string;
}

interface ItemHeaderProps {
	item?: Item | React.ReactNode | Invoice;
}

const OrderInvoiceHeader: React.FC<ItemHeaderProps> = ({ item }) => {
	const baseClasses =
		"flex w-full justify-between font-omnes-regular text-sm pr-2.5";

	return (
		<div className={baseClasses}>
			<div className="flex items-center w-1/2 max-md:flex-col max-md:items-start max-md:w-1/2">
				<div className="flex max-md:w-full pr-5">
					<p className="font-omnes-regular">
						{(item as Item).number}
					</p>
				</div>
				<div className="flex max-md:w-full pr-2.5">
					<p className="font-omnes-regular text-nowrap">
						Date: {formatModalDate((item as Item).dateInvoice)}
					</p>
				</div>
				<div className="flex max-md:w-full">
					<p className="font-omnes-regular text-nowrap">
						Due Date: {formatModalDate((item as Item).dateDue)}
					</p>
				</div>
			</div>
			<StatusColorDot
				status={(item as Item).status || ""}
				useConvertedStatus={true}
				additionalClasses="flex items-center"
			/>
		</div>
	);
};
export default OrderInvoiceHeader;
