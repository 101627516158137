import React, { useState } from "react";
import BaseModal from "../../../../../components/layout/Modal/BaseModal";
import ShipmentItem from "../../../../../components/ui/ShipmentItem";
import ItemsDataTable from "../../../../../components/ui/Tables/PrimaryTable/PrimaryTable";
import usePageListDetails from "../../../../PageHooks/usePageListDetails";
import { DataTableProvider } from "../../../../../hooks/dataTableHooks/useDataTableContext";
import { ItemsContextProvider } from "../../../../../hooks/dataTableHooks/useItemsContext";
import { getFontAwesomeIcon } from "../../../../../utils";
interface ProductListProps {
	products: ShipmentItem[];
	toggleProduct: (id: string) => void;
	openProducts: string[];
}

interface ShipmentDetailsHeaderProps {
	onUnitViewToggle: () => void;
	isUnitViewToggled: boolean;
	onlySerialNumbers?: boolean;
}

interface ShippingDetailsModalProps {
	isModalOpen: boolean;
	toggleModal: () => void;
	selectedTrackingNumber: string;
	products: ShipmentItem[];
	toggleProduct: (productId: string) => void;
	openProducts: string[];
}

interface UnitToggleProps {
	isToggled: boolean;
	onToggle: () => void;
}

const FulfillmentModal: React.FC<ShippingDetailsModalProps> = ({
	isModalOpen,
	toggleModal,
	selectedTrackingNumber,
	products,
	toggleProduct,
}) => {
	const [hasModalTable, setHasModalTable] = useState<boolean>(false);
	// const [pageTitle, setPageTitle] = useState("Orders");
	const [pageTitle, setPageTitle] = useState<string>("");
	const [data, setData] = useState<any[]>([]);

	const slug = "sales-order-items-fulfillments-listing";
	const { pageMeta, isLoadingMeta, isError } = usePageListDetails(
		"sales-order-items-fulfillments-listing"
	);
	const modalHeader = (
		<div className="flex w-full items-start text-22px font-omnes-regular text-navy-500">
			<h1>Fulfillment</h1>
		</div>
	);
	const modalButtonClassNames =
		"text-xl text-navy-300 hover:text-navy-500 p-2";

	return (
		<BaseModal
			onClose={toggleModal}
			isOpen={isModalOpen}
			header={modalHeader}
			altCloseButton={(toggleModal) => (
				<button onClick={toggleModal} className={modalButtonClassNames}>
					{getFontAwesomeIcon("xmark", "regular")}
				</button>
			)}
		>
			<DataTableProvider pageMeta={pageMeta} pageTitle={pageTitle}>
				<ItemsContextProvider hasModalTable={true} data={data}>
					<ItemsDataTable
						pageTitle={pageTitle}
						hasModalTable={hasModalTable}
						setPageTitle={setPageTitle}
						setHasModalTable={setHasModalTable}
						slug={"units"}
						setData={setData}
						additionalData={{ isLoadingMeta, isError }}
					/>
				</ItemsContextProvider>
			</DataTableProvider>
		</BaseModal>
	);
};

export default FulfillmentModal;

// CLIP BOARD ICON
{
	/* <div>
			<CopyToClipboardIconWrapper
				copyText="Copy All Serial Numbers"
				withText
				trackingNumber={"1Z175W8RD379281896"}
				lastCopiedTrackingNumber={""}
				iconStyles={""}
				setLastCopiedTrackingNumber={function (
				trackingNumber: string
				): void {
				throw new Error(
				"Function not implemented."
			);
			}}
		/>
	</div> */
}
