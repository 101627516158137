import React from "react";
import { useDarkMode, useHostName } from "../../hooks";
import { getFontAwesomeIcon } from "../../utils";
import { GoBackButton } from "../ui";

interface Props {
	text?: string;
	additionalClasses?: string;
	hasGoBackButton?: boolean;
	goBackButton?: React.FC;
	buttons?: React.ReactNode[];
	icon?: string[];
}

const DefaultHeading: React.FC<Props> = ({
	text,
	additionalClasses,
	hasGoBackButton = false,
	buttons,
	icon,
}) => {
	const { darkMode } = useDarkMode();
	const { hostName } = useHostName();
	const hostToLowerCase = hostName.toLowerCase();
	return (
		<div className="flex items-center">
			{hasGoBackButton && (
				<div>
					<GoBackButton />
				</div>
			)}

			<h1
				className={`${
					darkMode ? "" : "text-black"
				}    flex-1 ${additionalClasses}  items-center`}
			>
				{icon && (
					<span className="text-sm pr-1">
						{getFontAwesomeIcon(
							icon[0],
							icon[1] as (typeof icon)[1] as
								| "solid"
								| "regular"
								| "light"
								| undefined
						)}
					</span>
				)}
				{text}
			</h1>
			{buttons && hostToLowerCase === "prudential" && <>{buttons}</>}
		</div>
	);
};

export default DefaultHeading;
