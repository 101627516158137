import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUrl from "./useUrlContext";

type UseTablePaginationProps = {
	initialPage: number;
	totalPages: number;
	onPageChange?: (newPage: number) => void;
};

function useTablePagination({
	initialPage,
	totalPages,
	onPageChange,
}: UseTablePaginationProps) {
	const [currentPage, setCurrentPage] = useState(initialPage);
	const navigate = useNavigate();
	const location = useLocation();
	const { setPreviousUrl } = useUrl();
	const updateUrlSearchParams = useCallback(
		(newPage: number) => {
			const searchParams = new URLSearchParams(location.search);
			let pageUpdated = false;

			for (const [key] of searchParams) {
				if (key.endsWith("_page")) {
					searchParams.set(key, String(newPage));
					pageUpdated = true;
					break;
				}
			}

			if (!pageUpdated) {
				searchParams.append("sales-orders_page", String(newPage));
			}

			navigate(`?${searchParams.toString()}`);
		},
		[navigate, location.search]
	);
	const setCurrentPageAndUpdateUrl = useCallback(
		(newPage: number) => {
			setCurrentPage(newPage);
			updateUrlSearchParams(newPage);
			if (onPageChange) {
				onPageChange(newPage);
			}
		},
		[updateUrlSearchParams, onPageChange]
	);

	const handleFirstPage = useCallback(() => {
		setCurrentPageAndUpdateUrl(1);
		setPreviousUrl && setPreviousUrl(null);
	}, [setCurrentPageAndUpdateUrl]);

	const handlePrevPage = useCallback(() => {
		setCurrentPageAndUpdateUrl(Math.max(1, currentPage - 1));
		setPreviousUrl && setPreviousUrl(null);
	}, [currentPage, setCurrentPageAndUpdateUrl]);

	const handleNextPage = useCallback(() => {
		setCurrentPageAndUpdateUrl(Math.min(totalPages, currentPage + 1));
		setPreviousUrl && setPreviousUrl(null);
	}, [currentPage, totalPages, setCurrentPageAndUpdateUrl]);

	const handleLastPage = useCallback(() => {
		setCurrentPageAndUpdateUrl(totalPages);
		setPreviousUrl && setPreviousUrl(null);
	}, [totalPages, setCurrentPageAndUpdateUrl]);

	const handlePaginationOptions = useCallback(
		(value: number) => {
			setCurrentPageAndUpdateUrl(value);
		},
		[setCurrentPageAndUpdateUrl]
	);

	return {
		currentPage,
		setCurrentPageAndUpdateUrl,
		handleFirstPage,
		handlePrevPage,
		handleNextPage,
		handleLastPage,
		handlePaginationOptions,
	};
}

export default useTablePagination;
