import roundNumToDecimalPlaces from "./roundNumToDecimalPlaces";

// Take the input value, and return it formatted as currency
function formatCurrency(
	input: string | number,
	decimalPlaces: number = 2
): string {
	let inputStr: string = String(input);

	if (!inputStr.match(/^-?\d*\.?\d*$/)) {
		throw new Error(
			"input must be a valid number or string representation of a number"
		);
	}

	let outputStr: string = inputStr.replace(/[^-\d\.]/g, ""); // remove every character that isn't a digit, hyphen, or period
	outputStr = roundNumToDecimalPlaces(outputStr, decimalPlaces); // if it's over 2 decimals, round it to 2
	outputStr = outputStr.split(/(?=(?:\d{3})+(?:\.|$))/g).join(","); // insert commas every 3 digits

	return outputStr;
}

export default formatCurrency;

