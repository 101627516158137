import React, { useState } from "react";
import Logout from "./Logout";
import { useDarkMode } from "../../hooks";

interface Props {
	firstName: string;
	lastName: string;
}

const ProfileIcon: React.FC<Props> = ({ firstName, lastName }) => {
	const { darkMode } = useDarkMode();
	const initials =
		(firstName && firstName[0] ? firstName[0].toUpperCase() : "") +
		(lastName && lastName[0] ? lastName[0].toUpperCase() : "");
	const [showLogout, setShowLogout] = useState(false);

	return (
		<>
			<div
				className="flex items-center justify-center cursor-pointer right-8 h-full absolute"
				onClick={() => setShowLogout(!showLogout)}
			>
				<span
					className={`${
						darkMode
							? "hover:bg-accent  hover:text-slate-200 bg-secondary text-slate-800"
							: "text-primary hover:bg-blue-100"
					} text-sm border border-primary rounded-full h-10 w-10 flex items-center justify-center `}
				>
					{initials}
				</span>
			</div>
			{showLogout && <Logout show={showLogout} />}
		</>
	);
};

export default ProfileIcon;
