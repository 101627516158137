import React from "react";

export interface BaseBadgeTypes {
	text?: React.ReactNode;
	image?: React.ReactNode;
	borderColor?: string | undefined;
	backgroundColor?: string | undefined;
	borderWidth?: string | undefined;
	borderRadius?: string | undefined;
	hasMobileStyle?: boolean;
	mobileIcon?: JSX.Element | Element | null;
	mobileIconLabel?: string;
	icon?: JSX.Element | Element | null;
	hasLeftIcon?: boolean;
	hasRightIcon?: boolean;
	badgeContainerClasses?: string;
	iconSize?: string | undefined;
}

const BaseBadge: React.FC<BaseBadgeTypes> = ({
	borderColor,
	borderWidth,
	borderRadius,
	backgroundColor,
	hasMobileStyle,
	mobileIcon,
	mobileIconLabel,
	iconSize,
	hasLeftIcon,
	hasRightIcon,
	icon,
	image,
	text,
	badgeContainerClasses,
}) => {
	const iconClasses = `${iconSize}`;
	const badgeBackgroundClasses = `${backgroundColor}`;
	const badgeBorderClasses = `${borderWidth} ${borderColor} ${borderRadius}`;
	const badgeClasses = `${badgeBorderClasses}  ${badgeBackgroundClasses} ${badgeContainerClasses}`;


	return (
		<span className={badgeClasses}>
			{image && <span>{image}</span>}
			{icon && hasLeftIcon && (
				<span className={`${iconClasses} max-md:hidden`}>
					<>{icon}</>
				</span>
			)}
			{mobileIcon && hasMobileStyle && (
				<span
					className={`${iconClasses} hidden max-md:flex max-md:p-0`}
					aria-hidden="false"
					aria-label={mobileIconLabel}
				>
					<>{mobileIcon}</>
				</span>
			)}
			<div className={` ${hasMobileStyle ? "max-md:hidden" : ""} `}>
				{text}
			</div>
			{icon && hasRightIcon && (
				<span className={`${iconClasses} max-md:hidden`}>
					<>{icon}</>
				</span>
			)}
		</span>
	);
};

export default BaseBadge;