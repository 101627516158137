import React from "react";
import ImageComponent from "../../../../components/layout/ImageComponent";
import placeholderImage from "../../../../assets/empty-image.png";
import formatCurrency from "../../../../utils/formatCurrency";
import useHostName from "../../../../hooks/useHostName";
import ExpandableCellContent from "../../../../components/ui/Tables/ExpandableCellContent";
import OpenModalButton from "./OpenModalButton";
import { BasicTable } from "../../../../components/ui/Tables";
import { getFontAwesomeIcon } from "../../../../utils";

const prudentialHeaders = [
	{ id: "col1", Header: "Line", headerClasses: "text-left" },
	{ id: "col2", Header: "Part Number", headerClasses: "text-left" },
	{ id: "col3", Header: "Description", headerClasses: "text-left" },
];

type DataRow = {
	col1: string | number | JSX.Element;
	col2: string | number | JSX.Element;
	col3: string | number | JSX.Element;
	col4: string | number | JSX.Element;
	col5: string | number | JSX.Element;
	col6: string | number | JSX.Element;
	col7: string | number | JSX.Element;
	col8: string | number | JSX.Element;
	col9: string | number | JSX.Element;
	col10: string | number | JSX.Element;
	col11: string | number | JSX.Element;
	col12: string | number | JSX.Element;
};

type OrderItemsTableProps = {
	data: {
		items: DataRow[];
	};
	isLoading: boolean;
	openModal: () => void;
};

const OrderItemsTable: React.FC<OrderItemsTableProps> = ({
	data,
	openModal,
}) => {
	const hostName = useHostName().hostName;

	const manufacturerColumnClasses = `flex items-center text-sm relative font-omnes-regular font-light ${
		hostName === "PRUDENTIAL" ? "max-w-[90%]" : " "
	}`;
	const descriptionColumnClasses = `flex items-center justify-between text-sm font-omnes-regular font-light break-words ${
		hostName === "PRUDENTIAL" ? "max-w-[90%]" : " "
	}`;
	const quantityColumnsClasses = `flex items-center text-sm relative font-omnes-regular max-w-96 min-w-[70px] font-light  ${
		hostName === "PRUDENTIAL" ? "max-w-[90%]" : " "
	}`;
	const priceColumnClasses = `flex items-center text-sm relative font-omnes-regular max-w-96 min-w-[90px] font-light  ${
		hostName === "PRUDENTIAL" ? "max-w-[90%]" : " "
	}`;

	const tableData = React.useMemo(() => {
		return (data?.items ?? []).map((item: any, index: number) => {
			return {
				col1: <div className="flex justify-end">{index + 1}</div>,
				col2:
					(
						<div className="flex items-center justify-start w-fit min-w-40">
							<ImageComponent
								imageUrl={placeholderImage}
								altText="Sample Image"
								className=""
								width={20}
								height={10}
							/>
							<div className="pl-2">{item?.Items.partNumber}</div>
						</div>
					),

				col3: item.Items.skuNumber || "—",
				col4: (
					<>
						<ExpandableCellContent
							columnData={item?.Manufacturers?.name || "—"}
							columnDataLength={15}
							onHoverColumnDataLength={10}
							minWidth="min-w-[150px] max-w-[150px]"
							notShortenedClasses={manufacturerColumnClasses}
						/>
					</>
				),
				col5: (
					<>
						<ExpandableCellContent
							columnData={item?.Items.description || "—"}
							columnDataLength={40}
							onHoverColumnDataLength={35}
							minWidth="min-w-[325px] max-w-[325px]"
							notShortenedClasses={descriptionColumnClasses}
						/>
					</>
				),
				col6: (
					<div className={`${quantityColumnsClasses} justify-end`}>
						{Number(item?.quantity)}
					</div>
				),
				col7: (
					<div className={`${quantityColumnsClasses} justify-end`}>
						{Number(item?._qtyFulfilled)}
					</div>
				),
				col8: (
					<div className={`${quantityColumnsClasses} justify-end`}>
						{Number(item?._qtyBilled)}
					</div>
				),
				col9: (
					<div
						className={`${priceColumnClasses} flex justify-between`}
					>
						<span className="pr-2">
							{getFontAwesomeIcon("dollar", "regular")}
						</span>
						<span>{formatCurrency(item?.price)}</span>
					</div>
				),
				col10: (
					<div
						className={`${priceColumnClasses} flex justify-between`}
					>
						<span className="pr-2">
							{getFontAwesomeIcon("dollar", "regular")}
						</span>
						<span>{formatCurrency(item?._extPrice)}</span>
					</div>
				),
				col11: (
					<div className={`${quantityColumnsClasses} justify-end`}>
						{Number(item?.quantity)}
					</div>
				),
				col12: (
					<OpenModalButton
						text={"View All"}
						onClick={openModal}
						// TODO: when serial numbers data is available for the modal, uncomment the isActive prop. hardcoding to false for now
						isActive={
							item?.serialNumbers?.length != 0 ? true : false
						}
						// isActive={false}
						inactiveTextClasses="text-navy-250 w-full flex items-center justify-center text-sm"
						activeButtonClasses="text-supply-blue-500 w-full flex items-center justify-center text-sm hover:text-supply-blue-300 hover:underline hover:cursor-pointer"
					/>
				),
			};
		});
	}, [data]);

	const defaultHeaderData = React.useMemo(() => {
		return [
			{ id: "col1", Header: "Line", headerClasses: "text-right" },
			{ id: "col2", Header: "Part Number", headerClasses: "text-left" },
			{ id: "col3", Header: "SKU", headerClasses: "text-left" },
			{ id: "col4", Header: "Manufacturer", headerClasses: "text-left" },
			{ id: "col5", Header: "Description", headerClasses: "text-left" },
			{ id: "col6", Header: "Ordered", headerClasses: "text-right" },
			{ id: "col7", Header: "Fulfilled", headerClasses: "text-right" },
			{ id: "col8", Header: "Billed", headerClasses: "text-right" },
			{ id: "col9", Header: "Unit Price", headerClasses: "text-right" },
			{ id: "col10", Header: "Ext. Price", headerClasses: "text-right" },
			{ id: "col11", Header: "Quantity", headerClasses: "text-right" },
			{
				id: "col12",
				Header: (
					<OpenModalButton
						text={"Fulfillment"}
						onClick={openModal}
						isActive={false}
						inactiveTextClasses="text-navy-250 w-full flex items-center justify-center text-sm"
						activeButtonClasses="text-supply-blue-500 w-full flex items-center justify-center text-sm hover:text-supply-blue-300 hover:underline hover:cursor-pointer"
					/>
				),
				headerClasses: "text-center",
			},
		];
	}, []);

	return (
		<>
			<BasicTable
				data={tableData}
				headers={
					hostName != "PRUDENTIAL"
						? defaultHeaderData
						: prudentialHeaders
				}
				hasHeaderSpan={hostName != "PRUDENTIAL" ? true : false}
				tableBottomBorderClasses="border-b-1 border-navy-250"
				headerSpanText="Quantity"
				tableWidth={hostName === "PRUDENTIAL" ? "w-1/2" : "w-full"}
				quantityLabel="items"
				quantityLabelClasses="text-base"
				tableContainerHeight="max-h-[500px] overflow-x-scroll"
				headerBackgroundColor="bg-navy-100"
				headerTextColor="text-black"
				headerFontSize="text-sm"
				headerBorderColor="border-navy-250"
				headerBorderWidth="border-1"
				headerFontFamily=""
				tableBorderColor="border-transparent"
				rowBackgroundColor="bg-white"
				rowHoverBackgroundColor=""
				rowBorderWidth="border-1"
				rowBorderColor="border-navy-200"
				rowHoverCursor=""
				cellFontSize="text-sm"
				cellPadding="py-3 px-2"
				headerSpanBorderClasses="border-b-0 border-t-0 border-navy-250 border-r-1 border-l-1 "
				showQuantity={true}
			/>
		</>
	);
};

export default OrderItemsTable;
