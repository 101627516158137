// UserContext.tsx
// This file defines a React context that provides a user object and a function to update it. The user object contains the user's first name, last name, and a boolean flag indicating if the user is logged in or not. The context is useful for sharing the user's data and authentication status across the application.

// Interface: User
// The User interface defines the shape of the user object containing the following properties:

// firstName: A string representing the user's first name.
// lastName: A string representing the user's last name.
// isUserLoggedIn: A boolean indicating if the user is logged in or not.
// Interface: UserContextType
// The UserContextType interface defines the structure of the context containing the following properties:

// user: An object of type User containing the user's data.
// setUser: A function to update the user object. It accepts a SetStateAction<User> and has a Dispatch return type.
// Variables and Functions
// initialState: A User object with empty strings for firstName and lastName, and isUserLoggedIn set to false.
// loadUserFromLocalStorage(): A function that loads the user object from local storage. If a stored user object is found, it returns the parsed user object; otherwise, it returns the initialState.
// UserContext
// The UserContext is created using createContext with a default value of UserContextType. The default value contains the initialState for the user object and an empty function for setUser.

// UserContextProvider Component
// The UserContextProvider component is a functional component that accepts children as its props. It wraps the children inside a UserContext.Provider component and provides the user object and the setUser function.

// The component uses the useState hook with the loadUserFromLocalStorage function to initialize the user state. It also defines a setUserWrapper function that accepts a new user object, stores it in local storage, and updates the user state.

// Props
// children: React components that the UserContext.Provider will wrap and provide access to the context.
// Usage
// To use the UserContext in your application, wrap your components inside the UserContextProvider component. This will give your components access to the user object and the setUser function.

// To access the user object and the setUser function inside a component, use the useContext hook with the UserContext:

import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useState,
} from "react";

export interface User {
	firstName: string;
	lastName: string;
	isUserLoggedIn: boolean;
	isAdmin?: boolean;
	isSupervisor?: boolean;
	uuid?: string;
}

interface UserContextType {
	user: User;
	setUser: Dispatch<SetStateAction<User>>;
}

const initialState: User = {
	firstName: "",
	lastName: "",
	isUserLoggedIn: false,
};

const loadUserFromLocalStorage = (): User => {
	const storedUser = localStorage.getItem("user");
	if (storedUser) {
		return JSON.parse(storedUser);
	}
	return initialState;
};

const UserContext = createContext<UserContextType>({
	user: initialState,
	setUser: () => {},
});

export const UserContextProvider: React.FC<{ children?: ReactNode }> = ({
	children,
}) => {
	const [user, setUser] = useState<User>(loadUserFromLocalStorage());

	const setUserWrapper: Dispatch<SetStateAction<User>> = (newUser) => {
		localStorage.setItem("user", JSON.stringify(newUser));
		setUser(newUser);
	};

	return (
		<UserContext.Provider value={{ user, setUser: setUserWrapper }}>
			{children}
		</UserContext.Provider>
	);
};

export default UserContext;
