import Select, {
	MultiValueProps,
	components as defaultComponents,
	MenuPlacement,
} from "react-select";
import { OptionType } from "./types";
import { getFontAwesomeIcon } from "../../../utils";

const MultiValueRemove = (props: MultiValueProps<OptionType>) => {
	return (
		<defaultComponents.MultiValueRemove {...props}>
			<div className="flex text-white text-xs pl-2 items-center">
				{getFontAwesomeIcon("xmark", "regular")}
			</div>
		</defaultComponents.MultiValueRemove>
	);
};

export default MultiValueRemove;
