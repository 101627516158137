export type StatusReplacement = {
	slug: string;
	name: string;
};

function replaceStatusWithNames(
	data: { data: any }[],
	values: StatusReplacement[] = []
): { data: any }[] {
	const statusMap = new Map(values.map((item) => [item.slug, item.name]));

	return data.map((item) => {
		const originalStatus = item.data.status;
		const updatedStatus = statusMap.get(originalStatus) || originalStatus;

		return {
			...item,
			data: {
				...item.data,
				status: updatedStatus,
			},
		};
	});
}

export default replaceStatusWithNames;
