import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as SolidIcons from "@fortawesome/pro-solid-svg-icons";
import * as RegularIcons from "@fortawesome/pro-regular-svg-icons";
import * as LightIcons from "@fortawesome/pro-light-svg-icons"; // Import light icons

const getFontAwesomeIcon = (
	iconName: string,
	iconStyle: "solid" | "regular" | "light" | undefined // Add "light" to the possible styles
): React.ReactElement | null => {
	try {
		// CamelCase the iconName as before
		const iconKey = `fa${iconName.charAt(0).toUpperCase()}${iconName.slice(
			1
		)}`;

		// Determine the icon object based on the style
		let icon;
		switch (iconStyle) {
			case "solid":
				icon = (SolidIcons as any)[iconKey];
				break;
			case "regular":
				icon = (RegularIcons as any)[iconKey];
				break;
			case "light":
				icon = (LightIcons as any)[iconKey]; // Access the light icon
				break;
			default:
				throw new Error(`Unknown icon style: ${iconStyle}`);
		}

		if (!icon) {
			throw new Error(`Icon not found: ${iconKey}`);
		}

		return <FontAwesomeIcon icon={icon} />;
	} catch (error) {
		console.error(`Error loading Font Awesome icon: ${iconName}`, error);
		return null;
	}
};
export default getFontAwesomeIcon;
