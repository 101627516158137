import dayjs from "dayjs";

function formatDateMontDayYearTime(dateString?: string): string {
	if (!dateString) {
		return "";
	}
	return dayjs(dateString).format("MMM D, YYYY h:mm A");
}

export default formatDateMontDayYearTime;
