import { BasicTableProps } from "../types";
import React from "react";

const BasicTable: React.FC<BasicTableProps> = ({
	slug,
	data,
	headers,
	quantityLabel,
	quantityLabelClasses,
	tableWidth,
	tableContainerHeight,
	tableContainerWidth,
	tableBottomBorderClasses,
	headerBackgroundColor,
	headerTextColor,
	headerFontSize,
	headerBorderColor,
	headerBorderWidth,
	headerFontFamily,
	tableBorderColor,
	tableBorderWidth,
	rowBackgroundColor,
	rowBorderWidth,
	rowBorderColor,
	rowHoverBackgroundColor,
	rowHoverCursor,
	rowDisabledClasses,
	cellFontFamily,
	cellFontSize,
	cellTextColor,
	cellPadding,
	hasHeaderSpan = false,
	headerSpanBorderClasses,
	headerSpanText,
	noDataMessage = "No Data Found",
	showQuantity,
}) => {
	const errorMessageContainerClassNames =
		"min-h-[250px] flex justify-center items-center";
	let tbodyContent;
	switch (true) {
		case data.length === 0:
			tbodyContent = (
				<tr>
					<td colSpan={headers.length}>
						<p className={errorMessageContainerClassNames}>
							{noDataMessage}
						</p>
					</td>
				</tr>
			);
			break;
		default:
			tbodyContent = data.map((row, index) => (
				<tr
					key={index}
					className={`${
						row?.isDisabled
							? rowDisabledClasses
							: `${rowBackgroundColor} hover:${rowHoverBackgroundColor} ${rowHoverCursor}`
					}`}
				>
					{headers?.map((header) => (
						<td
							key={`${row?.id}-${header?.id}`}
							className={`text-left text-nowrap break-words ${rowBorderWidth} ${rowBorderColor} ${cellFontFamily} ${cellFontSize} ${cellTextColor} ${cellPadding}`}
						>
							{row[header?.id]}
						</td>
					))}
				</tr>
			));
	}

	return (
		<>
			<div
				className={` mb-4 ${tableContainerHeight} ${tableContainerWidth} ${tableBottomBorderClasses}`}
			>
				<table
					className={`table  ${tableWidth} ${tableBorderWidth} ${tableBorderColor}`}
				>
					<thead
						className={`w-full ${hasHeaderSpan ? "sticky top-0 z-10" : ""}`}
					>
						{hasHeaderSpan && (
							<tr className="bg-white border-0 ">
								<th className="bg-white" colSpan={6}></th>
								<th
									colSpan={3}
									className={`bg-navy-100 relative py-1.5 text-sm items-center justify-center  text-navy-500 text-center ${headerSpanBorderClasses}`}
								>
									<div className="after-border-top">
										{headerSpanText}
									</div>
								</th>
								<th className="bg-white" colSpan={4}></th>
							</tr>
						)}
						<tr>
							{headers.map((header) => (
								<th
									className={`py-3 px-2 text-nowrap ${
										hasHeaderSpan
											? "after-border-bottom"
											: ""
									} ${headerBorderColor} ${headerBorderWidth} ${headerBackgroundColor} ${headerTextColor} ${headerFontFamily} ${headerFontSize} ${
										header.headerClasses
									}`}
									key={header.id}
								>
									{header.Header}
								</th>
							))}
						</tr>
					</thead>
					<tbody>{tbodyContent}</tbody>
				</table>
			</div>
			{showQuantity && (
				<div className="flex items-start">
					<p className={quantityLabelClasses}>
						{data.length} {quantityLabel}
					</p>
				</div>
			)}
		</>
	);
};

export default BasicTable;
