import { useState, useEffect } from "react";

const usePrivilegeCheck = () => {
	const [isAdmin, setIsAdmin] = useState(false);
	const [isSupervisor, setIsSupervisor] = useState(false);

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user") || "{}");
		
		if (user && user.isAdmin === "1") {
			setIsAdmin(true);
		}

		if (user && user.isSupervisor === "1") {
			setIsSupervisor(true);
		}
	}, []);

	return { isAdmin, isSupervisor };
};

export default usePrivilegeCheck;
