import OrderDetailRow from "./OrderDetailRow";
import formatCurrency from "../../../../../utils/formatCurrency";
import formatModalDate from "../../../../../utils/formateModalDate";
import { downloadInvoicePdf, getFontAwesomeIcon } from "../../../../../utils";

interface Item {
	id?: string;
	labelIcon?: string;
	label?: string;
	valueKey: string;
	inputType?: string;
	labelTextClasses?: string;
	valueTextClasses?: string;
	hasValueLeftIconOne?: string;
	hasValueLeftIconTwo?: string;
	hasValueRightIcon?: string;
	detailIconClasses?: string;
	hasValueImage?: string;
	detailGridClasses?: string;
	inputGridClasses?: string;
	additionalInputContainerClasses?: string;
	isBoolean?: boolean;
	truthyValue?: string;
	falsyValue?: string;
	isConcatenated?: string;
	isEditOnly?: boolean;
	inputHasLeftIcon?: string;
	inputIconClasses?: string;
	disabledClasses?: string;
	secondValueTextClasses?: string;
	imageClasses?: string;
	flexDirection?: string;
	hasBottomBorder?: boolean;
	labelToolTip?: boolean;
	isCurrency?: boolean;
	isCustomerName?: boolean;
}

type OrderInvoiceContentProps = {
	item: any;
	fieldsConfig?: Item[];
	lastCopiedTrackingNumber?: string;
	setLastCopiedTrackingNumber?: React.Dispatch<React.SetStateAction<string>>;
	formatDate?: (date: string) => string;
	modalButtonText?: string;
	index: number;
	containerClasses?: string;
};

const OrderInvoiceContent: React.FC<OrderInvoiceContentProps> = ({
	item,
	index,
	fieldsConfig,
	containerClasses,
}) => {
	const invoiceContent = fieldsConfig?.map((field) => {
		let value = item[field.valueKey];

		if (field.valueKey === "" || value === null || value === undefined) {
			value = "—";
		} else if (field.valueKey.includes("date")) {
			value = formatModalDate(value);
		}

		return (
			<div key={field.id}>
				<OrderDetailRow
					dataItem={value}
					field={field}
					isEnabled={true}
					iconStyle="regular"
				/>
			</div>
		);
	});

	const downloadPdfData = async (index: number, uuid: string) => {
		if (uuid) {
			await downloadInvoicePdf(uuid);
		} else {
			console.log("Invoice UUID is undefined.");
		}
	};

	return (
		<div className={`w-full ${containerClasses}`}>
			{invoiceContent}
			<div
				className={`border border-transparent pt-3 pb-3 rounded flex items-center w-3/4`}
			>
				<button
					className={`flex items-center text-xs cursor-pointer text-supply-blue-500 font-omnes-regular hover:text-supply-blue-300 pr-2.5`}
					onClick={() => downloadPdfData(index, item.uuid)}
					aria-label="Download PDF"
				>
					{getFontAwesomeIcon("arrowDownToLine", "solid")}
					<p className="pl-1 font-omnes-regular underline text-sm ">
						{item.number}
					</p>
				</button>
				<p className="flex font-omnes-regular text-navy-500 text-sm pr-2.5">
					{formatModalDate(item.dateInvoice)}
				</p>
				<p className="flex font-omnes-regular text-navy-500 text-sm">
					${formatCurrency(item.total)}
				</p>
			</div>
		</div>
	);
};

export default OrderInvoiceContent;
