import { useEffect } from "react";

const useClickOutsideOfElement = (
	ref: React.RefObject<HTMLElement>,
	callback: () => void,
	ignoreRef?: React.RefObject<HTMLElement>
) => {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				ref.current &&
				!ref.current.contains(event.target as Node) &&
				!(
					ignoreRef?.current &&
					ignoreRef.current.contains(event.target as Node)
				)
			) {
				callback();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, callback, ignoreRef]);
};

export default useClickOutsideOfElement;
