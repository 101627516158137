import QuickActionModal from "../../../../../pages/Orders/listing/modals/QuickActionModal";
import { ModalType } from "../../../../../pages/Orders/view/modals/ApprovalView/types";

type RenderQuickActionModalProps = {
	activeModal: ModalType | null;
	setActiveModal: (modal: ModalType | null) => void;
	activeRowUuid: string;
	activeUuid: string;
	handleUpdateRowData: (uuid: string, key: string, newValue: any) => void;
	setDeactivatedQuickMenuIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const renderQuickActionModal = ({
	activeModal,
	setActiveModal,
	activeRowUuid,
	handleUpdateRowData,
	setDeactivatedQuickMenuIds,
}: RenderQuickActionModalProps) => {
	if (!activeModal) return null;

	let modalSelected = "";

	switch (activeModal) {
		case "approve":
			modalSelected = "approve";
			break;
		case "deny":
			modalSelected = "deny";
			break;
		case "assign":
			modalSelected = "assign";
			break;
		case "adminOnly":
			modalSelected = "adminOnly";
			break;
		default:
			return null;
	}

	return (
		<QuickActionModal
			isOpen={true}
			onClose={() => setActiveModal(null)}
			activeUuid={activeRowUuid}
			modalSelected={modalSelected}
			updateRowData={handleUpdateRowData}
			activeRowUuid={activeRowUuid}
			setDeactivatedQuickMenuIds={setDeactivatedQuickMenuIds || []}
			children={null}
		/>
	);
};
