// @ts-nocheck
//FIXME: fix the types in the api
import {
	OptionsType,
	togaApiRequest,
	checkForDifferences,
} from "../../../api/toga";
import { Invoice, OrderDetails } from "../OrdersInterfaces";

export interface Field {
	[key: string]: string | string[] | Field;
}

export interface ExtendedOptionsType extends Omit<OptionsType, "fields"> {
	depth?: number;
	fields?: (
		| string
		| Field
		| { location: string[]; shipToAddress: string[] }
	)[];
	where?: any;
	join?: any;
	ojoin?: any;
	sort?: any;
	recordsPerPage?: number;
	page?: number;
	[key: string]: any;
}

export async function getItemDetails(
	uuid: string
): Promise<OrderDetailsResult | undefined> {
	const storedHostName = localStorage.getItem("hostName");

	let output: { salesOrder: OrderDetails | null; invoices: Invoice[] } = {
		salesOrder: null,
		items: [],
		invoices: [],
		shipments: [],
	};

	/* ------------------------------------- Sales Order Header ------------------------------------- */
	let options: ExtendedOptionsType = {
		fields: ["uuid"],
	};

	let response = await togaApiRequest("GET", "/items/" + uuid, null, options);
	if (!response.isSuccess) {
		return;
	}
	output.salesOrder = response.data.salesOrders;

	/* -------------------------------------- Sales Order Items ------------------------------------- */
	options = {
		fields: ["uuid"],
	};
	response = await togaApiRequest(
		"GET",
		"/items/" + uuid + "/items",
		null,
		options
	);
	if (!response.isSuccess) {
		console.log(":(");
		return;
	}
	output.items = response.data.salesOrderItems;

	/* ------------------------------------------ Shipments ----------------------------------------- */
	options = {
		join: [
			{
				ItemFulfillmentPackages: {
					"TrackingNumbers.id":
						"ItemFulfillmentPackages.trackingNumberId",
				},
			},
			{
				ItemFulfillments: {
					"ItemFulfillments.id":
						"ItemFulfillmentPackages.itemFulfillmentId",
				},
			},
			{
				SalesOrders: {
					"SalesOrders.id": "ItemFulfillments.salesOrderId",
				},
			},
		],
		where: {
			and: [{ "SalesOrders.uuid": { "=": uuid } }],
		},
		fields: [
			"uuid",
			"number",
			"status",
			{
				shippingCarrier: ["name", "urlLogo", "urlLinkPrefix"],
				shippingMethod: ["name"],
			},
		],
	};
	if (storedHostName == "PRUDENTIAL") {
		const trackingResponse = await getTrackingNumber(uuid);

		if (
			output.salesOrder &&
			trackingResponse &&
			trackingResponse.length > 0
		) {
			output.salesOrder.trackingNumbers = [
				{
					shippingCarrierId: "1",
					shippingMethodId: null,
					number: trackingResponse[0].number,
					shippingCarrier: {
						name: "FedEx",
						urlLogo: "/carrierlogos/FedEx-logo.svg",
						urlLinkPrefix:
							"https://www.fedex.com/fedextrack/?trknbr=",
					},
					shippingMethod: {
						name: null,
					},
				},
			];
		}
	} else {
		//Getting tracking numbers of sales order
		response = await togaApiRequest(
			"GET",
			"/tracking-numbers",
			null,
			options
		);

		if (!response.isSuccess) {
			console.log(":(");
			return;
		}
		//Get Invoice Tracking Numbers
		const optionsInvoiceTracking: ExtendedOptionsType = {
			join: [
				{
					InvoiceTrackingNumbers: {
						"InvoiceTrackingNumbers.trackingNumberId":
							"TrackingNumbers.id",
					},
				},
				{
					Invoices: {
						"Invoices.id": "InvoiceTrackingNumbers.invoiceId",
					},
				},
				{
					SalesOrders: {
						"SalesOrders.id": "Invoices.salesOrderId",
					},
				},
			],
			where: {
				and: [{ "SalesOrders.uuid": { "=": uuid } }],
			},
			fields: [
				"number",
				{
					shippingCarrier: ["name", "urlLogo", "urlLinkPrefix"],
					shippingMethod: ["name"],
				},
			],
		};

		//Getting tracking numbers of sales order
		const invoiceTrackingResponse = await togaApiRequest(
			"GET",
			"/tracking-numbers",
			null,
			optionsInvoiceTracking
		);

		if (!invoiceTrackingResponse.isSuccess) {
			console.log(":(");
			return;
		}

		const combinedTrackingNumbers = [
			...response.data.trackingNumbers,
			...invoiceTrackingResponse.data.trackingNumbers,
		];
		// Filter for unique tracking numbers
		const uniqueTrackingNumbers = combinedTrackingNumbers.reduce(
			(acc, current) => {
				if (!acc.find((item) => item.number === current.number)) {
					acc.push(current);
				}
				return acc;
			},
			[]
		);

		output.shipments = uniqueTrackingNumbers;
	}

	/* ------------------------------------------ Invoices ------------------------------------------ */
	options = {
		fields: [
			"uuid",
			"number",
			"dateInvoice",
			"dateDue",
			"total",
			"amountDue",
			"PaymentTerms.name",
			"status",
		],
		join: [
			{
				SalesOrders: {
					"SalesOrders.id": "Invoices.salesOrderId",
				},
			},
		],
		ojoin: [
			{
				PaymentTerms: {
					"PaymentTerms.id": "Invoices.paymentTermsId",
				},
			},
		],
		where: {
			and: [{ "SalesOrders.uuid": { "=": uuid } }],
		},
	};
	response = await togaApiRequest("GET", "/invoices", null, options);
	if (!response.isSuccess) {
		console.log(":(");
		return;
	}
	output.invoices = response.data.invoices;

	/* ---------------------------------------- Invoice Items --------------------------------------- */
	options = {
		fields: ["SalesOrderItems.uuid", "Invoices.uuid", "Invoices.number"],
		join: [
			{
				SalesOrderItems: {
					"SalesOrderItems.id": "InvoiceItems.salesOrderItemId",
				},
			},
			{
				SalesOrders: {
					"SalesOrders.id": "SalesOrderItems.salesOrderId",
				},
			},
			{
				Invoices: {
					"Invoices.id": "InvoiceItems.invoiceId",
				},
			},
		],
		where: {
			and: [{ "SalesOrders.uuid": { "=": uuid } }],
		},
	};
	response = await togaApiRequest("GET", "/invoice-items", null, options);

	if (!response.isSuccess) {
		console.log(":(");
		return;
	}

	// build an array of invoice uuids to assign for each sales order item uuid
	const invoiceUuids: { [key: string]: string } = {};
	for (const invoiceItem of response.data.invoiceItems) {
		if (!invoiceUuids[invoiceItem.SalesOrderItems.uuid]) {
			invoiceUuids[invoiceItem.SalesOrderItems.uuid] = [];
		}

		invoiceUuids[invoiceItem.SalesOrderItems.uuid].push({
			uuid: invoiceItem.Invoices.uuid,
			number: invoiceItem.Invoices.number,
		});
	}

	// bring these into the output.items array
	for (const item of output.items) {
		if (invoiceUuids[item.uuid]) {
			item.invoices = invoiceUuids[item.uuid];
		} else {
			item.invoices = [];
		}
	}

	/* ----------------------------------- Item Fulfillment Items ----------------------------------- */
	options = {
		fields: [
			"SalesOrderItems.uuid",
			"TrackingNumbers.uuid",
			"TrackingNumbers.number",
		],
		join: [
			{
				SalesOrderItems: {
					"SalesOrderItems.id":
						"ItemFulfillmentItems.salesOrderItemId",
				},
			},
			{
				SalesOrders: {
					"SalesOrders.id": "SalesOrderItems.salesOrderId",
				},
			},
			{
				ItemFulfillments: {
					"ItemFulfillments.id":
						"ItemFulfillmentItems.itemFulfillmentId",
				},
			},
			{
				ItemFulfillmentPackages: {
					"ItemFulfillmentPackages.itemFulfillmentId":
						"ItemFulfillments.id",
				},
			},
			{
				TrackingNumbers: {
					"TrackingNumbers.id":
						"ItemFulfillmentPackages.trackingNumberId",
				},
			},
		],
		where: {
			and: [{ "SalesOrders.uuid": { "=": uuid } }],
		},
	};
	response = await togaApiRequest(
		"GET",
		"/item-fulfillment-items",
		null,
		options
	);
	if (!response.isSuccess) {
		console.log(":(");
		return;
	}

	// build an array of item fulfillment uuids to assign for each sales order item uuid
	const itemFulfillmentUuids: { [key: string]: string } = {};
	for (const itemFulfillmentItem of response.data.itemFulfillmentItems) {
		if (!itemFulfillmentUuids[itemFulfillmentItem.SalesOrderItems.uuid]) {
			itemFulfillmentUuids[itemFulfillmentItem.SalesOrderItems.uuid] = [];
		}

		itemFulfillmentUuids[itemFulfillmentItem.SalesOrderItems.uuid].push({
			uuid: itemFulfillmentItem.TrackingNumbers.uuid,
			number: itemFulfillmentItem.TrackingNumbers.number,
		});
	}

	// bring these into the output.items array
	for (const item of output.items) {
		if (itemFulfillmentUuids[item.uuid]) {
			item.shipments = itemFulfillmentUuids[item.uuid];
		} else {
			item.shipments = [];
		}
	}

	/* --------------------------------------- Serial Numbers --------------------------------------- */

	options = {
		fields: ["SalesOrderItems.uuid", "serialNumber"],
		join: [
			{
				ItemFulfillmentItemUnits: {
					"ItemFulfillmentItemUnits.unitId": "Units.id",
				},
			},
			{
				ItemFulfillmentItems: {
					"ItemFulfillmentItems.id":
						"ItemFulfillmentItemUnits.itemFulfillmentItemId",
				},
			},
			{
				SalesOrderItems: {
					"SalesOrderItems.id":
						"ItemFulfillmentItems.salesOrderItemId",
				},
			},
			{
				ItemFulfillments: {
					"ItemFulfillments.id":
						"ItemFulfillmentItems.itemFulfillmentId",
				},
			},
			{
				SalesOrders: {
					"SalesOrders.id": "ItemFulfillments.salesOrderId",
				},
			},
		],
		where: {
			and: [{ "SalesOrders.uuid": { "=": uuid } }],
		},
	};
	response = await togaApiRequest("GET", "/units", null, options);
	if (!response.isSuccess) {
		console.log(":(");
		return;
	}

	// bring this into an array of units by sales order item uuids
	const serialNumbersBySalesOrderItemUuid: { [key: string]: string } = {};
	for (const unit of response.data.units) {
		if (!serialNumbersBySalesOrderItemUuid[unit.SalesOrderItems.uuid]) {
			serialNumbersBySalesOrderItemUuid[unit.SalesOrderItems.uuid] = [];
		}

		serialNumbersBySalesOrderItemUuid[unit.SalesOrderItems.uuid].push(
			unit.serialNumber
		);
	}

	// bring these into the output.items array
	for (const item of output.items) {
		if (serialNumbersBySalesOrderItemUuid[item.uuid]) {
			item.serialNumbers = serialNumbersBySalesOrderItemUuid[item.uuid];
		} else {
			item.serialNumbers = [];
		}
	}
	return formatNumbersInObject(output);
}

function formatNumbersInObject(obj) {
	if (Array.isArray(obj)) {
		return obj.map(formatNumbersInObject);
	} else if (typeof obj === "object" && obj !== null) {
		const formattedObject = {};
		Object.entries(obj).forEach(([key, value]) => {
			formattedObject[key] = formatNumbersInObject(value);
		});
		return formattedObject;
	} else if (typeof obj === "number") {
		// Keep as a string to preserve two decimal places
		return obj.toFixed(2);
	}
	return obj;
}

//Api call to get Item Details based on the uuid
export async function getSingleItemDetails(uuid: string) {
	const response = await togaApiRequest("GET", "/items/" + uuid, null, {});
	var output = response.data.items;
	return output;
}

//Api call to update item details

export async function updateItemDetails(
	uuid: string,
	originalData: object,
	updatedData: object
) {
	// Extract personaItems and save it to a different variable
	const originalPersonaItems = originalData.personaItems;
	const updatedPersonaItems = updatedData.personaItems;

	// Remove personaItems from the original updatedData object
	delete updatedData.personaItems;
	delete originalData.personaItems;

	const differences = checkForDifferences(originalData, updatedData);

	if (isEmptyObject(differences)) {
		if (originalPersonaItems && updatedPersonaItems) {
			// Find removed items
			const removedItems = originalPersonaItems.filter(
				(origItem) =>
					!updatedPersonaItems.some(
						(updatedItem) =>
							updatedItem.uuid === origItem.persona.uuid
					)
			);

			// Find new items
			const newItems = updatedPersonaItems.filter(
				(updatedItem) =>
					!originalPersonaItems.some(
						(origItem) => origItem.persona.uuid === updatedItem.uuid
					)
			);

			// Find unchanged items
			const unchangedItems = updatedPersonaItems.filter((updatedItem) =>
				originalPersonaItems.some(
					(origItem) => origItem.persona.uuid === updatedItem.uuid
				)
			);

			// Batch delete the removed Personas
			const deleteRemovedItems = async () => {
				try {
					const deletePromises = removedItems.map((item) =>
						togaApiRequest(
							"DELETE",
							"/persona-items/" + item.uuid,
							null,
							{}
						)
					);
					const responses = await Promise.all(deletePromises);
					responses.forEach((response, index) => {
						console.log(
							`Deleted item with uuid: ${removedItems[index].uuid}`,
							response
						);
					});
				} catch (error) {
					return error;
				}
			};

			await deleteRemovedItems();

			// Batch add the new Personas
			const addNewItems = async () => {
				try {
					const addPromises = newItems.map((item) => {
						const payload = {
							item: {
								uuid: originalData.uuid,
							},
							persona: {
								uuid: item.uuid,
							},
						};
						return togaApiRequest(
							"POST",
							"/persona-items/",
							payload,
							{}
						);
					});
					await Promise.all(addPromises);
				} catch (error) {
					return error;
				}
			};

			await addNewItems();

			return { status: 200 };
		}
	} else {
		const response = await togaApiRequest(
			"PUT",
			"/items/" + uuid,
			differences,
			{}
		);
		if (response && response.isSuccess) {
			// Update personaItems
			if (originalPersonaItems && updatedPersonaItems) {
				// Find removed items
				const removedItems = originalPersonaItems.filter(
					(origItem) =>
						!updatedPersonaItems.some(
							(updatedItem) =>
								updatedItem.uuid === origItem.persona.uuid
						)
				);

				// Find new items
				const newItems = updatedPersonaItems.filter(
					(updatedItem) =>
						!originalPersonaItems.some(
							(origItem) =>
								origItem.persona.uuid === updatedItem.uuid
						)
				);

				// Find unchanged items
				const unchangedItems = updatedPersonaItems.filter(
					(updatedItem) =>
						originalPersonaItems.some(
							(origItem) =>
								origItem.persona.uuid === updatedItem.uuid
						)
				);

				// Batch delete the removed Personas
				const deleteRemovedItems = async () => {
					try {
						const deletePromises = removedItems.map((item) =>
							togaApiRequest(
								"DELETE",
								"/persona-items/" + item.uuid,
								null,
								{}
							)
						);
						await Promise.all(deletePromises);
					} catch (error) {
						return error;
					}
				};

				await deleteRemovedItems();

				// Batch add the new Personas
				const addNewItems = async () => {
					try {
						const addPromises = newItems.map((item) => {
							const payload = {
								item: {
									uuid: originalData.uuid,
								},
								persona: {
									uuid: item.uuid,
								},
							};
							return togaApiRequest(
								"POST",
								"/persona-items/",
								payload,
								{}
							);
						});
						await Promise.all(addPromises);
					} catch (error) {
						return error;
					}
				};

				await addNewItems();

				return response;
			} else {
				return response;
			}
		}
	}
}

//Api call to create item
export async function createItem(data: object) {
	return await togaApiRequest("POST", "/items", data, {});
}

// Check if an object is empty
const isEmptyObject = (obj: any): boolean => {
	return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

interface Meta {
	depth: number;
	page: number;
	prevPage: number | null;
	nextPage: number;
	totalPageCount: number;
}

//get the data based on the slug provided
export async function getData(slug: string, fields?: Array , options?: ExtendedOptionsType) {
	const output = [];
	const recordsPerPage = 1000;
	let page = 0;
	let hasNextPage = true;
	const camelCaseSlug = toCamelCase(slug);
	const fieldsOptions =
		typeof fields !== "undefined" && fields.length > 0
			? fields
			: ["uuid", "name"];
	try {
		while (hasNextPage) {
			page++;
			const options = {
				fields: fieldsOptions,
				recordsPerPage: recordsPerPage,
				page: page,
			};
			const response = await togaApiRequest(
				"GET",
				`/${slug}`,
				null,
				options
			);
			const { data, meta } = response;

			if (data && data[camelCaseSlug]) {
				output.push(...data[camelCaseSlug]);
			}
			hasNextPage = meta.nextPage;
		}
		return output;
	} catch (error) {
		console.error("Error fetching data:", error);
		return [];
	}
}

//Get Integration details based on vendorId
export async function getIntegrationData(vendorUuid:string) {
	const output = [];
	const recordsPerPage = 1000;
	let page = 0;
	let hasNextPage = true;
	const slug = "integrations";
	const camelCaseSlug = toCamelCase(slug);
	try {
		while (hasNextPage) {
			page++;
			const options = {
				fields: ["uuid", "type", "name"],
				recordsPerPage: recordsPerPage,
				page: page,
				join: [
					{
						Vendors: {
							"Vendors.id": "Integrations.vendorId",
						},
					},
				],
				where: {
					and: [{ "Vendors.uuid": { "=": vendorUuid } }],
				},
			};
			const response = await togaApiRequest(
				"GET",
				`/${slug}`,
				null,
				options
			);
			const { data, meta } = response;

			if (data && data[camelCaseSlug]) {
				output.push(...data[camelCaseSlug]);
			}
			hasNextPage = meta.nextPage;
		}
		return output;
	} catch (error) {
		console.error("Error fetching data:", error);
		return [];
	}
}

// Utility function to convert slug to camel case
function toCamelCase(slug) {
	return slug.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
}
