import React from "react";
import BaseModal from "../../../../components/layout/Modal/BaseModal";
import { getFontAwesomeIcon } from "../../../../utils";
import { BaseToolTip } from "../../../../components/ui";
import {BaseButton, BaseText} from "../../../../components/ui";

interface SerialNumbersModalProps {
	isModalOpen: boolean;
	toggleModal: () => void;
	children: React.ReactNode;
}

const SerialNumbersModal: React.FC<SerialNumbersModalProps> = ({
	isModalOpen,
	toggleModal,
	children
}) => {
	const modalButtonClassNames = "text-xl text-navy-300 hover:text-navy-500 border-none bg-transparent";
	const modalHeader = (
		<div className="flex w-full items-center justify-between">
			<h1 className="text-22px font-omnes-medium text-navy-500">
				Add Serial Numbers
			</h1>
			<BaseButton
				text={
					<BaseText
						text={"Close"}
						size={""}
						color={""}
						tag={"span"}
						fontFamily={""}
						additionalClasses="sr-only"
					/>
				}
				additionalClasses={modalButtonClassNames}
				as="button"
				onClick={toggleModal}
				icon={getFontAwesomeIcon("xmark", "regular")}
			/>
		</div>
	);

	return (
		<div>
			<BaseModal
				onClose={toggleModal}
				isOpen={isModalOpen}
				header={modalHeader}
				containerClassNames="bg-white w-[1020px] h-[822px] rounded-20px p-5"
				children={<>{children}</>}
			/>
		</div>
	);
};

export default SerialNumbersModal;
