import { togaApiRequest } from "../../../api/toga";
import { ExtendedOptionsType } from "../../Items/api/itemsApi";

export async function getShipments(internalId: string, isFulfilled?: boolean) {
	let options: ExtendedOptionsType = {
		fields: [
			"uuid",
			"itemFulfillmentPackages.trackingNumber.description",
			"itemFulfillmentPackages.trackingNumber.containsBattery",
			"itemFulfillmentPackages.trackingNumber.requiresSignature",
			"itemFulfillmentPackages.trackingNumber.weight",
			"itemFulfillmentPackages.trackingNumber.length",
			"itemFulfillmentPackages.trackingNumber.height",
			"itemFulfillmentPackages.trackingNumber.width",
			"itemFulfillmentPackages.trackingNumber.uuid",
			"itemFulfillmentPackages.trackingNumber.shippingCarrier.name",
			"itemFulfillmentPackages.trackingNumber.shippingCarrier.urlLogo",
			"itemFulfillmentPackages.trackingNumber.shippingMethod.name",
			"itemFulfillmentPackages.trackingNumber.shippingCarrierAccountNumber.accountNumber",
			"itemFulfillmentPackages.uuid",
			"shipToAddress.addressee",
			"shipToAddress.attention",
			"shipToAddress.line1",
			"shipToAddress.line2",
			"shipToAddress.city",
			"shipToAddress.zip",
			"shipToAddress.phoneNumber",
			"shipToAddress.state.name",
			"shipToAddress.state.code",
			"shipToAddress.state.country.name",
			"shipToAddress.state.country.code",
			"itemFulfillmentItems.quantity",
			"itemFulfillmentItems.uuid",
		],
		join: [
			{
				SalesOrders: {
					"ItemFulfillments.salesOrderId": "SalesOrders.id",
				},
			},
		],
		depth: 4,
		where: {
			and: [
				{ c_netsuiteInternalSalesOrderId: { "=": internalId } },
				{
					"ItemFulfillments.dtSubmitted": isFulfilled
						? { ne: null }
						: { eq: null },
				},
			],
		},
	};

	let response = await togaApiRequest(
		"GET",
		"/item-fulfillments",
		null,
		options
	);

	if (!response.isSuccess) {
		console.log(":(");
		return;
	}
	return response.data;
}

export async function deleteShipments(
	shipmentUuid: string,
	itemFulfillmentPackageUuid: string,
	trackingNumberUuid: string
) {
	let response = await togaApiRequest(
		"DELETE",
		"/item-fulfillment-packages/" + itemFulfillmentPackageUuid
	);

	response = await togaApiRequest(
		"DELETE",
		"/tracking-numbers/" + trackingNumberUuid
	);

	//Get Item Fulfillment Items.
	let options: ExtendedOptionsType = {
		fields: [
			"itemFulfillmentItems.uuid",
			"itemFulfillmentItems.itemFulfillmentItemUnits.uuid",
		],
	};
	response = await togaApiRequest(
		"GET",
		"/item-fulfillments/" + shipmentUuid,
		null,
		options
	);
	const itemsUuids = response.data.itemFulfillments.itemFulfillmentItems.map(
		// @ts-ignore

		(item) => item.uuid
	);

	const itemFulfillmentItemUnitsUuids =
		// @ts-ignore

		response.data.itemFulfillments.itemFulfillmentItems.flatMap((item) =>
			// @ts-ignore
			item.itemFulfillmentItemUnits.map((unit) => unit.uuid)
		);

	//Delete All the Item Fulfillment Item Units.
	for (const uuid of itemFulfillmentItemUnitsUuids) {
		try {
			const response = await togaApiRequest(
				"DELETE",
				"/item-fulfillment-item-units/" + uuid
			);
		} catch (error) {
			console.error(`Error deleting item with UUID ${uuid}:`, error);
		}
	}

	for (const uuid of itemsUuids) {
		try {
			const response = await togaApiRequest(
				"DELETE",
				"/item-fulfillment-items/" + uuid
			);
			console.log(`Response for UUID ${uuid}:`, response);
		} catch (error) {
			console.error(`Error deleting item with UUID ${uuid}:`, error);
		}
	}

	response = await togaApiRequest(
		"DELETE",
		"/item-fulfillments/" + shipmentUuid
	);
	return response.data;
}

export async function handleReprintPdf(uuid: any) {
	//Get the tracking number and base64 pdf from the shipment
	let response = await togaApiRequest(
		"GET",
		"/tracking-numbers/"+uuid
	);
	if (!response.isSuccess) {
		console.log(
			"Unable to get details for order with UUID: ",
			uuid
		);
		return response
	}

	try {
		const base64Pdf =
			response.data.trackingNumbers.labelPdfFile;

			// Convert base64 string to a Blob
		const byteCharacters = atob(base64Pdf); // decode base64
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: "application/pdf" });

		// Create a Blob URL for the PDF
		const url = window.URL.createObjectURL(blob);

		// Open the PDF in a new window/tab
		const newWindow = window.open(url);

		// Automatically trigger the print dialog after the PDF has been loaded
		if (newWindow) {
			newWindow.onload = function () {
				newWindow.focus(); // Ensure the new tab is focused
				newWindow.print(); // Trigger the print dialog
			};
		} else {
			alert("Please allow popups for this site to print the label.");
		}

		// Clean up the Blob URL after some delay to ensure the print dialog works
		setTimeout(() => {
			window.URL.revokeObjectURL(url);
		}, 1000); // 1-second delay to ensure the URL is still available
	} catch (error) {
		console.error("Error handling shipment download:", error);
	}
}
