import { MouseEvent } from "react";
import useUrl from "./useUrlContext";

type DataType = any; //FIXME: replace 'any' with legit type
type SetStateFunction<T> = React.Dispatch<React.SetStateAction<T>>;
type ClickedRowPos = { height: number; top: number };

const useHandleRowClick = (
	data: DataType[],
	setActiveIndex: SetStateFunction<number>,
	setShowDetailsBox: SetStateFunction<boolean>,
	setOrderUuid: SetStateFunction<string>,
	setClickedRowPos?: SetStateFunction<ClickedRowPos>,
	setClickRow?: SetStateFunction<boolean>
) => {
	const {
		setPreviousItemUuid,
		setPreviousActiveIndex,
		setPreviousHeightAndTop,
	} = useUrl();
	return async (index: number, event: MouseEvent<HTMLTableRowElement>) => {
		setPreviousItemUuid && setPreviousItemUuid("");
		const parent = event.currentTarget.offsetParent;
		const rect = event.currentTarget.getBoundingClientRect();
		const parentRect = parent?.getBoundingClientRect();
		const rowHeight = rect.height + 1;
		const rowTop = rect.top - (parentRect?.top ?? 0) - 1;
		setClickRow && setClickRow(true);
		setActiveIndex(index);
		setPreviousActiveIndex && setPreviousActiveIndex(String(index));
		setShowDetailsBox(true);

		const parentItem = data ? data[index] : null;
		setPreviousHeightAndTop &&
			setPreviousHeightAndTop({ height: rowHeight, top: rowTop });
		if (parentItem) {
			setOrderUuid(parentItem.data.uuid);
			setClickedRowPos &&
				setClickedRowPos({ height: rowHeight, top: rowTop });
		}
	};
};

export default useHandleRowClick;
