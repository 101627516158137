import React, { useRef } from "react";
import ToggleButton from "../ToggleButton";
import { useFormContext, Controller } from "react-hook-form";
import Select, { MenuPlacement } from "react-select";
import classNames from "classnames";
import { getFontAwesomeIcon, formatPhoneNumber } from "../../../utils";
import { OptionType, Field, BaseInputTypes, NestedObject } from "./types";
import Option from "./Option";
import MultiValueRemove from "./MultiValueRemove";
import SingleValue from "./SingleValue";

const BaseInput: React.FC<BaseInputTypes<any>> = ({
	dataItem,
	field,
	allFields,
	mobileBreakpoint,
	inputContainerClasses,
	onInputChange,
	fontFamily = "text-omnes-regular",
	textSize = "",
	inputFontColor = "text-navy-500",
	inputBorderColor = "border-navy-200",
	inputBorderHoverColor = "border-navy-500",
	inputBorderFocusColor = "border-supply-blue-300",
	labelTextColor = "text-navy-350",
	labelFontFamily = "text-omnes-medium",
	dropdownIndicatorClasses = "text-lg pr-2 text-supply-blue-300",
	menuBackgroundColor = "bg-white",
	menuBorderColor = "border-navy-200",
	options,
	inputTextSize,
	uuid,
	hasParentControl,
	dynamicDefaultValue,
	isArrayValue,
	onBlur,
	menuPlacement,
	hasDirtyValidation,
}) => {
	const input = field;
	const {
		register,
		control,
		watch,
		getValues,
		formState: { errors, dirtyFields },
		setValue,
	} = useFormContext();
	const selectRef = useRef<any>(null);

	// const handleValueChange = (inputKey: string, value: any, input: any) => {
	// 	if (onInputChange && input.isSearchable) {
	// 		onInputChange(inputKey, value);
	// 	}
	// };
	const handleValueChange = (inputKey: string, value: any, input: any) => {
		// Your onInputChange callback with three arguments
		if (onInputChange && input.isSearchable) {
			onInputChange(inputKey, value);
		}
	};

	const getDefaultValue = (dynamicDefaultValue: any, valueKey: string) => {
		const value = dynamicDefaultValue ?? getValues(valueKey);
		if (value === undefined || value === null) {
			return "";
		} else {
			return value;
		}
	};

	////// TEXT AREA CLASSES //////
	const textAreaInputDirtyFieldClasses = `p-1 w-full border-1 border-mint-600 rounded focus:${inputBorderFocusColor}`;
	const textAreaInputErrorFieldClasses = `"p-1 w-full border-1 border-crimson-500 rounded focus:${inputBorderFocusColor}`;
	const extraSmallTextAreaClasses = "h-28 min-h-12 max-h-16 w-full";
	const smallTextAreaClasses = "h-28 min-h-12 max-h-36 w-full";
	const largeTextAreaClasses = "h-48 min-h-12 max-h-72 w-full";

	////// TEXT INPUT CLASSES //////
	const textInputDirtyFieldClasses = `border-1 border-mint-600 rounded focus:${inputBorderFocusColor}`;
	const textInputErrorFieldClasses = `border-1 border-crimson-500 rounded focus:${inputBorderFocusColor}`;

	////// SELECT & MULTISELECT INPUT CLASSES //////
	const controlClasses = `rounded border-1 bg-white w-full`;
	const selectInputDirtyFieldClasses = "border-mint-600 w-full";
	const selectInputErrorFieldClasses = "border-crimson-500 w-full";
	const menuClasses = `rounded border-1 shadow-md ${menuBackgroundColor} ${menuBorderColor} overflow-x-hidden`;

	const getInput = (input: Field) => {
		const getNestedValue = (obj: any, path: any) => {
			if (!obj || !path) return undefined;
			const keys = path.split(".");
			let current = obj;
			for (let key of keys) {
				if (current[key] === undefined) return undefined;
				current = current[key];
			}
			return current;
		};

		let isFieldError: any;
		let errorMessage: any;
		let isFieldDirty: any;

		if (input.requireUuidField && !isArrayValue && uuid) {
			isFieldError = getNestedValue(errors, uuid);
			errorMessage = getNestedValue(errors, `${uuid}.message`);
			isFieldDirty = getNestedValue(dirtyFields, input.valueKey);
		} else if (input.requireUuidField && isArrayValue && uuid) {
			isFieldDirty = getNestedValue(dirtyFields, uuid);
			isFieldError = getNestedValue(errors, `${uuid}.message`);
			errorMessage = getNestedValue(errors, `${uuid}.message`);
		} else {
			isFieldError = getNestedValue(errors, input.valueKey);
			errorMessage = getNestedValue(errors, `${input.valueKey}.message`);
			isFieldDirty = getNestedValue(dirtyFields, input.valueKey);
		}
		const validMenuPlacement: MenuPlacement | undefined =
			menuPlacement === "top" ||
			menuPlacement === "bottom" ||
			menuPlacement === "auto"
				? menuPlacement
				: undefined;

		return (
			<div
				key={input.id}
				className={`flex mb-1 w-full ${input.inputGridClasses} ${mobileBreakpoint} ${inputContainerClasses} ${input.specificInputContainerClasses} ${fontFamily} ${textSize}`}
			>
				<label
					htmlFor={input.id}
					className={`pr-2 text-left ${
						input.allowTextWrap ? "" : "text-nowrap"
					} ${labelTextColor} ${labelFontFamily} ${
						input.labelTextClasses
					}`}
				>
					{input.inputLabel ? input.inputLabel : input.label}
					{input.isRequired && input.showRequiredIndicator && (
						<span className="text-crimson-700 ml-0.5">*</span>
					)}
				</label>
				<div
					className={`flex text-left relative ${input.inputContainerWidth}`}
				>
					{(() => {
						switch (input.inputType) {
							case "text":
								return (
									<div className="flex w-full items-center">
										<div
											className={`${
												input.inputHasLeftIcon
													? "relative block"
													: "flex"
											} w-full ${inputTextSize} `}
										>
											{input.inputHasLeftIcon && (
												<div
													className={`pointer-events-none text-xs text-navy-300 absolute top-[12px] left-2`}
												>
													{getFontAwesomeIcon(
														input.inputHasLeftIcon,
														"solid"
													)}
												</div>
											)}

											<Controller
												name={`${
													input.requireUuidField
														? uuid
														: input.valueKey
												}`}
												control={control}
												defaultValue={getDefaultValue(
													dynamicDefaultValue,
													input.valueKey
												)}
												rules={{
													...(input.isRequired
														? {
																required: {
																	value: true,
																	message: `Required field.`,
																},
														  }
														: {}),
													...(input.hasTypeCheck ===
													"number"
														? {
																pattern: {
																	value: /^[0-9]*\.?[0-9]*$/,
																	message: `Only numbers allowed.`,
																},
														  }
														: {}),
												}}
												render={({ field }) => (
													<input
														{...field}
														id={input.id}
														type={"text"}
														className={`p-1 pr-2 py-2 text-sm border-1 rounded ${inputFontColor}
													${input.inputClasses}
													hover:${inputBorderHoverColor}
													focus:${inputBorderFocusColor}
													${input.inputWidth} ${
															isFieldDirty &&
															!isFieldError &&
															hasDirtyValidation
																? textInputDirtyFieldClasses
																: (isFieldError &&
																		isFieldDirty &&
																		hasDirtyValidation) ||
																  (isFieldError &&
																		!isFieldDirty)
																? textInputErrorFieldClasses
																: inputBorderColor
														}}`}
														placeholder={
															input.placeholder
														}
														onFocus={(e) =>
															e.target.select()
														}
														onBlur={onBlur}
														onChange={(e) => {
															let value =
																e.target.value;
															if (
																input.isPhoneNumber
															) {
																value =
																	formatPhoneNumber(
																		value
																	);
															}
															field.onChange(
																value
															);
														}}
													/>
												)}
											/>
										</div>
										{errorMessage && (
											<span
												className="absolute top-full mt-.5"
												style={{ width: "500px" }}
											>
												<p
													className={`text-xs text-crimson-700`}
												>
													{errorMessage}
												</p>
											</span>
										)}
									</div>
								);

							case "select":

								return (
									options && (
										<div className="flex items-center w-full">
											<Controller
												defaultValue={getDefaultValue(
													dynamicDefaultValue,
													input.valueKey
												)}
												name={`${
													input.requireUuidField
														? `${uuid}`
														: input.valueKey
												}`}
												rules={{
													required: input.isRequired
														? {
																value: true,
																message: `Required field.`,
														  }
														: false,
												}}
												control={
													hasParentControl
														? hasParentControl
														: control
												}
												render={({
													field,
												}: {
													field: any;
												}) => {
													return (
														<Select
															menuPlacement={
																validMenuPlacement
															}
															id={input.id}
															unstyled
															isClearable={true}
															closeMenuOnSelect={
																true
															}
															ref={selectRef}
															maxMenuHeight={160}
															placeholder={
																input.placeholder
															}
															isSearchable={
																input.isSearchable
															}
															components={{
																SingleValue: (
																	props: any
																) => (
																	<SingleValue
																		{...props}
																		isDoubleValue={
																			input.isDoubleValue
																		}
																	/>
																),
																Option: (
																	props: any
																) => (
																	<Option
																		{...props}
																		isMulti={
																			false
																		}
																		selectedOption={getValues(
																			input.valueKey
																		)}
																		isDoubleValue={
																			input.isDoubleValue
																		}
																	/>
																),
															}}
															className={`${input.inputWidth} ${inputTextSize}`}
															classNames={{
																clearIndicator:
																	({
																		isFocused,
																	}) =>
																		classNames(
																			isFocused
																				? "text-neutral-600"
																				: "text-neutral-200",
																			isFocused
																				? "hover:text-neutral-800"
																				: "hover:text-neutral-400"
																		),
																control: ({
																	isFocused,
																}) =>
																	classNames(
																		input.isDoubleValue
																			? "h-[50px]"
																			: "",
																		controlClasses,
																		isFieldDirty &&
																			!isFocused &&
																			hasDirtyValidation &&
																			!isFieldError
																			? selectInputDirtyFieldClasses
																			: isFieldError &&
																			  isFieldDirty &&
																			  hasDirtyValidation &&
																			  !isFocused
																			? selectInputErrorFieldClasses
																			: isFieldDirty
																			? selectInputDirtyFieldClasses
																			: inputBorderColor,
																		isFocused &&
																			"border-2 border-supply-blue-400",
																		!isFocused &&
																			"hover:border-navy-500"
																	),
																dropdownIndicator:
																	({
																		isFocused,
																	}) =>
																		classNames(
																			dropdownIndicatorClasses,
																			isFocused
																				? "text-neutral-600"
																				: "text-neutral-200",
																			isFocused
																				? "hover:text-neutral-800"
																				: "hover:text-neutral-400"
																		),
																input: () =>
																	classNames(
																		inputFontColor,
																		"p-0"
																	),
																menu: () =>
																	classNames(
																		menuClasses
																	),
																menuList: () =>
																	classNames(
																		"overflow-y-scroll",
																		"overflow-x-hidden"
																	),
																placeholder:
																	() =>
																		classNames(
																			"text-navy-300",
																			"text-sm",
																			"text-nowrap",
																			"truncate"
																		),
																valueContainer:
																	() =>
																		classNames(
																			"py-0.5",
																			"px-2"
																		),
															}}
															getOptionValue={(
																option: OptionType
															) => option.uuid}
															getOptionLabel={(
																option: OptionType
															) => option.name}
															options={options}
															value={
																options.find(
																	(
																		option: any
																	) =>
																		field.value &&
																		option.uuid ===
																			field
																				.value
																				.uuid &&
																		(input.isDoubleValue ||
																			option._name ===
																				field
																					.value
																					._name) // Use `_name` here
																) || null
															}
															onChange={(
																selectedOption: OptionType | null
															) => {
																if (
																	selectedOption ===
																	null
																) {
																	field.onChange(
																		null
																	);
																} else {
																	const selectedValue =
																		{
																			uuid: selectedOption.uuid,
																			name: selectedOption.name,
																		};
																	field.onChange(
																		selectedValue
																	);
																	setValue(
																		input.valueKey,
																		selectedValue
																	);
																	handleValueChange(
																		input.valueKey,
																		selectedValue,
																		input
																	);
																}
																selectRef.current.blur();
															}}
														/>
													);
												}}
											/>
											{errorMessage && (
												<span
													className="absolute top-full mt-.5"
													style={{ width: "500px" }}
												>
													<p
														className={`text-xs text-crimson-700`}
													>
														{errorMessage}
													</p>
												</span>
											)}
										</div>
									)
								);

							case "multiSelect":
								return (
									<>
										<Controller
											defaultValue={getValues(
												input.valueKey
											)}
											name={input.valueKey}
											rules={{
												required: input.isRequired
													? {
															value: true,
															message: `Required field.`,
													  }
													: false,
											}}
											control={control}
											render={({
												field,
											}: {
												field: {
													value: OptionType[];
													onChange: (
														value: any
													) => void;
												};
											}) => {
												const selectedOptions =
													Array.isArray(field.value)
														? field.value.map(
																(val) =>
																	options.find(
																		(
																			option: any
																		) =>
																			option.uuid ===
																			val.uuid
																	)
														  )
														: [];
												return (
													<Select
														isSearchable={
															input.isSearchable
														}
														id={input.id}
														unstyled
														placeholder={
															input.placeholder
														}
														{...field}
														isMulti
														components={{
															Option: (
																props: any
															) => (
																<Option
																	{...props}
																	isMulti={
																		true
																	}
																/>
															),
															MultiValueRemove: (
																props: any
															) => (
																<MultiValueRemove
																	{...props}
																/>
															),
															ClearIndicator:
																() => null,
														}}
														hideSelectedOptions={
															true
														}
														closeMenuOnSelect={
															false
														}
														options={
															Array.isArray(
																options
															)
																? options
																: []
														}
														getOptionValue={(
															option: OptionType
														) => option.uuid}
														getOptionLabel={(
															option: OptionType
														) => option.name}
														value={
															input.useDefaultValue
																? selectedOptions ||
																  null
																: undefined
														}
														onChange={(
															selectedValues: ReadonlyArray<OptionType>,
															actionMeta: any
														) => {
															if (
																selectedValues ===
																null
															) {
																field.onChange(
																	null
																);
															} else {
																field.onChange(
																	selectedValues
																);
																const values =
																	selectedValues
																		? selectedValues.map(
																				(
																					option: any
																				) =>
																					option.value
																		  )
																		: [];
																handleValueChange(
																	input.valueKey,
																	values,
																	input
																);
															}
														}}
														className={`${input.inputWidth} ${inputTextSize}`}
														classNames={{
															control: ({
																isFocused,
															}) =>
																classNames(
																	controlClasses,
																	isFieldDirty &&
																		!isFocused &&
																		!isFieldError &&
																		hasDirtyValidation
																		? selectInputDirtyFieldClasses
																		: isFieldError &&
																		  isFieldDirty &&
																		  !isFocused &&
																		  hasDirtyValidation
																		? selectInputErrorFieldClasses
																		: isFieldDirty
																		? selectInputDirtyFieldClasses
																		: inputBorderColor,
																	isFocused &&
																		"border-2 border-supply-blue-400",
																	!isFocused &&
																		"hover:border-navy-500"
																),
															dropdownIndicator:
																({
																	isFocused,
																}) =>
																	classNames(
																		dropdownIndicatorClasses,
																		isFocused
																			? "text-neutral-600"
																			: "text-neutral-200",
																		"",
																		isFocused
																			? "hover:text-neutral-800"
																			: "hover:text-neutral-400"
																	),
															input: () =>
																classNames(
																	inputFontColor,
																	"p-0"
																),
															menu: () =>
																classNames(
																	menuClasses
																),
															placeholder: () =>
																classNames(
																	"text-navy-300",
																	"text-sm",
																	"text-nowrap",
																	"truncate"
																),
															valueContainer:
																() =>
																	classNames(
																		"py-0.5",
																		"px-2"
																	),
															multiValue: () =>
																classNames(
																	"rounded-full",
																	"bg-supply-blue-400",
																	"text-white",
																	"text-xs",
																	"px-2",
																	"py-1",
																	"mr-1",
																	"my-0.5",
																	"items-center"
																),
														}}
													/>
												);
											}}
										/>
										{errorMessage && (
											<span
												className="absolute top-full mt-.5"
												style={{ width: "500px" }}
											>
												<p
													className={`text-xs text-crimson-700`}
												>
													{errorMessage}
												</p>
											</span>
										)}
									</>
								);
							case "toggle":
								return (
									<div className="flex items-center py-2">
										<Controller
											control={control}
											name={input.valueKey}
											defaultValue={
												dataItem
													? dataItem[
															input.valueKey as keyof typeof dataItem
													  ]
													: ""
											}
											render={({
												field: { value, onChange },
											}) => (
												<ToggleButton
													initialStatus={Boolean(
														value
													)}
													onClick={() =>
														onChange(!value)
													}
													textPosition={"right"}
													textSize={"sm"}
													activeColorBackground="bg-blue-600"
													inactiveColorBackground="bg-navy-300"
													activeColorBorder="border-supply-blue-500"
													inactiveColorBorder="border-navy-300"
													activeTextColor="text-supply-blue-500"
													inactiveTextColor="text-navy-300"
													additionalClasses={`w-1/4`}
													hasDivider={false}
													fontFamily="omnes-regular"
													activeLabel={"Yes"}
													inactiveLabel={"No"}
													id={input.id}
													smallToggle={true}
													borderStyle={true}
												/>
											)}
										/>
									</div>
								);
							case "textArea":
								return (
									<Controller
										control={control}
										name={input.valueKey}
										defaultValue={getValues(input.valueKey)}
										render={({ field }) => {
											const textAreaValue = watch(
												input.valueKey
											);
											const characterCount = textAreaValue
												? textAreaValue.length
												: 0;

											return (
												<div className="w-full">
													<div
														className={`${input.inputWidth} w-full flex`}
													>
														<textarea
															{...field}
															id={input.id}
															placeholder={
																input.placeholder
															}
															{...register(
																input.valueKey,
																{
																	...(input.characterLimit
																		? {
																				maxLength:
																					{
																						value: input.characterLimit,
																						message: `Maximum length is ${input.characterLimit} characters`,
																					},
																		  }
																		: {}),
																	...(input.isRequired
																		? {
																				required:
																					{
																						value: true,
																						message: `${input.label} is required`,
																					},
																		  }
																		: {}),
																}
															)}
															value={
																field.value ||
																""
															}
															className={`p-1 border-1  ${inputFontColor}
															${input.inputClasses}
															hover:${inputBorderHoverColor}
															focus:${inputBorderFocusColor} ${input.inputWidth} ${inputTextSize}
															${
																input.textAreaHeight ===
																"small"
																	? smallTextAreaClasses
																	: input.textAreaHeight ===
																	  "large"
																	? largeTextAreaClasses
																	: extraSmallTextAreaClasses
															}
															 ${
																	isFieldDirty &&
																	!isFieldError
																		? textAreaInputDirtyFieldClasses
																		: (isFieldError &&
																				isFieldDirty) ||
																		  (isFieldError &&
																				!isFieldDirty)
																		? textAreaInputErrorFieldClasses
																		: inputBorderColor
																}`}
															onFocus={(e) =>
																e.target.select()
															}
														/>
													</div>
													<div
														className={`flex w-full ${
															input.characterLimit &&
															!isFieldError
																? "items-end justify-end"
																: "justify-between"
														}`}
													>
														{errors[input.valueKey]
															?.message && (
															<span className="flex items-start justify-start">
																<p
																	className={`text-xs ${
																		isFieldError &&
																		"text-crimson-700"
																	}`}
																>
																	{`${
																		errors[
																			input
																				.valueKey
																		]
																			?.message
																	}`}
																</p>
															</span>
														)}
														{input.characterLimit && (
															<span className="flex items-end justify-end">
																<p
																	className={`text-xs ${
																		isFieldError &&
																		isFieldDirty
																			? "text-crimson-700"
																			: "text-navy-350"
																	}`}
																>
																	{`${characterCount}/${input.characterLimit}`}
																</p>
															</span>
														)}
													</div>
												</div>
											);
										}}
									/>
								);

							case "disabledSelect":
								return (
									<div
										className={`flex text-left p-1 pr-2 py-2 text-sm border-1 rounded h-[38px bg-navy-200 ${input.inputContainerWidth} hover:cursor-not-allowed`}
									>
										<div
											className={`flex items-center justify-between w-full `}
										>
											<div className="text-navy-400">
												{input.disabledSelectText}
											</div>
											<div className="flex items-end">
												<svg
													height="20"
													width="20"
													viewBox="0 0 20 20"
													aria-hidden="true"
													focusable="false"
													className="fill-navy-400"
												>
													<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
												</svg>
											</div>
										</div>
									</div>
								);

							case "none":
								return (
									<p
										className={`flex items-center  ${
											input.hasSetHeight ? "h-[38px]" : ""
										} ${input.noEditClasses}`}
									>
										{dataItem}
									</p>
								);
							case "checkbox":
								return (
									<input
										type="checkbox"
										{...register(input.valueKey)}
										defaultValue={getValues(input.valueKey)}
									></input>
								);
							default:
								return null;
						}
					})()}
					{input.afterInputText && (
						<div className="flex items-center h-[38px] ml-2 text-nowrap">
							<p
								className={`${labelTextColor} ${labelFontFamily} ${input.afterInputTextClasses}`}
							>
								{input.afterInputText}
							</p>
						</div>
					)}
				</div>
			</div>
		);
	};

	// @ts-ignore
	return <>{getInput(input)}</>;
};

export default BaseInput;
