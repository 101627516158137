import React, { useCallback, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";

type GenericData = {
	id: string;
	[key: string]: any;
};

export type GenericListTypes<T extends GenericData> = {
	data: T[];
	renderItem: (item: T, index?: number) => JSX.Element;
	itemHeight?: number;
	containerHeight?: number;
	containerClasses?: string;
	listDirection?: "horizontal" | "vertical";
	hasVirtualization?: boolean;
	listType: "ordered" | "unordered" | "none";
	parentRef?: React.RefObject<HTMLElement>;
	index?: number;
	isVirtualizer?: boolean;
};

const getListDirectionClasses = (listDirection?: string): string => {
	if (listDirection === "horizontal") {
		return "flex";
	} else if (listDirection === "vertical") {
		return "flex flex-col";
	} else {
		return "";
	}
};

const GenericList = <T extends GenericData>({
	data,
	renderItem,
	itemHeight = 35,
	containerHeight,
	containerClasses = "",
	listDirection,
	hasVirtualization = false,
	listType,
	parentRef,
	isVirtualizer = false,
}: GenericListTypes<T>) => {
	const listClasses = getListDirectionClasses(listDirection);

	// Use the passed parentRef or create a new one
	const internalRef = useRef<HTMLElement>(null);
	const scrollContainerRef = parentRef || internalRef;

	const estimateSize = useCallback(() => itemHeight, [itemHeight]);

	const count = data.length;

	if (!hasVirtualization || isVirtualizer) {
		// Render without virtualization
		return (
			<div
				ref={scrollContainerRef as React.RefObject<HTMLDivElement>}
				style={{
					height: containerHeight,
					overflow: "auto",
				}}
				className={`${listClasses} ${containerClasses}`}
			>
				{data.map((item, index) => (
					<div key={`${item.id}-${index}`}>
						{renderItem(item, index)}
					</div>
				))}
			</div>
		);
	}

	// Continue with virtualization
	const virtualizer = useVirtualizer({
		count,
		estimateSize,
		overscan: 5,
		getScrollElement: () => scrollContainerRef.current,
	});

	const totalSize = virtualizer.getTotalSize();
	const virtualItems = virtualizer.getVirtualItems();

	return (
		<div
			ref={scrollContainerRef as React.RefObject<HTMLDivElement>}
			style={{
				height: containerHeight,
				overflow: "auto",
				position: "relative",
			}}
			className={`${listClasses} ${containerClasses}`}
		>
			<div
				style={{
					height: totalSize,
					width: "100%",
					position: "relative",
				}}
			>
				{virtualItems.map((virtualItem) => (
					<div
						key={virtualItem.key}
						style={{
							position: "absolute",
							top: virtualItem.start,
							left: 0,
							width: "100%",
							height: virtualItem.size,
						}}
					>
						{renderItem(data[virtualItem.index], virtualItem.index)}
					</div>
				))}
			</div>
		</div>
	);
};

export default GenericList;
