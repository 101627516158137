const onlyAllowNumberValue = (
	event: React.ChangeEvent<HTMLInputElement>,
	setValue: React.Dispatch<React.SetStateAction<string>>
) => {
	const newValue = event.target.value;
	// Allow only numbers
	if (/^\d*$/.test(newValue)) {
		setValue(newValue);
	}
};
export default onlyAllowNumberValue;
