import { checkForDifferences, togaApiRequest } from "../../../api/toga";

export interface Field {
	[key: string]: string | string[] | Field;
}

//Api call to get Vendor Item Details based on the uuid
export async function getSingleVendorItem(uuid: string) {
	const options = {
		depth: 5,
	};
	const response = await togaApiRequest(
		"GET",
		"/vendor-items/" + uuid,
		null,
		options as any
	);
	var output = response.data.vendorItems;

	return output;
}

interface VendorItemData {
	item?: any;
	[key: string]: any;
}

interface Differences {
	item: any;
	[key: string]: any;
}
//Api call to update vendor Item details
export async function updateVendorItemDetails(
	uuid: string,
	originalData: VendorItemData,
	updatedData: VendorItemData
) {
	const itemDetails = originalData.item;
	const differences = checkForDifferences(originalData, updatedData) as Differences;
	differences.item = itemDetails;

	const response = await togaApiRequest(
		"PUT",
		"/vendor-items/" + uuid,
		differences,
		{}
	);
	return response;
}

//Api call to create a new vendor Item
export async function createVendorItem(data: object) {
	const response = await togaApiRequest("POST", "/vendor-items", data, {});
	return response;
}
