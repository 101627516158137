import { SingleValueProps, components } from "react-select";
import { OptionType } from "./types";

const SingleValue = (props: SingleValueProps<OptionType, false>) => {
	const { data, innerProps, isDisabled, clearValue, ...rest } = props;

	if (Array.isArray(data.name)) {
		let firstValue = data.name[0];
		let secondValue = data.name[1];

		return (
			<components.SingleValue {...props}>
				<div className="flex flex-col py-1">
					<div className="max-w-14">{firstValue}</div>
					{data.name.length > 1 && (
						<div className="text-navy-350">{secondValue}</div>
					)}
				</div>
			</components.SingleValue>
		);
	} else {
		return (
			<components.SingleValue {...props}>
				<div className="flex items-center py-1">{data.name}</div>
			</components.SingleValue>
		);
	}
};

export default SingleValue;
