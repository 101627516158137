import { useState, useEffect } from "react";
import { PageMeta } from "./usePageDetails";
import { getPageMeta } from "../../api/toga";

const usePageListDetails = (slug: string) => {
	const [pageMeta, setPageMeta] = useState<PageMeta | null>(null);
	const [isLoadingMeta, setIsLoadingMeta] = useState<boolean>(true);
	const [isError, setIsError] = useState<boolean>(false);

	useEffect(() => {
		const fetchPageMeta = async () => {
			setIsLoadingMeta(true);
			setIsError(false);
			try {
				const meta: PageMeta = await getPageMeta(slug);
				setPageMeta(meta);
			} catch (error) {
				console.error("Failed to fetch page meta:", error);
				setIsError(true);
			} finally {
				setIsLoadingMeta(false);
			}
		};

		fetchPageMeta();
	}, [slug]);

	return { pageMeta, isLoadingMeta, isError };
};

export default usePageListDetails;
