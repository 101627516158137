import { useState, useEffect, useCallback } from "react";
import { getPageMeta } from "../../api/toga";

export interface MetaSections {
	[key: string]: {
		label: string;
	};
}

export interface PageMeta {
	settings: {
		sections: MetaSections;
		fields: Record<string, any>;
	};
	acl: {
		"sales-orders": Record<string, any>;
		items: Record<string, any>;
		"vendor-items": Record<string, any>;
	};
}

export interface WithUuid {
	uuid: string;
	refetchData?: boolean;
	setRefetchData?: (value: boolean) => void;
}

interface FetchError {
	errorCode: number;
	errorMessage: string;
}

function isFetchError(obj: any): obj is FetchError {
	return (
		obj &&
		typeof obj.errorCode === "number" &&
		typeof obj.errorMessage === "string"
	);
}

const usePageDetails = <T extends WithUuid | undefined>(
	uuid: string,
	slug: string,
	fetchDetails: (uuid: string) => Promise<T | FetchError>,
	refetchData?: boolean
) => {
	const [data, setData] = useState<T | null>(null);
	const [labels, setLabels] = useState<Record<string, string>>({});
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isError, setIsError] = useState<boolean>(false);
	const [error, setError] = useState<FetchError | null>(null);

	const fetchData = useCallback(async () => {
		setIsLoading(true);
		setIsError(false);
		setError(null);

		try {
			const meta: PageMeta = await getPageMeta(slug);
			const labels: Record<string, string> = {};
			for (const [key, section] of Object.entries(
				meta.settings.sections
			)) {
				labels[key] = section?.label || "";
			}
			setLabels(labels);

			const details = await fetchDetails(uuid);

			if (isFetchError(details)) {
				setIsError(true);
				setError(details);
				setData(null);
			} else {
				setData(details);
			}
		} catch (err: any) {
			setIsError(true);
			setError({
				errorCode: err.response?.status || 500,
				errorMessage: err.message || "An error occurred",
			});
		} finally {
			setIsLoading(false);
		}
	}, [uuid, slug, fetchDetails, refetchData]);

	useEffect(() => {
		if (uuid) {
			fetchData();
		}
	}, [uuid, slug, fetchData]);

	return { data, labels, isLoading, isError, error };
};

export default usePageDetails;
