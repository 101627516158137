import React, { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import VendorItemsModalContentTemplate from "./edit/VendorItemsModalContentTemplate";
import { useDataTableContext } from "../../../../hooks";
import {
	slideUpModalOverlayVariants,
	slideUpModalVariants,
} from "../../../../motionVariants";

interface VendorSliderModalProps {
	isOpen: boolean;
	onClose: () => void;
	closeButton?: (handleClose: () => void) => React.ReactNode;
	hasBorder?: boolean;
	borderColor?: string;
	navChildren?: React.ReactNode;
	cancelButton?: React.ReactNode;
	saveButton?: React.ReactNode;
	parentData?: any;
}

const VendorSliderModal = ({
	isOpen,
	onClose,
	hasBorder = false,
	borderColor = "primary",
}: VendorSliderModalProps) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const { pageTitle } = useDataTableContext();
	const handleModalOpen = () => {
		document.body.classList.add("overflow-hidden");
	};
	const modalContainerClassNames =
		"fixed inset-0 flex items-end justify-center bg-transparent z-50 max-w-[1200px] mx-auto modal-bottom z-[9920]";
	const modalBorderClassNames = `modal-box min-w-90% min-h-[95dvh] p-0 ${
		isMenuOpen ? " overflow-hidden pt-0" : ` pt-0 `
	}  ${hasBorder ? `border-${borderColor}` : "border-transparent"}`;
	const modalOverLayClassNames = "fixed inset-0 bg-black z-[9910]";

	return (
		<div>
			<AnimatePresence>
				{isOpen && (
					<>
						{handleModalOpen()}
						<motion.div
							className={modalContainerClassNames}
							initial={slideUpModalVariants.initial()}
							animate={slideUpModalVariants.animate()}
							exit={slideUpModalVariants.exit()}
							transition={slideUpModalVariants.transition}
						>
							<div className="min-w-full">
								<div className={modalBorderClassNames}>
									<motion.div>
										<VendorItemsModalContentTemplate
											modalRef={modalRef}
											pageTitle={pageTitle}
											onClose={onClose}
											isFromVendorItemsPage={true}
										/>
									</motion.div>
								</div>
							</div>
						</motion.div>
						<>
							<motion.div
								initial={slideUpModalOverlayVariants.initial}
								animate={slideUpModalOverlayVariants.animate}
								exit={slideUpModalOverlayVariants.exit}
								transition={
									slideUpModalOverlayVariants.transition
								}
								className={modalOverLayClassNames}
							></motion.div>
						</>
					</>
				)}
			</AnimatePresence>
		</div>
	);
};

export default VendorSliderModal;
