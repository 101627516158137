import React from "react";
import BaseAccordion from "../../../../../components/ui/BaseAccordion";
import { OrderInvoiceContent, OrderInvoiceHeader, } from "..";

// TYPES
import {
	Invoice,
} from "./../../types";

interface Item {
	id?: string;
	labelIcon?: string;
	label?: string;
	valueKey: string;
	inputType?: string;
	labelTextClasses?: string;
	valueTextClasses?: string;
	hasValueLeftIconOne?: string;
	hasValueLeftIconTwo?: string;
	hasValueRightIcon?: string;
	detailIconClasses?: string;
	hasValueImage?: string;
	detailGridClasses?: string;
	inputGridClasses?: string;
	additionalInputContainerClasses?: string;
	isBoolean?: boolean;
	truthyValue?: string;
	falsyValue?: string;
	isConcatenated?: string;
	isEditOnly?: boolean;
	inputHasLeftIcon?: string;
	inputIconClasses?: string;
	disabledClasses?: string;
	secondValueTextClasses?: string;
	imageClasses?: string;
	flexDirection?: string;
	hasBottomBorder?: boolean;
	labelToolTip?: boolean;
	isCurrency?: boolean;
	isCustomerName?: boolean;
}

interface OrderInvoiceListProps {
	title?: React.ReactNode;
	items: Invoice[];
	fields?: Item[];
}



const OrderInvoiceList: React.FC<OrderInvoiceListProps> = ({
	title,
	items,
	fields,
}) => {
	return (
		<>
			{items && (
				<div className="flex flex-col h-full w-full items-center">
					<div className="flex">{title}</div>
					<ul className="w-full">
						{items.map((item, index) => (
							<li
								key={index}
								className={`mb-2 bg-white rounded-lg border-none hover:bg-navy-50`}
							>
								<BaseAccordion
									headerChildren={
										<OrderInvoiceHeader item={item} />
									}
									children={
										<OrderInvoiceContent
											item={item}
											index={index}
											fieldsConfig={fields}
											containerClasses={"bg-white px-2.5"}
										/>
									}
									accordionParentClasses={"px-2.5 py-2.5"}
									accordionExpandedClasses={
										"bg-white rounded-lg border-none"
									}
								/>
							</li>
						))}
					</ul>
				</div>
			)}
		</>
	);
};

export default OrderInvoiceList;
