import React from "react";
import { Link } from "react-router-dom";

interface BasePillTypes {
	onClick?: (
		e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
	) => void;
	text?: React.ReactNode;
	cursorPointer?: boolean;
	type: "href" | "to" | "span";
	color?: string | undefined;
	borderColor?: string | undefined;
	to?: string;
	href?: string;
	hoverBackgroundColor: string | undefined;
	additionalClasses?: string;
	hasMobileStyle?: boolean;
	mobileIcon?: JSX.Element | Element | null;
	mobileIconLabel?: string;
	children?: React.ReactNode;
	firstIcon?: JSX.Element | Element | null;
	secondIcon?: JSX.Element | Element | null;
	iconOrder?: "first" | "last";
	testId?: string;
	shape?: string;
	iconSize?: string;
	iconColor?: string;
}

const BasePill: React.FC<BasePillTypes> = ({
	onClick,
	color = "blue",
	cursorPointer = false,
	type = "span",
	to,
	href,
	hoverBackgroundColor = "blue",
	borderColor,
	iconColor = "black",
	hasMobileStyle,
	mobileIcon,
	mobileIconLabel,
	additionalClasses,
	firstIcon,
	secondIcon,
	text,
	iconSize,
	shape,
}) => {
	let pillClasses = `flex items-center ${color} border-1 border-${borderColor} hover:${hoverBackgroundColor} ${shape} ${
		cursorPointer ? "cursor-pointer" : ""
	} ${additionalClasses}`;
	let iconClasses = `text-${iconColor} text-${iconSize}`;
	const handleClick = (
		e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
	) => {
		e.preventDefault();
		onClick?.(e);
	};

	const baseProps = {
		className: pillClasses.trim(),
		onClick: handleClick,
		type: type,
	};

	switch (type) {
		case "href":
			return (
				<a {...baseProps} href={href}>
					{renderContent()}
				</a>
			);
		case "to":
			return to ? (
				<Link {...baseProps} to={to}>
					{renderContent()}
				</Link>
			) : null;
		case "span":
			return <span {...baseProps}>{renderContent()}</span>;
		default:
			return null;
	}

	function renderContent() {
		return (
			<>
				{firstIcon && (
					<span
						className={`flex pr-1.5 ${
							hasMobileStyle ? "max-md:hidden" : ""
						} ${iconClasses}`}
					>
						<>{firstIcon}</>
					</span>
				)}
				{mobileIcon && hasMobileStyle && (
					<span
						className={`hidden max-md:flex max-md:p-0 ${iconClasses}`}
						aria-hidden="false"
						aria-label={mobileIconLabel}
					>
						<>{mobileIcon}</>
					</span>
				)}
				<div className={` ${hasMobileStyle ? "max-md:hidden" : ""}`}>
					<span>
						<>{text}</>
					</span>
				</div>
				{secondIcon && (
					<span
						className={`flex pl-1.5 ${
							hasMobileStyle ? "max-md:hidden" : ""
						} ${iconClasses}`}
					>
						<>{secondIcon}</>
					</span>
				)}
			</>
		);
	}
};

export default BasePill;
