import React from "react";

interface Field {
	id: string;
	labelIcon?: string;
	label?: string;
	valueKey: string;
	inputType?: string;
	separatorType?: string;
	labelTextClasses?: string;
	valueTextClasses?: string;
	hasValueLeftIcon?: string;
	hasValueRightIcon?: string;
	detailGridClasses?: string;
	inputGridClasses?: string;
	specificInputContainerClasses?: string;
	isBoolean?: boolean;
	isSearchable?: boolean;
	truthyValue?: string;
	falsyValue?: string;
	isConcatenated?: string;
	isEditOnly?: boolean;
	inputHasLeftIcon?: string;
	inputIconClasses?: string;
	inputClasses?: string;
	placeholder?: string;
	textAreaHeight?: string;
	hasTypeCheck?: string;
	options?: [];
	inputContainerWidth?: string;
	inputWidth?: string;
	inputDirtyFieldClasses?: string;
	inputLabel?: string;
	characterLimit?: number;
	isRequired?: boolean;
	afterInputText?: string;
	isDoubleValue?: boolean;
	noEditClasses?: string;
	hasSetHeight?: boolean;
	allowTextWrap?: boolean;
	afterInputTextClasses?: string;
	requireUuidField?: boolean;
	textType?: string;
}

interface BaseDisabledInputProps<T> {
	field?: Field;
	mobileBreakpoint?: string;
	inputContainerClasses?: string;
	fontFamily?: string;
	textSize?: string;
	labelTextColor?: string;
	labelFontFamily?: string;
	text?: string;
	inputTextSize?: string;
	disabledInputClasses?: string;
}

const BaseDisabledInput: React.FC<BaseDisabledInputProps<any>> = ({
	field,
	mobileBreakpoint,
	inputContainerClasses,
	fontFamily = "text-omnes-regular",
	textSize,
	labelTextColor = "text-navy-350",
	labelFontFamily = "text-omnes-medium",
	text,
	inputTextSize,
	disabledInputClasses,
}) => {
	const input = field;

	const getInput = (input: Field) => {
		return (
			<div
				key={input.id}
				className={`flex mb-1 w-full ${input.inputGridClasses} ${mobileBreakpoint} ${inputContainerClasses} ${input.specificInputContainerClasses} ${fontFamily} ${textSize}`}
			>
				<label
					htmlFor={input.id}
					className={`pr-2 text-left ${
						input.allowTextWrap ? "text-nowrap" : ""
					} ${labelTextColor} ${labelFontFamily} ${
						input.labelTextClasses
					}`}
				>
					{input.inputLabel ? input.inputLabel : input.label}
				</label>
				<div className={`flex text-left ${input.inputContainerWidth}`}>
					<div
						className={`flex items-center justify-between h-[38px] ${disabledInputClasses}`}
					>
						<p className={`${inputTextSize}`}>{text}</p>
						{input.inputType === "select" && (
							<div className="">
								<svg
									height="20"
									width="20"
									viewBox="0 0 20 20"
									aria-hidden="true"
									focusable="false"
									className="fill-navy-250"
								>
									<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
								</svg>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	return <>{input && getInput(input)}</>;
};

export default BaseDisabledInput;
