import formatTimestamp from "../../../../utils/formatDateForEventLogs";

const formatNotes = (notes: any[], boldClassNames: string) => {
	return notes.map(({ note, user, timestamp }, index: number) => {
		const userName = user?._name;
		const time = timestamp ? formatTimestamp(timestamp) : "";

		return {
			id: index,
			itemTitle: (
				<>
					<span className={boldClassNames}>{note}</span>
					{userName && (
						<>
							<span> by </span>
							<span className={boldClassNames}>{userName}</span>
						</>
					)}
				</>
			),
			itemTime: time,
		};
	});
};

export default formatNotes;
