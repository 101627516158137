import React, { useEffect, useRef, useState } from "react";
import Input from "../Input";
import { AnimatePresence, motion } from "framer-motion";
import BasePill from "../BasePill";
import { ColumnInstance } from "react-table";
import { useDarkMode, useItemsContext } from "../../../hooks";
import { getFontAwesomeIcon } from "../../../utils";
import { simpleFadeVariants } from "../../../motionVariants";

type InputAndCheckProps<T extends object> = {
	closeOutSearch: (value: number | null) => void;
	setResetSearch: React.Dispatch<React.SetStateAction<boolean>>;
	columHeader?: any;
	parentIndex?: number;
	pillColor?: string;
	column: ColumnInstance<T>;
	setEditingHeader: (value: number | null) => void;
};

const InputAndCheck = <T extends object>({
	closeOutSearch,
	setResetSearch,
	setEditingHeader,
	columHeader,
	parentIndex,
	pillColor = "bg-supply-blue-600",
	column,
}: InputAndCheckProps<T>) => {
	const { darkMode } = useDarkMode();
	const { searchCriteria, setSearchCriteria } = useItemsContext();

	const containerRef = useRef<HTMLDivElement>(null);
	const [localSearchText, setLocalSearchText] = useState<string>("");

	// Load initial search text for this column from context
	useEffect(() => {
		const existingCriterion = searchCriteria.find(
			(criterion) => criterion.searchColumn.id === column.id
		);
		if (existingCriterion) {
			setLocalSearchText(existingCriterion.submittedSearchText);
		} else {
			setLocalSearchText("");
		}
	}, [searchCriteria, column.id]);

	// Handle input change
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalSearchText(event.target.value);
	};

	// Handle submit
	const handleSubmitClick = () => {
		const trimmedSearchText = localSearchText.trim();

		if (trimmedSearchText === "") {
			// Remove criterion if text is empty
			setSearchCriteria((prevCriteria) =>
				prevCriteria.filter(
					(criterion) => criterion.searchColumn.id !== column.id
				)
			);
		} else {
			// Update or add criterion
			setSearchCriteria((prevCriteria) => {
				const existingIndex = prevCriteria.findIndex(
					(criterion) => criterion.searchColumn.id === column.id
				);
				if (existingIndex >= 0) {
					// Update existing criterion
					const updatedCriteria = [...prevCriteria];
					updatedCriteria[existingIndex] = {
						searchColumn: column,
						submittedSearchText: trimmedSearchText,
					};
					return updatedCriteria;
				} else {
					// Add new criterion
					return [
						...prevCriteria,
						{
							searchColumn: column,
							submittedSearchText: trimmedSearchText,
						},
					];
				}
			});
		}

		setEditingHeader(null);
	};

	// Handle clear
	const handleIconClick = () => {
		// Remove criterion
		setSearchCriteria((prevCriteria) =>
			prevCriteria.filter(
				(criterion) => criterion.searchColumn.id !== column.id
			)
		);
		setLocalSearchText("");
		setResetSearch((prevResetSearch) => !prevResetSearch);
		closeOutSearch(null);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			e.preventDefault();
			handleSubmitClick();
		}
	};

	const pillClassnames = `${pillColor} p-1 max-w-fit min-w-20 rounded-full flex justify-between items-center text-white text-xs px-4 border-none`;

	return (
		<div ref={containerRef} className="">
			<div className="flex flex-col border-1 rounded-md border-navy-200 shadow-md">
				<div className="flex">
					<Input
						hasAutoFocus
						value={localSearchText}
						onKeyDown={handleKeyDown}
						required={false}
						id=""
						name=""
						type="text"
						onChange={handleInputChange}
						additionalClasses={`min-w-[250px] max-w-[250px] border-b-1 border-t-0 border-r-0  min-h-full rounded-tl-none rounded-tr-lg ${
							darkMode ? "" : " bg-navy-50 text-gray flex"
						}`}
						placeholder="Search"
						hasIcons
						firstIcon={
							localSearchText === "" ? (
								<AnimatePresence>
									<motion.div
										initial="initial"
										animate="animate"
										exit="exit"
										variants={simpleFadeVariants}
										className="text-navy-400"
									>
										{getFontAwesomeIcon(
											"search",
											"regular"
										)}
									</motion.div>
								</AnimatePresence>
							) : undefined
						}
						iconPosition="both"
						secondIcon={
							<div
								className={`${
									darkMode
										? "bg-transparent hover:bg-teal-400 text-primary border-1 border-primary"
										: "border-transparent text-white min-w-9"
								}`}
							>
								<AnimatePresence>
									{localSearchText !== "" && (
										<motion.div
											className="flex justify-between min-w-4 text-navy-400 hover:cursor-pointer hover:text-primary"
											initial="initial"
											animate="animate"
											exit="exit"
										>
											<div
												className="bg-navy-50 pr-2 pl-1"
												onClick={handleIconClick}
											>
												{getFontAwesomeIcon(
													"Xmark",
													"regular"
												)}
											</div>
										</motion.div>
									)}
								</AnimatePresence>
							</div>
						}
						onIconClick={handleIconClick}
					/>
				</div>
				{/* Optionally display the active search criterion as a pill */}
				{localSearchText && (
					<div className="bg-white py-2 px-2 rounded-md">
						<BasePill
							text={localSearchText}
							type={"span"}
							hoverBackgroundColor={""}
							additionalClasses={pillClassnames}
							cursorPointer={true}
							secondIcon={
								<div className="text-white text-xxs">
									{getFontAwesomeIcon("x", "solid")}
								</div>
							}
							onClick={handleIconClick}
						/>
					</div>
				)}
			</div>
			<AnimatePresence>
				{localSearchText !== "" && (
					<motion.div
						initial="initial"
						animate="animate"
						exit="exit"
						variants={simpleFadeVariants}
						onClick={handleSubmitClick}
						className="text-md absolute right-3 hover:cursor-pointer top-2"
					>
						<div className="text-navy-400 hover:text-primary">
							{getFontAwesomeIcon("search", "solid")}
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default InputAndCheck;
