import formatTimestamp from "../../../../utils/formatDateForEventLogs";

const formatEvents = (logs: Record<string, any>[], boldClassNames: string) => {
	return logs.map(
		(
			{ recordFieldLogs, timestamp, user }: Record<string, any>,
			index: number
		) => {
			const userName = user?._name;
			const time = timestamp ? formatTimestamp(timestamp) : "";

			return {
				id: index,
				itemNote: null,
				itemTitle: (
					<div className="flex flex-col">
						{Array.isArray(recordFieldLogs) &&
							recordFieldLogs.map(
								(
									{
										recordField,
										newValue,
									}: Record<string, any>,
									logIndex: number
								) =>
									logIndex === 0 ? (
										<div key={logIndex} >
											<span>Updated </span>
											<span className={boldClassNames}>
												{`${recordField?.field ?? ""}`}
											</span>
											<span>{" to "}</span>
											<span className={boldClassNames}>
												{newValue ?? ""}
											</span>
										</div>
									) : (
										<div className={"pl-14"} key={logIndex}>
											<span className={boldClassNames}>
												{recordField?.field ?? ""}
											</span>
											<span>{" to "}</span>
											<span className={boldClassNames}>
												{newValue ?? ""}
											</span>
										</div>
									)
							)}
					</div>
				),
				itemTime: time,
				itemUser: userName ?? "",
				longDescription: "",
				additionalUpdates: "",
			};
		}
	);
};

export default formatEvents;
