import {
	CapturedRowDetails,
	ShipmentData,
	SerialNumbers,
} from "../../../../types";

const handleRemoveItemFromQuantity = (
	capturedRowDetails: CapturedRowDetails,
	getValues: () => Record<string, string | boolean | number>,
	reset: (values?: Record<string, string | boolean | number>) => void,
	serialNumbers: { [key: string]: string[] },
	setSerialNumbers: (serialNumbers: { [key: string]: string[] }) => void,
	updateQuantity: (partNumber: string) => void,
	setIsGuardrailOpen: (isGuardrailOpen: boolean) => void
) => {
	if (capturedRowDetails) {
		const { partNumber, index, serialNumber } = capturedRowDetails;
		const currentFormValues = { ...getValues() };
		const updatedData: Record<string, string | boolean | number | object> =
			{ ...getValues() };

		const partNumberKey = `partNumber: ${partNumber}`;

		if (
			updatedData[partNumberKey] &&
			typeof updatedData[partNumberKey] === "object" &&
			!Array.isArray(updatedData[partNumberKey])
		) {
			const partNumberData = {
				...(updatedData[partNumberKey] as { [key: string]: any }),
			};
			delete partNumberData[`index:${index}`];
			updatedData[partNumberKey] = partNumberData;
		}

		const filteredFormValues = Object.keys(updatedData).reduce(
			(acc, key) => {
				const value = updatedData[key];
				if (
					typeof value === "string" ||
					typeof value === "number" ||
					typeof value === "boolean"
				) {
					acc[key] = value;
				}
				return acc;
			},
			{} as Record<string, string | number | boolean>
		);

		const newFormValues = { ...currentFormValues, ...filteredFormValues };
		reset(newFormValues);

		const updatedSerialNumbers = { ...serialNumbers };
		if (updatedSerialNumbers[partNumber]) {
			updatedSerialNumbers[partNumber] = updatedSerialNumbers[
				partNumber
			].filter((_, i) => i !== index);
			if (updatedSerialNumbers[partNumber].length === 0) {
				delete updatedSerialNumbers[partNumber];
			}
		}
		setSerialNumbers(updatedSerialNumbers);
		updateQuantity(partNumber);
	}
	setIsGuardrailOpen(false);
};

export default handleRemoveItemFromQuantity;