import React from "react";

interface ContainerProps {
	size?: "sm";
	containerClasses?: string;
	additionalClasses?: string;
	children: React.ReactNode;
	testId?: string;
}

const Container: React.FC<ContainerProps> = ({
	size,
	children,
	containerClasses,
	additionalClasses,
	testId,
}) => {
	switch (size) {
		case "sm":
			containerClasses += " sm:w-sm";
			break;
		default:
			break;
	}

	return (
		<div
			data-testid={testId}
			className={`${containerClasses} ${additionalClasses}`}
		>
			{children}
		</div>
	);
};

export default Container;
