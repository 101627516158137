import React from "react";
import { Link } from "react-router-dom";
import { useDarkMode } from "../../hooks";

// Define the interface for the ButtonProps
interface ButtonProps {
	onClick?: any; // A callback function to handle the click event
	text?: string; // The text to display inside the button
	color?: string; // The color of the button
	textColor?: string; // The color of the text inside the button
	size?: "xs" | "sm" | "md" | "lg" | "xl"; // The size of the button
	shape?: "flat" | "outline" | "underline" | "clear" | "semi-rounded"; // The shape of the button
	icon?: JSX.Element | (() => JSX.Element | React.ReactElement) | null; // An optional icon to display inside the button
	iconPosition?: "before" | "after" | "only"; // The position of the icon relative to the text
	iconStyle?: "thin" | "light" | "regular" | "solid" | "duotone"; // The style of the icon
	iconOrder?: number | "first" | "last"; // The order of the icon relative to other icons
	type?: "submit" | "reset" | "button"; // The type of the button (submit, reset or button)
	to?: string; // A string representing the location to navigate to when the button is clicked (used with the <Link> component)
	href?: string; // A string representing the URL to navigate to when the button is clicked (used with the <a> component)
	tooltip?: string; // A string representing the tooltip text to display when the button is hovered over
	additionalClasses?: string; // Additional CSS classes to apply to the button
	isDisabled?: boolean; // A boolean value indicating whether the button is disabled
	hasShadow?: boolean; // A boolean value indicating whether the button has a shadow
}

// Define the Button component as a functional component that takes in the ButtonProps
const Button: React.FC<ButtonProps> = (props) => {
	// Destructure the props object to get the individual values
	const {
		onClick,
		text,
		color = "primary",
		size = "md",
		shape = "flat",
		icon,
		iconPosition = "before",
		iconStyle = "regular",
		iconOrder = 1,
		additionalClasses,
		type = "button",
		to,
		href,
		tooltip,
		isDisabled = false,
		hasShadow = false,
	} = props;
	const { darkMode } = useDarkMode();

	// Define the buttonClasses variable based on the values of the props
	let buttonClasses = ` form-button form-button-shape--${shape} btn--size-${size} btn--icon-position-${iconPosition} btn--shape-${shape} transition ease-in-out font-light inline-flex gap-2 whitespace-nowrap
  	${
		isDisabled
			? "cursor-not-allowed bg-navy-270 border-navy-270"
			: ` cursor-pointer`
	}
	${hasShadow ? `shadow-button shadow-${color}` : ``}
  	${additionalClasses}`;
	let iconClasses = `{${darkMode ? "text-red-500" : "text-red-500"}}`;

	if (iconPosition === "only") {
		buttonClasses += ` px-2.5 py-2`;
		iconClasses += ` fa-fw`;
	} else {
		buttonClasses += ` px-6 py-2`;
	}

	// Depending on the shape of the button, add the appropriate classes to buttonClasses
	switch (shape) {
		case "outline":
			buttonClasses += ` rounded-full border-1 bg-surface border-${color} text-${color}
				${
					isDisabled
						? ""
						: `hover:bg-${color} hover:border-${color} hover:text-white`
				} `;
			break;
		case "flat":
			buttonClasses += ` rounded-full border-1  border-${color} text-${color}
				${
					isDisabled
						? ""
						: `hover:bg-primaryDefaultHover hover:border-${color} hover:bg-primaryDark`
				}`;
			break;
		case "underline":
			buttonClasses += ` rounded-sm border-1 bg-surface border-surface border-b-${color} text-${color} ${
				isDisabled ? "" : `hover:rounded hover:border-${color}`
			}`;
			break;
		case "clear":
			buttonClasses += ` rounded-full border-1 bg-surface border-surface text-${color} ${
				isDisabled ? "" : `hover:border-${color}`
			}`;
			break;
		case "semi-rounded":
			buttonClasses += ` rounded-md border-1 bg-surface border-surface text-${color} ${
				isDisabled ? "" : `hover:border-${color}`
			}`;
			break;
	}

	const handleClick = (e: React.MouseEvent) => {
		if (
			isDisabled ||
			e.currentTarget.classList.contains("pointer-events-none")
		) {
			e.preventDefault();
			return;
		}

		onClick && onClick(e);
	};

	if (to) {
		// If the 'to' prop is provided, render a Link component
		return (
			<Link
				data-testid="form-button"
				className={`${buttonClasses} text-black ${
					isDisabled ? "pointer-events-none" : ""
				}`}
				to={to}
				onClick={handleClick}
				data-tooltip={tooltip}
				data-is-disabled={isDisabled}
			>
				{icon && (
					<span className={`font-${iconStyle} `}>
						<>{icon}</>
					</span>
				)}
				<span
					className={`${
						darkMode ? "text-secondary" : "text-primary"
					}`}
				>
					{text}
				</span>
			</Link>
		);
	} else {
		// Otherwise, render an anchor element
		return (
			<a
				data-testid="form-button"
				className={`${buttonClasses} ${
					isDisabled ? "pointer-events-none" : ""
				}`}
				onClick={handleClick}
				type={type}
				data-tooltip={tooltip}
				data-is-disabled={isDisabled}
				href={href}
			>
				{icon && (
					<span
						className={`font-${iconStyle} order-${iconOrder}   ${
							darkMode ? "text-secondary" : ""
						}`}
					>
						<> {icon}</>
					</span>
				)}
				<span className={`${darkMode ? "text-secondary" : ""}`}>
					{text}
				</span>
			</a>
		);
	}
};

export default Button;
