// GENERAL
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

// COMPONENTS
import BaseModal from "../../../../../components/layout/Modal";
import { Guardrail } from "../../../../Items/types";
import {
	BaseButton,
	BaseContainer,
	BaseText,
	BorderGradientContainer,
} from "../../../../../components/ui";
import SupplyFormGuardrail from "../../../../../components/misc/SupplyFormGuardrail";
import ToggleButton from "../../../../../components/ui/ToggleButton";
import ModalContentSection from "../../../../../components/layout/Modal/ModalContentSection";
import Image from "../../../../../components/layout/ImageComponent";
import LoadingMessage from "../../../../../components/misc/LoadingMessage";

// UTILS, HOOKS, API, ASSETS, TYPES
import {
	getFontAwesomeIcon,
	handleGuardrailCloseAction,
} from "../../../../../utils";
import placeholderImage from "../../../../../assets/placeholder-no-image-available.png";
import dummyCreateVendorItemFields from "../create/DUMMYCREATEVENDORITEMFIELDS.json";
import { createVendorItem } from "../../../api/vendorItemsApi";
import { getData, getIntegrationData } from "../../../../Items/api/itemsApi";
import {
	CreateVendorItemModalProps,
	Vendor,
	CreateVendorItemFormData,
	VendorOptions,
	VendorItemDescription,
} from "../types";
import { useToaster } from "../../../../../hooks/useToasterContext";
import SupplyPortal from "../../../../../components/misc/SupplyPortal";
const CreateVendorItemModal = ({
	isOpen,
	handleCreateVendorItemModalClick,
}: CreateVendorItemModalProps) => {
	const [guardrail, setGuardrail] = useState<Guardrail | null>(null);
	const [toggleStatus, setToggleStatus] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [vendorItem, setVendorItem] = useState<any>({});
	const [vendorsOptions, setVendorsOptions] = useState<VendorOptions | null>(
		null
	);
	const [opsimOptions, setOpsimOptions] = useState<any | null>(null);
	const [hasErrors, setHasErrors] = useState(false);
	const { addToaster, clearAllToasters } = useToaster();

	const formMethods = useForm<CreateVendorItemFormData>({
		mode: "all",
		defaultValues: {
			overridePoSubmissionIntegration: { uuid: "", name: [] },
		},
	});

	const {
		reset,
		handleSubmit,
		watch,
		setValue,
		getValues,
		formState: { dirtyFields, errors, isValid },
	} = formMethods;

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				// Fetching data for dropdowns
				const vendorsData = await getData("vendors", [
					"uuid",
					"name",
					"number",
				]);

				const personas = await getData("personas");
				const kits = await getData("bundles");
				const items = await getData("items", [
					"uuid",
					"partNumber",
					"title",
					"manufacturer.name",
				]);

				const itemsData = items.map((item) => ({
					uuid: item.uuid,
					name: [
						`${item.title}`,
						`${item.manufacturer?.name} - Part #: ${item?.partNumber}`,
						`${item.uuid}`,
					],
				}));

				const itemsDescriptionData = items.map((item) => ({
					uuid: item.uuid,
					name: item.title,
				}));

				const vendorsNameData = vendorsData.map((vendor) => ({
					uuid: vendor.uuid,
					name: [
						`${vendor.name}`,
						`${vendor.number}`,
						`${vendor.uuid}`,
					],
				}));
				setVendorsOptions({
					vendorsData: vendorsNameData,
					personas: personas,
					kits: kits,
					itemsData: itemsData,
					itemsDescriptionData: itemsDescriptionData,
				});
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, []);

	const item = watch("item");
	const vendor = watch("vendor");
	const clearField = () => {
		setValue(
			"overridePoSubmissionIntegration",
			{ uuid: "", name: [] },
			{
				shouldDirty: false,
			}
		);
	};

	useEffect(() => {
		if (item && vendorsOptions) {
			const vendorDescription = vendorsOptions.itemsDescriptionData.find(
				(description: VendorItemDescription) =>
					description.uuid === item.uuid
			);
			setValue("vendorDescription", vendorDescription?.name ?? "");
		}
	}, [item, vendorsOptions]);

	useEffect(() => {
		if (vendor) {
			const fetchData = async () => {
				try {
					const vendorUuid = vendor.uuid;
					// Pass the vendorId based on the selection from the dropdown
					const overridePoSubmissionIntegrationMethods =
						await getIntegrationData(vendorUuid);

					const overridePoSubmissionIntegrationMethodData =
						overridePoSubmissionIntegrationMethods.map(
							(integration) => ({
								uuid: integration.uuid,
								name: [
									`${integration.type}`,
									`${integration.name}`,
									`${integration.uuid}`,
								],
							})
						);

					setOpsimOptions({
						overridePoSubmissionIntegrationMethods:
							overridePoSubmissionIntegrationMethodData,
					});

					clearField();
				} catch (error) {
					console.error("Error fetching data:", error);
				}
			};
			fetchData();
		} else if (vendor === null) {
			setOpsimOptions(null);
			clearField();
		}
	}, [vendor]);

	const formClassNames = "flex justify-around w-full max-md:flex-col  gap-10";
	const modalContainerClassNames =
		"fixed inset-0 flex items-end justify-center max-w-[1200px] mx-auto modal-bottom z-[9920]";
	const modalBorderClassNames = `modal-box min-w-wideModal min-h-[95dvh] p-0 `;
	const modalOverLayClassNames = "fixed inset-0 bg-black z-[9910]";

	// HEADER CLASSES:
	const headerTitleAndButtonsContainerClassNames =
		"flex items-start max-lg:flex-col justify-between w-full pb-2";
	const headerTitlesContainerClassNames =
		"flex w-2/3 max-lg:w-full max-lg:ml-1 bg-white justify-between";
	const headerButtonContainerClassNames =
		"flex w-1/3 justify-end  max-lg:pt-3 max-lg:justify-start";

	const guardrailBackgroundClassNames =
		"bg-black opacity-10 absolute z-[9920] w-full left-0 top-0 ";

	const updateGuardrails = (newGuardrail: Guardrail) => {
		setGuardrail(newGuardrail);
	};

	const handleCloseAction = () => {
		handleGuardrailCloseAction(
			dirtyFields,
			updateGuardrails,
			reset,
			clearAllToasters,
			setGuardrail,
			handleCreateVendorItemModalClick,
			"Do you want to create this new vendor item?",
			"Your new vendor item will be lost if you don’t create it.",
			"Don't Create",
			"Cancel",
			"Create Item"
		);
	};

	const handleGuardrailSave = () => {
		handleSubmit(onFormSubmit)();
		setGuardrail(null);
	};

	const handleGuardrailDoNotSave = () => {
		reset();
		setGuardrail(null);
		handleCreateVendorItemModalClick();
		document.body.classList.remove("overflow-hidden");
	};

	const onFormSubmit = async (data: CreateVendorItemFormData) => {
		const itemData = {
			uuid: data.item.uuid,
		};
		let overridePoSubmissionIntegration;

		if (
			data.overridePoSubmissionIntegration &&
			data.overridePoSubmissionIntegration.uuid === ""
		) {
			data.overridePoSubmissionIntegration = null;
		} else if (data.overridePoSubmissionIntegration) {
			overridePoSubmissionIntegration = {
				uuid: data.overridePoSubmissionIntegration.uuid,
			};
		}
		const vendorNameData = {
			uuid: data.vendor.uuid,
		};

		const newData = {
			...data,
			isActive: toggleStatus,
			item: itemData,
			overridePoSubmissionIntegration,
			vendor: vendorNameData,
		};

		const response = await createVendorItem(newData);

		if (response.status === 201) {
			addToaster({
				isOpen: true,
				message: "Vendor item created",
				status: "success",
			});
			setHasErrors(false);
			setGuardrail(null);
			handleCreateVendorItemModalClick();
			document.body.classList.remove("overflow-hidden");
			reset();
		} else {
			addToaster({
				isOpen: true,
				message: "An error took place",
				status: "error",
			});
		}
	};

	useEffect(() => {
		const containsErrors = Object.keys(errors).length > 0;

		if (containsErrors) {
			setHasErrors(true);
		} else {
			setHasErrors(false);
		}
	}, [errors]);

	return (
		<AnimatePresence>
			{isOpen && (
				<>
					<motion.div
						className={modalContainerClassNames}
						initial={{ y: "100vh" }}
						animate={{ y: "-3vh" }}
						exit={{ y: "100vh" }}
						transition={{
							duration: 0.1,
							type: "spring",
							stiffness: 100,
							damping: 15,
						}}
					>
						<div className="min-w-full">
							<div className={modalBorderClassNames}>
								{isLoading || !vendorsOptions ? (
									<div className="flex justify-center items-center min-h-svh w-full">
										<LoadingMessage message={""} />
									</div>
								) : (
									<motion.div>
										{guardrail && (
											<SupplyPortal containerId="portal-root">
												<div
													className={
														guardrailBackgroundClassNames
													}
													style={{
														height: "100vh",
														width: "100vw",
													}}
												>
													<SupplyFormGuardrail
														buttonText={guardrail}
														messages={guardrail}
														onDoNotSave={
															handleGuardrailDoNotSave
														}
														onCancel={() =>
															setGuardrail(null)
														}
														onSave={
															handleGuardrailSave
														}
													/>
												</div>
											</SupplyPortal>
										)}
										<BaseModal
											containerClassNames="bg-white overflow-auto min-h-[100%] w-full max-h-[80%] p-5"
											header={
												<>
													<div
														className={
															headerTitleAndButtonsContainerClassNames
														}
													>
														<div
															className={
																headerTitlesContainerClassNames
															}
														>
															<BaseText
																size={"text-xl"}
																color={""}
																text={
																	"Create Vendor Item"
																}
																fontFamily="font-medium"
																tag={"h2"}
															/>
															<div className="hidden max-lg:flex">
																{
																	<BaseButton
																		text=""
																		color="white"
																		fontColor="navy-300"
																		borderColor="none"
																		hoverFontColor="navy-500"
																		additionalClasses="p-2 mx-4px"
																		size="sm"
																		shape="rounded"
																		iconLabel="Close"
																		icon={getFontAwesomeIcon(
																			"Xmark",
																			"regular"
																		)}
																		as="button"
																		onClick={
																			handleCloseAction
																		}
																		iconSize="text-lg pr-0 size-3"
																	/>
																}
															</div>
														</div>
														<div
															className={
																headerButtonContainerClassNames
															}
														>
															{
																<ToggleButton
																	initialStatus={
																		toggleStatus
																	}
																	onClick={() => {
																		setToggleStatus(
																			!toggleStatus
																		);
																	}}
																	textPosition={
																		"right"
																	}
																	textSize={
																		"md"
																	}
																	activeColorBackground="bg-mint-600"
																	inactiveColorBackground="bg-crimson-500"
																	activeTextColor="text-mint-700"
																	inactiveTextColor="text-crimson-700"
																	hasDisabledStatus={
																		false
																	}
																	additionalClasses={`w-1/4 py-2`}
																	hasDivider={
																		true
																	}
																	fontFamily="omnes-medium"
																	activeLabel={
																		"Active"
																	}
																	inactiveLabel={
																		"Inactive"
																	}
																	id={
																		"isActive"
																	}
																	pillHeight="h-7"
																	smallToggle={
																		false
																	}
																	borderStyle={
																		false
																	}
																/>
															}
															<div className="flex flex-col">
																<div className="flex">
																	<BaseButton
																		text="Cancel"
																		color="white"
																		hoverBackground="primaryHoverLight"
																		fontColor="primary"
																		borderColor="primary"
																		additionalClasses="py-1 px-3 mx-2 max-sm:mx-1"
																		size="sm"
																		shape="rounded"
																		as="button"
																		onClick={() =>
																			handleCloseAction()
																		}
																		fontFamily="omnes-medium"
																		customWidth="w-67px"
																		customHeight="h-26px"
																	/>

																	<BaseButton
																		type="button"
																		text="Create Item"
																		color="primary"
																		fontColor="white"
																		hoverBackground="primaryHover"
																		borderColor="none"
																		additionalClasses="py-1 px-3 mx-2 max-sm:mx-1 text-nowrap"
																		size="sm"
																		shape="rounded"
																		as="button"
																		fontFamily="omnes-medium"
																		onClick={handleSubmit(
																			onFormSubmit
																		)}
																		customWidth=""
																		customHeight="h-26px"
																		isDisabled={
																			!isValid
																		}
																	/>
																	<div className="flex max-lg:hidden">
																		{
																			<BaseButton
																				text=""
																				color="white"
																				fontColor="navy-300"
																				borderColor="none"
																				hoverFontColor="navy-500"
																				additionalClasses="p-2 mx-4px"
																				size="sm"
																				shape="rounded"
																				iconLabel="Close"
																				icon={getFontAwesomeIcon(
																					"Xmark",
																					"regular"
																				)}
																				as="button"
																				onClick={
																					handleCloseAction
																				}
																				iconSize="text-lg pr-0 size-3"
																			/>
																		}
																	</div>
																</div>
																{hasErrors && (
																	<div className="flex items-start">
																		<p className="text-xs text-crimson-700">
																			Please
																			review
																			your
																			details
																			and
																			try
																			again.
																		</p>
																	</div>
																)}
															</div>
														</div>
													</div>
												</>
											}
											isOpen={isOpen}
											onClose={
												handleCreateVendorItemModalClick
											}
											children={
												<div className="bg-white mb-36">
													<AnimatePresence>
														<motion.div
															key="motionDiv2"
															initial={{
																opacity: 0,
															}}
															animate={{
																opacity: 1,
															}}
															transition={{
																ease: "easeIn",
																duration: 0.4,
															}}
														>
															<FormProvider
																{...formMethods}
															>
																<form
																	className={
																		formClassNames
																	}
																	onSubmit={handleSubmit(
																		onFormSubmit
																	)}
																>
																	<div className="flex justify-around w-full max-md:flex-col gap-8 px-10 max-lg:px-2">
																		<BaseContainer
																			columnContainerClasses="flex flex-col w-1/2 mr-2 max-md:w-full"
																			columnShape="rounded-full"
																			borderColor="transparent"
																			isEnabled={
																				true
																			}
																			children={
																				<>
																					<div className="flex items-center justify-center flex-col mb-6">
																						<Image
																							imageUrl={
																								placeholderImage
																							}
																							altText="No Image Available"
																							className="items-center size-54"
																						/>
																					</div>
																				</>
																			}
																		/>
																		<BorderGradientContainer
																			containerWidth="w-1/2 max-md:w-full"
																			startBorderColor="from-gradientStart"
																			endBorderColor="to-gradientEnd"
																			containerShape="rounded-full"
																			children={
																				<BaseContainer
																					columnContainerClasses="flex flex-col py-3.5 px-6 h-full max-md:w-full"
																					backgroundColor="bg-white"
																					borderColor="transparent"
																					columnShape="rounded-full"
																					isEnabled={
																						true
																					}
																					children={
																						<>
																							<ModalContentSection
																								sectionTitle={
																									<BaseText
																										size={
																											"text-md"
																										}
																										color={
																											"text-slate-950"
																										}
																										text={
																											"Overview"
																										}
																										fontFamily={
																											"font-omnes-medium"
																										}
																										tag={
																											"h2"
																										}
																										additionalClasses="pb-4"
																									/>
																								}
																								data={
																									vendorItem
																								}
																								selectOptions={
																									vendorsOptions
																								}
																								dataLabel="OVERVIEW"
																								fields={
																									dummyCreateVendorItemFields
																										.DUMMYCREATEVENDORITEMFIELDS[0]
																										.OVERVIEW
																								}
																								itemContainerClasses="w-full "
																								sectionContainerClasses="flex flex-col items-start"
																								detailsContainerClasses="items-start w-full"
																								isEnabled={
																									true
																								}
																								valueType={
																									"input"
																								}
																							/>
																							<ModalContentSection
																								sectionTitle={
																									<div className="flex pb-4">
																										<BaseText
																											size={
																												"text-md"
																											}
																											color={
																												"text-slate-950"
																											}
																											text={
																												"Costs"
																											}
																											fontFamily={
																												"font-omnes-medium"
																											}
																											tag={
																												"h2"
																											}
																										/>
																									</div>
																								}
																								valueType={
																									"input"
																								}
																								data={
																									vendorItem
																								}
																								dataLabel="COSTS"
																								fields={
																									dummyCreateVendorItemFields
																										.DUMMYCREATEVENDORITEMFIELDS[1]
																										.COSTS
																								}
																								itemContainerClasses="w-full"
																								sectionContainerClasses="flex flex-col items-start pt-28px"
																								detailsContainerClasses="grid grid-cols-2 items-start w-full max-lg:flex max-lg:flex-col"
																								isEnabled={
																									true
																								}
																							/>
																							<ModalContentSection
																								sectionTitle={
																									<BaseText
																										size={
																											"text-md"
																										}
																										color={
																											"text-slate-950"
																										}
																										text={
																											"Attributes (Optional)"
																										}
																										fontFamily={
																											"font-omnes-medium"
																										}
																										tag={
																											"h2"
																										}
																										additionalClasses="pb-4"
																									/>
																								}
																								valueType="input"
																								data={
																									vendorItem
																								}
																								dataLabel="ATTRIBUTES"
																								fields={
																									dummyCreateVendorItemFields
																										.DUMMYCREATEVENDORITEMFIELDS[2]
																										.ATTRIBUTES
																								}
																								selectOptions={
																									opsimOptions
																								}
																								itemContainerClasses="w-full"
																								sectionContainerClasses="flex flex-col items-start pt-28px"
																								detailsContainerClasses="items-start w-full"
																								isEnabled={
																									true
																								}
																							/>
																						</>
																					}
																				/>
																			}
																		/>
																	</div>
																</form>
															</FormProvider>
														</motion.div>
													</AnimatePresence>
												</div>
											}
										/>
									</motion.div>
								)}
							</div>
						</div>
					</motion.div>
					<>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 0.3 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.3 }}
							className={modalOverLayClassNames}
						></motion.div>
					</>
				</>
			)}
		</AnimatePresence>
	);
};

export default CreateVendorItemModal;
