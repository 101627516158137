import React from 'react';
import MainHeading from './MainHeading';
import { Link } from 'react-router-dom';

interface UnavailableFeatureProps {
	icon?: React.ReactNode;
	title: string;
	message: string;
	message2: string;
	emailLink?: string;
	emailText?: string;
	containerClass?: string;
	iconClass?: string;
	titleClass?: string;
	messageClass?: string;
	linkClassNames?: string;
}

const UnavailableFeature: React.FC<UnavailableFeatureProps> = ({
	icon,
	title,
	message,
	message2,
	emailLink,
	emailText,
	containerClass = '',
	iconClass = '',
	titleClass = '',
	messageClass = '',
	linkClassNames = '',
}) => (
	<div className={`${containerClass}`}>
		<div className={iconClass}>
			<div>{icon}</div>
		</div>
		<MainHeading text={title} additionalClasses={titleClass} />
		<div className={messageClass}>
			<div>
				<div>{message}</div>
				<div>
					{message2}
					<a href={emailLink} className={`text-primary ml-1 ${linkClassNames}`}>
						{emailText}
					</a>
				</div>
			</div>
		</div>
		<div className={linkClassNames}>
			<Link to={"/login"}>Return to Login</Link>
		</div>
	</div>
);

export default UnavailableFeature;
