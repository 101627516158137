import React from "react";

interface SingleLineShimmerProps {
	width?: string;
	height?: string;
	margin?: string;
	hasBorderBottom?: boolean;
	borderColor?: string;
	randomWidth?: boolean;
	minWidth?: number; // Minimum fraction numerator for random width (e.g., 1 for 1/8)
	maxWidth?: number; // Maximum fraction numerator for random width (e.g., 5 for 5/8)
	widthFraction?: number; // Denominator for width fraction (e.g., 8 for 1/8 width options)
	key?: string | number;
	color: string;
}

const getRandomWidthPercentage = (
	min: number,
	max: number,
	fraction: number
) => {
	const randomNumerator = Math.floor(Math.random() * (max - min + 1) + min);
	return `${(randomNumerator / fraction) * 100}%`;
};

const SingleLineShimmer: React.FC<SingleLineShimmerProps> = ({
	width = "w-1/5",
	height = "h-3",
	margin = "m-2",
	hasBorderBottom = false,
	borderColor = "border-gray-100",
	randomWidth = false,
	minWidth = 1,
	maxWidth = 5,
	widthFraction = 5,
	color = "bg-gray-100",
}) => {
	const finalWidthStyle = randomWidth
		? { width: getRandomWidthPercentage(minWidth, maxWidth, widthFraction) }
		: undefined;

	return (
		<>
			<div className={`${margin} ${width}`} style={finalWidthStyle}>
				<div
					className={`${height} ${color} rounded-lg  relative overflow-hidden`}
				>
					<div className="absolute inset-0 -translate-x-full bg-gradient-to-r from-transparent via-white/70 to-transparent animate-shimmer"></div>
				</div>
			</div>
			{hasBorderBottom && (
				<div className={`w-full border-b ${borderColor}`}></div>
			)}
		</>
	);
};

export default SingleLineShimmer;
