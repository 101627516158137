const handleGuardrailCloseAction = (
	dirtyFields: Record<string, any>,
	updateGuardrails: (args: {
		isOpen: boolean;
		messageOne: string;
		messageTwo: string;
		leftButtonText: string;
		centerButtonText: string;
		rightButtonText: string;
	}) => void,
	reset: () => void,
	clearAllToasters: () => void,
	setGuardrail: (value: any) => void,
	handleCreateVendorItemModalClick: () => void,
	messageOne: string,
	messageTwo: string,
	leftButtonText: string = "Don't Create",
	centerButtonText: string = "Cancel",
	rightButtonText: string = "Create Item"
) => {
	if (Object.keys(dirtyFields).length > 0) {
		updateGuardrails({
			isOpen: true,
			messageOne,
			messageTwo,
			leftButtonText,
			centerButtonText,
			rightButtonText,
		});
	} else {
		reset();
		clearAllToasters();
		setGuardrail(null);
		handleCreateVendorItemModalClick();
		document.body.classList.remove("overflow-hidden");
	}
};

export default handleGuardrailCloseAction;
