import React from "react";
import Section from "./Section";
import { useDarkMode } from "../../hooks";

interface FooterProps {
	companyName: string;
}

const Footer: React.FC<FooterProps> = ({ companyName }) => {
	const { darkMode } = useDarkMode();
	let date = new Date().getFullYear();
	return (
		<footer
			className={`${
				darkMode
					? "bg-primary text-black glass"
					: "bg-footerBg relative z-30 text-white"
			}  text-center`}
		>
			<Section>
				{/* TODD: add chat icon back after chat works */}
				{/* <main className="fixed right-0 bottom-4 max-md:relative">
					<img src={liveChat} alt="live chat icon" />
				</main> */}
				<h2 className="m-0 font-light">
					<span className="px-1">©</span>
					<span>{date}</span> {companyName}
				</h2>
			</Section>
		</footer>
	);
};

export default Footer;
