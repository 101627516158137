import React from "react";
import BaseText from "./BaseText";

interface BaseAddressTypes {
	additionalClasses?: string;
	addressLines?: string | string[];
	title?: string;
	contact?: string;
}

const BaseAddress: React.FC<BaseAddressTypes> = ({
	addressLines = [],
	additionalClasses,
	title = "Location",
}) => {
	const addressArray = Array.isArray(addressLines)
		? addressLines
		: [addressLines];
	const validAddress = addressArray.map((line: string) =>
		!line || line === "N/A" || line.includes("null") ? "" : line
	);

	if (validAddress.length === 0) {
		return (
			<div className={`flex items-center ${additionalClasses}`}>
				<div className="flex items-start pl-2 font-omnes-medium text-sm">
					<p className="text-left font-omnes-medium">
						No Address Data Provided
					</p>
				</div>
			</div>
		);
	}

	return (
		<div className={`flex flex-col items-start ${additionalClasses}`}>
			<BaseText
				text={title}
				size={"text-sm"}
				color={"text-navy-500"}
				tag={"h2"}
				fontFamily={"font-omnes-medium"}
			/>
			<div className="flex flex-col items-start font-omnes-regular text-sm">
				{validAddress.map((line: string, index: number) => (
					<p key={index} className="font-omnes-regular text-left">
						{line}
					</p>
				))}
			</div>
		</div>
	);
};

export default BaseAddress;
