import React from "react";
import BaseText from "../../../../../components/ui/BaseText";
import BaseContainer from "../../../../../components/ui/BaseContainer";
import { BundleFeeProps } from "./types";

const BundleFee: React.FC<BundleFeeProps> = ({ bundleFee }) => {
	return (
		<div className="ml-2 text-left py-1">
			<BaseContainer
				key={bundleFee.uuid}
				columnContainerClasses="rounded-lg w-full border-transparent"
				columnShape="semi-rounded"
				isEnabled={true}
			>
				<div className="flex justify-between items-center w-full">
					<div className="flex flex-col text-left">
						<BaseText
							text={bundleFee.Items.description}
							color="text-gray-700"
							size="text-sm"
							fontFamily="font-inter-medium mb-1"
							tag="p"
						/>
						<BaseText
							text={`$ ${bundleFee.price}/mo`}
							size="text-sm"
							color="text-gray-700"
							fontFamily="font-inter-regular font-medium"
							tag="p"
						/>
					</div>
				</div>
			</BaseContainer>
		</div>
	);
};

export default BundleFee;
