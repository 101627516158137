import React from "react";
import { motion } from "framer-motion";
import { LoadingMessage } from "../misc";

interface ValidationMessageProps {
	isValidating: boolean;
	isValid: boolean;
	loadingMessage: string;
	validMessage?: string;
	notValidMessage?: string;
	loadingIconClasses?: string;
	loadingMessageClasses?: string;
	loadingContainerClasses?: string;
	notValidMessageClasses?: string;
	validMessageClasses?: string;
	notValidIconClasses?: string;
	validIconClasses?: string;
	notValidIcon?: JSX.Element | null;
	validIcon?: JSX.Element | null;
	placeholderMessage: string;
	placeholderMessageClasses?: string;
	loadingMotionContainerClasses?: string;
}

const FormValidationLoadingMessage: React.FC<ValidationMessageProps> = ({
	isValidating,
	isValid,
	loadingMessage,
	loadingMessageClasses,
	loadingIconClasses,
	loadingContainerClasses,
	validMessage,
	notValidMessage,
	notValidMessageClasses,
	validMessageClasses,
	validIconClasses,
	notValidIconClasses,
	validIcon,
	notValidIcon,
	placeholderMessage,
	placeholderMessageClasses,
	loadingMotionContainerClasses,
}) => {

	if (isValidating) {
		return (
			<motion.div
				initial={{ opacity: 1 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{
					duration: 0.4,
					ease: "easeInOut",
				}}
				className={loadingMotionContainerClasses}
			>
				<LoadingMessage
					additionalClasses={loadingIconClasses}
					message={loadingMessage}
					containerClasses={loadingContainerClasses}
					messageAdditionalClasses={loadingMessageClasses}
				/>
			</motion.div>
		);
	} else if (isValid && !isValidating) {
		return (
			<div className="flex items-center">
				<span className={validIconClasses}>{validIcon}</span>
				<p className={validMessageClasses}>{validMessage}</p>
			</div>
		);
	} else if (!isValid && !isValidating) {
		return (
			<div className="flex items-center">
				<span className={notValidIconClasses}>{notValidIcon}</span>
				<p className={notValidMessageClasses}>{notValidMessage}</p>
			</div>
		);
	} else {
		return (
			<p className={placeholderMessageClasses}>{placeholderMessage}</p>
		);
	}
};

export default FormValidationLoadingMessage;
