import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { getFontAwesomeIcon } from "../../utils";
import { fadeInOutVariants } from "../../motionVariants";

type SupplyToasterProps = {
	isOpen: boolean;
	message: string;
	classNames?: string;
};

const SupplyToaster: React.FC<SupplyToasterProps> = ({
	isOpen,
	message = "",
	classNames = "bg-navy-300 border-1 border-navy-600 text-navy-800 rounded-lg",
}) => {
	// State to manage visibility for animation
	const [isVisible, setIsVisible] = useState(isOpen);
	const handelClose = () => {
		setIsVisible(false);
	};
	const supplyToasterClasses = `${classNames} p-4 rounded-md z-[9925] flex justify-between items-center `;
	return (
		<AnimatePresence>
			{isVisible && (
				<motion.div
					{...fadeInOutVariants}
					className={supplyToasterClasses}
				>
					<div>
						<span className="pr-2">
							{getFontAwesomeIcon("circleCheck", "solid")}
						</span>
						<span>{message}</span>
					</div>
					<div
						onClick={handelClose}
						className="text-xs pl-2 hover:cursor-pointer"
					>
						{getFontAwesomeIcon("x", "solid")}
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default SupplyToaster;
