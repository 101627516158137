export const dummyFields: any = {
	APPROVALDETAILROWS: [
		{
			APPROVALCONTENTHEADERFIELDS: [
				{
					id: "1",
					label: "Order Details",
					valueKey: "",
				},
				{
					id: "2",
					label: "Ordered on",
				},
				{
					id: "3",
					label: "submitted by",
				},
				{
					id: "4",
					label: "Order #",
				},
			],
		},
		{
			APPROVALCONTENTFIELDS: [
				{
					id: "5",
					label: "Order for",
				},
				{
					id: "6",
					label: "Shipping Address",
				},
				{
					id: "7",
					label: "Shipping Method",
				},
				{
					id: "8",
					label: "Notifications",
				},
				{
					id: "9",
					label: "Assigned Manager",
				},
				{
					id: "10",
					label: "Reason for Request",
				},
				{
					id: "11",
					label: "Priority",
				},
				{
					id: "12",
					label: "Cost Center",
				},
				{
					id: "13",
					label: "Order Summary",
				},
				{
					id: "14",
					label: "Subtotal",
				},
				{
					id: "15",
					label: "Est. Tax",
					tooltip: "A projected cost based on current data.",
					icon: "infoCircle",
					iconStyle: "solid",
				},
				{
					id: "16",
					label: "Shipping",
					tooltip: "A projected cost based on current data.",
					icon: "infoCircle",
					iconStyle: "solid",
				},
				{
					id: "17",
					label: "Total",
				},
				{
					id: "18",
					label: "Recurring Charges",
				},
				{
					id: "19",
					label: "Computer Support",
				},
				{
					id: "20",
					label: "Total",
				},
			],
		},
		{
			DYNAMICITEMSFIELDS: [
				{
					id: "21",
					label: "Items",
				},
			],
		},
	],
};
