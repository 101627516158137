import React from "react";
import ReactDOM from "react-dom";

interface PortalProps {
	children: React.ReactNode;
	containerId: string;
}

const SupplyPortal: React.FC<PortalProps> = ({ children, containerId }) => {
	const container = document.getElementById(containerId);

	if (!container) {
		console.warn(`No element with ID '${containerId}' found for Portal.`);
		return null;
	}

	return ReactDOM.createPortal(children, container);
};

export default SupplyPortal;
