import React from 'react';
import { LoadingMessage } from "../../../../components/misc";
import { AnimatePresence, motion } from 'framer-motion';
interface FulfilledShipLoadingScreenProps {
	loadingMessage: string;
	containerClasses?: string;
}

const FulfilledShipLoadingScreen: React.FC<FulfilledShipLoadingScreenProps> = ({
	loadingMessage = "Loading...",
	containerClasses,
}) => {
	return (
		<AnimatePresence>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{
					duration: 0.2,
					ease: "easeInOut",
				}}
				className={containerClasses}
			>
				<LoadingMessage message={""} />
				<p className="pt-4">{loadingMessage}</p>

			</motion.div>
		</AnimatePresence>
	);
};

export default FulfilledShipLoadingScreen;