// GENERAL
import React, { Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useState } from "react";

// COMPONENTS
import CardTable from "../../../../../../components/ui/CardTable/CardTable";
import BaseButton from "../../../../../../components/ui/BaseButton";
import { BaseToolTip } from "../../../../../../components/ui";
import LoadingMessage from "../../../../../../components/misc/LoadingMessage";
import { SupplyFormGuardrail } from "../../../../../../components/misc";
import FulfilledShipLoadingScreen from "../../FulfilledShipLoadingScreen";

// TYPES, FIELDS, UTILS, HOOKS
import dummyCardTableFormFields from "./DUMMYCARDTABLEFIELDS.json";
import { getFontAwesomeIcon } from "../../../../../../utils";
import {
	ShipmentsCardTableFormTypes,
	ShipmentCardTableData,
	ShipmentCardTableFormValues,
	FlattenedData,
} from "../../../types";
import { AnimatePresence, motion } from "framer-motion";
import {
	deleteShipments,
	handleReprintPdf,
} from "../../../../api/ShipmentsApi";
import { formatShipmentData } from "../utils/formatShipmentData";
import {
	fulfillShipment,
	handleShipmentDownload,
} from "../../../../../UpdateShipment/api/UpdateShipmentApi";
import { useToaster } from "../../../../../../hooks/useToasterContext";

const ShipmentsCardTableForm: React.FC<ShipmentsCardTableFormTypes> = ({
	data,
	onFetchData,
	isFulfilledShipmentsView,
}) => {
	const [isGuardrailOpen, setIsGuardrailOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isFulfillAndShipLoading, setIsFulfillAndShipLoading] =
		useState(false);
	const query = new URLSearchParams(useLocation().search);
	const internalId = query.get("internalId");
	const [rowsClicked, setRowsClicked] = useState<string[]>([]);
	const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
	const [hoveredShipment, setHoveredShipment] = React.useState<string | null>(
		null
	);
	const { addToaster, clearAllToasters } = useToaster();

	const shipmentItemsUrl = `/shipment-items?internalId=${internalId}`;
	const currentShipmentsUrl = `/shipments?internalId=${internalId}`;
	const fulfilledShipmentsUrl = `/fulfilled-shipments?internalId=${internalId}`;

	/////////////////////////////////////// CONDITIONAL CONTENT //////////////////////////////////////
	const shipmentText = `${data.length} ${
		data.length > 1 ? "shipments" : "shipment"
	}`;
	const fulfillAndShipText = `Fulfill & Ship ${
		rowsClicked.length > 0 ? ` (${rowsClicked.length})` : ""
	}`;

	const printLabelText = `Print Label ${
		rowsClicked.length > 0 ? ` (${rowsClicked.length})` : ""
	}`;

	const guardrailMessageOne = isFulfilledShipmentsView
		? `Reprint label for ${rowsClicked.length} ${
				rowsClicked.length === 1 ? "shipment" : "shipments"
		  }?`
		: `Fulfill and ship ${rowsClicked.length} ${
				rowsClicked.length === 1 ? "shipment" : "shipments"
		  }?`;

	const guardrailMessageTwo = isFulfilledShipmentsView
		? `This will reprint the label for the ${
				rowsClicked.length === 1 ? "shipment" : "shipments"
		  }.`
		: `This will send the ${
				rowsClicked.length === 1 ? "shipment" : "shipments"
		  } to the carrier for label generation and printing.`;
	////////////////////////////////////////////// STYLES ////////////////////////////////////////////
	const cardTableFormContainerClasses =
		"flex flex-col justify-between items-start max-h-[530px] w-full overflow-scroll mb-5";
	const guardrailBackgroundClasses =
		"fixed top-0 left-0 h-screen w-screen bg-black opacity-30 z-50";
	const loadingBackgroundClasses =
		"fixed top-0 left-0 h-screen w-screen z-50";
	const fulfillShipLoadingScreenContainerClasses =
		"flex flex-col items-center justify-center bg-white h-full w-full";

	const buttonColor = rowsClicked.length === 0 ? "navy-200" : "primary";
	const buttonFontColor = rowsClicked.length === 0 ? "navy-400" : "white";
	const isButtonDisabled = rowsClicked.length === 0 || rowsClicked.length > 1;

	const cardContainerClasses = "flex w-[1715px] px-5 py-2.5 mb-2 min-h-24";

	const deleteButtonClasses =
		"text-xl text-supply-blue-400 p-2 hover:text-supply-blue-300";
	const checkBoxClasses = "border-1 border-navy-200 size-4 mt-4 mr-2.5";

	//////////////////////////////////// GUARDRAILS AND TOASTERS ////////////////////////////////////

	const openGuardrail = () => {
		setIsGuardrailOpen(true);
	};

	/////////////////////////////////////////// FORM SETUP ///////////////////////////////////////////
	const { control, handleSubmit, setValue, getValues } = useForm({
		defaultValues: {
			shipments:
				data.map((shipment: ShipmentCardTableData) => ({
					uuid: shipment.uuid,
					isSelected: false,
				})) ?? [],
		},
	});

	// this function finds selected shipments from the form, and compiles all data to be sent to the backend

	const onSubmit = async (
		formAction: string,
		shipmentUuid?: string, // used for cancel and delete only
		itemFulfillmentPackageUuid?: string, // used for cancel and delete only
		trackingUuid?: string // used for cancel and delete only
	) => {
		const selectedShipments = data.filter(
			(shipment: ShipmentCardTableData) =>
				rowsClicked.includes(shipment.uuid)
		);
		let selectedShipmentUuid = selectedShipments[0].uuid;
		let selectedShipment = selectedShipments[0];
		let trackingNumberUuid =
			selectedShipment.itemFulfillmentPackages[0].trackingNumber.uuid;

		const successToasterMessage = `${selectedShipments.length} ${
			selectedShipments.length === 1 ? "shipment" : "shipments"
		} fulfilled & shipped`;

		const errorToasterMessage = `${selectedShipments.length} ${
			selectedShipments.length === 1 ? "shipment" : "shipments"
		} is incomplete`;

		if (formAction === "fulfillAndShip" && !isFulfilledShipmentsView) {
 			setRowsClicked?.([]);
			setIsFulfillAndShipLoading(true);

			try {
				const shipmentResponse = await fulfillShipment(
					selectedShipmentUuid || ""
				);
				// Call the function to download the PDF
				await handleShipmentDownload(shipmentResponse);

				if (shipmentResponse.status === 201) {
					addToaster({
						isOpen: true,
						message: successToasterMessage,
						status: "success",
					});
				}
			} catch (error) {
				addToaster({
					isOpen: true,
					message: errorToasterMessage,
					status: "error",
				});
			} finally {
				setIsFulfillAndShipLoading(false);
			}
		} else if (
			formAction === "delete" &&
			shipmentUuid &&
			trackingUuid &&
			itemFulfillmentPackageUuid &&
			!isFulfilledShipmentsView
		) {
			setIsFulfillAndShipLoading(true);

			try {
				let response = await deleteShipments(
					shipmentUuid,
					itemFulfillmentPackageUuid,
					trackingUuid
				);

				if (response.status === 201) {
					addToaster({
						isOpen: true,
						message: `Successfully deleted shipment`,
						status: "success",
					});
				}

				onFetchData();
			} catch (error) {
				addToaster({
					isOpen: true,
					message: `Failed to delete shipment`,
					status: "error",
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			setIsFulfillAndShipLoading(true);

			// print label again for selected shipment
			try {
				let response = await handleReprintPdf(trackingNumberUuid);

				if (response.status === 201) {
					addToaster({
						isOpen: true,
						message: `Successfully reprinted label`,
						status: "success",
					});
				}
			} catch (error) {
				addToaster({
					isOpen: true,
					message: `Failed to reprint shipment`,
					status: "error",
				});
			}
		}
		setIsGuardrailOpen(false);
		setIsFulfillAndShipLoading(false);
	};

	const deselectAllShipments = () => {
		setRowsClicked?.([]);
	};

	const selectAllShipments = () => {
		const allShipments = getValues("shipments"); // Assuming you have a way to get all shipments
		setValue(
			"shipments",
			allShipments.map((shipment: ShipmentCardTableFormValues) => ({
				...shipment,
				isSelected: true,
			}))
		);
		setRowsClicked?.(
			allShipments.map(
				(shipment: ShipmentCardTableFormValues) => shipment.uuid
			)
		);
		setSelectAllChecked?.(true);
	};

	const handleCheckboxClick = (
		event: React.ChangeEvent<HTMLInputElement>,
		uuid: string
	) => {
		const isChecked = event.currentTarget.checked;

		if (isChecked) {
			setValue("shipments", [
				...getValues("shipments").filter(
					(shipment: ShipmentCardTableFormValues) =>
						shipment.uuid !== uuid
				),
				{
					uuid: uuid,
					isSelected: true,
				},
			]);
		} else {
			setValue(
				"shipments",
				getValues("shipments").filter(
					(shipment: ShipmentCardTableFormValues) =>
						shipment.uuid !== uuid
				)
			);
		}

		setRowsClicked?.((prevRows) => {
			if (isChecked) {
				return prevRows.includes(uuid) ? prevRows : [...prevRows, uuid];
			} else {
				setSelectAllChecked?.(false);
				return prevRows.filter((rowUuid) => rowUuid !== uuid);
			}
		});
	};
	///////////////////////////////////////// FORM CONTENT /////////////////////////////////////////

	const cardTableFormData = React.useMemo(() => {
		return data.map((shipment: any) => {
			const isHovered = hoveredShipment === shipment.uuid;
			const isSelected = rowsClicked.includes(shipment.uuid);

			const statusIcon =
				shipment.dtSubmitted == null ? "boxTaped" : "boxOpen";
			const statusIconBaseClasses = "text-2xl p-2";
			const statusIconDefaultColor =
				shipment.dtSubmitted == null
					? "text-navy-200"
					: "text-navy-270";
			const statusIconSelectedColor = isSelected
				? "text-supply-blue-400"
				: statusIconDefaultColor;
			const statusIconHoveredColor =
				isHovered && shipment.dtSubmitted == null
					? "text-supply-blue-100"
					: "";

			const statusIconClasses = `${statusIconBaseClasses} ${statusIconSelectedColor} ${statusIconHoveredColor}`;

			const checkBoxClassName = `border-1 ${
				isHovered ? "border-supply-blue-200" : "border-navy-200"
			} size-4 mt-4 mr-2.5 cursor-pointer accent-supply-blue-400`;

			const checkBoxContent =
				shipment.dtSubmitted == null ? (
					<Controller
						control={control}
						name={`shipments.${shipment.uuid}`}
						render={({ field }) => (
							<>
								<label
									htmlFor={`shipmentCheckbox-${shipment.uuid}`}
									className="sr-only"
								>
									Select Shipment
								</label>
								<input
									id={`shipmentCheckbox-${shipment.uuid}`}
									{...field}
									type="checkbox"
									checked={rowsClicked?.includes(
										shipment.uuid as string
									)}
									onChange={(event) =>
										handleCheckboxClick(
											event,
											shipment.uuid as string
										)
									}
									className={checkBoxClassName}
								/>
							</>
						)}
					/>
				) : (
					<div className="flex items-center mt-4 mr-2.5">
						<BaseToolTip
							message={"Incomplete"}
							iconSize="text-base"
							icon="infoCircle"
							toolTipPlacement="bottom"
						/>
					</div>
				);
			let flattenedData: FlattenedData = {
				dtSubmitted: shipment.dtSubmitted,
				containsBattery:
					shipment.itemFulfillmentPackages[0]?.trackingNumber
						?.containsBattery,
				requiresSignature:
					shipment.itemFulfillmentPackages[0]?.trackingNumber
						?.requiresSignature,
				dimensions: {
					length:
						shipment.itemFulfillmentPackages[0]?.trackingNumber
							?.length || "",
					height:
						shipment.itemFulfillmentPackages[0]?.trackingNumber
							?.height || "",
					width:
						shipment.itemFulfillmentPackages[0]?.trackingNumber
							?.width || "",
					units: "inches",
				},
				address: {
					addressee: shipment?.shipToAddress?.addressee || "",
					attention: shipment?.shipToAddress?.attention || "",
					line1: shipment?.shipToAddress?.line1 || "",
					line2: shipment?.shipToAddress?.line2 || "",
					city: shipment?.shipToAddress?.city || "",
					state: shipment?.shipToAddress?.state?.code || "",
					zip: shipment?.shipToAddress?.zip || "",
					country:
						shipment?.shipToAddress?.state?.country?.name || "",
				},
				weight:
					shipment.itemFulfillmentPackages[0]?.trackingNumber
						.weight || "",
				description:
					shipment.itemFulfillmentPackages[0]?.trackingNumber
						.description || "",
				quantity: shipment.itemFulfillmentItems[0]?.quantity || "", // Fix this
				uuid: shipment.uuid,
				trackingUuid:
					shipment.itemFulfillmentPackages[0]?.trackingNumber?.uuid,
				carrier:
					shipment.itemFulfillmentPackages[0]?.trackingNumber
						?.shippingCarrier?.name,
				carrierLogo:
					shipment.itemFulfillmentPackages[0]?.trackingNumber
						?.shippingCarrier?.urlLogo,
				shipmentMethod:
					shipment.itemFulfillmentPackages[0]?.trackingNumber
						?.shippingMethod?.name,
				carrierAccountNumber:
					shipment.itemFulfillmentPackages[0]?.trackingNumber
						?.shippingCarrierAccountNumber?.accountNumber,
				itemFulfillmentPackageUuid:
					shipment.itemFulfillmentPackages[0]?.uuid,
			};

			return (
				<Fragment key={shipment.uuid}>
					<CardTable
						cardContainerClasses={cardContainerClasses}
						cardShape="rounded-xl"
						borderColor="navy-200"
						backgroundColor="bg-white"
						hasSecondRow={true}
						dataItem={flattenedData}
						fields={dummyCardTableFormFields.DUMMYCARDTABLEFIELDS}
						firstRowContainerClasses="flex w-full justify-around"
						secondRowContainerClasses="flex w-full justify-start"
						hasCheckBox={checkBoxContent}
						checkBoxClasses={checkBoxClasses}
						hasStatusIcon={statusIcon}
						statusIconStyle={"solid"}
						statusIconClasses={statusIconClasses}
						hasEditButton={false}
						deleteButtonClasses={deleteButtonClasses}
						buttonContainerClasses="justify-end"
						hasDeleteButton={!isFulfilledShipmentsView && true}
 						hasDuplicateButton={false}
						onDeleteClick={() =>
							onSubmit(
								"delete",
								flattenedData.uuid,
								flattenedData.itemFulfillmentPackageUuid,
								flattenedData.trackingUuid
							)
						}
						onCancelClick={() =>
							onSubmit(
								"cancel",
								flattenedData.uuid,
								flattenedData.itemFulfillmentPackageUuid,
								flattenedData.trackingUuid
							)
						}
						onDuplicateClick={() =>
							onSubmit("duplicate", shipment.uuid)
						}
						onMouseEnter={() => setHoveredShipment(shipment.uuid)}
						onMouseLeave={() => setHoveredShipment(null)}
						formatDataFunction={formatShipmentData}
					/>
				</Fragment>
			);
		});
	}, [data, rowsClicked, selectAllChecked, hoveredShipment]);

	return (
		<AnimatePresence>
			<motion.div
				key="motionDiv1"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{
					ease: "easeOut",
					duration: 0.3,
				}}
				className="w-full overflow-scroll"
			>
				<form
					className="flex flex-col w-full overflow-x-scroll"
					onSubmit={handleSubmit(() => {})}
				>
					<div className="flex py-5 justify-between">
						<div className="flex  items-start">
							<BaseButton
								text="Deselect All"
								color=""
								hoverBackground="primaryHoverLight"
								fontColor="primary"
								borderColor="primary"
								additionalClasses="py-1 px-3 mr-2.5"
								size="sm"
								shape="rounded"
								as="button"
								fontFamily="omnes-medium"
								customHeight="h-7"
								customWidth="w-28"
								onClick={deselectAllShipments}
							/>
							{isFulfilledShipmentsView && (
								<BaseButton
									text="Select All"
									color=""
									hoverBackground="primaryHoverLight"
									fontColor="primary"
									borderColor="primary"
									additionalClasses="py-1 px-3 mr-2.5"
									size="sm"
									shape="rounded"
									as="button"
									fontFamily="omnes-medium"
									customHeight="h-7"
									customWidth="w-28"
									onClick={selectAllShipments}
								/>
							)}
							{!isFulfilledShipmentsView && (
								<BaseButton
									text="Shipment"
									color="primary"
									fontColor="white"
									hoverBackground="primaryHover"
									borderColor="none"
									additionalClasses="py-1 px-3 text-nowrap text-sm"
									size="sm"
									shape="rounded"
									icon={getFontAwesomeIcon("plus", "regular")}
									as="link"
									to={shipmentItemsUrl}
									fontFamily="omnes-medium"
									iconPadding="pr-2"
									customHeight="h-7"
									customWidth="w-28"
								/>
							)}
						</div>
						{isFulfilledShipmentsView && (
							<BaseButton
								text="View Current Shipments"
								color=""
								fontColor="primary"
								hoverBackground="primaryHover"
								borderColor="primary"
								hoverFontColor="white"
								additionalClasses="py-1 px-3 text-nowrap text-14px"
								size="sm"
								shape="rounded"
								as="link"
								to={`${currentShipmentsUrl}`}
								fontFamily="omnes-medium"
								iconPadding="pr-2"
								customHeight="h-7"
								customWidth="w-[169px]"
							/>
						)}
						{!isFulfilledShipmentsView && (
							<BaseButton
								text="View Fulfilled Shipments"
								color=""
								fontColor="primary"
								hoverBackground="primaryHover"
								borderColor="primary"
								hoverFontColor="white"
								additionalClasses="py-1 px-3 text-nowrap text-14px"
								size="sm"
								shape="rounded"
								as="link"
								to={`${fulfilledShipmentsUrl}`}
								fontFamily="omnes-medium"
								iconPadding="pr-2"
								customHeight="h-7"
								customWidth="w-[169px]"
							/>
						)}
					</div>
					{isLoading ? (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{
								duration: 0.2,
								ease: "easeInOut",
							}}
							className="flex items-center justify-center w-full"
						>
							<div className="h-full">
								<LoadingMessage message={""} />
							</div>
						</motion.div>
					) : (
						<div className={cardTableFormContainerClasses}>
							{cardTableFormData}
						</div>
					)}
					<div className={"flex w-full justify-between"}>
						<p>{shipmentText}</p>
						<BaseButton
							text={
								isFulfilledShipmentsView
									? printLabelText
									: fulfillAndShipText
							}
							color={buttonColor}
							fontColor={buttonFontColor}
							hoverBackground="primaryHover"
							borderColor="none"
							additionalClasses="py-1 px-5 text-nowrap text-sm"
							size="sm"
							shape="rounded"
							as="button"
							fontFamily="omnes-medium"
							iconPadding="pr-1"
							customHeight="h-7"
							customWidth="w-40"
							onClick={openGuardrail}
							isDisabled={isButtonDisabled}
							icon={
								isFulfilledShipmentsView &&
								getFontAwesomeIcon("print", "regular")
							}
						/>
					</div>
					{isGuardrailOpen && (
						<>
							<div className={guardrailBackgroundClasses}>
								{""}
							</div>
							<SupplyFormGuardrail
								messages={{
									messageOne: guardrailMessageOne,
									messageTwo: guardrailMessageTwo,
									isOpen: true,
								}}
								onSave={() => {
									onSubmit("fulfillAndShip");
								}}
								onCancel={() => setIsGuardrailOpen(false)}
								buttonText={{
									centerButtonText: "Cancel",
									rightButtonText: isFulfilledShipmentsView
										? "Reprint Label"
										: "Fulfill & Ship",
								}}
								rightSideButtonContainerClasses="flex w-full justify-between"
							/>
						</>
					)}
					{isFulfillAndShipLoading && (
						<>
							<div className={loadingBackgroundClasses}>
								{""}
								<FulfilledShipLoadingScreen
									containerClasses={
										fulfillShipLoadingScreenContainerClasses
									}
									loadingMessage={
										"Fulfilling and shipping shipments..."
									}
								/>
							</div>
						</>
					)}
					{isFulfillAndShipLoading && isFulfilledShipmentsView && (
						<>
							<div className={loadingBackgroundClasses}>
								{""}
								<FulfilledShipLoadingScreen
									containerClasses={
										fulfillShipLoadingScreenContainerClasses
									}
									loadingMessage={"Printing your label..."}
								/>
							</div>
						</>
					)}
				</form>
			</motion.div>
		</AnimatePresence>
	);
};

export default ShipmentsCardTableForm;
