// utils/formatModalDate.ts

/**
 * Formats a date string from "YYYY-MM-DD" to "MM/DD/YYYY".
 * If the input is not a valid date string, it returns the original input.
 *
 * @param {string} [dateString] - The date string to format.
 * @returns {string} - The formatted date string or the original string if invalid.
 */
function formatModalDate(dateString?: string): string {
	if (!dateString) return "";

	const parts = dateString.split("-");
	if (parts.length !== 3) return dateString;

	return `${parts[1]}/${parts[2]}/${parts[0]}`;
}
export default formatModalDate;
