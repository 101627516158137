const handleValidation = (
	result: {
		[key: string]: any;
	},
	partNumberField: string,
	selectedItem: {
		[key: string]: any;
	},
	isModalOpen: boolean,
	clearErrors: any,
	setError: any,
	foundSerialNumberEntry?: string[]
) => {
	const { isValid, serialNumber } = result;
	const isSerialized =
		selectedItem?.itemData?.item.inventoryType === "SERIALIZED" ||
		selectedItem?.itemData?.item.inventoryType === "";

	if (!result || !partNumberField || !isSerialized) return;

	// WITHIN MODAL LIVE NETSUITE VALIDATION ERRORS
	if (selectedItem.quantity !== "1") {
		if (isValid && foundSerialNumberEntry && isModalOpen) {
			// inputs within serial number modal
			// requires index number to find exact input within modal
			clearErrors(`${partNumberField}.${foundSerialNumberEntry[0]}`);
		} else if (isValid && !isModalOpen) {
			// error beneath edit serial numbers button in the table
			// does not need index number, general form field error (not input
			clearErrors(partNumberField);
		} else if (foundSerialNumberEntry && isModalOpen) {
			// inputs within serial number modal
			// requires index number to find exact input within modal
			setError(`${partNumberField}.${foundSerialNumberEntry[0]}`, {
				type: "manual",
				message: `Invalid Serial Number.`,
			});
		} else {
			// error beneath edit serial numbers button in the table
			// does not need index number, general form field error (not input
			setError(partNumberField, {
				type: "manual",
				message: `Invalid Serial Number.`,
			});
		}
	}
	// SINGLE ITEM VALIDATION IN TABLE
	else if (Number(selectedItem.quantity) === 1) {
		if (!isValid && serialNumber) {
			setError(partNumberField, {
				type: "manual",
				message: `Invalid Serial Number.`,
			});
		} else if (!isValid && !serialNumber) {
			setError(partNumberField, {
				type: "manual",
				message: `Required field.`,
			});
		} else if (isValid) {
			clearErrors(partNumberField);
		}
	}
};

export default handleValidation;