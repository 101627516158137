import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { getFontAwesomeIcon } from "../../utils";

interface BaseToolTipProps {
	children?: React.ReactNode;
	textSize?: string;
	message: string;
	icon?: string;
	iconStyle?: "solid" | "regular" | "light";
	iconColor?: string;
	iconSize?: string;
	textBackgroundColor?: string;
	textColor?: string;
	toolTipPlacement?: "top" | "bottom" | "right" | "left";
	customPlacement?: string;
}

const BaseToolTip: React.FC<BaseToolTipProps> = ({
	children,
	message,
	icon,
	iconStyle = "solid",
	iconColor = "text-navy-270",
	iconSize = "text-xs",
	textBackgroundColor = "bg-navy-400",
	textColor = "text-white",
	toolTipPlacement = "right",
	customPlacement,
}) => {
	const [isHovered, setIsHovered] = useState(false);

	let toolTipPlacementClasses;

	if (toolTipPlacement === "right" && !customPlacement) {
		toolTipPlacementClasses =
			"absolute left-full ml-2 top-1/2 transform -translate-y-1/2";
	} else if (toolTipPlacement === "bottom" && !customPlacement) {
		toolTipPlacementClasses =
			"absolute top-full mt-2 left-1/2 transform -translate-x-1/2";
	} else if (toolTipPlacement === "top" && !customPlacement) {
		toolTipPlacementClasses =
			"absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2";
	} else if (toolTipPlacement === "left" && !customPlacement) {
		toolTipPlacementClasses =
			"absolute right-full mr-2 top-1/2 transform -translate-y-1/2";
	}

	const toolTipMessageClasses = ` transition-opacity px-2 py-1 font-omnes-regular rounded-[3px] text-xs text-left text-nowrap ${textColor} ${textBackgroundColor} ${toolTipPlacementClasses}`;
	const iconClasses = `relative group flex items-center justify-center rounded-full ${iconSize} ${iconColor}`;
	const childrenContainerClasses =
		"relative flex items-center justify-center";

	return (
		<div className={"relative flex items-center z-50"}>
			{icon ? (
				<div
					className={iconClasses}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					{getFontAwesomeIcon(icon, iconStyle)}
					<AnimatePresence>
						{isHovered && (
							<motion.span
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.1 }}
								className={toolTipMessageClasses}
							>
								{message}
							</motion.span>
						)}
					</AnimatePresence>
				</div>
			) : (
				<div
					className={childrenContainerClasses}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					{children}
					<AnimatePresence>
						{isHovered && (
							<motion.span
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.2 }}
								className={toolTipMessageClasses}
							>
								{message}
							</motion.span>
						)}
					</AnimatePresence>
				</div>
			)}
		</div>
	);
};

export default BaseToolTip;
