import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	ReactNode,
} from "react";

// Define the context type
interface DarkModeContextType {
	darkMode: boolean;
	toggleDarkMode: () => void;
}

// Create the context with a default value
const DarkModeContext = createContext<DarkModeContextType>({
	darkMode: false,
	toggleDarkMode: () => {},
});

// Custom hook to use the context
const useDarkMode = () => useContext(DarkModeContext);

// Context provider component
export const DarkModeProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [darkMode, setDarkMode] = useState<boolean>(() => {
		const savedMode = localStorage.getItem("darkMode");
		return savedMode !== null ? JSON.parse(savedMode) : false;
	});

	useEffect(() => {
		localStorage.setItem("darkMode", JSON.stringify(darkMode));
	}, [darkMode]);

	const toggleDarkMode = () => {
		setDarkMode(!darkMode);
	};

	return (
		<DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
			{children}
		</DarkModeContext.Provider>
	);
};
export default useDarkMode;
