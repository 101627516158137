// InputField Component

// This is a reusable input component that can be used to take input from users in different forms.

// Props:

// - label: string
//   A string that describes the input field. This prop is optional and defaults to "form label" if not provided.

// - value: string
//   The current value of the input field.

// - iconColor?: string
//   A string representing the color of the icon used in the input field. This prop is optional and defaults to "primary" if not provided.

// - placeholder?: string
//   A string representing the placeholder text in the input field. This prop is optional.

// - iconPosition?: "before" | "after"
//   A string that specifies the position of the icon in the input field. This prop is optional and defaults to "before" if not provided.

// - required: boolean
//   A boolean that specifies if the input field is required. This prop is optional and defaults to "false" if not provided.

// - icon?: JSX.Element
//   A JSX element representing the icon to be used in the input field. This prop is optional.

// - onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
//   A callback function that is triggered when the input value changes.

// - checked?: boolean
//   A boolean value that specifies if the input field is checked. This prop is only available for checkbox input types.

// - id: string
//   A string representing the ID of the input field.

// - name: string
//   A string representing the name of the input field.

// - type:
//   A string that specifies the type of the input field. Valid values are "text", "checkbox", "radio", "number", "email", "password", "file", "color", "tel", "url", "range".

// Example Usage:

// <InputField
//   label="Name"
//   value=""
//   placeholder="Enter your name"
//   required={true}
//   onChange={(event) => console.log(event.target.value)}
//   id="name"
//   name="name"
//   type="text"
// />

import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, forwardRef, ForwardedRef } from "react";
import { UseFormRegister } from "react-hook-form";
import { useDarkMode } from "../../hooks";

interface InputFieldProps {
	label?: string;
	value?: string | number;
	readOnlyInfo?: string | JSX.Element;
	iconColor?: string;
	placeholder?: string;
	iconPosition?: "before" | "after" | "both";
	required: boolean;
	firstIcon?: JSX.Element | (() => JSX.Element | React.ReactElement) | null;
	secondIcon?: JSX.Element | (() => JSX.Element | React.ReactElement) | null;
	hasToolTip?: boolean;
	toolTipText?: string;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	checked?: boolean extends InputFieldProps["type"] ? boolean : undefined;
	id?: string;
	name?: string;
	additionalClasses?: string;
	onIconClick?: () => void;
	type:
		| "text"
		| "checkbox"
		| "radio"
		| "number"
		| "email"
		| "password"
		| "file"
		| "color"
		| "tel"
		| "url"
		| "range";
	isValid?: boolean;
	isReadOnly?: boolean;
	hasIcons?: boolean;
	labelClasses?: string;
	disabled?: boolean;
	hasAutoFocus?: boolean;
	register?: UseFormRegister<any>;
}

const InputField = forwardRef(
	(
		{
			label,
			placeholder,
			required = false,
			checked,
			id,
			name,
			type = "text",
			firstIcon,
			secondIcon,
			iconPosition = "before",
			iconColor = "primary",
			isValid = true,
			isReadOnly = false,
			onChange,
			value,
			readOnlyInfo = "",
			toolTipText = "",
			hasToolTip = false,
			additionalClasses = "",
			labelClasses = "",
			hasAutoFocus,
			onIconClick,
			onKeyDown,
			disabled,
			register,
		}: InputFieldProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const { darkMode } = useDarkMode();
		const [isFocused, setIsFocused] = useState(false);
		const hasValue = value != null && !!value.toString().trim(); // Check if input value has at least one non-space character
		const isNumberInput = type === "number";
		let formattedValue =
			isNumberInput &&
			value != null &&
			value.toString().trim() &&
			isNaN(Number(value))
				? ""
				: value;

		if (formattedValue === "$NaN") {
			formattedValue = "";
		}

		const renderFirstIcon = () => {
			if (!firstIcon) return null;

			return (
				<span
					onClick={onIconClick}
					className={`input-icon input-icon--first-icon absolute top-[10px] left-4 ${
						disabled
							? "text-gray-500"
							: isValid
							? `text-${iconColor}`
							: "text-redText"
					}`}
				>
					<>{firstIcon}</>
				</span>
			);
		};

		const renderSecondIcon = () => {
			if (!secondIcon) return null;

			return (
				<span
					onClick={onIconClick} // Add the onClick event handler here
					className={`input-icon input-icon--second-icon absolute top-2 right-4 text-${
						disabled
							? "text-gray-500"
							: isValid
							? `text-${iconColor}`
							: "text-redText"
					}`}
				>
					<>{secondIcon}</>
				</span>
			);
		};

		const renderIcons = () => {
			switch (iconPosition) {
				case "before":
					return renderFirstIcon();
				case "after":
					return renderSecondIcon();
				case "both":
					return (
						<>
							{renderFirstIcon()}
							{renderSecondIcon()}
						</>
					);
				default:
					return null;
			}
		};

		const registerProps = register
			? register(name || "", { required })
			: {};

		return isReadOnly ? (
			<div className="input-wrapper relative">
				{renderIcons()}
				<label
					htmlFor={id}
					className={`block font-light text-left mb-1 ${labelClasses} `}
				>
					{label}
					{hasToolTip && (
						<span className="pl-4 group">
							<FontAwesomeIcon
								icon={faCircleInfo}
								className="text-primary group-hover:text-blue-600"
							/>
							<span className="opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-sm rounded-md px-2 py-1 absolute top-[-14px]">
								{toolTipText}
							</span>
						</span>
					)}
				</label>
				<div
					className={`
					border text-left focus:outline-none focus:ring-2 block w-full py-2 px-2 rounded-lg shadow-input outline outline-0 outline-barelyPrimary focus:outline-4 md:w-full lg:w-full xl:w-full
					${firstIcon ? "pl-10" : ""}
					${secondIcon ? "pr-10" : ""}
					${additionalClasses}
				`}
				>
					{readOnlyInfo}
				</div>
				{iconPosition === "after" && renderSecondIcon()}
			</div>
		) : (
			<>
				{label && (
					<label
						htmlFor={id}
						className={`block font-light text-left mb-1 ${labelClasses}`}
					>
						{label}
						{hasToolTip && (
							<span className="pl-4 group">
								<FontAwesomeIcon
									icon={faCircleInfo}
									className="text-primary group-hover:text-blue-600"
								/>
								<span className="opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-sm rounded-md px-2 py-1 absolute top-[-14px]">
									{toolTipText}
								</span>
							</span>
						)}
					</label>
				)}

				<div className="input-wrapper relative">
					{renderIcons()}
					<input
						autoComplete=""
						{...registerProps}
						className={`rounded-md focused:ring-0 focus:ring-navy-500 ${
							darkMode ? "text-secondary" : "text-navy-500"
						}  border focus:outline-none focus:ring-2  ${
							disabled
								? "border-gray-500 focus:ring-gray-500"
								: isValid
								? `" focus:ring-navy-200" `
								: "border-redText focus:ring-red-500"
						} block w-full py-2 px-2
						${firstIcon ? "pl-10" : ""}
						${secondIcon ? "pr-10" : ""}
						rounded-lg   outline outline-0 outline-barelyPrimary focus:outline-4  md:w-full lg:w-full ${additionalClasses} ${
							isFocused && !disabled
								? ` ${isValid ? "" : "shadow-redText"}`
								: disabled
						}
						focus:ring-navy-200 focus:ring-2
						`}
						autoFocus={hasAutoFocus}
						placeholder={placeholder}
						type={type}
						id={id}
						value={formattedValue}
						name={name}
						onChange={onChange}
						checked={type === "checkbox" ? checked : undefined}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
						disabled={disabled}
						required={required && !hasValue} // Add the required prop only if there is no value or only spaces in the input
						onKeyDown={onKeyDown}
					/>
					{iconPosition === "after" && renderSecondIcon()}
				</div>
			</>
		);
	}
);

export default InputField;
