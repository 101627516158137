import React, { useState } from "react";
import {
	BaseDataItem,
	SearchColumnType,
} from "../../components/ui/Tables/types";

export interface TableFieldValues {
	index: number;
	slug: string;
	field: string;
	name: string;
	isVisible: boolean;
}

interface DataTableState {
	activeIndex: number;
	setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
	currentPage: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
	isLoading: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	noDataFound: boolean;
	setNoDataFound: React.Dispatch<React.SetStateAction<boolean>>;
	isError: boolean;
	setIsError: React.Dispatch<React.SetStateAction<boolean>>;
	data: any;
	setData: React.Dispatch<React.SetStateAction<BaseDataItem[]>>;
	convertedData: any[];
	setConvertedData: React.Dispatch<React.SetStateAction<any[]>>;
	sortColumn: { [key: string]: string };
	setSortColumn: React.Dispatch<
		React.SetStateAction<{ [key: string]: string }>
	>;
	clickedRowPos: { height: number; top: number };
	setClickedRowPos: React.Dispatch<
		React.SetStateAction<{ height: number; top: number }>
	>;
	resetSearch: boolean;
	setResetSearch: React.Dispatch<React.SetStateAction<boolean>>;
	editingHeader: number | null;
	setEditingHeader: React.Dispatch<React.SetStateAction<number | null>>;
	headerValue: string;
	setHeaderValue: React.Dispatch<React.SetStateAction<string>>;
	searchText: string;
	setSearchText: React.Dispatch<React.SetStateAction<string>>;
	submittedSearchText: string;
	setSubmittedSearchText: React.Dispatch<React.SetStateAction<string>>;
	detailsAreUndefined: boolean;
	setDetailsAreUndefined: React.Dispatch<React.SetStateAction<boolean>>;
	showDetailsBox: boolean;
	setShowDetailsBox: React.Dispatch<React.SetStateAction<boolean>>;
	itemUuid: string;
	setItemUuid: React.Dispatch<React.SetStateAction<string>>;
	columnTitles: string[];
	setColumnTitles: React.Dispatch<React.SetStateAction<string[]>>;
	totalPages: number;
	setTotalPages: React.Dispatch<React.SetStateAction<number>>;
	setTotalRecords: React.Dispatch<React.SetStateAction<number>>;
	columnMappings: Array<{ name: string; slug: string; settings: any }>;
	setColumnMappings: React.Dispatch<
		React.SetStateAction<
			Array<{ name: string; slug: string; settings: any }>
		>
	>;
	searchColumn: SearchColumnType | null;
	setSearchColumn: React.Dispatch<
		React.SetStateAction<SearchColumnType | null>
	>;
	clearSearch: boolean;
	setClearSearch: React.Dispatch<React.SetStateAction<boolean>>;
	clickRow: boolean;
	setClickRow: React.Dispatch<React.SetStateAction<boolean>>;
	prevPageFromURL: number;
	childColumnTitles: string[];
	setPrevPageFromURL: React.Dispatch<React.SetStateAction<number>>;
	setChildColumnTitles?: React.Dispatch<React.SetStateAction<string[]>>;
	childColumnMappings?: Array<{ name: string; slug: string }>;
	setChildColumnMappings?: React.Dispatch<
		React.SetStateAction<Array<{ name: string; slug: string }>>
	>;
	isNested?: boolean;
	setIsNested?: React.Dispatch<React.SetStateAction<boolean>>;
	totalPagesInChildTable?: number;
	setTotalPagesInChildTable?: React.Dispatch<React.SetStateAction<number>>;
	recordsPerPageAfterChange: number;
	setRecordsPerPageAfterChange: React.Dispatch<React.SetStateAction<number>>;
	dataTableValueFields?: TableFieldValues[];
	setDataTableValueFields?: React.Dispatch<
		React.SetStateAction<TableFieldValues[]>
	>;
	paginationInput?: number;
	setHasModalTable?: React.Dispatch<React.SetStateAction<boolean>>;
	hasModalTable?: boolean;
	totalRecords: number;
}

const useDataTableState = (): DataTableState => {
	const [activeIndex, setActiveIndex] = useState(-1);
	const [currentPage, setCurrentPage] = useState(1);
	const [childColumnTitles, setChildColumnTitles] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [noDataFound, setNoDataFound] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [data, setData] = useState<BaseDataItem[]>([]);
	const [convertedData, setConvertedData] = useState<any[]>([]);
	const [sortColumn, setSortColumn] = useState<{ [key: string]: string }>({});
	const [clickedRowPos, setClickedRowPos] = useState({ height: 0, top: 0 });
	const [resetSearch, setResetSearch] = useState<boolean>(false);
	const [editingHeader, setEditingHeader] = useState<number | null>(null);
	const [headerValue, setHeaderValue] = useState("");
	const [searchText, setSearchText] = useState("");
	const [submittedSearchText, setSubmittedSearchText] = useState("");
	const [detailsAreUndefined, setDetailsAreUndefined] = useState(false);
	const [showDetailsBox, setShowDetailsBox] = useState(false);
	const [itemUuid, setItemUuid] = useState("");
	const [columnTitles, setColumnTitles] = useState<string[]>([]);
	const [totalPages, setTotalPages] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);
	const [columnMappings, setColumnMappings] = useState<
		Array<{ name: string; slug: string; settings: any }>
	>([]);
	const [childColumnMappings, setChildColumnMappings] = useState<
		Array<{ name: string; slug: string }>
	>([]);

	const [searchColumn, setSearchColumn] = useState<SearchColumnType | null>(
		null
	);
	const [clearSearch, setClearSearch] = useState(false);
	const [clickRow, setClickRow] = useState<boolean>(false);
	const [prevPageFromURL, setPrevPageFromURL] = useState(-1);
	const [isNested, setIsNested] = useState(false);
	const [totalPagesInChildTable, setTotalPagesInChildTable] = useState(0);
	const [recordsPerPageAfterChange, setRecordsPerPageAfterChange] =
		useState(10);
	useState<Array<{ name: string; slug: string }>>([]);
	const [dataTableValueFields, setDataTableValueFields] = useState<
		TableFieldValues[]
	>([]);
	const [paginationInput, setPaginationInput] = useState<number>(1);
	const [hasModalTable, setHasModalTable] = useState<boolean>(false);

	return {
		activeIndex,
		setActiveIndex,
		currentPage,
		setCurrentPage,
		isLoading,
		setIsLoading,
		noDataFound,
		setNoDataFound,
		isError,
		setIsError,
		data,
		setData,
		convertedData,
		setConvertedData,
		sortColumn,
		setSortColumn,
		clickedRowPos,
		setClickedRowPos,
		resetSearch,
		setResetSearch,
		editingHeader,
		setEditingHeader,
		headerValue,
		setHeaderValue,
		searchText,
		setSearchText,
		submittedSearchText,
		setSubmittedSearchText,
		detailsAreUndefined,
		setDetailsAreUndefined,
		showDetailsBox,
		setShowDetailsBox,
		itemUuid,
		setItemUuid,
		columnTitles,
		setColumnTitles,
		totalPages,
		setTotalPages,
		columnMappings,
		setColumnMappings,
		searchColumn,
		setSearchColumn,
		clearSearch,
		setClearSearch,
		clickRow,
		setClickRow,
		prevPageFromURL,
		setPrevPageFromURL,
		setChildColumnTitles,
		childColumnTitles,
		childColumnMappings,
		setChildColumnMappings,
		isNested,
		setIsNested,
		totalPagesInChildTable,
		setTotalPagesInChildTable,
		setTotalRecords,
		recordsPerPageAfterChange,
		setRecordsPerPageAfterChange,
		dataTableValueFields,
		setDataTableValueFields,
		paginationInput,
		setHasModalTable,
		hasModalTable,
		totalRecords,
	};
};

export default useDataTableState;
