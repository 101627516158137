import React from "react";

interface SectionProps {
	children: React.ReactNode;
	additionalClasses?: string;
	padding?: string;
}

const Section: React.FC<SectionProps> = ({
	additionalClasses,
	children,
	padding = "py-16",
}) => {
	let className = "section";

	return (
		<section className={`${className} ${padding} ${additionalClasses}`}>
			{children}
		</section>
	);
};

export default Section;
