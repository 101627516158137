const checkDimensions = (
	formValues: Record<string, any>,
	setError: any,
	clearErrors: any
) => {
	let length = formValues.dimensions.length;
	let width = formValues.dimensions.width;
	let height = formValues.dimensions.height;

	if (length && !width && !height) {
		setError("dimensions.width", {
			type: "manual",
			message: "Required",
		});
		setError("dimensions.height", {
			type: "manual",
			message: "Required",
		});
	}

	if (length && width && !height) {
		setError("dimensions.height", {
			type: "manual",
			message: "Required",
		});
	}

	if (length && !width && height) {
		setError("dimensions.width", {
			type: "manual",
			message: "Required",
		});
	}

	if (!length && width && !height) {
		setError("dimensions.length", {
			type: "manual",
			message: "Required",
		});
		setError("dimensions.height", {
			type: "manual",
			message: "Required",
		});
	}

	if (!length && width && height) {
		setError("dimensions.length", {
			type: "manual",
			message: "Required",
		});
	}

	if (!length && !width && height) {
		setError("dimensions.width", {
			type: "manual",
			message: "Required",
		});
		setError("dimensions.length", {
			type: "manual",
			message: "Required",
		});
	}

	if (!length && !width && !height) {
		clearErrors("dimensions.length");
		clearErrors("dimensions.width");
		clearErrors("dimensions.height");
	}
};

export default checkDimensions;