import { useState, useEffect } from "react";

const useViewportHeight = (threshold: number) => {
	const [isOver, setIsOver] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			const viewportHeight = window.innerHeight;
			setIsOver(viewportHeight > threshold);
		};

		handleResize();

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [threshold]);

	return isOver;
};

export default useViewportHeight;
