import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/index.scss";
import { UserContextProvider } from "./hooks/useUserContext";
import { URLProvider } from "./hooks/dataTableHooks/useUrlContext";
import ContextComposer from "./utils/ContextComposer";
import { DarkModeProvider } from "./hooks/useDarkMode";
import { ToasterProvider } from "./hooks/useToasterContext";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
Sentry.init({
	dsn: "https://2174945f02c6deac0ca43127a3420a50@o4507766209183744.ingest.us.sentry.io/4508257929461760",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["*"],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if(localStorage.getItem("user")){
	const user = JSON.parse(localStorage.getItem("user") || "{}");
	console.log("firstName: ", user.firstName);
	Sentry.setUser({
		firstName:user.firstName,
		lastName:user.lastName,
		uuid:user.uuid,
		isAdmin:user.isAdmin,
		isSupervisor:user.isSupervisor,
		isUserLoggedIn:user.isUserLoggedIn
	});
}

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ContextComposer
				contexts={[
					UserContextProvider,
					URLProvider,
					DarkModeProvider,
					ToasterProvider,
				]}
			>
				<App />
			</ContextComposer>
		</BrowserRouter>
	</React.StrictMode>
);
