import React, { useContext, useEffect, useRef, useState } from "react";
import { faBars, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Profile from "../../ui/Profile";
import Logo from "../Logo";
import SlideMenu from "../SlideMenu";
import UserContext from "../../../hooks/useUserContext";
import ThemeToggle from "../../ui/ThemeToggle";
import { useDarkMode } from "../../../hooks";

type HeaderProps = {
	setTheme?: React.Dispatch<React.SetStateAction<string>>;
};

const Header: React.FC<HeaderProps> = ({ setTheme }) => {
	const apiUrl = import.meta.env.VITE_API;

	const { darkMode } = useDarkMode();
	const [localTheme, setLocalTheme] = useState(
		localStorage.getItem("theme") || "togaSupply"
	);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const containerRef = useRef(null);
	const [fadeInOut, setFadeInOut] = useState("fade-out");
	const { user } = useContext(UserContext);
	const { isUserLoggedIn, firstName, lastName } = user;

	// Determine which theme setter to use
	const effectiveSetTheme = setTheme || setLocalTheme;

	useEffect(() => {
		// Update the data-theme attribute on the html tag
		document.documentElement.setAttribute("data-theme", localTheme);

		// Store the theme in localStorage
		localStorage.setItem("theme", localTheme);
	}, [localTheme]);

	const toggleTheme = () => {
		effectiveSetTheme((theme) =>
			theme === "togaSupply" ? "dark" : "togaSupply"
		);
	};

	const useHostName = () => {
		const [hostName, setHostName] = useState("");

		useEffect(() => {
			setHostName(window.location.hostname);
		}, []);

		return hostName;
	};
	const hostName = useHostName();

	const handleToggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
		setFadeInOut("fade-in");

		if (!isMenuOpen) {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}
	};

	const handleTransitionEnd = () => {
		if (!isMenuOpen) {
			setIsMenuOpen(!isMenuOpen);
		}
	};

	return (
		<div ref={containerRef} className="container-ref">
			{isUserLoggedIn ? (
				<>
					<header
						className={`fixed z-[9910]  py-1 flex w-full h-[80px] glass ${
							darkMode ? "bg-primary" : "bg-white"
						} items-center`}
					>
						<div className="flex items-center ml-8">
							{isUserLoggedIn && (
								<div
									className={` ${
										darkMode
											? "bg-secondary text-slate-800 hover:dark:bg-accent hover:text-slate-200"
											: "bg-white"
									}  mr-4 text-primary hover:bg-primaryVeryLight cursor-pointer rounded-full p-5 relative flex items-center justify-center`}
									onClick={handleToggleMenu}
								>
									<span className="absolute">
										{isMenuOpen ? (
											<FontAwesomeIcon
												icon={faXmark}
												data-testid="close-icon"
												className={`${
													darkMode
														? "text-gray-300"
														: "text-primary"
												}`}
											/>
										) : (
											<FontAwesomeIcon
												icon={faBars}
												data-testid="menu-icon"
												className={`${
													darkMode
														? ""
														: "text-primary"
												}`}
											/>
										)}
									</span>
								</div>
							)}
							{/* FIXME: more host name checks here */}
							<div
								className={`${
									hostName.includes("compass") ||
									hostName.includes("bankofamerica")
										? "hidden sm:block"
										: ""
								}`}
							>
								<Logo
									path="/TOGa-Supply-Logo.svg"
									shouldOpenInNewTab={false}
								/>
							</div>
							{hostName.includes("bankofamerica") && (
								<Logo
									path="/bank-of-america-logo.svg"
									shouldOpenInNewTab={false}
								/>
							)}
							{hostName.includes("compass") && (
								<Logo
									path="/Compass-Tech-Logo 1.svg"
									shouldOpenInNewTab={false}
								/>
							)}
							{/* used to tell the developer what env they are in  */}
							{/* <p className="text-xl ">Environment: {apiUrl}</p> */}
						</div>

						{/* TODO: add global search in header when ready */}
						{/* <div className="m-auto w-[400px] hidden md:block">
							<Input
								value={searchText}
								required={false}
								id={""}
								name={""}
								type={"text"}
								onChange={(event) =>
									setSearchText(event.target.value)
								}
								additionalClasses={
									"bg-primaryVeryLight border-0 shadow-transparent"
								}
								placeholder={
									"Search for people, assets, quotes..."
								}
								hasIcons={true}
								firstIcon={
									<FontAwesomeIcon icon={faMagnifyingGlass} />
								}
							/>
						</div> */}
						{!hostName.includes("bankofamerica") && (
							<div className="">
								<ThemeToggle toggleTheme={toggleTheme} />
							</div>
						)}

						{isUserLoggedIn && (
							<Profile
								firstName={firstName}
								lastName={lastName}
							/>
						)}
					</header>
					{isMenuOpen && (
						<div
							role="overlay"
							onClick={handleToggleMenu}
							className={`fixed inset-0 z-[1000] backdrop-blur-sm bg-gray-200 bg-opacity-75 transition-opacity duration-300 ${fadeInOut}`}
							onTransitionEnd={handleTransitionEnd}
						></div>
					)}
				</>
			) : (
				<header className="fixed z-20 shadow-header shadow-primary py-1 flex w-full bg-white items-center">
					<div
						className="mr-4 text-primary  hover:bg-blue-100 rounded-full p-5 relative flex items-center justify-center"
						onClick={handleToggleMenu}
					></div>
					<Logo
						path="/TOGa-Supply-Logo.svg"
						shouldOpenInNewTab={false}
					/>

					{isUserLoggedIn && (
						<Profile firstName={firstName} lastName={lastName} />
					)}
				</header>
			)}
			{isUserLoggedIn && (
				<menu
					className={`fixed top-slideMenu h-full  ${
						isMenuOpen ? "z-[2000]" : "z-[-10]"
					} w-slideMenu`}
				>
					<SlideMenu
						isMenuOpen={isMenuOpen}
						handleToggleMenu={handleToggleMenu}
					/>
				</menu>
			)}
		</div>
	);
};

export default Header;
