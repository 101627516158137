import React, { ElementType } from "react";
import { Link } from "react-router-dom";
import { useDarkMode } from "../../hooks";

// TODO: NOTE: classNames not installed, refactored to if statements
export const getHoverClassNames = (
	hoverBackground: string | undefined,
	hoverFontColor: string | undefined,
	hoverUnderline: boolean | undefined,
	hoverBorderColor: string | undefined,
	isDisabled: boolean | undefined
) => {
	let classNames = "";

	if (hoverBackground === "primaryHover" && !isDisabled) {
		classNames += " hover:bg-primaryHover";
	} else if (hoverBackground === "primaryHoverLight" && !isDisabled) {
		classNames += " hover:bg-primaryHoverLight";
	} else if (hoverBackground === "crimson-300" && !isDisabled) {
		classNames += " hover:bg-crimson-300";
	} else if (hoverBackground === "mint-200" && !isDisabled) {
		classNames += " hover:bg-mint-200";
	} else if (hoverBackground === "none" && !isDisabled) {
		classNames += " hover:bg-transparent text-black";
	} else if (hoverBackground && !isDisabled) {
		classNames += ` bg-${hoverBackground}`;
	}

	if (hoverFontColor === "primary" && !isDisabled) {
		classNames += " hover:text-primary";
	} else if (hoverFontColor === "darkText" && !isDisabled) {
		classNames += " hover:text-darkText";
	} else if (hoverFontColor === "white" && !isDisabled) {
		classNames += " hover:text-slate-50";
	} else if (hoverFontColor === "black" && !isDisabled) {
		classNames += " hover:text-black";
	} else if (hoverFontColor === "navy-300" && !isDisabled) {
		classNames += " hover:text-navy-300";
	} else if (hoverFontColor === "navy-500" && !isDisabled) {
		classNames += " hover:text-navy-500";
	} else if (hoverFontColor === "supply-blue-300" && !isDisabled) {
		classNames += " hover:text-supply-blue-300";
	}

	if (hoverBorderColor === "primary" && !isDisabled) {
		classNames += " hover:border-primary";
	} else if (hoverBorderColor === "black" && !isDisabled) {
		classNames += " hover:border-black";
	} else if (hoverBorderColor === "crimson-700" && !isDisabled) {
		classNames += " hover:border-crimson-700";
	} else if (hoverBackground === "none" && !isDisabled) {
		classNames += " hover:border-transparent";
	} else if (hoverBorderColor && !isDisabled) {
		classNames += ` hover:border-${hoverBorderColor}`;
	}

	if (hoverUnderline === true && !isDisabled) {
		classNames += " hover:underline hover:underline-offset-4";
	} else if (hoverUnderline === false || isDisabled) {
		classNames += "";
	}

	if (isDisabled ) {
		classNames += "cursor-not-allowed";
	} else if (!isDisabled) {
		classNames += " cursor-pointer";
	}

	return classNames;
};

export const getColorClassNames = (
	color: string | undefined,
	fontColor: string | undefined,
	borderColor: string | undefined,
	darkMode: boolean,
	isDisabled: boolean | undefined,
	disabledClasses: string | undefined
) => {
	let classNames = "";

	if (isDisabled) {
		if (disabledClasses) {
			classNames += ` ${disabledClasses}`;
		} else {
			classNames +=
				" bg-navy-270 border-navy-270 hover:bg-navy-270 hover:cursor-not-allowed ";
		}
	} else {
		// Apply the usual color classes if not disabled
		if (color === "primary") {
			classNames += " bg-primary";
		} else if (color === "white") {
			classNames += " bg-white";
		} else if (color === "clear") {
			classNames += " bg-transparent border-black";
		} else if (color === "navy-270") {
			classNames += " bg-navy-270";
		} else if (color) {
			classNames += ` bg-${color}`;
		}

		// Apply border color classes only if not disabled
		if (borderColor === "primary") {
			classNames += " border-primary";
		} else if (borderColor === "supply-blue-400") {
			classNames += " border-supply-blue-400";
		} else if (borderColor === "none") {
			classNames += " border-transparent";
		}
	}

	if (fontColor === "white" && color !== "clear" && !darkMode) {
		classNames += " text-white";
	} else if (fontColor === "black" && !darkMode) {
		classNames += " text-black";
	} else if (fontColor === "navy-300" && !darkMode) {
		classNames += " text-navy-300";
	} else if (fontColor === "primary" && !darkMode) {
		classNames += " text-primary";
	} else if (fontColor === "supply-blue-500" && !darkMode) {
		classNames += " text-supply-blue-500";
	} else if (darkMode) {
		classNames += " text-secondary";
	} else if (fontColor) {
		classNames += ` text-${fontColor}`;
	}

	return classNames;
};

export const getIconClasses = (iconOrder: string | undefined) => {
	let classNames = "";

	if (iconOrder === "first") {
		classNames += " flex items-center order-first";
	} else if (iconOrder === "last") {
		classNames += " flex items-center order-last";
	}

	return classNames;
};

export const getSizeClasses = (
	buttonWide: boolean | undefined,
	size: string | undefined
) => {
	let classNames = "";

	if (buttonWide === true) {
		classNames += " btn-wide";
	} else if (buttonWide === false) {
		classNames += "";
	}

	if (size === "xs") {
		classNames += " text-xs ";
	} else if (size === "sm") {
		classNames += " text-sm ";
	} else if (size === "md") {
		classNames += " text-md ";
	} else if (size === "lg") {
		classNames += " text-lg ";
	} else if (size === "xl") {
		classNames += " text-xl ";
	} else if (size !== undefined) {
		classNames += ` text-${size}`;
	} else {
		classNames += `text-${size}`;
	}

	return classNames;
};

export const getTextClasses = (
	textStyle: string | undefined,
	fontFamily: string | undefined
) => {
	let classNames = "";

	if (textStyle === "normal") {
		classNames += " font-normal";
	} else if (textStyle === "bold") {
		classNames += " font-bold";
	}

	if (fontFamily === "sans") {
		classNames += " font-sans";
	} else if (fontFamily === "serif") {
		classNames += " font-serif";
	} else if (fontFamily === "mono") {
		classNames += " font-mono";
	} else if (fontFamily === "omnes-regular") {
		classNames += " font-omnes-regular";
	} else if (fontFamily === "omnes-medium") {
		classNames += " font-omnes-medium";
	}

	return classNames;
};

interface BaseButtonClassProps {
	buttonColorClasses: string;
	buttonSizeClasses: string;
	buttonShapeClasses: string;
	textStyleClasses: string;
	hoverClasses: string;
	hasShadow: boolean;
	customWidth?: string;
	customHeight?: string;
	additionalClasses?: string;
	disabledClasses?: string;
}

export const getButtonClasses = ({
	buttonColorClasses,
	buttonSizeClasses,
	buttonShapeClasses,
	textStyleClasses,
	hoverClasses,
	hasShadow,
	customWidth,
	customHeight,
	additionalClasses,
	disabledClasses,
}: BaseButtonClassProps) => {
	let classNames =
		"border-1 flex justify-center items-center" +
		buttonColorClasses +
		buttonSizeClasses +
		buttonShapeClasses +
		textStyleClasses +
		hoverClasses;

	if (hasShadow) {
		classNames += " shadow-md";
	}

	if (customWidth) {
		classNames += " " + customWidth;
	}

	if (customHeight) {
		classNames += " " + customHeight;
	}

	if (additionalClasses) {
		classNames += " " + additionalClasses;
	}

	if (disabledClasses) {
		classNames += " " + disabledClasses;
	}

	return classNames;
};

export interface BaseButtonTypes {
	onClick?: (
		e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
	) => void;
	text?: string | JSX.Element;
	color?: string;
	fontColor?: string;
	borderColor?: string;
	size?: "xs" | "sm" | "md" | "lg" | "xl" | string;
	textStyle?: "thin" | "light" | "normal" | "semibold" | "bold";
	fontFamily?: "sans" | "mono" | "serif" | string;
	customWidth?: string;
	customHeight?: string;
	shape?: "cornered" | "semiRounded" | "rounded" | "underline" | string;
	icon?: JSX.Element | boolean | null;
	iconOrder?: "first" | "last";
	iconBreakpoint?: string;
	type?: "submit" | "reset" | "button";
	to?: string;
	href?: string;
	tooltip?: string;
	additionalClasses?: string;
	isDisabled?: boolean;
	hasShadow?: boolean;
	as?: ElementType;
	hoverBackground?: string;
	hoverFontColor?: string;
	hoverUnderline?: boolean;
	hoverBorderColor?: string;
	buttonWide?: boolean;
	iconLabel?: string;
	hasTextBreakpoint?: boolean;
	textBreakpoint?: string;
	iconSize?: string;
	iconPadding?: string;
	ariaSelected?: boolean;
	textAdditionalClasses?: string;
	disabledClasses?: string;
}

const BaseButton: React.FC<BaseButtonTypes> = ({
	onClick,
	text,
	textAdditionalClasses,
	textStyle = "regular",
	fontFamily,
	color,
	fontColor,
	size = "md",
	customWidth,
	customHeight,
	shape = "",
	buttonWide = false,
	borderColor,
	icon,
	iconOrder = "first",
	iconBreakpoint,
	additionalClasses = "",
	type = "button",
	to,
	href,
	tooltip,
	isDisabled = false,
	disabledClasses,
	hasShadow = false,
	as: Element = "a",
	hoverBackground,
	hoverFontColor,
	hoverUnderline,
	hoverBorderColor,
	iconLabel,
	hasTextBreakpoint,
	textBreakpoint,
	iconSize,
	iconPadding,
	ariaSelected,
	...props
}) => {
	// TODO: add dark mode
	const { darkMode } = useDarkMode();

	let hoverClasses = getHoverClassNames(
		hoverBackground,
		hoverFontColor,
		hoverUnderline,
		hoverBorderColor,
		isDisabled
	);
	let buttonColorClasses = getColorClassNames(
		color,
		fontColor,
		borderColor,
		darkMode,
		isDisabled,
		disabledClasses
	);
	let buttonSizeClasses = getSizeClasses(buttonWide, size);
	let textStyleClasses = getTextClasses(textStyle, fontFamily);

	const shapeClassesMap: { [key: string]: string } = {
		cornered: "rounded-none",
		semiRounded: "rounded-md",
		rounded: "rounded-full",
		underline: "rounded-none border-b-6 border-b-black",
		none: "border-none rounded-none",
	};

	let iconClasses = getIconClasses(iconOrder);

	const buttonShapeClasses = shapeClassesMap[shape];

	let buttonClasses = getButtonClasses({
		buttonColorClasses,
		buttonSizeClasses,
		buttonShapeClasses,
		textStyleClasses,
		hoverClasses,
		hasShadow,
		customWidth,
		customHeight,
		additionalClasses,
	});

	buttonClasses += darkMode ? " text-secondary" : "";

	const handleClick = (
		e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
	) => {
		if (isDisabled) {
			e.preventDefault();
			return;
		}
		onClick?.(e);
	};

	const baseProps = {
		className: buttonClasses.trim(),
		onClick: handleClick,
		type: type,
		"aria-disabled": isDisabled ? "true" : "false",
		"data-tooltip": tooltip,
		"aria-label": iconLabel,
		"aria-selected": ariaSelected,
	};

	if (Element === "a") {
		props = { ...baseProps, href: href };
	} else if (Element === "link") {
		Element = Link;
		props = { ...baseProps, to: to };
	} else if (Element === "button") {
		props = { ...baseProps };
	}

	return (
		<Element {...props}>
			{icon && (
				<span
					className={`${iconClasses} ${iconBreakpoint} ${iconSize} ${iconPadding} ${
						darkMode ? "text-secondary" : ""
					}`}
				>
					{icon}
				</span>
			)}
			<div
				className={`${
					hasTextBreakpoint ? textBreakpoint : ""
				} ${textAdditionalClasses}`}
			>
				{text}
			</div>
		</Element>
	);
};

export default BaseButton;
