// localStorageUtils.ts

/**
 * Clears specified keys from local storage and optionally executes a callback function.
 * @param localStorageKeys An array of keys to be cleared from local storage.
 * @param onClear An optional callback function to be executed after clearing.
 */
const clearLocalStorage = (
	localStorageKeys: string[],
	onClear?: () => void
) => {
	localStorageKeys.forEach((key) => {
		localStorage.removeItem(key);
	});

	if (onClear) {
		onClear();
	}
};
export default clearLocalStorage;
