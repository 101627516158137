import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useManageModalUrl = (
	pageTitle: string,
	activeUuid: string | null,
	setActiveUuid: (uuid: string) => void,
	isModalOpen: boolean,
	setIsModalOpen: (isOpen: boolean) => void,
	setCallFromFilter?: (value: boolean) => void,
	callFromFilter?: boolean
) => {
	// get the current location / url
	const location = useLocation();
	// set variable to access the uuid query param from the url
	const uuidVariable = "uuid";

	// updates the url with the new search parameters. if there is a search parameter provided, sets url to that
	// if there is no search parameter provided, removes the search parameter from the url
	// window.history is updating browser history
	const updateUrl = (searchParams: URLSearchParams, param: string | null) => {
		if (param) {
			searchParams.set(pageTitle, param);
		} else {
			searchParams.delete(pageTitle);
		}
		const newUrl = `${location.pathname}?${searchParams.toString()}`;
		window.history.replaceState(null, "", newUrl);
	};

	// handles url updating when opening the modal
	const handleModalOpen = (searchParams: URLSearchParams, uuid: string) => {
		setActiveUuid(uuid);
		setIsModalOpen(true);
		if (
			searchParams.get(pageTitle) === "approvals-view" &&
			setCallFromFilter
		) {
			setCallFromFilter(true);
		}
	};

	// handles url updating when closing the modal
	const handleModalClose = (searchParams: URLSearchParams) => {
		let newParam = `${pageTitle}-view&uuid=${activeUuid}`;
		if (pageTitle === "Orders") {
			newParam = callFromFilter
				? `approvals-view&uuid=${activeUuid}`
				: `orders-view&uuid=${activeUuid}`;
		} else {
			newParam = `${pageTitle
				.toLowerCase()
				.replace(" ", "-")}-view&uuid=${activeUuid}`;
		}
		const existingParams = location.search.replace(/^\?/, "");
		const newUrl = `${location.pathname}?${existingParams}&${pageTitle}=${newParam}`;
		const finalUrl = newUrl.replace(/(uuid=[^&]*)(&uuid=[^&]*)/, "$1");
		window.history.replaceState(null, "", finalUrl);
	};

	// invokes the correct function based on the state of the modal, and what is in the url
	// if there is a modal param and uuid from the url (i.e. approvals-view and uuid), invoke handleModalOpen
	// if the modal is already open, invoke handleModalClose
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const modalParam = searchParams.get(pageTitle);
		const uuid = searchParams.get(uuidVariable);

		if (modalParam && uuid) {
			handleModalOpen(searchParams, uuid);
			return;
		}

		if (isModalOpen) {
			handleModalClose(searchParams);
			return;
		}

		updateUrl(searchParams, null);
	}, [location, activeUuid, isModalOpen, pageTitle]);
};

export default useManageModalUrl;