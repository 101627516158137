import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SupplyPortal from "./SupplyPortal";
import SupplyToaster from "./SupplyToaster";

const getStatusColor = (status: string) => {
	let colorClasses = "";

	switch (status) {
		case "success":
			colorClasses = "bg-mint-200 border-mint-600 text-mint-800";
			break;
		case "error":
			colorClasses = "bg-crimson-300 border-crimson-500 text-crimson-700";
			break;
		default:
			colorClasses = "bg-gray-200 border-gray-600 text-gray-600";
			break;
	}

	return `${colorClasses} border-1 rounded-lg min-w-96 my-1`;
};

interface Toaster {
	isOpen: boolean;
	message: string;
	status: string;
}

interface ToasterWithId extends Toaster {
	id: string;
}

interface ToasterListProps {
	messages: Toaster[];
}

const ToasterList: React.FC<ToasterListProps> = ({ messages }) => {
	const [visibleMessages, setVisibleMessages] = useState<ToasterWithId[]>([]);

	useEffect(() => {
		const newVisibleMessages = messages.map((message) => ({
			...message,
			id: `${Date.now()}-${Math.random()}`,
		}));

		setVisibleMessages(newVisibleMessages);

		const timers = newVisibleMessages.map((message) => {
			return setTimeout(() => {
				setVisibleMessages((currentMessages) =>
					currentMessages.filter((m) => m.id !== message.id)
				);
			}, 3000);
		});

		return () => {
			timers.forEach((timer) => clearTimeout(timer));
		};
	}, [messages]);

	return (
		<SupplyPortal containerId="portal-root">
			<AnimatePresence>
				{visibleMessages.length !== 0 && (
					<motion.div
						className="absolute top-12 right-20 z-[9999] bg-transparent"
						initial={{ opacity: 0, translateY: -20 }}
						animate={{ opacity: 1, translateY: 40 }}
						exit={{ opacity: 0, translateY: -20 }}
						transition={{ duration: 0.3, ease: "easeOut" }}
					>
						{visibleMessages.map((message) => (
							<div
								className={"relative z-[9999]"}
								key={`toaster-${message.id}`}
							>
								<SupplyToaster
									isOpen={message.isOpen}
									message={message.message}
									classNames={getStatusColor(message.status)}
								/>
							</div>
						))}
					</motion.div>
				)}
			</AnimatePresence>
		</SupplyPortal>
	);
};

export default ToasterList;
