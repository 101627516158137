import React from "react";
import { getFontAwesomeIcon } from "../../utils";
import GoogleMapsLink from "./GoogleMapsLink";
import ImageComponent from "../layout/ImageComponent";
import placeholderImage from "../../assets/placeholder-no-image-available.png";
interface Field {
	id?: string;
	labelIcon?: string;
	label?: string;
	valueKey: string;
	inputType?: string;
	labelTextClasses?: string;
	valueTextClasses?: string;
	hasValueLeftIcon?: string;
	hasValueRightIcon?: string;
	hasValueImage?: string;
	detailGridClasses?: string;
	inputGridClasses?: string;
	additionalInputContainerClasses?: string;
	isBoolean?: boolean;
	truthyValue?: string;
	falsyValue?: string;
	isConcatenated?: string;
	isEditOnly?: boolean;
	inputHasLeftIcon?: string;
	inputIconClasses?: string;
	disabledClasses?: string;
	detailIconClasses?: string;
	secondValueTextClasses?: string;
	secondValueKey?: string;
	secondValueLabel?: string;
	hideLabel?: boolean;
	isLink?: string;
	isGoogleMapsLink?: string;
	hoverClasses?: string;
	isDoubleValue?: boolean;
	isPersonaValue?: boolean;
	textAfterValue?: string;
}

type Address = {
	addressee?: string;
	street?: string;
	line1?: string;
	line2?: string;
	city?: string;
	zip?: string;
};

interface BaseDetailRowProps {
	field?: Field;
	allFields?: Field[];
	dataItem?: Address | string | number | boolean | undefined;
	allData?: object;
	keyTextColor?: string;
	keyTextSize?: string;
	valueTextColor?: string;
	valueTextSize?: string;
	containerWidth?: string;
	hasValuePrice?: boolean;
	hasValueLongDescription?: boolean;
	iconStyle?: "solid" | "regular" | "light";
	mobileBreakpoint?: string;
	isEnabled?: boolean;
	textSize?: string;
	imageClasses?: string;
}

const BaseDetailRow: React.FC<BaseDetailRowProps> = ({
	dataItem,
	field,
	allFields,
	allData,
	containerWidth = "w-full",
	iconStyle = "solid",
	mobileBreakpoint,
	isEnabled,
	textSize = "",
	imageClasses = "rounded-full w-18px h-18px",
}) => {
	const baseDetailClasses = "w-full flex text-left items-center";
	const baseLabelClasses = "pr-2 text-left text-nowrap";

	const renderValue = <T extends React.ReactNode>(
		field: Field,
		value: Address | string | boolean | number | T
	) => {
		if (Array.isArray(value) && field.isDoubleValue) {
			return (
				<div
					className={`flex flex-col ${
						!isEnabled && field.disabledClasses
					}`}
				>
					<>
						<p
							className={`${textSize} ${
								!isEnabled && field.disabledClasses
							}`}
						>
							{value[0]}
						</p>
						<p
							className={`${textSize} ${
								field.secondValueTextClasses
							} ${!isEnabled && field.disabledClasses}`}
						>
							{value[1]}
						</p>
					</>
				</div>
			);
		} else if (field.isBoolean) {
			return (
				<p
					className={`${textSize}  ${
						!isEnabled && field.disabledClasses
					}`}
				>
					{value === true ? field.truthyValue : field.falsyValue}
				</p>
			);
		} else if (Array.isArray(value) && field.isPersonaValue) {
			return (
				<div>
					{value.length === 0 ? (
						<span>—</span>
					) : (
						<ul
							className={`flex flex-wrap ${
								!isEnabled && field.disabledClasses
							}`}
						>
							{value.map((val, index) => (
								<li
									key={index}
									className={` ${textSize} ${
										!isEnabled && field.disabledClasses
									}`}
								>
									<p className="text-nowrap">
										{val.name ? val.name : val}
										{index < value.length - 1 && (
											<span className="pr-2">, </span>
										)}
									</p>
								</li>
							))}
						</ul>
					)}
				</div>
			);
		} else if (field.isBoolean) {
			return (
				<p
					className={`${textSize}  ${
						!isEnabled && field.disabledClasses
					}`}
				>
					{value === true ? field.truthyValue : field.falsyValue}
				</p>
			);
		} else if (field.isLink) {
			return (
				<a
					className={`${textSize} ${
						!isEnabled && field.disabledClasses
					}`}
					href={field.isLink && field.isLink}
				>
					{field.isLink}
				</a>
			);
		} else if (field.isGoogleMapsLink) {
			return (
				<GoogleMapsLink
					address={dataItem as Address}
					hoverClasses={field.hoverClasses as string}
				/>
			);
		} else if (field.isConcatenated && dataItem && allData) {
			const secondValue = allFields?.find((singleField) => {
				return singleField.valueKey === field.isConcatenated;
			});

			let secondValueKey = secondValue?.valueKey as keyof typeof dataItem;
			return (
				<p
					className={`${textSize} ${
						!isEnabled && field.disabledClasses
					}`}
				>
					{value + " " + allData[secondValueKey]}
				</p>
			);
		} else if (field.secondValueKey) {
			let valueTwo: any =
				field.secondValueKey
					.split(".")
					.reduce(
						(accum, currentValue) =>
							accum ? (accum as any)[currentValue] : undefined,
						allData
					) || "";

			return (
				<div className="flex flex-col">
					<p
						className={`${textSize} ${
							!isEnabled && field.disabledClasses
						}`}
					>
						{value as string | number | boolean | T}
					</p>
					<p
						className={`${textSize} ${
							!isEnabled && field.disabledClasses
						} ${field.secondValueTextClasses}`}
					>
						{`${field.secondValueLabel}: ${valueTwo}`}
					</p>
				</div>
			);
		} else if (field.textAfterValue && value !== "—") {
			  return (
					<p
						className={`${textSize} ${
							!isEnabled && field.disabledClasses
						}`}
					>
						{`${value} ${field.textAfterValue}`}
					</p>
				);
		} else {
			return (
				<p
					className={`${textSize} ${
						!isEnabled && field.disabledClasses
					}`}
				>
					{value as string | number | boolean | T}
				</p>
			);
		}
	};

	return field && !field.isEditOnly ? (
		<div
			key={field.id}
			className={`mb-1 ${containerWidth} ${field.detailGridClasses}`}
		>
			<div className={`flex w-full ${mobileBreakpoint}`}>
				{field.labelIcon === "true" && field.label && iconStyle ? (
					<div className={`pr-2 size-12`}>
						{getFontAwesomeIcon(
							field.label,
							iconStyle as "solid" | "regular" | "light"
						)}
					</div>
				) : (
					<div
						className={`${baseLabelClasses} ${
							!isEnabled
								? field.disabledClasses
								: field.labelTextClasses
						} ${field.hideLabel ? "hidden" : ""}`}
					>
						<p className={`${textSize}`}>{field.label}</p>
					</div>
				)}
				<div
					className={`${baseDetailClasses} ${field.valueTextClasses}`}
				>
					{field.hasValueLeftIcon &&
						iconStyle &&
						dataItem !== "—" && (
							<div className={`px-1 ${field.detailIconClasses}`}>
								{getFontAwesomeIcon(
									field.hasValueLeftIcon,
									iconStyle
								)}
							</div>
						)}
					{renderValue(field, dataItem)}
					{field.hasValueRightIcon && iconStyle && (
						<div className="pl-6">
							{getFontAwesomeIcon(
								field.hasValueRightIcon,
								iconStyle
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	) : null;
};

export default BaseDetailRow;
