import React from "react";
import DataPill from "./DataPill";

interface ToggleProps {
	initialStatus?: boolean;
	onClick: (value: boolean) => void;
	textPosition: "left" | "right" | "top" | "bottom" | "none";
	activeColorBackground?: string;
	inactiveColorBackground?: string;
	activeColorBorder?: string;
	inactiveColorBorder?: string;
	textSize?: string;
	additionalClasses?: string;
	hasDisabledStatus?: boolean;
	hasDivider?: boolean;
	fontFamily?: string;
	activeLabel: string;
	inactiveLabel: string;
	activeTextColor?: string;
	inactiveTextColor?: string;
	id?: string;
	smallToggle: boolean;
	pillHeight?: string;
	borderStyle: boolean;
}

const textPositionMap: { [key: string]: string } = {
	left: "flex items-center",
	right: "flex items-center flex-row-reverse",
	top: "flex flex-col items-start",
	bottom: "flex flex-col-reverse items-start",
};

const getSizeClasses = (textSize?: string) => {
	switch (textSize) {
		case "xs":
			return " text-xs ";
		case "sm":
			return " text-sm ";
		case "md":
			return " text-md ";
		case "lg":
			return " text-lg ";
		case "xl":
			return " text-xl ";
		default:
			return textSize ? ` text-${textSize}` : "";
	}
};

const getToggleClasses = (
	status: boolean,
	smallToggle: boolean,
	borderStyle: boolean,
	activeColorBorder?: string,
	inactiveColorBorder?: string,
	activeColorBackground?: string,
	inactiveColorBackground?: string
) => {
	const baseClasses = "peer rounded-full border";
	const borderToggleClasses =
		"after:absolute after:left-[4px] after:top-1 after:h-3 after:w-3 after:rounded-full after:bg-navy-300 after:transition-all after:content-['']";
	const borderToggleCircleClasses =
		"peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-supply-blue-500";
	const smallBackgroundToggleClasses =
		"after:absolute after:left-[4px] after:top-1 after:h-3 after:w-3 after:rounded-full after:bg-navy-300 after:transition-all after:content-['']";
	const largeBackgroundToggleClasses =
		"after:absolute after:left-[3px] after:top-[4.5px] after:h-[19px] after:w-[19px] after:rounded-full after:bg-white after:transition-all after:content-['']";

	// small toggle with border style
	if (smallToggle && borderStyle) {
		return `${baseClasses} h-[1.25rem] w-[2rem] ${
			status ? activeColorBorder : inactiveColorBorder
		} ${borderToggleClasses} ${status ? borderToggleCircleClasses : ""} `;

		// small toggle with background style
	} else if (smallToggle && !borderStyle) {
		return `${baseClasses} h-[1.25rem] w-[2rem] ${
			status ? activeColorBackground : inactiveColorBackground
		} ${smallBackgroundToggleClasses} ${
			status ? "peer-checked:after:translate-x-full " : ""
		}`;

		// large toggle with background style
	} else if (!smallToggle && !borderStyle) {
		return `${baseClasses} h-7 w-11 ${
			status ? activeColorBackground : inactiveColorBackground
		} ${largeBackgroundToggleClasses} ${
			status ? "peer-checked:after:translate-x-full " : ""
		}`;
	} else {
		return `${baseClasses} h-[1.25rem] w-[2rem] ${
			status ? activeColorBackground : inactiveColorBackground
		} ${smallBackgroundToggleClasses} ${
			status ? "peer-checked:after:translate-x-full " : ""
		}`;
	}
};

const ToggleButton: React.FC<ToggleProps> = ({
	initialStatus,
	onClick,
	id,
	textPosition,
	textSize,
	activeColorBackground,
	inactiveColorBackground,
	activeColorBorder,
	inactiveColorBorder,
	activeLabel,
	inactiveLabel,
	additionalClasses,
	hasDisabledStatus,
	hasDivider,
	fontFamily,
	activeTextColor,
	inactiveTextColor,
	smallToggle,
	pillHeight,
	borderStyle,
}) => {
	const handleToggle = () => {
		const newValue = !initialStatus;
		onClick(newValue);
	};

	const textColor =
		initialStatus === true ? activeTextColor : inactiveTextColor;

	let textStyleClasses = getSizeClasses(textSize);

	const textPositionClasses =
		textPositionMap[textPosition as keyof typeof textPositionMap];

	const toggleClasses = getToggleClasses(
		initialStatus!,
		smallToggle,
		borderStyle,
		activeColorBorder,
		inactiveColorBorder,
		activeColorBackground,
		inactiveColorBackground
	);

	return (
		<div
			className={`flex justify-center ${
				hasDivider && textPosition === "right"
					? "border-r-1 border-navy-200 pr-2"
					: ""
			} mr-2 ${pillHeight}`}
		>
			{hasDisabledStatus ? (
				<div className={`${pillHeight}`}>
					<DataPill
						text={initialStatus ? "Active" : "Inactive"}
						noRightIcon={true}
						shape="semi-rounded"
						backgroundColor={
							initialStatus ? "bg-mint-200" : "bg-crimson-300"
						}
						textColor={initialStatus ? "mint-700" : "crimson-700"}
						borderColor="transparent"
						additionalClasses="flex items-center text-sm px-3 h-full"
						fontFamily={fontFamily}
					/>
				</div>
			) : (
				<div
					className={`justify-between w-full ${textPositionClasses} ${textStyleClasses} ${textColor} ${additionalClasses} `}
				>
					<label
						htmlFor={id}
						className={`flex justify-center ${
							smallToggle ? " min-w-10" : "min-w-20"
						}`}
					>
						{initialStatus === true ? activeLabel : inactiveLabel}
					</label>
					<label className="relative inline-flex cursor-pointer items-center">
						<input
							id={id}
							type="checkbox"
							className="peer sr-only"
							checked={initialStatus}
							onChange={handleToggle}
						/>
						<div className={toggleClasses}></div>
					</label>
				</div>
			)}
		</div>
	);
};

export default ToggleButton;
