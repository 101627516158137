import React, { useState } from "react";
import Container from "../../components/layout/Container";
import MainHeading from "../../components/layout/MainHeading";
import Section from "../../components/layout/Section";
import { LoginForm } from ".";

const LoginPage: React.FC = () => {
	const [_, setIsLoading] = useState(false);

	const handleFormSubmit = (email: string) => {
		setIsLoading(true);
	};
	const additionalSectionClasses = "mb-12 text-center mt-20 text-4xl";

	return (
		<Container size="sm" containerClasses="container mx-auto px-4">
			<Section additionalClasses="text-center font-omnes-medium font-normal">
				<MainHeading
					text="Log In"
					additionalClasses={additionalSectionClasses}
				/>
				<LoginForm onSubmit={handleFormSubmit} />
			</Section>
		</Container>
	);
};

export default LoginPage;
