// Helper function to get serial numbers for a part number
const getSerialNumbers = (
	partNumberField: string,
	getValues: () => any,
	serialNumber?: string
) => {
	const serialNumberEntries = Object.entries(getValues()).filter(
		([key, value]) => key.startsWith(partNumberField)
	);
	const serialNumbers = serialNumberEntries.map(([key, value]) => {
		return { value };
	});

	const foundSerialNumberEntry = Object.entries(
		serialNumbers[0].value as { [key: string]: any }
	).find(([key, value]) => value === serialNumber);

	return foundSerialNumberEntry;
};

export default getSerialNumbers;
