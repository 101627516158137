import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

// COMPONENTS
import ShipmentItemsTable from "./forms/ShipmentItemsTable";
import BaseText from "../../../components/ui/BaseText";
import Section from "../../../components/layout/Section";
import BaseButton from "../../../components/ui/BaseButton";
import { LoadingMessage } from "../../../components/misc";

// TYPES, FIELDS, UTILS, HOOKS
import { getOrderDetails } from "./api/ShipmentItemsApi";
import getFontAwesomeIcon from "../../../utils/getFontAwesomeIcon";

const ShipmentItemsPage: React.FC = () => {
	const [pageTitle, setPageTitle] = useState("Shipment Items");
	const [data, setData] = useState<any[]>([]);
	const query = new URLSearchParams(useLocation().search);
	const internalId = query.get("internalId");

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const data = await getOrderDetails(""+internalId);
				setData(data?.salesOrders[0].salesOrderItems);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [internalId, navigate]);

	const shipmentsUrl = `/shipments?internalId=${internalId}`;

	////////////////////////////////////////////// STYLES ////////////////////////////////////////////
	const containerClassNames =
		"pt-24 px-60px min-h-full max-h-full font-omnes-regular mb-10";
	const sectionClassNames =
		"flex flex-col items-start bg-navy-100 border-1 border-navy-200 rounded-xl min-h-[862px]";
	const additionalSectionClassNames = "flex flex-col items-start pb-2.5 pt-0";
	const thirdSectionClassNames =
		"flex flex-col items-start justify-between w-full";
	const motionDivClasses = "flex items-center justify-center w-full";
	//////////////////////////////////////////////////////////////////////////////////////////////////

	return (
		<div className={containerClassNames}>
			<Section additionalClasses={sectionClassNames} padding="p-10">
				<Section additionalClasses={additionalSectionClassNames}>
					<BaseButton
						text="Shipments"
						color="none"
						fontColor="supply-blue-500"
						hoverFontColor="supply-blue-300"
						hoverBackground="none"
						borderColor="none"
						shape="rounded"
						icon={getFontAwesomeIcon("chevronLeft", "regular")}
						iconSize="text-xl"
						fontFamily="omnes-regular"
						iconPadding="pr-2"
						as="link"
						to={shipmentsUrl}
					/>
					<BaseText
						text={pageTitle}
						additionalClasses="py-4"
						size="text-32px"
						color="text-navy-500"
						tag="h1"
						fontFamily="font-omnes-medium"
					/>
					<BaseText
						text="Choose items to include in the shipment."
						additionalClasses=""
						size="text-base"
						color="text-navy-500"
						tag="h1"
						fontFamily="font-omnes-regular"
					/>
				</Section>
				<Section
					additionalClasses={thirdSectionClassNames}
					padding="p-0"
				>
					<AnimatePresence>
						{isLoading ? (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{
									duration: 0.2,
									ease: "easeInOut",
								}}
								className={motionDivClasses}
							>
								<div className="h-full">
									<LoadingMessage message={""} />
								</div>
							</motion.div>
						) : (
							data?.length > 0 && (
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.2 }}
									className={motionDivClasses}
								>
									<ShipmentItemsTable data={data} />
								</motion.div>
							)
						)}
					</AnimatePresence>
				</Section>
			</Section>
		</div>
	);
};

export default ShipmentItemsPage;
