import React from "react";

const convertStatus = (status: string, hostName: string) => {
	let color = "#000"; // Default color
	const lowerStatus = status.toLowerCase();
	let convertedStatus;

	if (hostName === "HPFS") {
		switch (lowerStatus) {
			case "returned":
				convertedStatus = "Returned";
				break;
			case "lease return":
				convertedStatus = "Lease Return";
				break;
			case "in transit to hpfs":
				convertedStatus = "In Transit to HPFS";
				break;
			case "pending receipt":
				convertedStatus = "Pending Receipt";
				break;
			case "pending return":
				convertedStatus = "Pending Return";
				break;
			case "pending mary feedback":
				convertedStatus = "Pending Mary Feedback";
				break;
			case "pending recycle":
				convertedStatus = "Pending Recycle";
				break;
			case "recycled":
				convertedStatus = "Recycled";
				break;
			case "quarantine":
				convertedStatus = "Quarantine";
				break;
			case "recycle":
				convertedStatus = "Recycle";
				break;
			default:
				convertedStatus = "Unknown";
				break;
		}
	} else if (hostName === "PRUDENTIAL") {
		switch (status) {
			case "pod":
				convertedStatus = "POD";
				break;
			case "open":
				convertedStatus = "Open";
				break;
			case "configuration":
				convertedStatus = "Configuration";
				break;
			case "ready to ship":
				convertedStatus = "Ready to ship";
				break;
			case "cancelled":
				convertedStatus = "Cancelled";
				break;
			case "rejected":
				convertedStatus = "Rejected";
				break;
			case "received":
				convertedStatus = "Received";
				break;
			default:
				convertedStatus = "Unknown";
		}
	} else {
		switch (status.toLowerCase()) {
			case "billed":
				convertedStatus = "Billed";
				color = "#4CAF50";
				break;
			case "fulfilled":
				convertedStatus = "Fulfilled";
				color = "#388E3C";
				break;
			case "pendingapproval":
				convertedStatus = "Pending Approval";
				color = "#FF8F00";
				break;
			case "pendinginitialapproval":
				convertedStatus = "Pending Initial Approval";
				color = "#FF8F00";
				break;
			case "pending billing/partially fulfilled || pendingBillingPartiallyFulfilled":
				convertedStatus = "Pending Billing/Partially Fulfilled";
				color = "#9C27B0";
				break;
			case "pendingBillingPartiallyFulfilled":
				convertedStatus = "Pending Billing/Partially Fulfilled";
				color = "#00FFFF";
				break;
			case "pendingbillingpartiallyfulfilled":
				convertedStatus = "Pending Billing/Partially Fulfilled";
				color = "#00FFFF";
				break;
			case "pending fulfillment":
				convertedStatus = "Pending Fulfillment";
				color = "#1E88E5";
				break;
			case "pendingfulfillment":
				convertedStatus = "Pending Fulfillment";
				color = "#1E88E5";
				break;
			case "partially fulfilled":
				convertedStatus = "Partially Fulfilled";
				color = "#FDD835";
				break;
			case "partiallyfulfilled":
				convertedStatus = "Partially Fulfilled";
				color = "#FDD835";
				break;
			case "pendingbilling":
				convertedStatus = "Pending Billing";
				color = "#9C27B0";
				break;
			case "pending billing":
				convertedStatus = "Pending Billing";
				color = "#9C27B0";
				break;
			case "closed":
				convertedStatus = "Closed";
				color = "#9E9E9E";
				break;
			case "canceled":
				convertedStatus = "Canceled";
				color = "#EF5350";
				break;
			case "vendor":
				convertedStatus = "Vendor";
				color = "#4CAF50";
				break;
			case "location":
				convertedStatus = "Location";
				color = "#4CAF50";
				break;
			case "paid":
				convertedStatus = "Paid";
				color = "#4CAF50";
				break;
			case "open":
				convertedStatus = "Open";
				color = "#4CAF50";
				break;
			case "n/a":
				convertedStatus = "N/A";
				color = "#ff0000";
				break;
			case "true":
				convertedStatus = "True";
				color = "#ff0000";
				break;

			case "false":
				convertedStatus = "False";
				color = "#ff0000";
				break;
			default:
				convertedStatus = "Unknown";
				color = "#A7A9AF";
		}
	}
	return { convertedStatus, color };
};

type Status =
	| "billed"
	| "pending_billing"
	| "closed"
	| "pending_billing_partially_fulfilled"
	| "pending_fulfillment"
	| "partially_fulfilled"
	| "vendor"
	| "location"
	| "pod"
	| "open"
	| "received"
	| "In Transit to HPFS"
	| "Pending Receipt"
	| "configuration"
	| "rejected"
	| "cancelled"
	| "ready to ship"
	| "returned"
	| "in progress"
	| "Lease Return"
	| "Quarantine"
	| "Recycle"
	| "unknown";

type StatusOrString = Status | string;

interface Props {
	borderColor?: string;
	status: StatusOrString;
	additionalClasses?: string;
	typeValues?: Record<string, any>;
	useConvertedStatus?: boolean;
	fontFamily?: string;
	useCustomColor?: string;
}

const StatusColorDot: React.FC<Props> = ({
	borderColor = "transparent",
	status,
	additionalClasses,
	typeValues,
	useConvertedStatus,
	fontFamily,
	useCustomColor,
}) => {
	let color = "#000";
	let displayStatus = "";
	let convertedStatus = ""; // Define convertedStatus here

	const hostName = localStorage.getItem("hostName") || "";
	// Use typeValues directly instead of statusMapping
	const statusObj = typeValues?.find(
		(s: Record<string, any>) =>
			s.slug.toLowerCase() === status.toLowerCase() ||
			s.name.toLowerCase() === status.toLowerCase()
	);
	// Set the color based on the statusObj
	if (statusObj) {
		color = `#${statusObj.colorHex}`; // Ensure to add '#' if it's not included in the colorHex
	} else {
		color = "#f00"; // Use the desired color for "Unknown" status
	}
	const dotStyle = {
		backgroundColor: color,
		borderRadius: "9999px",
		borderColor: borderColor,
		borderWidth: "1px",
		padding: "3px",
	};

	let convertedStatusDotStyle = {};

	if (useConvertedStatus) {
		const result = convertStatus(status, hostName);
		convertedStatus = result.convertedStatus;
		const color = result.color;
		convertedStatusDotStyle = {
			backgroundColor: color,
			borderRadius: "9999px",
			borderColor: borderColor,
			borderWidth: "1px",
			padding: "3px",
		};
	}

	return hostName === "BANKOFAMERICA" ? (
		<div className="flex items-center">
			<div className="pr-1.5">
				<span style={dotStyle} className={additionalClasses}></span>
			</div>
			{displayStatus}
		</div>
	) : useConvertedStatus ? (
		<div className={`flex items-center`}>
			<div className="pr-1.5">
				<span
					style={convertedStatusDotStyle}
					className={additionalClasses}
				></span>
			</div>
			<p className={`${fontFamily}`}>{convertedStatus}</p>
		</div>
	) : useCustomColor ? (
		<span
			style={{
				backgroundColor: useCustomColor,
				borderRadius: "9999px",
				borderColor: borderColor,
				borderWidth: "1px",
				padding: "3px",
			}}
			className={additionalClasses}
		></span>
	) : (
		<span style={dotStyle} className={additionalClasses}></span>
	);
};

export default StatusColorDot;