import { set } from "react-hook-form";
import { getFontAwesomeIcon } from "../../../utils";
import GenericList from "../../layout/GenericList";
import { SupplyPortal } from "../../misc";

interface ListItem {
	id: string;
	content: string | React.ReactNode;
	icon: string;
	color: string;
	weight: "solid" | "regular" | "light";
	setActionModal: () => void;
}

interface ActionListProps {
	listData: ListItem[];
	containerId: string;
	activeRowUuid: string;
	setQuickMenuVisible: (visible: string | null) => void;
}

const ActionList: React.FC<ActionListProps> = ({
	listData,
	containerId,
	setQuickMenuVisible,
}) => {
	const renderItem = (item: ListItem, index?: number) => (
		<div
			className={`hover:cursor hover:bg-supply-blue-50 flex items-center ${
				index !== undefined && index > 0
					? "border-t border-gray-200"
					: ""
			}`}
			onClick={(e) => {
				e.stopPropagation();
				setQuickMenuVisible(null);
				item.setActionModal();
			}}
		>
			<span
				className={`w-6 text-${item.color} flex-shrink-0 text-center`}
			>
				{getFontAwesomeIcon(item.icon, item.weight)}
			</span>
			<span className="ml-2 text-navy-500 font-omnes-regular">
				{item.content}
			</span>
		</div>
	);

	return (
		<>
			{/* Modal overlay to close the action menu by clicking outside */}
			<SupplyPortal containerId={containerId}>
				<div className="opacity-0 size-full absolute z-0 top-0"></div>
			</SupplyPortal>

			<div className="relative z-10">
				<GenericList<ListItem>
					containerClasses="bg-white w-40 border-1 border-navy-200 rounded-lg z-50 text-sm"
					data={listData}
					renderItem={renderItem}
					listType="unordered"
				/>
			</div>
		</>
	);
};

export default ActionList;
