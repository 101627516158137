type OpenModalButtonProps = {
	onClick?: () => void;
	text?: string;
	isActive: boolean;
	activeButtonClasses?: string;
	inactiveTextClasses?: string;
};

const OpenModalButton = ({
	onClick,
	text,
	isActive,
	activeButtonClasses,
	inactiveTextClasses = "text-navy-250 w-full flex items-center justify-center text-sm",
}: OpenModalButtonProps) => {
	if (isActive) {
		return (
			<button className={activeButtonClasses} onClick={onClick}>
				{text}
			</button>
		);
	} else {
		return <p className={inactiveTextClasses}>{text}</p>;
	}
};

export default OpenModalButton;
