import React, { useEffect, useRef, useState } from "react";
import SortArrowIcon from "../../layout/SortArrowIcon";
import { ColumnInstance } from "react-table";
import { useItemsContext } from "../../../hooks/dataTableHooks/useItemsContext";
import useClickOutsideOfElement from "../../../hooks/useClickOutsideOfElement";
import { motion } from "framer-motion";
import SupplyPortal from "../../misc/SupplyPortal";
import { useUrl } from "../../../hooks";
import { getFontAwesomeIcon } from "../../../utils";
import { arrowContainerFadeInOutVariants } from "../../../motionVariants";
//this portal overlay creates a transparent overlay that covers the entire screen when the user is editing a header.  This will make sure the input is always closed before the user can interact with the rest of the page
const PortalOverlay: React.FC = () => {
	return (
		<SupplyPortal containerId="portal-root">
			<div className="bg-transparent w-full h-full absolute top-0"></div>
		</SupplyPortal>
	);
};

interface SortArrowsProps<T extends object> {
	column: ColumnInstance<T>;
	setSortColumn: React.Dispatch<
		React.SetStateAction<{ [key: string]: string }>
	>;
	slug: string;
	isNested?: boolean;
	parentIndex?: number;
	onSortChange?: (column: string, direction: string) => void;
}

const SortArrows = <T extends object>({
	column,
	setSortColumn,
	slug,
	isNested,
	parentIndex,
	onSortChange,
}: SortArrowsProps<T>): JSX.Element | null => {
	const containerRef = useRef<HTMLDivElement>(null);
	useClickOutsideOfElement(containerRef, () => setShowArrowContainer(false));

	const { activeSortColumn, setActiveSortColumn } = useItemsContext();
	const [activeColumn, setActiveColumn] = useState<string>("");
	const [activeDirection, setActiveDirection] = useState<string>("");
	const [showArrowContainer, setShowArrowContainer] =
		useState<boolean>(false);
	const { setParentIndex } = useUrl();

	useEffect(() => {
		let activeInfo = null;
		const storedSortInfo = localStorage.getItem("activeHeader");

		if (storedSortInfo) {
			const sortInfo = JSON.parse(storedSortInfo);
			activeInfo = sortInfo;
		} else {
			const query = new URLSearchParams(window.location.search);

			const sortValue = isNested
				? query.get(`${slug}_sort[0][0]`)
				: query.get(`${slug}_sort[0]`);
			if (sortValue) {
				activeInfo = {
					columnId: sortValue.substring(1),
					direction: sortValue[0] === "-" ? "down" : "up",
				};
			}
		}

		if (activeInfo && activeInfo.columnId === column.id) {
			setActiveColumn(activeInfo.columnId);
			setActiveDirection(activeInfo.direction);
		}
	}, [column.id, isNested, slug]);

	const updateSortState = (direction: "up" | "down") => {
		const newDirection = direction === "up" ? "asc" : "desc";
		setActiveColumn(column.id);
		setActiveDirection(direction);
		localStorage.setItem(
			"activeHeader",
			JSON.stringify({ columnId: column.id, direction: newDirection })
		);
		onSortChange?.(column.id, newDirection);
	};

	const handleParentClick = () => {
		const shouldShow =
			activeSortColumn !== column.id || !showArrowContainer;
		setShowArrowContainer(shouldShow); // Show or hide the current arrow container
		setActiveSortColumn(shouldShow ? column.id : null); // Update the active sort column or reset
	};

	const isActiveUp = activeColumn === column.id && activeDirection === "asc";
	const isActiveDown =
		activeColumn === column.id && activeDirection === "desc";

	const arrowClasses = `min-w-[100%] flex justify-center hover:bg-navy-50`;

	const arrowContainerClassNames = `hover:bg-navy-50 size-[18px] rounded-sm flex flex-col items-center justify-center cursor-pointer relative`;

	const handleClick = (direction: "up" | "down") => {
		const directionValue = direction === "up" ? "+" : "-";
		setSortColumn({ [slug]: directionValue });
		setParentIndex && setParentIndex(parentIndex || 0);

		updateSortState(direction);
	};

	return (
		<div
			ref={containerRef}
			className={arrowContainerClassNames}
			onClick={handleParentClick}
		>
			{isActiveUp ? (
				<div className="text-supply-blue-400">
					{getFontAwesomeIcon("arrowUp", "regular")}
				</div>
			) : (
				!isActiveDown && (
					<SortArrowIcon
						slug={slug}
						parentIndex={parentIndex}
						columnName={String(column.render("Header"))}
						direction="up"
						setColumn={() => {}}
						isActive={isActiveUp}
						onClick={() => updateSortState("up")}
					/>
				)
			)}

			{isActiveDown ? (
				<div className="text-supply-blue-400">
					{getFontAwesomeIcon("arrowDown", "regular")}
				</div>
			) : (
				!isActiveUp && (
					<SortArrowIcon
						slug={slug}
						parentIndex={parentIndex}
						columnName={String(column.render("Header"))}
						direction="down"
						setColumn={() => {}}
						isActive={isActiveDown}
						onClick={() => updateSortState("down")}
					/>
				)
			)}

			{showArrowContainer && activeSortColumn === column.id && (
				<motion.div
					className={`bg-white rounded flex flex-col border-1 absolute top-8 w-32 z-10 ${
						column.id === "col1" ? "left-[5px]" : ""
					}`}
					variants={arrowContainerFadeInOutVariants}
					initial="initial"
					animate="animate"
					exit="exit"
				>
					<div
						className="flex border-b-1 p-1 hover:bg-blue-50"
						onClick={() => handleClick("up")}
					>
						<span className="pl-1 text-primary ">
							{getFontAwesomeIcon("arrowUp", "regular")}
						</span>
						<span className=" flex-1 text-left pl-4">
							Ascending
						</span>
					</div>
					<div
						className="flex p-1 hover:bg-blue-50"
						onClick={() => handleClick("down")}
					>
						<span className="pl-1 text-primary ">
							{getFontAwesomeIcon("arrowDown", "regular")}
						</span>
						<span className="flex-1 text-left pl-4">
							Descending
						</span>
					</div>
					<PortalOverlay />
				</motion.div>
			)}
		</div>
	);
};

export default SortArrows;
