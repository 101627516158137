import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface EllipsisIconProps {
	additionalClasses?: string;
}

const EllipsisIcon: React.FC<EllipsisIconProps> = ({ additionalClasses }) => {
	return (
		<span className={`${additionalClasses}`}>
			<FontAwesomeIcon icon={faEllipsisVertical} className="w-4 h-4" />
		</span>
	);
};

export default EllipsisIcon;
