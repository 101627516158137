import React, { createContext, useContext, useState, useCallback } from "react";
import useConstructUrl from "./useConstructUrl";

type UseConstructUrlOptions = {
	slug?: string;
	submittedSearchText?: string;
	pageSize?: number;
	sortColumn?: Record<string, string>;
	searchColumn?: any;
	columnMappings?: any;
	page?: number;
	isNested?: boolean;
	childIndex?: number;
	childSlug?: string;
	setChildSlug?: React.Dispatch<React.SetStateAction<string | undefined>>;
	parentIndex?: number | null;
	setParentIndex?: React.Dispatch<React.SetStateAction<number | null>>;
	columnHeader?: string;
	searchCriteria: any;
};

type URLContextType = {
	url: string;
	parentIndex: number | null;
	setParentIndex: React.Dispatch<React.SetStateAction<number | null>>;
	constructAndUpdateUrl: (options: UseConstructUrlOptions) => void;
	updatedSearchText?: string;
	setUpdatedSearchText?: React.Dispatch<React.SetStateAction<string>>;
	searchColumnHeader?: string;
	setSearchColumnHeader?: React.Dispatch<React.SetStateAction<string>>;
	recordsInChildTable?: number;
	setRecordsInChildTable?: React.Dispatch<
		React.SetStateAction<number | undefined>
	>;
	previousUrl?: string | null;
	setPreviousUrl?: React.Dispatch<
		React.SetStateAction<string | null | undefined>
	>;
	previousItemNumber?: string;
	setPreviousItemNumber?: React.Dispatch<React.SetStateAction<string>>;
	previousItemUuid?: string;
	setPreviousItemUuid?: React.Dispatch<React.SetStateAction<string>>;
	previousActiveIndex?: string;
	setPreviousActiveIndex?: React.Dispatch<React.SetStateAction<string>>;
	previousHeightAndTop?: { height: number; top: number };
	setPreviousHeightAndTop?: React.Dispatch<
		React.SetStateAction<{ height: number; top: number } | undefined>
	>;
};
type RequiredUseConstructUrlOptions = Required<UseConstructUrlOptions>;

const URLContext = createContext<URLContextType | undefined>(undefined);

const useUrl = (
	initialPreviousUrl: string | null | undefined = null,
	initialPreviousItemNumber: string = ""
) => {
	const context = useContext(URLContext);
	if (!context) {
		throw new Error("useURL must be used within a URLProvider");
	}
	let updatedContext = context;
	if (initialPreviousUrl !== null && initialPreviousUrl !== undefined) {
		updatedContext = { ...updatedContext, previousUrl: initialPreviousUrl };
	}
	if (initialPreviousItemNumber !== "") {
		updatedContext = {
			...updatedContext,
			previousItemNumber: initialPreviousItemNumber,
		};
	}
	return updatedContext;
};

export const URLProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [url, setUrl] = useState<string>("");
	const [parentIndex, setParentIndex] = useState<number | null>(null);
	const [updatedSearchText, setUpdatedSearchText] = useState<string>("");
	const [searchColumnHeader, setSearchColumnHeader] = useState<string>("");
	const [recordsInChildTable, setRecordsInChildTable] = useState<
		number | undefined
	>(undefined);
	const [previousUrl, setPreviousUrl] = useState<string | null | undefined>(
		null
	);
	const [previousItemUuid, setPreviousItemUuid] = useState<string>("");
	const [previousActiveIndex, setPreviousActiveIndex] = useState<string>("");
	const [previousHeightAndTop, setPreviousHeightAndTop] = useState<
		| {
				height: number;
				top: number;
		  }
		| undefined
	>({ height: 0, top: 0 });

	const constructAndUpdateUrl = useCallback(
		(options: UseConstructUrlOptions) => {
			// We define the properties that are expected by useConstructUrl.
			// Since setChildSlug and setParentIndex are not used by useConstructUrl, we exclude them.
			const completeOptions: Omit<
				RequiredUseConstructUrlOptions,
				"setChildSlug" | "setParentIndex"
			> = {
				slug: options.slug ?? "default-slug",
				submittedSearchText: options.submittedSearchText ?? "",
				pageSize: options.pageSize ?? 5,
				sortColumn: options.sortColumn ?? {},
				searchColumn: options.searchColumn ?? {},
				columnMappings: options.columnMappings ?? (() => ""),
				page: options.page ?? 1,
				isNested: options.isNested ?? false,
				childIndex: options.childIndex ?? 0,
				childSlug: options.childSlug ?? "",
				parentIndex: options.parentIndex ?? null,
				columnHeader: options.columnHeader ?? "",
				searchCriteria: "",
			};
			const newUrl = useConstructUrl(completeOptions);
			setUrl(newUrl);
		},
		[]
	);

	return (
		<URLContext.Provider
			value={{
				url,
				parentIndex,
				setParentIndex,
				constructAndUpdateUrl,
				updatedSearchText,
				setUpdatedSearchText,
				searchColumnHeader,
				setSearchColumnHeader,
				recordsInChildTable,
				setRecordsInChildTable,
				previousUrl,
				setPreviousUrl,
				previousItemUuid,
				setPreviousItemUuid,
				previousActiveIndex,
				setPreviousActiveIndex,
				previousHeightAndTop,
				setPreviousHeightAndTop,
			}}
		>
			{children}
		</URLContext.Provider>
	);
};

export default useUrl;
