import BaseText from "../../../../../components/ui/BaseText";
import { getFontAwesomeIcon } from "../../../../../utils";
import { SingleItemProps } from "./types";

const Item: React.FC<SingleItemProps> = ({ item }) => {
	const renderImageOrIcon = (image?: string) => {
		if (image) {
			return (
				<img
					src={image}
					alt="Item"
					className="w-full h-full object-cover rounded-md"
				/>
			);
		} else {
			return (
				<div className="text-3xl text-gray-700">
					{getFontAwesomeIcon("box", "light")}
				</div>
			);
		}
	};
	return (
		<div className="flex py-3 px-2">
			<div
				className={`mr-6 p-3 rounded-lg flex items-center justify-center h-full w-16 `}
			>
				{renderImageOrIcon("")}
			</div>
			<div className="flex flex-col text-left">
				<BaseText
					text={item.Items.description}
					size="text-base"
					color="text-gray-700"
					fontFamily="font-inter-semibold font-semibold"
					tag="h3"
					additionalClasses="mb-1"
				/>
				<BaseText
					text={item.Items.partNumber}
					size="text-sm"
					color="text-primary-text"
					fontFamily="font-inter-regular"
					tag="p"
					additionalClasses="mb-1"
				/>
				<div className="flex flex-row gap-2">
					<BaseText
						text={`$${item.price}`}
						size="text-sm"
						color="text-gray-700"
						fontFamily="font-inter-regular"
						tag="p"
					/>
					<BaseText
						text={`Qty: ${parseInt(item.quantity, 10)}`}
						size="text-sm"
						color="text-gray-700"
						fontFamily="font-inter-medium"
						tag="p"
					/>
				</div>
			</div>
		</div>
	);
};

export default Item;
