import { FC } from "react";

interface ImageComponentProps {
	imageUrl: string;
	altText: string;
	className?: string;
	width?: number;
	height?: number;
	additionalClasses?: string;
}

const ImageComponent: FC<ImageComponentProps> = ({
	imageUrl,
	altText,
	className,
	width,
	height,
}) => {
	return (
		<img
			src={imageUrl}
			alt={altText}
			className={className}
			width={width}
			height={height}
		/>
	);
};

export default ImageComponent;
