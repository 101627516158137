import React from "react";

interface BorderGradientContainerTypes {
	children: React.ReactNode;
	containerClasses?: string;
	containerShape?: "rounded-full" | "semi-rounded" | "none";
	startBorderColor?: string;
	endBorderColor?: string;
	containerWidth?: string;
	containerHeight?: string;
	additionalClasses?: string;
}

const BorderGradientContainer: React.FC<BorderGradientContainerTypes> = ({
	children,
	containerClasses,
	containerShape,
	startBorderColor,
	endBorderColor,
	containerWidth,
	containerHeight,
	additionalClasses,
}) => {
	let containerShapeClasses;

	if (containerShape === "rounded-full") {
		containerShapeClasses = "rounded-3xl";
	} else if (containerShape === "semi-rounded") {
		containerShapeClasses = "rounded-xl";
	} else {
		containerShapeClasses = "rounded-none";
	}

	return (
		<div
			className={`${containerClasses} ${containerShapeClasses} p-[3px] bg-gradient-to-r ${startBorderColor} ${endBorderColor} ${containerWidth} ${containerHeight} ${additionalClasses}`}
		>
			{children}
		</div>
	);
};

export default BorderGradientContainer;
