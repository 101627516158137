const convertConstructorColumnTitles = (
	pageMeta: any,
	dataTableValueFields: any[]
) => {
	return dataTableValueFields.map((field) => {
		const table = field.settings.table;
		const fieldName = field.settings.field;
		// Retrieve the label-singular from pageMeta
		const labelSingular =
			pageMeta.settings.fields[table]?.[fieldName]?.["label-singular"] ||
			"";
		return {
			...field,
			labelSingular,
		};
	});
};

export default convertConstructorColumnTitles;
